import { InviteUserType } from '../type/InviteUser.type';
import { ClientUserTypeEnum } from '../enum/ClientUserType.enum';

export const inviteUserInitialize: InviteUserType =  {
    input: {
        email: '',
        type: ClientUserTypeEnum.Admin,
        emailReceive: true,
        stepSecondFlag: false,
    },
    result: {
        flag: false,
        reason: '',
    }
}
