import { Action } from 'redux';
import { MyPageActionTypes } from './index';
import { SchoolDetailData } from '../../../reducers/domain/schools/type/SchoolDetailData.type';


export const getMySchools = (authToken: string, limit: number, page: number): GetMySchools => ({
    type: MyPageActionTypes.GET_MY_SCHOOLS,
    payload: { authToken, limit, page }
});

export interface GetMySchools extends Action {
    type: string;
    payload: {
        authToken: string,
        limit: number,
        page: number
    };
}

export const setMySchools = (schools: Array<SchoolDetailData>): SetMySchools => ({
    type: MyPageActionTypes.SET_MY_SCHOOLS,
    payload: { schools }
});

export interface SetMySchools extends Action {
    type: string;
    payload: { schools: Array<SchoolDetailData> };
}

export const setMySchoolsCount = (schoolsCount: number): SetMySchoolsCount => ({
    type: MyPageActionTypes.SET_MY_SCHOOLS_COUNT,
    payload: { schoolsCount }
});

export interface SetMySchoolsCount extends Action {
    type: string;
    payload: { schoolsCount: number };
}


export const setMySchoolsCurrentPage = (currentPage: number): SetMySchoolsCurrentPage => ({
    type: MyPageActionTypes.SET_MY_SCHOOLS_CURRENT_PAGE,
    payload: { currentPage }
});

export interface SetMySchoolsCurrentPage extends Action {
    type: string;
    payload: { currentPage: number };
}
