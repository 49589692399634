import { CreateSchoolDataType } from '../../../../../util/type/CreateSchoolData.type';
import { SchoolDetail } from '../../../../../util/type/SchoolDetail.type';
import { DateRequestType } from '../../../../../util/type/DateRequest.type';
import { SchoolNearestSpotType } from '../../../../../util/type/SchoolNearestSpot.type';

export const schoolDataToCreateSchoolData = (schoolData: SchoolDetail): CreateSchoolDataType => ({
    name: schoolData.name,
    nameKana: schoolData.nameKana,
    copy: schoolData.copy,
    addressDetail: schoolData.addressDetail,
    nearestStationText: schoolData.nearestStationText,
    costAverageFrom: schoolData.costAverageFrom,
    costAverageTo: schoolData.costAverageTo,
    description: schoolData.description,
    homepageUrl: schoolData.homepageUrl,
    twitterUrl: schoolData.twitterUrl,
    instagramUrl: schoolData.instagramUrl,
    facebookUrl: schoolData.facebookUrl,
    lineUrl: schoolData.lineUrl,
    establishmentDate: schoolData.establishmentDate,
    questions: schoolData.questions,
    clientId: schoolData.clientId,
    prefectureId: schoolData.prefecture ? schoolData.prefecture.id : 0,
    townId: schoolData.town ? schoolData.town.id : 0,
    streetId: Number(schoolData.streetId),
    note: schoolData.note,
    scheduleWeekSchedule: schoolData.scheduleWeekSchedule
        .map((d, index) => {
            if (d !== null) {
                return {
                    day: index,
                    from: d.from,
                    to: d.to,
                } as DateRequestType;
            }
            return false;
        })
        .filter((d) => !!d) as Array<DateRequestType>,
    nearestStation: schoolData.nearestStation
        ? ({
            id: schoolData.nearestStation.id,
            onFoot: schoolData.nearestStationOnFoot,
            label: schoolData.nearestStation.nameJp,
        } as SchoolNearestSpotType)
        : null,
    nearestBusstop: schoolData.nearestBusstop
        ? ({
            id: schoolData.nearestBusstopId,
            onFoot: schoolData.nearestBusstopOnFoot,
            label: schoolData.nearestBusstop.nameJp,
        } as SchoolNearestSpotType)
        : null,
    categoryId: schoolData.schoolCategoryId,
    keywordIds: schoolData.schoolKeywords.map((d) => d.id),
    tagIds: schoolData.schoolTags.map((d) => d.id),
    stationIds: [],
    busstopIds: [],
    classStyleIds: schoolData.classStyles.map((d) => d.id),
    targetAgeIds: schoolData.schoolTargetAge.map((d) => d.id),
    withoutAddress: schoolData.withoutAddress,
    deleteImageIds: [],
    withTargetUniversity: schoolData.withTargetUniversity,
    withTargetWorking: schoolData.withTargetWorking,
});
