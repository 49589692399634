import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/ja';
import moment from 'moment';
import { ClientUserType } from '../../../util/type/ClientUser.type';
import { ClientUserTypeEnum } from '../../../redux/reducers/domain/user/enum/ClientUserType.enum';
import styles from './css/style.module.scss';
import { RootState } from '../../../redux/store';
import {
    deleteClientUser,
    patchClientUserEmailInquiry,
    updateClientUser
} from '../../../redux/actions/domain/user/clientUser';
import { setCommonModalContentType, setCommonModalShow, setCommonModalTitle } from '../../../redux/actions/ui';
import { CommonModalContentTypeEnum } from '../../../redux/reducers/ui/enum/CommonModalContentType.enum';
import { setChangeClientUserEmailOfferResult } from '../../../redux/actions/domain/user/changeEmail';

type UsersListRowProps = {
    user: ClientUserType,
    myDataFlag: boolean,
    editableFlag: boolean,
    deletableFlag: boolean,
    index: number
}

export const UsersListRow: React.FC<UsersListRowProps> = (props) => {
    const { user, myDataFlag, editableFlag, deletableFlag, index } = props;
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const dispatch = useDispatch();

    return <li className={`${styles.row} ${index % 2 !== 0 ? styles.evenBg : ''}`}>
        <div className={`${styles.listTitleUser} ${styles.noBorder}`}>
            <div>{user.email}{myDataFlag && <>(ログイン中)</>}</div>
            {myDataFlag && <button type='button' onClick={() => {
                dispatch(setCommonModalShow(true))
                dispatch(setCommonModalTitle('メールアドレスの変更'))
                dispatch(setCommonModalContentType(CommonModalContentTypeEnum.CHANGE_MAIL_ADDRESS))
                dispatch(setChangeClientUserEmailOfferResult(
                    false,
                    '',
                ));
            }}>編集</button>}</div>

        <div className={styles.listTitleLastLogin}>{moment(user.loginDate).format('YYYY/MM/DD')}</div>
        <div className={styles.listTitleRole}>
            {editableFlag ?
                (<div className={styles.selectBoxWrap}>
                    <select className={styles.selectBox} value={user.type} onChange={(e) => {
                        const type = e.target.value === '2' ? ClientUserTypeEnum.Admin : ClientUserTypeEnum.User;
                        dispatch(updateClientUser(authToken, user.uuid, type));
                    }}>
                        <option value="2">管理者</option>
                        <option value="3">一般</option>
                    </select>
                </div>) : (<>
                    {user.type === ClientUserTypeEnum.Owner && (<>代表者</>)}
                    {user.type === ClientUserTypeEnum.Admin && (<>管理者</>)}
                    {user.type === ClientUserTypeEnum.User && (<>一般</>)}
                </>)}
        </div>
        <div className={styles.listTitleAddDay}>{moment(user.createdAt).format('YYYY/MM/DD')}</div>
        <div className={styles.listTitleMail}>
            <input type='radio' name={`user_list_acceptInquiry_${user.uuid}`} id={`user_list_acceptInquiry_on_${user.uuid}`} value={1} defaultChecked={user.acceptInquiry}
                onChange={() => dispatch(patchClientUserEmailInquiry(authToken, user.uuid, true))}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`user_list_acceptInquiry_on_${user.uuid}`}>ON</label>
            <input type='radio' name={`user_list_acceptInquiry_${user.uuid}`} id={`user_list_acceptInquiry_off_${user.uuid}`} value={2} defaultChecked={!user.acceptInquiry}
                onChange={() => dispatch(patchClientUserEmailInquiry(authToken, user.uuid, false))}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`user_list_acceptInquiry_off_${user.uuid}`}>OFF</label>
        </div>
        <div className={styles.listTitleAction}>
            {deletableFlag && (<button type='button' onClick={() => {
                // eslint-disable-next-line no-alert
                if (window.confirm("削除しますか?")) {
                    dispatch(deleteClientUser(authToken, user.uuid));
                }
            }}>削除</button>)}
        </div>
    </li>;
};