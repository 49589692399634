import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './css/styles.module.scss';
import helpIconImage from './img/help_icon.svg';
import searchIconImage from './img/search.svg';
import { useKeywordPicker } from './hooks/useKeywordPicker';
import { SchoolsApi } from '../../../util/api/schools';
import {
    setCommonModalContentType,
    setCommonModalShow,
    setCommonModalTitle,
    setDisplayTextModalText
} from '../../../redux/actions/ui';
import { CommonModalContentTypeEnum } from '../../../redux/reducers/ui/enum/CommonModalContentType.enum';
import { keywordDescriptionText } from '../../Common/CommonModalContent/DisplayText/const/keywordDescriptionText';

type KeywordPickerProps = {
    returnFunc: (value: Array<{ value: number, label: string }>) => void,
    defaultValues: number[],
}

export const KeywordPicker: React.FC<KeywordPickerProps> = (props: KeywordPickerProps) => {
    const { returnFunc, defaultValues } = props;
    const dispatch = useDispatch();

    const [state, callback] = useKeywordPicker();

    useEffect(() => {
        returnFunc([...state.selectData]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selectData]);

    useEffect(() => {
        (async () => {
            if (defaultValues.length > 0) {
                callback.setSelectData([]);
                const queryVal = defaultValues.map(d => d.toString());
                try {
                    const res = await SchoolsApi.getKeywordStrFromIds(queryVal);
                    if (res.data) {
                        res.data.forEach(d => {
                            state.selectData.push({
                                value: d.id,
                                label: d.text
                            });
                        });
                        callback.setSelectData([...state.selectData]);
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className={styles.wrap}>
        <div className={styles.label}>
            関連キーワードの選択
            <button className={styles.label__Button} onClick={() => {
                dispatch(setCommonModalShow(true))
                dispatch(setCommonModalTitle('関連キーワードとは？'))
                dispatch(setCommonModalContentType(CommonModalContentTypeEnum.DISPLAY_TEXT))
                dispatch(setDisplayTextModalText(keywordDescriptionText));
            }} type='button'><img src={helpIconImage} alt='関連キーワードとは？' />関連キーワードとは？</button>
        </div>

        <div className={styles.selected}>
            {state.selectData.length === 0 ? (
                <div className={styles.noSelected}>下部からキーワードを選択してください（最大３個）</div>
            ) : (
                <ul className={styles.selectedList}>
                    {state.selectData.map(d => (<li key={`selected_${d.value}`}>
                        <button type='button' onClick={() => {
                            const target = state.selectData.findIndex(data => data.value === d.value);
                            if (target > -1) {
                                state.selectData.splice(target, 1);
                                callback.setSelectData([...state.selectData]);
                            }
                        }}>
                            {d.label} ×
                        </button>
                    </li>))}
                </ul>
            )}
        </div>

        <div className={styles.pickerWrap}>
            <div className={styles.textInput}>
                <img src={searchIconImage} alt='search' />
                <input
                    type='text'
                    value={state.searchText}
                    onChange={async (event) => {
                        callback.setSearchText(event.target.value);
                        await callback.getKeywordSuggest(event.target.value);
                    }}
                    placeholder='追加したいキーワードを検索'
                />
            </div>

            <ul className={styles.picker}>
                {state.keywords.map(d => <li key={`suggest_${d.id}`}>
                    <button type='button' onClick={() => {
                        if (state.selectData.length < 3 && state.selectData.filter(select => select.value !== d.id)) {
                            state.selectData.push({
                                value: d.id,
                                label: d.text
                            });
                            callback.setSelectData([...state.selectData]);
                        }
                    }}>{d.text}</button>
                </li>)}
            </ul>
        </div>

        <div className={styles.annotation}>※上部の入力エリアで、キーワードを検索し、最大３個まで選択してください</div>

    </div>;
};