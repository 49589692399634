import axiosbase from 'axios';
import { store } from '../../redux/store';
import { clearState } from '../../redux/actions/app';
import { setApiLoading } from '../../redux/actions/ui';

export const DOMAIN = process.env.REACT_APP_API_DOMAIN;

export const axiosBase = axiosbase.create({
    baseURL: process.env.REACT_APP_BUILD !== 'local' ? `https://${DOMAIN}` : `http://${DOMAIN}`,
    headers: {
        'Content-Type': 'application/json'
    },
    responseType: 'json'
});

axiosBase.interceptors.request.use(request => {
    // eslint-disable-next-line no-console
    if (process.env.REACT_APP_BUILD !== 'production') console.log(request.url);
    const { dispatch } = store;
    dispatch(setApiLoading(true));
    return request;
});

axiosBase.interceptors.response.use(response => {
    // eslint-disable-next-line no-console
    if (process.env.REACT_APP_BUILD !== 'production') console.log(response);
    const { dispatch } = store;
    dispatch(setApiLoading(false));
    return response;
}, error => {
    // eslint-disable-next-line no-console
    if (process.env.REACT_APP_BUILD !== 'production') console.log(error);
    const { dispatch } = store;
    dispatch(setApiLoading(false));
    if (error.response.status === 401) {
        return dispatch(clearState());
    }
    return Promise.reject(error);
});