import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from '../../../asset/css/schoolCommon.module.scss';
import inputStyles from '../../../asset/css/newSchool.module.scss';
import { Header } from '../../../components/Common/Header';
import { OwnerApplication } from '../../../components/MyPage/OwnerApplication';
import { TabBar } from '../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../components/Common/BreadCrumb';
import { Footer } from '../../../components/Common/Footer';
import { SchoolHeader } from '../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../components/Schools/SchoolTabBar';
import { SchoolPageExplanation } from '../../../components/Schools/SchoolPageExplanation';
import { SchoolH3 } from '../../../components/Schools/SchoolH3';
import { SchoolDetailParamTypes } from './type/SchoolDetaillParam.type';
import { RootState } from '../../../redux/store';
import { getSchoolDetail, setSchoolDetail } from '../../../redux/actions/domain/schools/schoolDetail';
import {
    putSchoolDetailEditData,
    setSchoolDetailAdvancedEditFlag,
    setSchoolDetailBaseEditFlag,
    setSchoolDetailEditData,
    setSchoolDetailEditValidate,
} from '../../../redux/actions/domain/schools/schoolIndexEdit';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { editSchoolIndexValidateInitial } from '../../../redux/reducers/domain/schools/InitialState/index/newSchoolInputValidateInitial';
import { CreateSchoolDataType } from '../../../util/type/CreateSchoolData.type';
import { KeywordPicker } from '../../../components/SchoolInput/KeywordPicker';
import { CategorySelect } from '../../../components/SchoolInput/CategorySelect';
import { CheckBoxInput, CheckBoxInputType } from '../../../components/Common/Input/CheckBoxInput';
import { classStyleMaster } from '../../../util/const/Schools/classStyleMaster';
import { schoolTagMaster } from '../../../util/const/Schools/schoolTagMaster';
import { NearestStationBusStopPicker } from '../../../components/SchoolInput/NearestStationBusStopPicker';
import { AddressInput } from '../../../components/SchoolInput/AddressInput';
import { TextArea } from '../../../components/Common/Input/TextArea';
import { WeekScheduleInput } from '../../../components/SchoolInput/WeekScheduleInput';
import { CostAverageInput } from '../../../components/SchoolInput/CostAverageInput';
import { TargetAgeInput } from '../../../components/SchoolInput/TargetAgeInput';
import { YearMonthSelect } from '../../../components/SchoolInput/YearMonthSelect';
import { CurrentSchoolDataType } from '../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { schoolStyleDescriptionText } from '../../../components/Common/CommonModalContent/DisplayText/const/keywordDescriptionText';

export const SchoolDetailIndex: React.FC = () => {
    const { schoolId } = useParams<SchoolDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        // 一旦リセット
        dispatch(setSchoolDetail(null));
        dispatch(setSchoolDetailEditData(null));
        dispatch(setSchoolDetailEditValidate(editSchoolIndexValidateInitial));
        dispatch(setSchoolDetailBaseEditFlag(false));
        dispatch(setSchoolDetailAdvancedEditFlag(false));
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
    }, [authToken, dispatch, schoolId]);

    if (schoolStore.schoolDetailData !== null && schoolStore.schoolDetailEditData !== null && schoolStore.schoolDetailEditValidate !== null) {
        return (
            <>
                <Header />
                <OwnerApplication />
                <TabBar currentPage="/school" />
                <div className={styles.schoolContainer}>
                    <BreadCrumb
                        data={[
                            { text: '教室一覧', path: '/school' },
                            { text: '教室名', path: '' },
                        ]}
                    />
                    <SchoolHeader data={schoolStore.schoolDetailData} />
                    <SchoolTabBar currentPage={`/school/${schoolId}`} schoolId={schoolId} />
                    <SchoolPageExplanation title="教室情報" textDom={<>教室の基本情報、詳細情報を追加、編集します。</>} btnText={null} path={null} />
                    <SchoolH3
                        otherEditPlace={null}
                        text="基本情報"
                        editable={!schoolStore.baseEditFlag}
                        ButtonFunc={() => {
                            if (!schoolStore.baseEditFlag) {
                                dispatch(setSchoolDetailBaseEditFlag(true));
                            } else {
                                dispatch(setSchoolDetailBaseEditFlag(false));
                                dispatch(getSchoolDetail(authToken, Number(schoolId)));
                                dispatch(setSchoolDetailEditValidate(editSchoolIndexValidateInitial));
                            }
                        }}
                        isShow={null}
                    />
                    {!schoolStore.baseEditFlag ? (
                        <div className={styles.schoolInfoWrap}>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>教室名</div>
                                <div className={styles.schoolInfo__text}>{schoolStore.schoolDetailData.name}</div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>教室名フリガナ</div>
                                <div className={styles.schoolInfo__text}>{schoolStore.schoolDetailData.nameKana}</div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>カテゴリー</div>
                                <div className={styles.schoolInfo__text}>
                                    {schoolStore.schoolDetailData.schoolCategory && schoolStore.schoolDetailData.schoolCategory.text}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>検索キーワード</div>
                                <div className={styles.schoolInfo__text}>
                                    {schoolStore.schoolDetailData.schoolKeywords.length > 0 &&
                                        schoolStore.schoolDetailData.schoolKeywords.map((d) => (
                                            <span key={`schoolInfo_keyword_${d.id}`}>
                                                {d.text}
                                                <br />
                                            </span>
                                        ))}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>授業形態</div>
                                <div className={styles.schoolInfo__text}>
                                    {schoolStore.schoolDetailData.classStyles.length > 0 &&
                                        schoolStore.schoolDetailData.classStyles.map((d, index) =>
                                            schoolStore.schoolDetailData && index === schoolStore.schoolDetailData.classStyles.length - 1 ? (
                                                <span key={`schoolInfo_class_style_${d.id}`}>{d.classStyleText}</span>
                                            ) : (
                                                <span key={`schoolInfo_class_style_${d.id}`}>{d.classStyleText}/</span>
                                            )
                                        )}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>教室の特徴</div>
                                <div className={styles.schoolInfo__text}>
                                    {schoolStore.schoolDetailData.schoolTags.length > 0 &&
                                        schoolStore.schoolDetailData.schoolTags.map((d, index) =>
                                            schoolStore.schoolDetailData && index === schoolStore.schoolDetailData.schoolTags.length - 1 ? (
                                                <span key={`schoolInfo_tag_${d.id}`}>{d.text}</span>
                                            ) : (
                                                <span key={`schoolInfo_tag_${d.id}`}>{d.text}/</span>
                                            )
                                        )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.schoolInfoWrap}>
                            <div className={inputStyles['inputWrap--update']}>
                                <div className={inputStyles.input}>
                                    <div className={inputStyles.inputTextWrap}>
                                        <TextInput
                                            label="教室名"
                                            name="name"
                                            id="name"
                                            require
                                            inputType="text"
                                            validateType={InputValidateTypeEnum.TEXT}
                                            annotation=""
                                            changeReturnFunc={(text: string, validate: boolean) => {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        name: text,
                                                    })
                                                );
                                                dispatch(
                                                    setSchoolDetailEditValidate({
                                                        ...schoolStore.schoolDetailEditValidate,
                                                        name: validate,
                                                    })
                                                );
                                            }}
                                            defaultInput={schoolStore.schoolDetailEditData.name}
                                            placeHolder="教室名を入力"
                                            minLength={1}
                                            defaultInputValue={schoolStore.schoolDetailEditData.name}
                                            disabled={false}
                                        />
                                    </div>
                                </div>

                                <div className={inputStyles.input}>
                                    <div className={inputStyles.inputTextWrap}>
                                        <TextInput
                                            label="教室名フリガナ"
                                            name="nameKana"
                                            id="nameKana"
                                            require
                                            inputType="text"
                                            validateType={InputValidateTypeEnum.KATAKANA}
                                            annotation=""
                                            changeReturnFunc={(text: string, validate: boolean) => {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        nameKana: text,
                                                    })
                                                );
                                                dispatch(
                                                    setSchoolDetailEditValidate({
                                                        ...schoolStore.schoolDetailEditValidate,
                                                        nameKana: validate,
                                                    })
                                                );
                                            }}
                                            defaultInput={schoolStore.schoolDetailEditData.nameKana}
                                            placeHolder="フリガナ"
                                            minLength={1}
                                            defaultInputValue={schoolStore.schoolDetailEditData.nameKana}
                                            disabled={false}
                                        />
                                    </div>
                                </div>

                                <div className={inputStyles.input}>
                                    <CategorySelect
                                        returnFunc={(value) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    categoryId: Number(value),
                                                })
                                            );
                                            dispatch(
                                                setSchoolDetailEditValidate({
                                                    ...schoolStore.schoolDetailEditValidate,
                                                    categoryId: !!value,
                                                })
                                            );
                                        }}
                                        defaultInputValue={schoolStore.schoolDetailEditData.categoryId}
                                        annotation=""
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <KeywordPicker
                                        returnFunc={(value: Array<{ value: number; label: string }>) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    keywordIds: value.map((d) => d.value),
                                                })
                                            );
                                            // dispatch(setSchoolDetailEditValidate({
                                            //     ...schoolStore.schoolDetailEditValidate,
                                            //     keywordIds: value.length !== 0
                                            // }));
                                        }}
                                        defaultValues={schoolStore.schoolDetailEditData.keywordIds}
                                    />
                                </div>
                                <div className={inputStyles.input}>
                                    <CheckBoxInput
                                        label="習い事形態"
                                        name="schoolStyle"
                                        require
                                        data={classStyleMaster.map((d) => ({
                                            id: `classStyle_${d.value}`,
                                            value: d.value,
                                            uncheckValue: '',
                                            label: d.label,
                                        }))}
                                        changeReturnFunc={(values) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    classStyleIds: values.map((v) => Number(v)),
                                                })
                                            );
                                            dispatch(
                                                setSchoolDetailEditValidate({
                                                    ...schoolStore.schoolDetailEditValidate,
                                                    classStyleIds: values.length !== 0,
                                                })
                                            );
                                        }}
                                        defaultValue={schoolStore.schoolDetailEditData.classStyleIds}
                                        buttonType={CheckBoxInputType.BUTTON}
                                        annotation="※当てはまるもの、全てを選択してください"
                                        displayTextModalData={{
                                            displayText: '習い事形態とは？',
                                            modalText: schoolStyleDescriptionText,
                                        }}
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <CheckBoxInput
                                        label="教室の特徴"
                                        name="schoolTags"
                                        require
                                        data={schoolTagMaster.map((d) => ({
                                            id: `schoolTags_${d.value}`,
                                            value: d.value,
                                            uncheckValue: '',
                                            label: d.label,
                                        }))}
                                        changeReturnFunc={(values) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    tagIds: values.map((v) => Number(v)),
                                                })
                                            );
                                            dispatch(
                                                setSchoolDetailEditValidate({
                                                    ...schoolStore.schoolDetailEditValidate,
                                                    tagIds: values.length !== 0,
                                                })
                                            );
                                        }}
                                        defaultValue={schoolStore.schoolDetailEditData.tagIds}
                                        buttonType={CheckBoxInputType.BUTTON}
                                        annotation="※当てはまるもの、全てを選択してください"
                                        displayTextModalData={null}
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <NearestStationBusStopPicker
                                        defaultData={{
                                            nearestStation: schoolStore.schoolDetailEditData.nearestStation,
                                            nearestBusstop: schoolStore.schoolDetailEditData.nearestBusstop,
                                        }}
                                        returnFunc={(value: { value: number; onFoot: number; label: string } | null, isBusStop: boolean) => {
                                            if (!isBusStop) {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        nearestStation: value
                                                            ? {
                                                                id: value.value,
                                                                onFoot: value.onFoot,
                                                                label: value.label,
                                                            }
                                                            : null,
                                                        nearestBusstop: null,
                                                    })
                                                );
                                            } else {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        nearestStation: null,
                                                        nearestBusstop: value
                                                            ? {
                                                                id: value.value,
                                                                onFoot: value.onFoot,
                                                                label: value.label,
                                                            }
                                                            : null,
                                                    })
                                                );
                                            }
                                        }}
                                    />
                                </div>

                                <button
                                    type="button"
                                    className={`${styles.editInputButton} ${
                                        Object.entries(schoolStore.schoolDetailEditValidate).some((d) => !d[1])
                                            ? styles['editInputButton--disable']
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (schoolStore.schoolDetailData) {
                                            dispatch(
                                                putSchoolDetailEditData(
                                                    authToken,
                                                    schoolId.toString(),
                                                    schoolStore.schoolDetailEditData as CreateSchoolDataType
                                                )
                                            );
                                        }
                                    }}>
                                    {loadingSelector ? '通信中...' : '保存する'}
                                </button>
                            </div>
                        </div>
                    )}

                    <SchoolH3
                        otherEditPlace={null}
                        text="詳細情報"
                        editable={!schoolStore.advancedEditFlag}
                        ButtonFunc={() => {
                            if (!schoolStore.advancedEditFlag) {
                                dispatch(setSchoolDetailAdvancedEditFlag(true));
                            } else {
                                dispatch(setSchoolDetailAdvancedEditFlag(false));
                                dispatch(getSchoolDetail(authToken, Number(schoolId)));
                                dispatch(setSchoolDetailEditValidate(editSchoolIndexValidateInitial));
                            }
                        }}
                        isShow={null}
                    />
                    {!schoolStore.advancedEditFlag ? (
                        <div className={styles.schoolInfoWrap}>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>所在地</div>
                                <div className={styles.schoolInfo__text}>
                                    {schoolStore.schoolDetailData.prefecture && schoolStore.schoolDetailData.prefecture.nameJp}
                                    {schoolStore.schoolDetailData.town && schoolStore.schoolDetailData.town.nameJp}
                                    {schoolStore.schoolDetailData.street && schoolStore.schoolDetailData.street.nameJp}
                                    {schoolStore.schoolDetailData.addressDetail}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>交通手段</div>
                                <div className={styles.schoolInfo__text}>
                                    {schoolStore.schoolDetailData.nearestStationText && schoolStore.schoolDetailData.nearestStationText}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>開校時間・休校</div>
                                <div className={styles.schoolInfo__text}>開校時間：</div>
                                <table className={styles.schoolDetailInfoSchedule}>
                                    <tbody>
                                        <tr>
                                            <th className={schoolStore.schoolDetailData.scheduleWeekSchedule[0] ? '' : styles.holiday}>月</th>
                                            <th className={schoolStore.schoolDetailData.scheduleWeekSchedule[1] ? '' : styles.holiday}>火</th>
                                            <th className={schoolStore.schoolDetailData.scheduleWeekSchedule[2] ? '' : styles.holiday}>水</th>
                                            <th className={schoolStore.schoolDetailData.scheduleWeekSchedule[3] ? '' : styles.holiday}>木</th>
                                            <th className={schoolStore.schoolDetailData.scheduleWeekSchedule[4] ? '' : styles.holiday}>金</th>
                                            <th className={styles.saturday}>土</th>
                                            <th className={styles.sunday}>日</th>
                                        </tr>
                                        <tr>
                                            {schoolStore.schoolDetailData.scheduleWeekSchedule.map((d, index) =>
                                                d ? (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <td key={`schedule_${index}`}>
                                                        {d.from}
                                                        <br />-<span className={styles.spaceForPc}>&nbsp;</span>
                                                        <span className={styles.finishTime}>{d.to}</span>
                                                    </td>
                                                ) : (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <td key={`schedule_${index}`} className={styles.holiday}>
                                                        休
                                                    </td>
                                                )
                                            )}
                                        </tr>
                                    </tbody>
                                </table>

                                <div className={styles.schoolInfo__text}>
                                    休校：
                                    {(() => {
                                        let text = '';
                                        if (schoolStore.schoolDetailData.scheduleOpenDays.find((d) => d !== 0)) {
                                            schoolStore.schoolDetailData.scheduleOpenDays.forEach((d, index) => {
                                                if (d === 0) {
                                                    text += text ? '、' : '';
                                                    text += index === 0 ? '月' : '';
                                                    text += index === 1 ? '火' : '';
                                                    text += index === 2 ? '水' : '';
                                                    text += index === 3 ? '木' : '';
                                                    text += index === 4 ? '金' : '';
                                                    text += index === 5 ? '土' : '';
                                                    text += index === 6 ? '日' : '';
                                                }
                                            });
                                        }
                                        return `毎週${text}曜日`;
                                    })()}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>平均月費用</div>
                                <div className={styles.schoolInfo__text}>
                                    {schoolStore.schoolDetailData.costAverageFrom}
                                    円〜
                                    {schoolStore.schoolDetailData.costAverageTo}円
                                </div>
                            </div>
                            {schoolStore.schoolDetailData.schoolTargetAge.length && (
                                <div className={styles.schoolInfo}>
                                    <div className={styles.schoolInfo__title}>対象年齢</div>
                                    <div className={styles.schoolInfo__text}>
                                        {schoolStore.schoolDetailData.schoolTargetAge[0] ? schoolStore.schoolDetailData.schoolTargetAge[0].text : ''}
                                        {schoolStore.schoolDetailData.schoolTargetAge.length > 1 && (
                                            <>
                                                〜
                                                {
                                                    schoolStore.schoolDetailData.schoolTargetAge[
                                                        schoolStore.schoolDetailData.schoolTargetAge.length - 1
                                                    ].text
                                                }
                                            </>
                                        )}
                                    </div>
                                    {schoolStore.schoolDetailData.withTargetUniversity && <div className={styles.schoolInfo__text}>大学生</div>}
                                    {schoolStore.schoolDetailData.withTargetWorking && <div className={styles.schoolInfo__text}>大人</div>}
                                </div>
                            )}
                            {/* <div className={styles.schoolInfo}>
                        <div  className={styles.schoolInfo__title}>会社名</div>
                        <div  className={styles.schoolInfo__text}>未実装？</div>
                    </div> */}
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>開校</div>
                                <div className={styles.schoolInfo__text}>
                                    {new Date(schoolStore.schoolDetailData.establishmentDate).getFullYear()}年
                                    {new Date(schoolStore.schoolDetailData.establishmentDate).getMonth() + 1}月
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>公式サイト</div>
                                <div className={styles.schoolInfo__text}>
                                    <a href={schoolStore.schoolDetailData.homepageUrl} target="_blank" rel="nofollow noopener noreferrer">
                                        {schoolStore.schoolDetailData.homepageUrl}
                                    </a>
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>公式SNS</div>
                                <div className={`${styles.schoolInfo__text} ${styles.schoolInfo__sns}`}>
                                    Twitter
                                    <br />
                                    <a href={schoolStore.schoolDetailData.twitterUrl} target="_blank" rel="nofollow noopener noreferrer">
                                        {schoolStore.schoolDetailData.twitterUrl}
                                    </a>
                                </div>
                                <div className={`${styles.schoolInfo__text} ${styles.schoolInfo__sns}`}>
                                    Instagram
                                    <br />
                                    <a href={schoolStore.schoolDetailData.instagramUrl} target="_blank" rel="nofollow noopener noreferrer">
                                        {schoolStore.schoolDetailData.instagramUrl}
                                    </a>
                                </div>
                                <div className={`${styles.schoolInfo__text} ${styles.schoolInfo__sns}`}>
                                    LINE@
                                    <br />
                                    <a href={schoolStore.schoolDetailData.lineUrl} target="_blank" rel="nofollow noopener noreferrer">
                                        {schoolStore.schoolDetailData.lineUrl}
                                    </a>
                                </div>
                                <div className={`${styles.schoolInfo__text} ${styles.schoolInfo__sns}`}>
                                    Facebook
                                    <br />
                                    <a href={schoolStore.schoolDetailData.facebookUrl} target="_blank" rel="nofollow noopener noreferrer">
                                        {schoolStore.schoolDetailData.facebookUrl}
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={inputStyles.schoolInfoWrap}>
                            <div className={inputStyles['inputWrap--update']}>
                                <div className={inputStyles.input}>
                                    <AddressInput
                                        returnFunc={(selectData) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    withoutAddress: selectData.withoutAddress,
                                                    prefectureId: selectData.prefecture ? selectData.prefecture : 0,
                                                    townId: selectData.town ? selectData.town : 0,
                                                    streetId: selectData.street ? selectData.street : 0,
                                                    addressDetail: selectData.addressDetail,
                                                })
                                            );
                                            dispatch(
                                                setSchoolDetailEditValidate({
                                                    ...schoolStore.schoolDetailEditValidate,
                                                    prefectureId: selectData.withoutAddress ? true : !!selectData.prefecture,
                                                    townId: selectData.withoutAddress ? true : !!selectData.town,
                                                    streetId: selectData.withoutAddress ? true : !!selectData.street,
                                                    addressDetail: selectData.withoutAddress ? true : !!selectData.addressDetail,
                                                })
                                            );
                                        }}
                                        defaultWithoutAddress={schoolStore.schoolDetailEditData.withoutAddress}
                                        defaultPrefectureId={schoolStore.schoolDetailEditData.prefectureId}
                                        defaultTown={schoolStore.schoolDetailEditData.townId}
                                        defaultStreet={schoolStore.schoolDetailEditData.streetId}
                                        defaultAddressDetail={schoolStore.schoolDetailEditData.addressDetail}
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <TextArea
                                        label="交通手段"
                                        name="nearestStationText"
                                        id="nearestStationText"
                                        require={false}
                                        validateType={InputValidateTypeEnum.TEXT}
                                        annotation={null}
                                        changeReturnFunc={(text, validate) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    nearestStationText: text,
                                                })
                                            );
                                            dispatch(
                                                setSchoolDetailEditValidate({
                                                    ...schoolStore.schoolDetailEditValidate,
                                                    nearestStationText: validate,
                                                })
                                            );
                                        }}
                                        defaultInput={schoolStore.schoolDetailEditData.nearestStationText}
                                        placeHolder="交通手段を入力
例：JR・東急東横・東急田園都市・東京メトロ各線「渋谷駅」より徒歩10分
JR原宿駅より徒歩10分
小田急電鉄「代々木八幡駅」より徒歩10分"
                                        forReview={false}
                                        forInquiry={false}
                                        countFlag
                                        maxLength={500}
                                        minLength={null}
                                        height={174}
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <WeekScheduleInput
                                        defaultValue={schoolStore.schoolDetailEditData.scheduleWeekSchedule}
                                        returnFunc={(data) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    scheduleWeekSchedule: data,
                                                })
                                            );
                                            dispatch(
                                                setSchoolDetailEditValidate({
                                                    ...schoolStore.schoolDetailEditValidate,
                                                    scheduleWeekSchedule: !data.find((d) => d !== null && (d.to === '' || d.from === '')),
                                                })
                                            );
                                        }}
                                        label="開校時間"
                                        name="schoolScheduleWeekSchedule"
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <CostAverageInput
                                        defaultValue={{
                                            from: schoolStore.schoolDetailEditData.costAverageFrom,
                                            to: schoolStore.schoolDetailEditData.costAverageTo,
                                        }}
                                        defaultValidate={{
                                            from: schoolStore.schoolDetailEditValidate.costAverageFrom,
                                            to: schoolStore.schoolDetailEditValidate.costAverageTo,
                                        }}
                                        returnFunc={(data, validate) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    costAverageFrom: data.from,
                                                    costAverageTo: data.to,
                                                })
                                            );
                                            dispatch(
                                                setSchoolDetailEditValidate({
                                                    ...schoolStore.schoolDetailEditValidate,
                                                    costAverageFrom: validate.from,
                                                    costAverageTo: validate.to,
                                                })
                                            );
                                        }}
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <TargetAgeInput
                                        defaultValue={schoolStore.schoolDetailEditData.targetAgeIds}
                                        returnFunc={(data) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    targetAgeIds: data,
                                                })
                                            );
                                            dispatch(
                                                setSchoolDetailEditValidate({
                                                    ...schoolStore.schoolDetailEditValidate,
                                                    targetAgeIds: true,
                                                })
                                            );
                                        }}
                                    />
                                    <CheckBoxInput
                                        label=""
                                        name="targetAgeIdAdd"
                                        require={false}
                                        data={[
                                            {
                                                id: `targetAgeIdAdd_1`,
                                                value: '1',
                                                uncheckValue: '',
                                                label: '大学生',
                                            },
                                            {
                                                id: `targetAgeIdAdd_2`,
                                                value: '2',
                                                uncheckValue: '',
                                                label: '大人',
                                            },
                                        ]}
                                        changeReturnFunc={(values) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    withTargetUniversity: values.some((v) => v === '1'),
                                                    withTargetWorking: values.some((v) => v === '2'),
                                                })
                                            );
                                        }}
                                        defaultValue={[
                                            schoolStore.schoolDetailEditData.withTargetUniversity ? 1 : 0,
                                            schoolStore.schoolDetailEditData.withTargetWorking ? 2 : 0,
                                        ]}
                                        buttonType={CheckBoxInputType.BUTTON}
                                        annotation=""
                                        displayTextModalData={null}
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <YearMonthSelect
                                        defaultValue={schoolStore.schoolDetailEditData.establishmentDate}
                                        returnFunc={(data) => {
                                            dispatch(
                                                setSchoolDetailEditData({
                                                    ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                    establishmentDate: data,
                                                })
                                            );
                                        }}
                                    />
                                </div>

                                <div className={inputStyles.input}>
                                    <div className={inputStyles.inputTextWrap}>
                                        <TextInput
                                            label="公式サイトURL"
                                            name="homepageUrl"
                                            id="homepageUrl"
                                            require={false}
                                            inputType="homepageUrl"
                                            validateType={InputValidateTypeEnum.TEXT}
                                            annotation=""
                                            changeReturnFunc={(text: string, validate: boolean) => {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        homepageUrl: text,
                                                    })
                                                );
                                                dispatch(
                                                    setSchoolDetailEditValidate({
                                                        ...schoolStore.schoolDetailEditValidate,
                                                        homepageUrl: validate,
                                                    })
                                                );
                                            }}
                                            defaultInput={schoolStore.schoolDetailEditData.homepageUrl}
                                            placeHolder={null}
                                            minLength={null}
                                            defaultInputValue={schoolStore.schoolDetailEditData.homepageUrl}
                                            disabled={false}
                                        />
                                    </div>
                                </div>

                                <div className={inputStyles.input}>
                                    <div className={inputStyles.snsLabel}>公式SNS URL</div>
                                    <div className={inputStyles.snsInner}>
                                        <TextInput
                                            label="Twitter"
                                            name="twitter"
                                            id="twitter"
                                            require={false}
                                            inputType="text"
                                            validateType={InputValidateTypeEnum.TWITTER_URL}
                                            annotation=""
                                            changeReturnFunc={(text: string, validate: boolean) => {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        twitterUrl: text,
                                                    })
                                                );
                                                dispatch(
                                                    setSchoolDetailEditValidate({
                                                        ...schoolStore.schoolDetailEditValidate,
                                                        twitterUrl: validate,
                                                    })
                                                );
                                            }}
                                            defaultInput={schoolStore.schoolDetailEditData.twitterUrl}
                                            placeHolder="https://twitter.com/********"
                                            minLength={null}
                                            defaultInputValue={schoolStore.schoolDetailEditData.twitterUrl}
                                            disabled={false}
                                        />
                                        <TextInput
                                            label="Instagram"
                                            name="Instagram"
                                            id="Instagram"
                                            require={false}
                                            inputType="text"
                                            validateType={InputValidateTypeEnum.INSTAGRAM_URL}
                                            annotation=""
                                            changeReturnFunc={(text: string, validate: boolean) => {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        instagramUrl: text,
                                                    })
                                                );
                                                dispatch(
                                                    setSchoolDetailEditValidate({
                                                        ...schoolStore.schoolDetailEditValidate,
                                                        instagramUrl: validate,
                                                    })
                                                );
                                            }}
                                            defaultInput={schoolStore.schoolDetailEditData.instagramUrl}
                                            placeHolder="https://www.instagram.com/********/"
                                            minLength={null}
                                            defaultInputValue={schoolStore.schoolDetailEditData.instagramUrl}
                                            disabled={false}
                                        />
                                        <TextInput
                                            label="LINE@"
                                            name="line"
                                            id="line"
                                            require={false}
                                            inputType="text"
                                            validateType={InputValidateTypeEnum.LINE_AT_URL}
                                            annotation=""
                                            changeReturnFunc={(text: string, validate: boolean) => {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        lineUrl: text,
                                                    })
                                                );
                                                dispatch(
                                                    setSchoolDetailEditValidate({
                                                        ...schoolStore.schoolDetailEditValidate,
                                                        lineUrl: validate,
                                                    })
                                                );
                                            }}
                                            defaultInput={schoolStore.schoolDetailEditData.lineUrl}
                                            placeHolder="http://line.me/ti/p/********"
                                            minLength={null}
                                            defaultInputValue={schoolStore.schoolDetailEditData.lineUrl}
                                            disabled={false}
                                        />
                                        <TextInput
                                            label="Facebook"
                                            name="Facebook"
                                            id="Facebook"
                                            require={false}
                                            inputType="text"
                                            validateType={InputValidateTypeEnum.FACEBOOK_URL}
                                            annotation=""
                                            changeReturnFunc={(text: string, validate: boolean) => {
                                                dispatch(
                                                    setSchoolDetailEditData({
                                                        ...(schoolStore.schoolDetailEditData as CreateSchoolDataType),
                                                        facebookUrl: text,
                                                    })
                                                );
                                                dispatch(
                                                    setSchoolDetailEditValidate({
                                                        ...schoolStore.schoolDetailEditValidate,
                                                        facebookUrl: validate,
                                                    })
                                                );
                                            }}
                                            defaultInput={schoolStore.schoolDetailEditData.facebookUrl}
                                            placeHolder="https://www.facebook.com/********"
                                            minLength={null}
                                            defaultInputValue={schoolStore.schoolDetailEditData.facebookUrl}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className={`${styles.editInputButton} ${
                                        Object.entries(schoolStore.schoolDetailEditValidate).some((d) => !d[1])
                                            ? styles['editInputButton--disable']
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (schoolStore.schoolDetailData) {
                                            dispatch(
                                                putSchoolDetailEditData(
                                                    authToken,
                                                    schoolId.toString(),
                                                    schoolStore.schoolDetailEditData as CreateSchoolDataType
                                                )
                                            );
                                        }
                                    }}>
                                    {loadingSelector ? '通信中...' : '保存する'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/schools" />
            <div className={styles.schoolContainer}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室名', path: '' },
                    ]}
                />
            </div>
            <Footer />
        </>
    );
};
