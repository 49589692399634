import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getInquiryDetail, InquiryActionTypes, setInquiryDetail } from '../redux/actions/domain/inquiry';
import { SchoolInquiryDataType } from '../util/type/SchoolInquiryData.type';
import { InquiryApi } from '../util/api/inquiry';

// お問い合わせ詳細取得
function* fetchInquiry({ payload }: ReturnType<typeof getInquiryDetail>) {
    try {
        const res: AxiosResponse<SchoolInquiryDataType> = yield call(InquiryApi.getInquiry, payload.token, payload.inquiryId);
        yield put(setInquiryDetail(res.data));
    } catch (e) {
        // TODO 404
        yield put(setInquiryDetail(null));
    }
}

export function* getInquiryAsync() {
    yield takeEvery(InquiryActionTypes.GET_INQUIRY_DETAIL, fetchInquiry);
}