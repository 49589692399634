import React from 'react';
import styles from './css/style.module.scss';
import { SchoolDetailData } from '../../../redux/reducers/domain/schools/type/SchoolDetailData.type';

type SchoolDetailInfoProps = {
    school: SchoolDetailData;
};

export const SchoolDetailInfo: React.FC<SchoolDetailInfoProps> = (props: SchoolDetailInfoProps) => {
    const { school } = props;

    return (
        <>
            <section className={styles.schoolDetailInfo}>
                <table>
                    <tbody>
                        {school.name && (
                            <tr>
                                <th>教室名</th>
                                <td>{school.name}</td>
                            </tr>
                        )}
                        {school.schoolCategory && (
                            <tr>
                                <th>カテゴリー</th>
                                <td>
                                    {school.schoolCategory.text}
                                    {school.schoolKeywords.length > 0 &&
                                        school.schoolKeywords.map((d) => <span key={`schoolDetailInfo_keyword_${d.id}`}>、{d.text}</span>)}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th>所在地</th>
                            <td className={styles.schoolDetailInfo__place}>
                                {school.prefecture && school.street && school.town ? (
                                    <>
                                        {school.prefecture && school.prefecture.nameJp}
                                        {school.street && school.street.nameJp}
                                        {school.town && school.town.nameJp}
                                        {school.addressDetail && school.addressDetail}
                                    </>
                                ) : (
                                    'オンライン'
                                )}
                            </td>
                        </tr>

                        {school.nearestStationText && (
                            <tr>
                                <th>交通手段</th>
                                <td>
                                    <div className={styles.transportation}>{school.nearestStationText}</div>
                                </td>
                            </tr>
                        )}

                        {(school.scheduleWeekSchedule || school.scheduleOpenDays) && (
                            <tr>
                                <th>&nbsp;</th>
                                <td>
                                    <div className={styles.openHours}>
                                        <div className={styles.title}>開校時間</div>
                                        <table className={styles.schoolDetailInfoSchedule}>
                                            <tbody>
                                                <tr>
                                                    <th className={school.scheduleWeekSchedule[0] ? '' : 'holiday'}>月</th>
                                                    <th className={school.scheduleWeekSchedule[1] ? '' : 'holiday'}>火</th>
                                                    <th className={school.scheduleWeekSchedule[2] ? '' : 'holiday'}>水</th>
                                                    <th className={school.scheduleWeekSchedule[3] ? '' : 'holiday'}>木</th>
                                                    <th className={school.scheduleWeekSchedule[4] ? '' : 'holiday'}>金</th>
                                                    <th className={styles.saturday}>土</th>
                                                    <th className={styles.sunday}>日</th>
                                                </tr>
                                                <tr>
                                                    {school.scheduleWeekSchedule.map((d, index) =>
                                                        d ? (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <td key={`schedule_${index}`}>
                                                                {d.from}
                                                                <br />-<span className={styles.spaceForPc}>&nbsp;</span>
                                                                <span className={styles.finishTime}>{d.to}</span>
                                                            </td>
                                                        ) : (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <td key={`schedule_${index}`} className={styles.holiday}>
                                                                休
                                                            </td>
                                                        )
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {school.scheduleOpenDays && (
                                        <>
                                            <div className={styles.openHours}>
                                                <div className={styles.title}>休校</div>
                                                {(() => {
                                                    let text = '';
                                                    if (school.scheduleOpenDays.find((d) => d !== 0)) {
                                                        school.scheduleOpenDays.forEach((d, index) => {
                                                            if (d === 0) {
                                                                text += text ? '、' : '';
                                                                text += index === 0 ? '月' : '';
                                                                text += index === 1 ? '火' : '';
                                                                text += index === 2 ? '水' : '';
                                                                text += index === 3 ? '木' : '';
                                                                text += index === 4 ? '金' : '';
                                                                text += index === 5 ? '土' : '';
                                                                text += index === 6 ? '日' : '';
                                                            }
                                                        });
                                                    }
                                                    return `毎週${text}曜日`;
                                                })()}
                                            </div>
                                            <div className={styles.openHours}>
                                                <p className={styles.caution}>
                                                    新型コロナウイルス感染拡大により、開校時間・休校日が記載と異なる場合がございます。ご来校時は事前に教室にご確認ください。
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </td>
                            </tr>
                        )}
                        {(school.costAverageFrom || school.costAverageTo) && (
                            <tr>
                                <th>平均月費用</th>
                                <td>
                                    {school.costAverageFrom && school.costAverageFrom.toLocaleString()}円
                                    {school.costAverageFrom && school.costAverageTo && '〜'}
                                    {school.costAverageTo && school.costAverageTo.toLocaleString()}円
                                </td>
                            </tr>
                        )}

                        {school.schoolTargetAge.length > 0 && (
                            <tr>
                                <th>対象年齢</th>
                                <td>
                                    {school.schoolTargetAge[0] ? school.schoolTargetAge[0].text : ''}
                                    {school.schoolTargetAge.length > 1 && <>〜{school.schoolTargetAge[school.schoolTargetAge.length - 1].text}</>}
                                </td>
                            </tr>
                        )}

                        {/* <tr>*/}
                        {/*    <th>会社名</th>*/}
                        {/*    <td>株式会社たけすえスクール</td>*/}
                        {/* </tr>*/}

                        {school.establishmentDate && (
                            <tr>
                                <th>開校</th>
                                <td>
                                    {new Date(school.establishmentDate).getFullYear()}年{new Date(school.establishmentDate).getMonth() + 1}月
                                </td>
                            </tr>
                        )}

                        {school.homepageUrl && (
                            <tr>
                                <th>公式サイト</th>
                                <td>
                                    <a href={school.homepageUrl} target="_blank" rel="nofollow noopener noreferrer">
                                        {school.homepageUrl}
                                    </a>
                                </td>
                            </tr>
                        )}

                        {(school.twitterUrl !== '' || school.instagramUrl !== '' || school.lineUrl !== '' || school.facebookUrl !== '') && (
                            <tr>
                                <th>公式SNS</th>
                                <td>
                                    {school.twitterUrl && (
                                        <div className={styles.sns}>
                                            <span>Twitter</span>
                                            <a href="http://takesueschool.com" target="_blank" rel="nofollow noopener noreferrer">
                                                ＠takesueschool
                                            </a>
                                        </div>
                                    )}
                                    {school.instagramUrl && (
                                        <div className={styles.sns}>
                                            <span>Instagram</span>
                                            <a href="http://takesueschool.com" target="_blank" rel="nofollow noopener noreferrer">
                                                ＠takesueschool
                                            </a>
                                        </div>
                                    )}
                                    {school.lineUrl && (
                                        <div className={styles.sns}>
                                            <span>LINE＠</span>
                                            <a href="http://takesueschool.com" target="_blank" rel="nofollow noopener noreferrer">
                                                ＠takesueschool
                                            </a>
                                        </div>
                                    )}
                                    {school.facebookUrl && (
                                        <div className={styles.sns}>
                                            <span>Facebook</span>
                                            <a href="http://takesueschool.com" target="_blank" rel="nofollow noopener noreferrer">
                                                ＠takesueschool
                                            </a>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </section>
        </>
    );
};
