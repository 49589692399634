import React from 'react';
import { useTextArea } from './hooks/useTextArea';
import { InputValidateTypeEnum } from '../TextInput/InputValidateType.enum';
import styles from './css/style.module.scss';

type TextAreaProps = {
    label: string;
    name: string;
    id: string;
    require: boolean;
    validateType: InputValidateTypeEnum;
    annotation: string | null;
    changeReturnFunc: (text: string, validate: boolean) => void;
    defaultInput: string | null;
    placeHolder: string | null;
    forReview: boolean;
    forInquiry: boolean;
    countFlag: boolean;
    maxLength: number;
    minLength: number | null;
    height: number | null;
};

export const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
    const {
        label,
        name,
        id,
        require,
        validateType,
        annotation,
        changeReturnFunc,
        defaultInput,
        placeHolder,
        forReview,
        forInquiry,
        countFlag,
        maxLength,
        minLength,
        height,
    } = props;
    const [inputData, callBack] = useTextArea(defaultInput, require, validateType, minLength);
    return (
        <>
            <div className={styles.wrap}>
                {label && (<div className={`${styles.label} ${require ? styles.require : ''}`}>{label}</div>)}
                <textarea
                    className={`${styles.input} ${inputData.errorText ? styles.error : ''}
                     ${forReview ? 'forReview' : ''}
                     ${forInquiry ? 'forInquiry' : ''}
                     `}
                    style={height ? {height: `${height}px`} : {}}
                    name={name}
                    id={id}
                    value={inputData.input}
                    onChange={(e) => {
                        const result = callBack.setTextCallBack(e.target.value);
                        changeReturnFunc(result.data, result.validate);
                    }}
                    placeholder={placeHolder || ''}
                    maxLength={maxLength}
                />
                {annotation && <div className={styles.annotation}>{annotation}</div>}
                {inputData.errorText && <div className={styles.errorText}>{inputData.errorText}</div>}
                {countFlag && (
                    <div className={styles.countWrap}>
                        {inputData.input.length}/{maxLength}
                    </div>
                )}
            </div>
        </>
    );
};
