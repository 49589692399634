import React from 'react';
import styles from './css/style.module.scss';
// import bookmark from './img/icon_bookmark.svg';
import { SchoolListItem } from '../SchoolListItem';
import { SchoolEditBtn } from '../../Common/SchoolEditBtn';
import { SchoolDetailData } from '../../../redux/reducers/domain/schools/type/SchoolDetailData.type';
import { useSchoolList } from './hooks/useSchoolList';

type SchoolListProps = {
    schoolData: Array<SchoolDetailData>;
    isSchoolListPage: boolean;
    page: number;
    count: number;
};

export const SchoolList: React.FC<SchoolListProps> = (props: SchoolListProps) => {
    const { schoolData, isSchoolListPage, page, count } = props;
    const [schoolListState, schoolListCallback] = useSchoolList();

    return (
        <>
            <p className={styles.title}>教室・スクールを選択</p>
            <div className={styles.btnWrap}>
                <button
                    type="button"
                    className={`${styles.btn} ${schoolListState.checkedSchools.length !== 1 ? styles.disable : ''}`}
                    onClick={() => schoolListCallback.pushDetailButton()}>
                    {/* <img src={bookmark} alt="編集" />*/}
                    編集・詳細を確認
                </button>
                <button
                    type="button"
                    className={`${styles.btn} ${schoolListState.checkedSchools.length !== 1 ? styles.disable : ''}`}
                    onClick={() => schoolListCallback.pushDuplicateButton()}>
                    {/* <img src={bookmark} alt="複製" />*/}
                    複製
                </button>
                <select
                    className={`${styles.selectBox} ${schoolListState.checkedSchools.length === 0 ? styles.disable : ''}`}
                    onChange={(e) => {
                        const val: string = e.target.value.toString();
                        schoolListCallback.changeStatus(val, count, page);
                    }}
                    value={schoolListState.statusSelected}>
                    <option value="">ステータスを変更</option>
                    <option value="1">掲載中</option>
                    <option value="2">掲載停止中</option>
                </select>
                {/* <button type="button" className={`${styles.btn} ${schoolListState.checkedSchools.length !== 1 ? styles.disable : ''}`}>*/}
                {/*    予算を変更*/}
                {/* </button>*/}
                {isSchoolListPage && <SchoolEditBtn />}
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>
                            <input
                                className={styles.schoolListCheckBox}
                                type="checkbox"
                                id="all"
                                onChange={(e) => schoolListCallback.checkAllSchool(schoolData, e.target.checked)}
                            />
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className={styles.schoolListLabel} htmlFor="all">
                                教室名
                            </label>
                        </th>
                        <th>所在地</th>
                        <th>更新日</th>
                        <th>ステータス</th>
                        <th>
                            当月問い
                            <br />
                            合わせ
                        </th>
                        <th>当月費用</th>
                        <th>当月予算</th>
                    </tr>
                    {schoolData.map((data, index) => (
                        <SchoolListItem
                            key={data.id}
                            data={data}
                            even={index % 2 !== 0}
                            listChecked={schoolListState.checkedSchools.some((d) => d.id === data.id)}
                            listCheckReturnFunk={() => schoolListCallback.checkSchool(data.id, data)}
                            page={page}
                            count={count}
                        />
                    ))}
                </tbody>
            </table>
        </>
    );
};
