export const keywordDescriptionText = `関連キーワードとは、教室・スクール情報を検索でヒットしやすくするために追加できるカテゴリーに関するキーワードです。

oriori側ですでに登録されているキーワードからご選択いただけます。

検索にヒットしやすくなるだけではなく、関連キーワード情報は、ユーザーが教室・スクール情報を見るかどうかを決めるときの判断材料にもなります。`;

export const schoolStyleDescriptionText = `【集合型】特定の教室や場所に複数人が集まって行う形式の習い事

【個別指導】個別で行う少人数形式の習い事

【オンライン】オンラインで行う形式の習い事

【家庭教師・派遣型】特定の教室を持たず、生徒や保護者の指定する場所へ赴き習い事を行う形式`;
