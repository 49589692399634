import { useCallback, useState } from 'react';
import { InputValidateTypeEnum } from '../../TextInput/InputValidateType.enum';
import { inputTextValidate } from '../../TextInput/inputValidate';

export const useTextArea = (
    defaultInput: string | null,
    require: boolean,
    type: InputValidateTypeEnum,
    minLength: number | null
): [
        {
            input: string;
            errorText: string;
        },
        {
            setTextCallBack: (data: string) => { data: string; validate: boolean };
        }
    ] => {
    const [input, setInput] = useState(defaultInput !== null ? defaultInput : '');
    const [errorText, setErrorText] = useState('');

    const setTextCallBack = useCallback(
        (data: string) => {
            const validated = inputTextValidate(data, type, require, minLength);
            setInput(data);
            setErrorText(validated.errorText);

            return {
                data,
                validate: validated.flag,
            };
        },
        [minLength, require, type]
    );

    return [{ input, errorText }, { setTextCallBack }];
};
