import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';

type SchoolTabBarProps = {
    currentPage: string;
    schoolId: string;
};

export const SchoolTabBar: React.FC<SchoolTabBarProps> = (props: SchoolTabBarProps) => {
    const { currentPage, schoolId } = props;
    const pageList = [
        {
            text: '教室情報',
            path: `/school/${schoolId}`,
        },
        {
            text: '教室トップ',
            path: `/school/${schoolId}/top`,
        },
        {
            text: 'コース',
            path: `/school/${schoolId}/course`,
        },
        {
            text: 'ブログ',
            path: `/school/${schoolId}/blog`,
        },
        {
            text: 'クーポン',
            path: `/school/${schoolId}/coupon`,
        },
    ];
    return (
        <>
            <nav className={styles.schoolTabBar}>
                <ul className={styles.schoolTabBar__list}>
                    {pageList.map((data) => (
                        // deactivateの判定は？
                        <li
                            key={data.text}
                            className={`${styles.schoolTabBar__item} ${currentPage === data.path && styles['schoolTabBar__item--active']}`}>
                            <Link to={data.path}>
                                {data.text}
                                {/* {data.path===`/school/${schoolId}/course` &&*/}
                                {/* <span className={styles.schoolTabBar__count}>(10)</span>*/}
                                {/* }*/}
                                {/* {data.path===`/school/${schoolId}/coupon` &&*/}
                                {/* <span className={styles.schoolTabBar__count}>(10)</span>*/}
                                {/* }*/}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
};
