import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    getSchoolCourseDetail,
    getSchoolCourseList,
    patchSchoolCoursePublish,
    patchSchoolCourseUnPublish,
    postNewSchoolCourse,
    putSchoolCourseEditData,
    setSchoolCourseDetail,
    setSchoolCourseEditData,
    setSchoolCourseEditFlag,
    setSchoolCourseList,
    setSchoolNewCourseInput,
    setSchoolNewCourseValidate,
} from '../../redux/actions/domain/schools/schoolCourse';
import { SchoolCourseData } from '../../util/type/School/SchoolCourseData.type';
import { SchoolsApi } from '../../util/api/schools';
import { SchoolsActionTypes } from '../../redux/actions/domain/schools';
import { schoolCourseToCourseInputData } from '../../redux/reducers/domain/schools/converter/schoolCourseToCourseInputData';
import { numberScheduleToString } from '../../util/helper/numberScheduleToString';
import { schoolDetailCourseNewInitial } from '../../redux/reducers/domain/schools/InitialState/Course/schoolDetailCourseNewInitial';
import { schoolDetailCourseNewValidateInitial } from '../../redux/reducers/domain/schools/InitialState/Course/schoolDetailCourseNewValidateInitial';

// スクールコース一覧取得
function* fetchSchoolCourseList({ payload }: ReturnType<typeof getSchoolCourseList>) {
    try {
        const res: AxiosResponse<Array<SchoolCourseData>> = yield call(SchoolsApi.getSchoolCourseList, payload.token, payload.schoolId);
        yield put(setSchoolCourseList(res.data));
    } catch (e) {
        // TODO 404
        yield put(setSchoolCourseList(null));
    }
}

export function* getSchoolCourseAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_COURSE_LIST, fetchSchoolCourseList);
}

// スクールコース詳細取得
function* fetchSchoolCourseDetail({ payload }: ReturnType<typeof getSchoolCourseDetail>) {
    try {
        const res: AxiosResponse<SchoolCourseData> = yield call(SchoolsApi.getSchoolCourseDetail, payload.token, payload.courseId);
        // スケジュールの時間を文字列にする
        const schoolCourseData: SchoolCourseData = res.data;
        schoolCourseData.scheduleWeekSchedule = [...numberScheduleToString(schoolCourseData.scheduleWeekSchedule)];
        yield put(setSchoolCourseDetail(schoolCourseData));
        yield put(setSchoolCourseEditData(schoolCourseToCourseInputData(schoolCourseData)));
    } catch (e) {
        // TODO 404
        yield put(setSchoolCourseDetail(null));
    }
}

export function* getSchoolCourseDetailAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_COURSE_DETAIL, fetchSchoolCourseDetail);
}

// スクールコース新規登録
function* postSchoolNewCourse({ payload }: ReturnType<typeof postNewSchoolCourse>) {
    try {
        const res: AxiosResponse<SchoolCourseData> = yield call(SchoolsApi.postCreateSchoolCourse, payload.token, payload.schoolId, payload.data);
        yield put(setSchoolCourseDetail(res.data));
        yield put(setSchoolNewCourseInput(schoolDetailCourseNewInitial));
        yield put(setSchoolNewCourseValidate(schoolDetailCourseNewValidateInitial));
        if (payload.withPublish) {
            yield put(patchSchoolCoursePublish(payload.token, payload.schoolId, res.data.id.toString(), true));
        }
        yield put(push(`/school/${payload.schoolId}/course`));
    } catch (e) {
        // TODO 404
        yield put(setSchoolCourseDetail(null));
    }
}

export function* postSchoolCourseAsync() {
    yield takeEvery(SchoolsActionTypes.POST_NEW_SCHOOL_COURSE, postSchoolNewCourse);
}

// スクールコース公開
function* patchSchoolDetailCoursePublish({ payload }: ReturnType<typeof patchSchoolCoursePublish>) {
    try {
        yield call(SchoolsApi.patchPublishSchoolCourse, payload.authToken, payload.courseId);
        // レスポンスは使用しない 最新取得
        if (payload.inList) {
            yield put(getSchoolCourseList(payload.authToken, Number(payload.schoolId)));
        } else {
            yield put(getSchoolCourseDetail(payload.authToken, Number(payload.courseId)));
        }
    } catch (e) {
        yield put(setSchoolCourseDetail(null));
    }
}

export function* patchSchoolCoursePublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_SCHOOL_COURSE_PUBLISH, patchSchoolDetailCoursePublish);
}

// スクールコース非公開
function* patchSchoolDetailCourseUnPublish({ payload }: ReturnType<typeof patchSchoolCourseUnPublish>) {
    try {
        yield call(SchoolsApi.patchUnPublishSchoolCourse, payload.authToken, payload.courseId);
        // レスポンスは使用しない 最新取得
        if (payload.inList) {
            yield put(getSchoolCourseList(payload.authToken, Number(payload.schoolId)));
        } else {
            yield put(getSchoolCourseDetail(payload.authToken, Number(payload.courseId)));
        }
    } catch (e) {
        yield put(setSchoolCourseDetail(null));
    }
}

export function* patchSchoolCourseUnPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_SCHOOL_COURSE_UN_PUBLISH, patchSchoolDetailCourseUnPublish);
}

// スクールコース情報更新
function* putSchoolCourse({ payload }: ReturnType<typeof putSchoolCourseEditData>) {
    try {
        yield call(SchoolsApi.putSchoolCourse, payload.token, payload.courseId, payload.data);
        // レスポンスは使用しない 最新取得
        yield put(getSchoolCourseDetail(payload.token, Number(payload.courseId)));
        yield put(setSchoolCourseEditFlag(false));
    } catch (e) {
        yield put(setSchoolCourseDetail(null));
        yield put(setSchoolCourseEditFlag(false));
    }
}

export function* putSchoolCourseAsync() {
    yield takeEvery(SchoolsActionTypes.PUT_SCHOOL_COURSE_DATA, putSchoolCourse);
}
