import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';

type BreadCrumbProps = {
    data:Array<
    {
        text:string;
        path:string;
    }
    >
};


export const BreadCrumb: React.FC<BreadCrumbProps> = (props: BreadCrumbProps) => {
    const {data} = props;
    return(
        <>
            {data.map((d,index)=>
                index===data.length-1 ?
                    <Link to={d.path} className={styles.breadCrumb} key={d.path}>{d.text}</Link>
                    :
                    <Link to={d.path} className={`${styles.breadCrumb} ${styles["breadCrumb--withArrow"]}`} key={d.path} >{d.text}</Link>
            )}
        </>
    )}