import { InputValidateTypeEnum } from './InputValidateType.enum';

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passRegex = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
// eslint-disable-next-line no-irregular-whitespace
const katakanaRegex = /^([ァ-ヴ][ァ-ヴー・ 　]+)$/i;
const telRegex = /^([0-9]+)$/i;
const priceRegex = /^(0|[1-9]\d*|[1-9]\d{0,2}(,\d{3})+)$/i;

const urlRegex = /^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/;

const twitterUrlRegex = /^https:\/\/twitter.com\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+$/;
const instagramUrlRegex = /^https:\/\/www.instagram.com\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+$/;
const lineAtUrlRegex = /^https:\/\/(line\.me|(page\.)?line\.me)(\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?$/;
const facebookUrlRegex = /^https:\/\/www.facebook.com\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+$/;

export const inputTextValidate = (
    inputText: string,
    type: InputValidateTypeEnum,
    require: boolean,
    minLength: number | null
): { errorText: string; flag: boolean } => {
    if (require && inputText === '') {
        return {
            errorText: '必須入力です',
            flag: false,
        };
    }

    if (!require && inputText === '') {
        return {
            errorText: '',
            flag: true,
        };
    }

    switch (type) {
        case InputValidateTypeEnum.EMAIL:
            if (!emailRegex.test(inputText)) {
                return {
                    errorText: 'メールアドレスが不正です',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.PASSWORD:
            if (inputText.length < 8) {
                return {
                    errorText: 'パスワードは8文字以上で入力してください',
                    flag: false,
                };
            }

            if (!passRegex.test(inputText)) {
                return {
                    errorText: 'パスワードは英字大文字小文字、数字、記号をすべて含む8桁以上で入力してください',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.KATAKANA:
            if (!katakanaRegex.test(inputText)) {
                return {
                    errorText: '全角カタカナで入力してください',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.TEL:
            if (!telRegex.test(inputText)) {
                return {
                    errorText: '半角数字で入力してください',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.PRICE:
            if (!priceRegex.test(inputText)) {
                return {
                    errorText: '値段を正しく入力してください',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.TWITTER_URL:
            if (!twitterUrlRegex.test(inputText)) {
                return {
                    errorText: 'TiwtterのURLを正しく入力してください',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.INSTAGRAM_URL:
            if (!instagramUrlRegex.test(inputText)) {
                return {
                    errorText: 'InstagramのURLを正しく入力してください',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.LINE_AT_URL:
            if (!lineAtUrlRegex.test(inputText)) {
                return {
                    errorText: 'Lint＠のURLを正しく入力してください',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.FACEBOOK_URL:
            if (!facebookUrlRegex.test(inputText)) {
                return {
                    errorText: 'FacebookのURLを正しく入力してください',
                    flag: false,
                };
            }
            break;

        case InputValidateTypeEnum.ORIORI_URL:
            if (!urlRegex.test(inputText)) {
                return {
                    errorText: 'URLを正しく入力してください',
                    flag: false,
                };
            }
            break;

        default:
            break;
    }

    if (minLength && inputText.length < minLength) {
        return {
            errorText: `${minLength}文字以上入力してください`,
            flag: false,
        };
    }
    return {
        errorText: '',
        flag: true,
    };
};
