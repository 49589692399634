export const targetAgeMaster: Array<{ value: string; label: string }> = [
    { value: '1', label: '0歳' },
    { value: '2', label: '1歳' },
    { value: '3', label: '2歳' },
    { value: '4', label: '3歳' },
    { value: '5', label: '年少' },
    { value: '6', label: '年中' },
    { value: '7', label: '年長' },
    { value: '8', label: '小1' },
    { value: '9', label: '小2' },
    { value: '10', label: '小3' },
    { value: '11', label: '小4' },
    { value: '12', label: '小5' },
    { value: '13', label: '小6' },
    { value: '14', label: '中1' },
    { value: '15', label: '中2' },
    { value: '16', label: '中3' },
    { value: '17', label: '高1' },
    { value: '18', label: '高2' },
    { value: '19', label: '高3' },
];
