import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { Header } from '../../../../components/Common/Header';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { RootState } from '../../../../redux/store';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { SchoolDetailCourseType } from '../../../../redux/reducers/domain/schools/type/Course/SchoolDetailCourse.type';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { GreenBtn } from '../../../../components/Common/GreenBtn';
import { WhiteBtn } from '../../../../components/Common/WhiteBtn';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import {
    getSchoolCourseDetail,
    patchSchoolCoursePublish,
    patchSchoolCourseUnPublish,
    putSchoolCourseEditData,
    setSchoolCourseEditData,
    setSchoolCourseEditFlag,
    setSchoolCourseEditValidate,
} from '../../../../redux/actions/domain/schools/schoolCourse';
import { SchoolDetailInstructorType } from '../../../../redux/reducers/domain/schools/type/Instructor/SchoolDetailInstructor.type';
import { TextInput } from '../../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../../components/Common/Input/TextInput/InputValidateType.enum';
import { WeekScheduleInput } from '../../../../components/SchoolInput/WeekScheduleInput';
import { ImageUploader, ImageUploaderSize } from '../../../../components/SchoolInput/ImageUploader';
import { TargetAgeInput } from '../../../../components/SchoolInput/TargetAgeInput';
import { TextArea } from '../../../../components/Common/Input/TextArea';
import { CourseFeesInput } from '../../../../components/SchoolInput/CourseFeesInput';
import { CourseTimeInput } from '../../../../components/SchoolInput/CourseTimeInput';
import { SchoolDetailCourseInputType } from '../../../../redux/reducers/domain/schools/type/Course/SchoolDetailCourseNew.type';

type SchoolCourseDetailParamTypes = {
    schoolId: string;
    courseId: string;
};

export const SchoolDetailCourseDetail: React.FC = () => {
    const { schoolId, courseId } = useParams<SchoolCourseDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolCourseStore: SchoolDetailCourseType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailCourse);
    const schoolInstructorStore: SchoolDetailInstructorType = useSelector(
        (state: RootState) => state.rootReducer.domain.schools.SchoolDetailInstructor
    );
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(getSchoolCourseDetail(authToken, Number(courseId)));
    }, [authToken, dispatch, schoolId, courseId]);

    if (
        schoolStore.schoolDetailData !== null &&
        schoolCourseStore.detail !== null &&
        schoolCourseStore.edit !== null &&
        schoolCourseStore.editValidate !== null
    ) {
        const course = schoolCourseStore.detail;
        return (
            <>
                <Header />
                <OwnerApplication />
                <TabBar currentPage="/school" />
                <div className={styles.schoolContainer}>
                    <BreadCrumb
                        data={[
                            { text: '教室一覧', path: '/school' },
                            { text: `${schoolStore.schoolDetailData.name}`, path: `/school/${schoolId}/course/${courseId}` },
                        ]}
                    />
                    <SchoolHeader data={schoolStore.schoolDetailData} />
                    <SchoolTabBar currentPage={`/school/${schoolId}/course`} schoolId={schoolId} />
                    <Link to={`/school/${schoolId}/course`}>
                        <a className={styles.schoolDetailBackBtn}>&lt;コース一覧に戻る</a>
                    </Link>
                    <SchoolH3
                        otherEditPlace={null}
                        text={`コース${courseId}`}
                        editable={!schoolCourseStore.editFlag}
                        ButtonFunc={() => {
                            if (!schoolCourseStore.editFlag) {
                                dispatch(setSchoolCourseEditFlag(true));
                            } else {
                                dispatch(setSchoolCourseEditFlag(false));
                                dispatch(getSchoolDetail(authToken, Number(schoolId)));
                                dispatch(getSchoolCourseDetail(authToken, Number(courseId)));
                            }
                        }}
                        isShow={schoolCourseStore.detail.showStatus}
                    />
                    {!schoolCourseStore.editFlag ? (
                        <div className={styles.schoolInfoWrap}>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>コース名</div>
                                <div className={styles.schoolInfo__text}>{course.name}</div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>メイン画像</div>
                                {course.mainImageUrl && (
                                    <div className={styles.schoolDetailImgWrapper}>
                                        <img src={course.mainImageUrl} alt="schoolImage" />
                                    </div>
                                )}
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>スケジュール</div>
                                <table className={styles.schoolDetailInfoSchedule}>
                                    <tbody>
                                        <tr>
                                            <th className={course.scheduleWeekSchedule[0] ? '' : styles.holiday}>月</th>
                                            <th className={course.scheduleWeekSchedule[1] ? '' : styles.holiday}>火</th>
                                            <th className={course.scheduleWeekSchedule[2] ? '' : styles.holiday}>水</th>
                                            <th className={course.scheduleWeekSchedule[3] ? '' : styles.holiday}>木</th>
                                            <th className={course.scheduleWeekSchedule[4] ? '' : styles.holiday}>金</th>
                                            <th className={styles.saturday}>土</th>
                                            <th className={styles.sunday}>日</th>
                                        </tr>
                                        <tr>
                                            {course.scheduleWeekSchedule.map((d, index) =>
                                                d ? (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <td key={`course_schedule_${index}`}>
                                                        {d.from.toString().slice(0, -2)}
                                                        {d.from.toString().slice(-2)}
                                                        <br />-{d.to.toString().slice(0, -2)}
                                                        {d.to.toString().slice(-2)}
                                                        <br />
                                                    </td>
                                                ) : (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <td key={`course_schedule_${index}`} className={styles.holiday}>
                                                        休
                                                    </td>
                                                )
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>対象年齢</div>
                                <div className={styles.schoolInfo__text}>
                                    {course.schoolTargetAge[0] ? course.schoolTargetAge[0].text : ''}
                                    {course.schoolTargetAge.length > 1 && <>〜{course.schoolTargetAge[course.schoolTargetAge.length - 1].text}</>}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>受講条件</div>
                                <div className={styles.schoolInfo__text}>{course.requirements}</div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>コース説明</div>
                                <div className={styles.schoolInfo__text}>{course.description}</div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>料金・費用について</div>
                                <div className={styles.schoolInfo__text}>
                                    入会費：{course.membershipFee ? course.membershipFee.toLocaleString() : '-'}円<br />
                                    月会費：{course.monthlyFee ? course.monthlyFee.toLocaleString() : '-'}円<br />
                                    年会費：{course.annualFee ? course.annualFee.toLocaleString() : '-'}円<br />
                                    教材費・その他費用：{course.textFee ? course.textFee.toLocaleString() : '-'}円<br />
                                    <br />
                                    費用に対する捕捉：{course.feeRemark}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>受講について</div>
                                <div className={styles.schoolInfo__text}>
                                    １回あたりの受講時間：
                                    <span>{course.timeMinutesFrom && course.timeMinutesFrom}</span>分 〜
                                    <span>{course.timeMinutesTo && course.timeMinutesTo}</span>分<br />
                                    <br />
                                    受講に関する捕捉：{course.timeRemarks}
                                </div>
                            </div>
                            <div className={styles.schoolInfo}>
                                <div className={styles.schoolInfo__title}>講師紹介</div>
                                {course.schoolInstructor && course.schoolInstructor.length > 0 && (
                                    <div className={styles.schoolCourseDetailInstructorWrap}>
                                        {course.schoolInstructor.map((d) => (
                                            <div key={`course_id_${d.id}`} className={styles.schoolCourseDetailInstructor}>
                                                <div className={styles.schoolCourseDetailInstructor__head}>
                                                    {d.imageUrl && (
                                                        <img className={styles.schoolCourseDetailInstructor__img} src={d.imageUrl} alt="講師画像" />
                                                    )}
                                                    <div className={styles.schoolCourseDetailInstructor__info}>
                                                        <div className={styles.schoolCourseDetailInstructor__name}>名前：{d.name}</div>
                                                        {/* <div className={styles.schoolCourseDetailInstructor__time}>講師歴：講師歴は未実装？</div> */}
                                                    </div>
                                                </div>
                                                <div className={styles.schoolCourseDetailInstructor__body}>紹介文：{d.description}</div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <Link to={`/school/${schoolId}/course`}>
                                <a className={`${styles.schoolDetailBackBtn} ${styles['schoolDetailBackBtn--bottom']}`}>&lt;コース一覧に戻る</a>
                            </Link>
                            <div className={styles.schoolDetailBtnWrap}>
                                <GreenBtn
                                    text="公開する"
                                    style={{}}
                                    handleClick={() => {
                                        dispatch(patchSchoolCoursePublish(authToken, schoolId, courseId, true));
                                        history.push(`/school/${schoolId}/course`);
                                    }}
                                    isLink={false}
                                    path=""
                                />
                                <WhiteBtn
                                    style={null}
                                    text="非公開にする"
                                    handleClick={() => {
                                        dispatch(patchSchoolCourseUnPublish(authToken, schoolId, courseId, true));
                                        history.push(`/school/${schoolId}/course`);
                                    }}
                                    isLink={false}
                                    path="/"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.schoolInfoWrap}>
                            <div className={styles.editInputWrap}>
                                <div className={styles.editInputLabel}>コース名</div>
                                <TextInput
                                    label=""
                                    name="courseName"
                                    id="courseName"
                                    require
                                    inputType="text"
                                    validateType={InputValidateTypeEnum.TEXT}
                                    annotation=""
                                    changeReturnFunc={(text: string, validate: boolean) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                name: text,
                                            })
                                        );
                                        dispatch(
                                            setSchoolCourseEditValidate({
                                                ...schoolCourseStore.editValidate,
                                                name: validate,
                                            })
                                        );
                                    }}
                                    defaultInput={schoolCourseStore.edit.name}
                                    placeHolder="初心者向けプログラミング初級"
                                    minLength={1}
                                    defaultInputValue={schoolCourseStore.edit.name}
                                    disabled={false}
                                />
                            </div>

                            <div className={styles.editInputLabel}>メイン画像</div>
                            <div className={styles.editInputWrap}>
                                <ImageUploader
                                    uiSize={ImageUploaderSize.BIG}
                                    identificationTitle={null}
                                    identificationText={null}
                                    defaultImageUrl={schoolCourseStore.detail.mainImageUrl}
                                    returnFunc={(file, deleteFlag) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                image: file,
                                                deletingImage: deleteFlag,
                                            })
                                        );
                                    }}
                                />
                            </div>

                            <div className={styles.editInputLabel}>スケジュール</div>
                            <div className={styles.editInputWrap}>
                                <WeekScheduleInput
                                    defaultValue={schoolCourseStore.edit.scheduleWeekSchedule}
                                    returnFunc={(data) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                scheduleWeekSchedule: data,
                                            })
                                        );
                                        dispatch(
                                            setSchoolCourseEditValidate({
                                                ...schoolCourseStore.editValidate,
                                                scheduleWeekSchedule: !data.find((d) => d !== null && (d.to === '' || d.from === '')),
                                            })
                                        );
                                    }}
                                    label=""
                                    name="schoolCourseScheduleWeekSchedule"
                                />
                            </div>

                            <div className={styles.editInputLabel}>対象年齢</div>
                            <div className={styles.editInputWrap}>
                                <TargetAgeInput
                                    defaultValue={schoolCourseStore.edit.targetAgeIds}
                                    returnFunc={(data) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                targetAgeIds: data,
                                            })
                                        );
                                        dispatch(
                                            setSchoolCourseEditValidate({
                                                ...schoolCourseStore.editValidate,
                                                targetAgeIds: true,
                                            })
                                        );
                                    }}
                                />
                            </div>
                            <div className={styles.editInputLabel}>受講条件</div>
                            <div className={styles.editInputWrap}>
                                <TextInput
                                    label=""
                                    name="courseRequirements"
                                    id="courseRequirements"
                                    require={false}
                                    inputType="text"
                                    validateType={InputValidateTypeEnum.TEXT}
                                    annotation=""
                                    changeReturnFunc={(text: string, validate: boolean) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                requirements: text,
                                            })
                                        );
                                        dispatch(
                                            setSchoolCourseEditValidate({
                                                ...schoolCourseStore.editValidate,
                                                requirements: validate,
                                            })
                                        );
                                    }}
                                    defaultInput={schoolCourseStore.edit.requirements}
                                    placeHolder="〇〇コースを終了している方"
                                    minLength={1}
                                    defaultInputValue={schoolCourseStore.edit.requirements}
                                    disabled={false}
                                />
                            </div>
                            <div className={styles.editInputLabel}>コース説明</div>
                            <div className={styles.editInputWrap}>
                                <TextArea
                                    label=""
                                    name="courseDescription"
                                    id="courseDescription"
                                    require={false}
                                    validateType={InputValidateTypeEnum.TEXT}
                                    annotation={null}
                                    changeReturnFunc={(text, validate) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                description: text,
                                            })
                                        );
                                        dispatch(
                                            setSchoolCourseEditValidate({
                                                ...schoolCourseStore.editValidate,
                                                description: validate,
                                            })
                                        );
                                    }}
                                    defaultInput={schoolCourseStore.edit.description}
                                    placeHolder="ニュージーランド式メソッドで教えます。新しいプログラミング体験。ニュージーランド式メソッドで教えます。新しいプログラミング体験。ニュージーランド式メソッドで教えます。"
                                    forReview={false}
                                    forInquiry={false}
                                    countFlag
                                    maxLength={1000}
                                    minLength={null}
                                    height={341}
                                />
                            </div>
                            <div className={styles.editInputLabel}>料金・費用について</div>
                            <div className={styles.editInputWrap}>
                                <CourseFeesInput
                                    returnFunc={(input, validate) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                membershipFee: input.membershipFee,
                                                annualFee: input.annualFee,
                                                monthlyFee: input.monthlyFee,
                                                textFee: input.textFee,
                                                feeRemark: input.feeRemark,
                                            })
                                        );
                                        dispatch(
                                            setSchoolCourseEditValidate({
                                                ...schoolCourseStore.editValidate,
                                                membershipFee: validate.membershipFee,
                                                annualFee: validate.annualFee,
                                                monthlyFee: validate.monthlyFee,
                                                textFee: validate.textFee,
                                                feeRemark: validate.feeRemark,
                                            })
                                        );
                                    }}
                                    defaultInput={{
                                        membershipFee: schoolCourseStore.edit.membershipFee,
                                        annualFee: schoolCourseStore.edit.annualFee,
                                        monthlyFee: schoolCourseStore.edit.monthlyFee,
                                        textFee: schoolCourseStore.edit.textFee,
                                        feeRemark: schoolCourseStore.edit.feeRemark,
                                    }}
                                    defaultValidate={{
                                        membershipFee: schoolCourseStore.editValidate.membershipFee,
                                        annualFee: schoolCourseStore.editValidate.annualFee,
                                        monthlyFee: schoolCourseStore.editValidate.monthlyFee,
                                        textFee: schoolCourseStore.editValidate.textFee,
                                        feeRemark: schoolCourseStore.editValidate.feeRemark,
                                    }}
                                />
                            </div>
                            <div className={styles.editInputLabel}>受講について</div>
                            <div className={styles.editInputWrap}>
                                <CourseTimeInput
                                    defaultValue={{
                                        from: schoolCourseStore.edit.timeMinutesFrom,
                                        to: schoolCourseStore.edit.timeMinutesTo,
                                        timeRemarks: schoolCourseStore.edit.timeRemarks,
                                    }}
                                    returnFunc={(from, to) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                timeMinutesFrom: from,
                                                timeMinutesTo: to,
                                            })
                                        );
                                    }}
                                    returnFuncRemarks={(text, validate) => {
                                        dispatch(
                                            setSchoolCourseEditData({
                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                timeRemarks: text,
                                            })
                                        );
                                        dispatch(
                                            setSchoolCourseEditValidate({
                                                ...schoolCourseStore.editValidate,
                                                timeRemarks: validate,
                                            })
                                        );
                                    }}
                                />
                            </div>
                            <SchoolH3 editable={false} otherEditPlace={null} ButtonFunc={null} text="講師紹介" isShow={null} />
                            <div className={styles.schoolInfoWrap}>
                                <ul className={styles.schoolCourseDetailInstructorInputList}>
                                    {schoolInstructorStore.list?.map((d) => (
                                        <li className={styles['schoolCourseDetailInstructorInputListLow--inDetail']} key={d.id}>
                                            <input
                                                type="checkbox"
                                                id={`coach_input_row_${d.id}`}
                                                value={d.id}
                                                checked={
                                                    schoolCourseStore.edit ? schoolCourseStore.edit.instructorIds.some((val) => val === d.id) : false
                                                }
                                                onChange={(e) => {
                                                    if (schoolCourseStore.edit) {
                                                        const newArray = schoolCourseStore.edit.instructorIds.filter((val) => val !== Number(d.id));
                                                        if (e.target.checked) {
                                                            if (schoolCourseStore.edit.instructorIds.length === 3) return;
                                                            newArray.push(d.id);
                                                        }
                                                        dispatch(
                                                            setSchoolCourseEditData({
                                                                ...(schoolCourseStore.edit as SchoolDetailCourseInputType),
                                                                instructorIds: [...newArray],
                                                            })
                                                        );
                                                    }
                                                }}
                                            />
                                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                            <label htmlFor={`coach_input_row_${d.id}`} className={styles.inputLabel} />
                                            {d.imageUrl && (
                                                <div className={styles.schoolCourseDetailInstructorInputListImage}>
                                                    <img src={d.imageUrl} alt="" />
                                                </div>
                                            )}
                                            <div className={styles.schoolCourseDetailInstructorInputListText}>
                                                <div>名前：{d.name}</div>
                                                <div>{d.description}</div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className={styles.courseInstructorDescription}>講師は３人まで選択可能です。</div>
                                <div className={styles.courseInstructorDescription}>
                                    講師の追加、削除編集は
                                    <Link className={styles.linkText} to={`/school/${schoolId}/instructor/`}>
                                        コース一覧
                                    </Link>
                                    から行ってください。
                                </div>
                            </div>

                            <button
                                type="button"
                                className={`${styles.editInputButton} ${
                                    Object.entries(schoolStore.schoolDetailEditValidate).some((d) => !d[1]) ? styles['editInputButton--disable'] : ''
                                }`}
                                onClick={() => {
                                    if (schoolStore.schoolDetailData) {
                                        dispatch(
                                            putSchoolCourseEditData(
                                                authToken,
                                                courseId.toString(),
                                                schoolCourseStore.edit as SchoolDetailCourseInputType
                                            )
                                        );
                                    }
                                }}>
                                {loadingSelector ? '通信中...' : '保存する'}
                            </button>
                        </div>
                    )}
                </div>
                <Footer />
            </>
        );
    }
    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.schoolContainer}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室名', path: '' },
                    ]}
                />
            </div>
            <Footer />
        </>
    );
};
