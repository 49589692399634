import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import styles from './css/style.module.scss';
import { setCommonModalShow, } from '../../../../redux/actions/ui';
import { GreenBtn } from '../../GreenBtn';

export const DisplayText: React.FC = () => {

    const commonModalSelector = useSelector((state: RootState) => state.rootReducer.ui.commonModalData);
    const dispatch = useDispatch();

    return (
        <div className={styles.wrap}>
            {commonModalSelector.displayModalText}

            <div className={styles.buttonWrap}>
                <GreenBtn text='このウィンドウを閉じる' style={{}} handleClick={() => {
                    dispatch(setCommonModalShow(false))
                }}
                isLink={false}
                path='' />
            </div>
        </div>
    )

}