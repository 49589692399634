export enum InputValidateTypeEnum {
    TEXT,
    EMAIL,
    PASSWORD,
    KATAKANA,
    TEL,
    PRICE,
    ORIORI_URL,
    TWITTER_URL,
    INSTAGRAM_URL,
    LINE_AT_URL,
    FACEBOOK_URL,
}
