
// クライアント情報編集
import { Action } from 'redux';
import { SettingsActions } from './index';
import { EditClientInputType } from '../../../reducers/domain/settings/type/EditClientInput.type';
import { EditClientValidateType } from '../../../reducers/domain/settings/type/EditClientValidate.type';

export const setClientEditInput = (editInput: EditClientInputType | null): SetClientEditInput => ({
    type: SettingsActions.SET_CLIENT_EDIT_INPUT,
    payload: {
        editInput
    }
});

export interface SetClientEditInput extends Action {
    type: string,
    payload: {
        editInput: EditClientInputType | null
    }
}

export const setClientEditValidate = (editValidate: EditClientValidateType,): SetClientEditValidate => ({
    type: SettingsActions.SET_CLIENT_EDIT_VALIDATE,
    payload: {
        editValidate
    }
});

export interface SetClientEditValidate extends Action {
    type: string,
    payload: {
        editValidate: EditClientValidateType,
    }
}

export const setClientEditFlag = (editFlag: boolean,): SetClientEditFlag => ({
    type: SettingsActions.SET_CLIENT_EDIT_FLAG,
    payload: {
        editFlag
    }
});

export interface SetClientEditFlag extends Action {
    type: string,
    payload: {
        editFlag: boolean,
    }
}

export const putClientEdit = (authToken: string, editInput: EditClientInputType,): PutClientEdit => ({
    type: SettingsActions.PUT_CLIENT_EDIT,
    payload: {
        editInput,
        authToken
    }
});

export interface PutClientEdit extends Action {
    type: string,
    payload: {
        editInput: EditClientInputType,
        authToken: string
    }
}
