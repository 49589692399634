import { changeEmailInitialize } from './Initialize/changeEmailInitialize';
import { UserActions } from '../../../actions/domain/user';
import { ChangeEmailType } from './type/ChangeEmail.type';

export const changeEmail = (state = changeEmailInitialize, action: any): ChangeEmailType => {
    switch (action.type) {
        case UserActions.SET_CHANGE_CLIENT_USER_EMAIL_OFFER_RESULT:
            return {
                ...state,
                result: action.payload.result,
            }
        case UserActions.SET_CHANGE_CLIENT_USER_EMAIL_REGISTER_RESULT:
            return {
                ...state,
                approveResult: action.payload.result,
            }
        default:
            return state;
    }
}