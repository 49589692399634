import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'querystring';
import { SchoolDetailParamTypes } from '../type/SchoolDetaillParam.type';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { Header } from '../../../../components/Common/Header';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import { ShowMoreBtn } from '../../../../components/Common/ShowMoreBtn';
import { PageNation, TargetPage } from '../../../../components/Common/PageNation';
import { SchoolCouponCard } from '../../../../components/Schools/SchoolCouponCard';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { SchoolDetailCouponType } from '../../../../redux/reducers/domain/schools/type/Coupon/SchoolDetailCoupon.type';
import { RootState } from '../../../../redux/store';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import { getSchoolCouponList } from '../../../../redux/actions/domain/schools/schoolCoupon';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolPageExplanation } from '../../../../components/Schools/SchoolPageExplanation';
import { SCHOOL_COUPON_COUNT } from '../../../../util/const/schoolCouponCount';


export const SchoolDetailCouponList:  React.FC = () => {
    const { schoolId } = useParams<SchoolDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolCouponStore: SchoolDetailCouponType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailCoupon);
    const dispatch = useDispatch();
    const history = useHistory()
    const {search} = useLocation();
    const query = queryString.parse(search.replace('?', ''));
    const page = query.page ? Number(query.page) : 0;

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(getSchoolCouponList(authToken, Number(schoolId), SCHOOL_COUPON_COUNT, page));
    }, [authToken, dispatch, page, schoolId]);

    if (schoolStore.schoolDetailData !== null
    ) {
        return (<>
            <Header />
            <OwnerApplication />
            <TabBar currentPage='/school' />
            <div className={styles.schoolContainer}>
                <BreadCrumb data={[{ text: '教室一覧', path: '/school' },{ text: `${schoolStore.schoolDetailData.name}`, path: `/school/${schoolId}/coupon` }]}  />
                <SchoolHeader data={schoolStore.schoolDetailData} />
                <SchoolTabBar currentPage={`/school/${schoolId}/coupon`} schoolId={schoolId} />
                <SchoolPageExplanation title='クーポン' textDom={<>各クーポンの詳細を追加、編集します。</>} btnText="＋ クーポンを追加する" path={`/school/${schoolId}/coupon/new`}/>
                <SchoolH3 editable={!schoolStore.noticeEditFlag} otherEditPlace={null}
                    ButtonFunc={null} text='クーポン一覧' isShow={null}/>
                {schoolCouponStore.list && schoolCouponStore.list.length > 0 && (
                    <div>
                        {schoolCouponStore.list.map((d, index) =>
                            <SchoolCouponCard key={`blog_id_${d.id}`} schoolId={schoolId} schoolCouponData={d} authToken={authToken} index={index} listLength={Number(schoolCouponStore.list?.length)}/>
                        )}
                    </div>
                )}
                {schoolCouponStore.list && schoolCouponStore.list.length > 0 && (
                    <>
                        {page < Math.floor(schoolCouponStore.list.length / SCHOOL_COUPON_COUNT) && (
                            <ShowMoreBtn
                                text={`次の${SCHOOL_COUPON_COUNT}件を見る`}
                                onClick={() => history.push(`/school/${schoolId}/coupon/?page=${page + 1}`)}
                            />
                        )}
                        <PageNation page={page} dataLength={schoolCouponStore.listCount} path="/school" targetPage={TargetPage.SCHOOL}/>
                    </>
                )}

            </div>
            <Footer />
        </>);
    }
    return <>
        <Header />
        <OwnerApplication />
        <TabBar currentPage='/school' />
        <div className={styles.schoolContainer}>
            <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, { text: '教室名', path: '' }]} />
        </div>
        <Footer />
    </>;
};
