import { SchoolDetailEditImagesType } from '../../type/Top/SchoolDetailEditImages.type';

export const schoolDetailEditImagesInitial: SchoolDetailEditImagesType = {
    image0: null,
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    image6: null,
    image7: null,
    image8: null,
    image9: null,
}