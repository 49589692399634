export const MyPageActionTypes = {
    GET_MY_SCHOOLS: 'domain/my_page/get_my_schools',
    SET_MY_SCHOOLS: 'domain/my_page/set_my_schools',
    SET_MY_SCHOOLS_COUNT: 'domain/my_page/set_my_schools_count',
    SET_MY_SCHOOLS_CURRENT_PAGE: 'domain/my_page/set_my_schools_current_page',
    GET_MY_SCHOOLS_REVIEWS: 'domain/my_page/get_my_schools_reviews',
    SET_MY_SCHOOLS_REVIEWS: 'domain/my_page/set_my_schools_reviews',
    GET_MY_INQUIRIES:'domain/my_page/get_my_inquiries',
    SET_MY_INQUIRIES:'domain/my_page/set_my_inquiries',
    SET_MY_INQUIRIES_COUNT:'domain/my_page/set_my_inquiries_count',
    SET_MY_INQUIRIES_CURRENT_PAGE:'domain/my_page/set_my_inquiries_current_page',
}