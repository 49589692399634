import { combineReducers } from 'redux';
import { client } from './client';
import { schools } from './schools';
import { myPage } from './mypage';
import { inquiry } from './inquiry';
import { user } from './user';
import { settings } from './settings';
import { payment } from './payment';
import { contracts } from './contracts';

export const domain = combineReducers({
    client,
    schools,
    myPage,
    inquiry,
    user,
    settings,
    payment,
    contracts,
});
