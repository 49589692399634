export const schoolCategoryMaster: Array<{ value: string; label: string }> = [
    { value: '1', label: 'スポーツ・運動' },
    { value: '2', label: 'プログラミング・科学' },
    { value: '3', label: '音楽' },
    { value: '4', label: 'アート・芸術' },
    { value: '5', label: '武道・格闘技' },
    { value: '6', label: 'ダンス・バレエ' },
    { value: '7', label: '学習・塾' },
    { value: '8', label: 'その他' },
];
