import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SchoolDetailData } from '../../../../redux/reducers/domain/schools/type/SchoolDetailData.type';
import { setNewSchoolInput, setNewSchoolInputValidate } from '../../../../redux/actions/domain/schools/newSchool';
import { schoolDataToCreateSchoolData } from '../../../../redux/reducers/domain/schools/converter/schoolDataToCreateSchoolData';
import { NewSchoolInputValidateType } from '../../../../redux/reducers/domain/schools/type/New/NewSchoolInputValidate.type';
import { patchAllSchoolPublish, patchAllSchoolUnPublish } from '../../../../redux/actions/domain/schools/schoolIndexEdit';
import { RootState } from '../../../../redux/store';

/*
 * スクール一覧のチェックボックスと連動した操作機能
 *
 * スクールの選択状態によって機能が変化する
 *
 * 0: すべて非活性
 * 1: 確認、複製、ステータス変更、予算を変更
 * 2以上: ステータスを変更
 *
 * 確認；詳細画面へ
 * 複製；初期値を入れた形で新規登録へ遷移
 * ステータスを変更；公開ステータスを変更 有料公開と公開を停止のみ
 * 予算を変更：予算変更モーダルを表示する
 * */

export const newSchoolInputValidateInitial: NewSchoolInputValidateType = {
    name: true,
    nameKana: true,
    copy: true,
    addressDetail: true,
    nearestStationText: true, // 任意
    costAverageFrom: true, // 任意
    costAverageTo: true, // 任意
    description: true,
    homepageUrl: true, // 任意
    twitterUrl: true, // 任意
    instagramUrl: true, // 任意
    facebookUrl: true, // 任意
    lineUrl: true, // 任意
    establishmentDate: true, // 任意
    questions: true, // 任意
    prefectureId: true,
    townId: true,
    streetId: true,
    note: true,
    scheduleWeekSchedule: true,
    nearestStation: true, // 任意
    nearestBusstop: true, // 任意
    categoryId: true,
    keywordIds: true,
    tagIds: true,
    stationIds: true,
    busstopIds: true,
    classStyleIds: true,
    targetAgeIds: true,
    realSchool: true,
};

export const useSchoolList = (): [
    { checkedSchools: Array<{ id: number; data: SchoolDetailData }>; statusSelected: string },
    {
        checkSchool: (schoolId: number, schoolData: SchoolDetailData) => void;
        checkAllSchool: (schoolsData: Array<SchoolDetailData>, checkFlag: boolean) => void;
        pushDetailButton: () => void;
        pushDuplicateButton: () => void;
        changeStatus: (status: string, count: number, page: number) => void;
    }
] => {
    const [checkedSchools, setCheckedSchools] = useState<Array<{ id: number; data: SchoolDetailData }>>([]);
    const [statusSelected, setStatusSelected] = useState('');
    const authToken = useSelector((store: RootState) => store.rootReducer.app.authToken);

    const history = useHistory();
    const dispatch = useDispatch();

    // スクール選択
    const checkSchool = useCallback(
        (schoolId: number, schoolData: SchoolDetailData) => {
            const targetIndex = checkedSchools.findIndex((school) => school.id === schoolId);
            if (targetIndex === -1) {
                checkedSchools.push({ id: schoolId, data: schoolData });
            } else {
                checkedSchools.splice(targetIndex, 1);
            }
            setCheckedSchools([...checkedSchools]);
        },
        [checkedSchools]
    );

    const checkAllSchool = useCallback((schoolsData: Array<SchoolDetailData>, checkFlag: boolean) => {
        if (checkFlag) {
            setCheckedSchools(
                schoolsData.map((d) => ({
                    id: d.id,
                    data: d,
                }))
            );
        } else {
            setCheckedSchools([]);
        }
    }, []);

    // 確認ボタン 詳細へ遷移
    const pushDetailButton = useCallback(() => {
        if (checkedSchools.length !== 1) return;
        history.push(`/school/${checkedSchools[0].id}`);
    }, [checkedSchools, history]);

    const pushDuplicateButton = useCallback(() => {
        if (checkedSchools.length !== 1) return;

        dispatch(setNewSchoolInput(schoolDataToCreateSchoolData(checkedSchools[0].data)));
        dispatch(setNewSchoolInputValidate(newSchoolInputValidateInitial));

        history.push('/school/new_1');
    }, [checkedSchools, dispatch, history]);

    const changeStatus = useCallback(
        (status: string, count: number, page: number) => {
            if (checkedSchools.length === 0) return;

            if (status === '1') {
                dispatch(
                    patchAllSchoolPublish(
                        authToken,
                        checkedSchools.map((d) => d.id.toString()),
                        true,
                        page,
                        count
                    )
                );
            } else if (status === '2') {
                dispatch(
                    patchAllSchoolUnPublish(
                        authToken,
                        checkedSchools.map((d) => d.id.toString()),
                        true,
                        page,
                        count
                    )
                );
            }

            setStatusSelected('');
            setCheckedSchools([]);
        },
        [authToken, checkedSchools, dispatch]
    );

    // const changeBudgetButtonPush = useCallback(() => {
    //     if (checkedSchools.length !== 1) return;
    //
    //
    // }, [])
    return [
        { checkedSchools, statusSelected },
        { checkSchool, checkAllSchool, pushDetailButton, pushDuplicateButton, changeStatus },
    ];
};
