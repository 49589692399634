import { combineReducers } from 'redux';
import { AppActionTypes } from '../../actions/app';

const authToken = (state = '', action: any) => {
    switch (action.type) {
        case AppActionTypes.SET_AUTH_TOKEN:
            return action.payload.authToken;
        default:
            return state;
    }
};

const clientDataEntered = (state = false, action: any) => {
    switch (action.type) {
        case AppActionTypes.SET_CLIENT_DATA_ENTERED:
            return action.payload.entered
        default:
            return state;
    }
}

export const app = combineReducers({
    authToken,
    clientDataEntered,
});
