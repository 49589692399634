import React from 'react';
import styles from './css/style.module.scss';


type SchoolDetailTopNoticeProps = {
    text:string;
};

export const SchoolDetailTopNotice: React.FC<SchoolDetailTopNoticeProps> = (props: SchoolDetailTopNoticeProps) => {
    const {text} = props;

    return(
        <>
            <div className={styles.schoolDetailTopNotice}>
                <div className={styles.schoolDetailTopNotice__title}>お知らせ</div>
                <div className={styles.schoolDetailTopNotice__text}>{text}</div>
            </div>
        </>
    )}