import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { RootState } from '../../../redux/store';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import styles from '../../../asset/css/accountCommon.module.scss';
import { WhiteBtn } from '../../../components/Common/WhiteBtn';
import { postChangeClientUserEmailCode } from '../../../redux/actions/domain/user/changeEmail';
import completeMainImg from './img/complete_main.png';
import failedMainImg from './img/main_failed.png';
import { setSignInEmail, setSignInEmailValidate, setSignInPassword, setSignInPasswordValidate } from '../../../redux/actions/domain/client/signIn';
import { getAuthToken } from '../../../redux/actions/app';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { TextInput } from '../../../components/Common/Input/TextInput';

export const ChangeEmailRegister: React.FC = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const clientUserSelector = useSelector((state: RootState) => state.rootReducer.domain.user);
    const signInSelector = useSelector((state: RootState) => state.rootReducer.domain.client.signInInput);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();
    const token = query.get('token');

    useEffect(() => {
        if (authToken !== '' && token) {
            dispatch(postChangeClientUserEmailCode(authToken, token));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken]);

    if (authToken === '' && !token) {
        return <Redirect to="/" />;
    }

    if (authToken === '') {
        return (
            <>
                <Header />
                <div className={styles.authContainer}>
                    <h1>一度変更前のメールアドレスでログインをお願いいたします。</h1>
                    <div className={styles.grayBg}>
                        <TextInput
                            label="メールアドレス"
                            name="textInput"
                            id="textInput"
                            require
                            inputType="email"
                            validateType={InputValidateTypeEnum.EMAIL}
                            annotation=""
                            changeReturnFunc={(text, validate) => {
                                dispatch(setSignInEmail(text));
                                dispatch(setSignInEmailValidate(validate));
                            }}
                            defaultInput=""
                            placeHolder="メールアドレスを入力"
                            minLength={0}
                            defaultInputValue={signInSelector.email}
                            disabled={false}
                        />

                        <TextInput
                            label="パスワード"
                            name="textInput2"
                            id="textInput2"
                            require
                            inputType="password"
                            validateType={InputValidateTypeEnum.PASSWORD}
                            annotation=""
                            changeReturnFunc={(text, validate) => {
                                dispatch(setSignInPassword(text));
                                dispatch(setSignInPasswordValidate(validate));
                            }}
                            defaultInput=""
                            placeHolder="パスワードを入力"
                            minLength={0}
                            defaultInputValue={signInSelector.password}
                            disabled={false}
                        />

                        {signInSelector.result.reason !== '' && <div>{signInSelector.result.reason}</div>}

                        <GreenBtn
                            text={loadingSelector ? '送信中' : 'ログイン'}
                            style={!(signInSelector.emailValidate && signInSelector.passwordValidate) ? { pointerEvents: 'none' } : {}}
                            handleClick={() => {
                                dispatch(getAuthToken(signInSelector.email, signInSelector.password));
                            }}
                            isLink={false}
                            path=""
                        />
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    if (clientUserSelector.changeEmail.approveResult.success) {
        return (
            <>
                <Header />
                <div className={styles.authContainer}>
                    <h1>メールアドレスの認証が完了しました</h1>
                    <div className={styles.confirmation}>
                        <div className={styles.confirmation__conversation}>Thank you !!</div>
                        <div className={styles.completeMainImg}>
                            <img src={completeMainImg} alt="" />
                        </div>
                        <GreenBtn style={null} text="ログイン" handleClick={null} isLink path="/" />
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    if (clientUserSelector.changeEmail.approveResult.success === null) return <></>;

    return (
        <>
            <Header />
            <div className={styles.authContainer}>
                <h1>このメールアドレスの認証ができません</h1>
                <div className={styles.confirmation}>
                    <div className={styles.confirmation__conversation}>Sorry..</div>
                    <p className={styles.confirmation__text}>メールアドレスの認証ができません。下記の方法をお試しください。</p>
                    <div className={styles.confirmation__sub}>
                        <div className={styles.confirmation__subImageFailed}>
                            <img src={failedMainImg} alt="" />
                        </div>
                        <ul className={styles.confirmation__emphasisList}>
                            <li>
                                既に認証済みの可能性があります
                                <br />
                                ログイン画面で、ログインをお試しください
                                <WhiteBtn style={null} text="ログインへ" handleClick={null} isLink path="/" />
                            </li>
                            <li>
                                認証メールの有効期限がきれている可能性があります
                                <br />
                                新規登録画面で、再度、新規登録をしてください
                                <WhiteBtn style={null} text="新規登録へ" handleClick={null} isLink path="/signup" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
