import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styles from './css/style.module.scss';
import { SelectInput } from '../../Common/Input/SelectInput';

type YearMonthSelectProps = {
    defaultValue: string | null,
    returnFunc: (val: string | null) => void,
}

export const YearMonthSelect: React.FC<YearMonthSelectProps> = (props) => {
    const { defaultValue, returnFunc } = props;

    const [establishmentYear, setEstablishmentYear] = useState<string | null>(defaultValue ? moment(defaultValue.toString()).year().toString() : null);
    const [establishmentMonth, setEstablishmentMonth] = useState<string | null>(defaultValue ? moment(defaultValue.toString()).month().toString() : null);

    useEffect(() => {
        if (establishmentYear && establishmentMonth) {
            returnFunc(`${establishmentYear}-${establishmentMonth}-01`);
        } else {
            returnFunc(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [establishmentYear, establishmentMonth]);

    return (<>
        <div className={styles.label}>開校年月</div>
        <div className={styles.inputWrap}>
            <div className={styles.input}>
                <SelectInput
                    label=''
                    require={false}
                    name='targetAgeFrom'
                    id='targetAgeFrom'
                    data={[...Array(100)].map((_, index) => (Number(new Date().getFullYear().toString()) - index)).reverse().map(d => ({
                        value: d.toString(),
                        label: `${d}年`
                    }))}
                    changeReturnFunc={(text) => {
                        setEstablishmentYear(text);
                        setEstablishmentMonth(null);
                    }}
                    defaultInput={establishmentYear || null}
                    disableSelectText='年'
                    disable={false}
                    selectStartIndex={null}
                    selectEndIndex={null}
                />
            </div>
            <div className={styles.inputCenter} />
            <div className={styles.input}>
                <SelectInput
                    label=''
                    require={false}
                    name='targetAgeTo'
                    id='targetAgeTo'
                    data={[...Array(12)].map((_, index) => (index + 1)).map(d => ({
                        value: d.toString(),
                        label: `${d}月`
                    }))}
                    changeReturnFunc={(text) => {
                        setEstablishmentMonth(text);
                    }}
                    defaultInput={establishmentMonth || null}
                    disableSelectText='下限'
                    disable={false}
                    selectStartIndex={null}
                    selectEndIndex={null}
                />
            </div>
        </div>
    </>);
};