import { Action } from 'redux';
import { Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';
import { PaymentActionTypes } from './index';
import { SchoolDetailData } from '../../../reducers/domain/schools/type/SchoolDetailData.type';
import { PaymentResultErrorTypeEnum } from '../../../reducers/domain/payment/enum/PaymentResultErrorType.enum';

export const setPaymentModalStop = (step: number): SetPaymentModalStep => ({
    type: PaymentActionTypes.SET_PAYMENT_MODAL_STEP,
    payload: { step },
});

export interface SetPaymentModalStep extends Action {
    type: string;
    payload: {
        step: number;
    };
}

export const postPaymentSetup = (authToken: string): PostPaymentSetup => ({
    type: PaymentActionTypes.POST_PAYMENT_SET_UP,
    payload: {
        authToken,
    },
});

export interface PostPaymentSetup extends Action {
    type: string;
    payload: {
        authToken: string;
    };
}

export const setPaymentSecret = (secret: string): SetPaymentSecret => ({
    type: PaymentActionTypes.SET_PAYMENT_INTENT_SECRET,
    payload: { secret },
});

export interface SetPaymentSecret extends Action {
    type: string;
    payload: {
        secret: string;
    };
}

export const putPaymentMethodId = (authToken: string, paymentMethodId: string): PostPaymentMethodId => ({
    type: PaymentActionTypes.PUT_PAYMENT_METHOD_ID,
    payload: {
        authToken,
        paymentMethodId,
    },
});

export interface PostPaymentMethodId extends Action {
    type: string;
    payload: {
        authToken: string;
        paymentMethodId: string;
    };
}

export const setNewPaymentMethodId = (newPaymentMethodId: string | null): SetNewPaymentMethodId => ({
    type: PaymentActionTypes.SET_NEW_PAYMENT_METHOD_ID,
    payload: {
        newPaymentMethodId,
    },
});

export interface SetNewPaymentMethodId extends Action {
    type: string;
    payload: {
        newPaymentMethodId: string | null;
    };
}

export const setBudgetLimit = (budgetLimit: number | null): SetBudgetLimit => ({
    type: PaymentActionTypes.SET_BUDGET_LIMIT,
    payload: {
        budgetLimit,
    },
});

export interface SetBudgetLimit extends Action {
    type: string;
    payload: {
        budgetLimit: number | null;
    };
}

export const setPaymentTargetSchool = (paymentTargetSchool: SchoolDetailData | null): SetPaymentTargetSchool => ({
    type: PaymentActionTypes.SET_PAYMENT_TARGET_SCHOOL,
    payload: {
        paymentTargetSchool,
    },
});

export interface SetPaymentTargetSchool extends Action {
    type: string;
    payload: {
        paymentTargetSchool: SchoolDetailData | null;
    };
}

export const postStartSubscription = (authToken: string, schoolId: string, budgetLimit: number | null): PostStartSubscription => ({
    type: PaymentActionTypes.POST_START_SUBSCRIPTION,
    payload: {
        authToken,
        schoolId,
        budgetLimit,
    },
});

export interface PostStartSubscription extends Action {
    type: string;
    payload: {
        authToken: string;
        schoolId: string;
        budgetLimit: number | null;
    };
}

export const setStripeCardNumberElement = (cardNumberElement: StripeCardNumberElement | null): SetStripeCardNumberElement => ({
    type: PaymentActionTypes.SET_STRIPE_CARD_NUMBER_ELEMENT,
    payload: {
        cardNumberElement,
    },
});

export interface SetStripeCardNumberElement extends Action {
    type: string;
    payload: {
        cardNumberElement: StripeCardNumberElement | null;
    };
}

export const postPaymentMethodToStripe = (
    stripe: Stripe,
    elements: StripeElements,
    paymentSecret: string,
    cardElement: StripeCardNumberElement,
    authToken: string,
    withStartSubscription: boolean,
    schoolId: string | null,
    budgetLimit: number | null
): PostPaymentMethodToStripe => ({
    type: PaymentActionTypes.POST_PAYMENT_METHOD_TO_STRIPE,
    payload: {
        stripe,
        elements,
        paymentSecret,
        cardElement,
        authToken,
        withStartSubscription,
        schoolId,
        budgetLimit,
    },
});

export interface PostPaymentMethodToStripe extends Action {
    type: string;
    payload: {
        stripe: Stripe;
        elements: StripeElements;
        paymentSecret: string;
        cardElement: StripeCardNumberElement;
        authToken: string;
        withStartSubscription: boolean;
        schoolId: string | null;
        budgetLimit: number | null;
    };
}

export const setPaymentResult = (paymentResult: {
    success: boolean;
    error: null | {
        type: PaymentResultErrorTypeEnum;
        text: string;
    };
}): SetPaymentResult => ({
    type: PaymentActionTypes.SET_PAYMENT_RESULT,
    payload: {
        paymentResult,
    },
});

export interface SetPaymentResult extends Action {
    type: string;
    payload: {
        paymentResult: {
            success: boolean;
            error: null | {
                type: PaymentResultErrorTypeEnum;
                text: string;
            };
        };
    };
}

export const initPaymentStore = () => ({
    type: PaymentActionTypes.INIT_PAYMENT_STORE,
});

export const postCancelSubscription = (authToken: string, schoolId: string): PostCancelSubscription => ({
    type: PaymentActionTypes.POST_CANCEL_SUBSCRIPTION,
    payload: {
        authToken,
        schoolId,
    },
});

export interface PostCancelSubscription extends Action {
    type: string;
    payload: {
        authToken: string;
        schoolId: string;
    };
}
