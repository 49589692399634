import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/ja';
import moment from 'moment';
import { SchoolDetailData } from '../../../redux/reducers/domain/schools/type/SchoolDetailData.type';
import styles from './css/style.module.scss';
import { patchSchoolPublish, patchSchoolUnPublish } from '../../../redux/actions/domain/schools/schoolIndexEdit';
import { RootState } from '../../../redux/store';
import { setCommonModalContentType, setCommonModalShow, setCommonModalTitle } from '../../../redux/actions/ui';
import { CommonModalContentTypeEnum } from '../../../redux/reducers/ui/enum/CommonModalContentType.enum';
import { BillingStatus } from '../../../util/enum/BillingStatus.enum';
import {
    initPaymentStore,
    postCancelSubscription,
    postPaymentSetup,
    setPaymentTargetSchool,
} from '../../../redux/actions/domain/payment/schoolSubscription';
import { getClientUser } from '../../../redux/actions/domain/user/clientUser';
import { getClient } from '../../../redux/actions/domain/client/client';

type SchoolHeaderProps = {
    data: SchoolDetailData;
};

export enum SchoolStatusEnum {
    INACTIVE,
    FREE_ACTIVE,
    PAYMENT_ACTIVE,
    PAYMENT_INACTIVE,
}

export const SchoolHeader: React.FC<SchoolHeaderProps> = (props: SchoolHeaderProps) => {
    const { data } = props;
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const dispatch = useDispatch();

    // 表示中かどうかを判定し、その後支払い中かどうかを判定
    const [schoolStatus, setSchoolStatus] = useState<SchoolStatusEnum>(
        data.showStatus
            ? data.billingStatus === BillingStatus.PaidBilling
                ? SchoolStatusEnum.PAYMENT_ACTIVE
                : SchoolStatusEnum.FREE_ACTIVE
            : data.billingStatus === BillingStatus.PaidBilling
                ? SchoolStatusEnum.PAYMENT_INACTIVE
                : SchoolStatusEnum.INACTIVE
    );

    const handleChangeInFree = (e: ChangeEvent<HTMLSelectElement>) => {
        const val: SchoolStatusEnum = Number(e.target.value);
        if (val === SchoolStatusEnum.FREE_ACTIVE) dispatch(patchSchoolPublish(authToken, data.id.toString(), false, null, null));
        if (val === SchoolStatusEnum.INACTIVE) dispatch(patchSchoolUnPublish(authToken, data.id.toString(), false, null, null));
    };

    const handleChangeInPayment = (e: ChangeEvent<HTMLSelectElement>) => {
        const val: SchoolStatusEnum = Number(e.target.value);
        if (val === SchoolStatusEnum.PAYMENT_ACTIVE) {
            dispatch(patchSchoolPublish(authToken, data.id.toString(), false, null, null));
        }
        if (val === SchoolStatusEnum.PAYMENT_INACTIVE) {
            dispatch(patchSchoolUnPublish(authToken, data.id.toString(), false, null, null));
        }
        if (val === SchoolStatusEnum.FREE_ACTIVE) {
            if (
                // eslint-disable-next-line no-alert
                window.confirm(
                    '有料契約をキャンセルします。よろしいでしょうか？(契約締日以前までに再度契約いただければ、再度請求されることはありません。)'
                )
            ) {
                dispatch(postCancelSubscription(authToken, data.id.toString()));
                dispatch(patchSchoolPublish(authToken, data.id.toString(), false, null, null));
            }
        }
        if (val === SchoolStatusEnum.INACTIVE) {
            if (
                // eslint-disable-next-line no-alert
                window.confirm(
                    '有料契約をキャンセルします。よろしいでしょうか？(契約締日以前までに再度契約いただければ、再度請求されることはありません。)'
                )
            ) {
                dispatch(postCancelSubscription(authToken, data.id.toString()));
                dispatch(patchSchoolUnPublish(authToken, data.id.toString(), false, null, null));
            }
        }
    };

    useEffect(() => {
        setSchoolStatus(
            data.showStatus
                ? data.billingStatus === BillingStatus.PaidBilling
                    ? SchoolStatusEnum.PAYMENT_ACTIVE
                    : SchoolStatusEnum.FREE_ACTIVE
                : data.billingStatus === BillingStatus.PaidBilling
                    ? SchoolStatusEnum.PAYMENT_INACTIVE
                    : SchoolStatusEnum.INACTIVE
        );
    }, [data]);

    return (
        <>
            <div className={styles.schoolHeader}>
                <div className={styles.schoolHeader__inner}>
                    <div>
                        <div className={styles.schoolHeader__name}>{data.name}</div>
                        <div className={styles.schoolHeader__place}>
                            【{data.prefecture?.nameJp}】{data.town?.nameJp} | {data.schoolCategory?.text}
                            <br />
                            最寄り駅：{data.nearestStation?.nameJp}
                        </div>
                    </div>
                    <div className={styles.schoolHeader__status}>
                        <div className={styles.schoolHeader__update}>更新日：{moment(data.updatedAt).format('YYYY/MM/DD')}</div>
                        {!data.publishedAt && (
                            <div className={styles.schoolHeader__notPublish}>
                                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.72792 6.5996C2.59992 6.5996 2.47192 6.5436 2.38392 6.4556L0.46392 4.4796C0.27992 4.2876 0.27992 3.9836 0.47192 3.7996C0.66392 3.6156 0.96792 3.6156 1.15192 3.8076L2.73592 5.4396L6.62392 1.4956C6.80792 1.3036 7.11192 1.3036 7.30392 1.4876C7.49592 1.6716 7.49592 1.9756 7.31192 2.1676L3.07192 6.4636C2.98392 6.5516 2.85592 6.5996 2.72792 6.5996Z"
                                        fill="white"
                                    />
                                </svg>
                                教室公開はココを選択
                            </div>
                        )}
                        <div
                            className={
                                data.showStatus
                                    ? `${styles.state__selectBoxWrap} ${styles['state__selectBoxWrap--active']}`
                                    : styles.state__selectBoxWrap
                            }>
                            {data.billingStatus === BillingStatus.PaidBilling ? (
                                <select className={styles.state__selectBox} value={schoolStatus} onChange={handleChangeInPayment}>
                                    <option className={styles.state__active} value={SchoolStatusEnum.PAYMENT_ACTIVE}>
                                        有料:掲載中
                                    </option>
                                    {/* <option className={styles.state__deactivate} value={SchoolStatusEnum.PAYMENT_INACTIVE}>*/}
                                    {/*    有料:掲載停止*/}
                                    {/* </option>*/}
                                    <option className={styles.state__active} value={SchoolStatusEnum.FREE_ACTIVE}>
                                        無料:掲載中
                                    </option>
                                    <option className={styles.state__deactivate} value={SchoolStatusEnum.INACTIVE}>
                                        無料:掲載停止
                                    </option>
                                </select>
                            ) : (
                                <select className={styles.state__selectBox} value={schoolStatus} onChange={handleChangeInFree}>
                                    <option className={styles.state__active} value={SchoolStatusEnum.FREE_ACTIVE}>
                                        掲載中
                                    </option>
                                    <option className={styles.state__deactivate} value={SchoolStatusEnum.INACTIVE}>
                                        掲載停止
                                    </option>
                                </select>
                            )}
                        </div>
                        {data.billingStatus === BillingStatus.PaidBilling ? (
                            <table className={styles.schoolHeader__table}>
                                <tbody>
                                    <tr>
                                        <th>当月問い合わせ</th>
                                        <th>当月費用</th>
                                        <th>当月予算</th>
                                    </tr>
                                    <tr>
                                        <td>{data.sumPrice ? `${data.sumPrice / 3300}` : '0'}件</td>
                                        <td>¥{data.sumPrice ? `${data.sumPrice.toLocaleString()}` : '0'}/月</td>
                                        <td>{data.budgetLimit ? `¥${data.budgetLimit.toLocaleString()}/月` : 'なし'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <>
                                <button
                                    className={styles.schoolHeader__greenBtn}
                                    onClick={() => {
                                        // payment関係初期化 モーダルステップ、paymentIntent取得、スクールId設定、新規カード登録情報を初期化
                                        dispatch(initPaymentStore());
                                        dispatch(postPaymentSetup(authToken));
                                        dispatch(setPaymentTargetSchool(data));
                                        // ユーザーデータを更新
                                        dispatch(getClientUser(authToken));
                                        // クライアントデータを更新
                                        dispatch(getClient(authToken));

                                        dispatch(setCommonModalShow(true));
                                        dispatch(setCommonModalTitle('有料掲載の開始'));
                                        dispatch(setCommonModalContentType(CommonModalContentTypeEnum.START_PAID_PLAN));
                                    }}
                                    type="button">
                                    有料掲載を開始する
                                </button>
                                {/* リンク先実装までコメントアウト*/}
                                {/* <Link className={styles.schoolHeader__link} to="/">*/}
                                {/*    ※有料掲載について詳しく知る*/}
                                {/* < /Link>*/}
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.schoolHeader__note}>※タブを選んで情報を追加、編集をしてください</div>
            </div>
        </>
    );
};
