import { SchoolDetailCouponValidateType } from '../../type/Coupon/SchoolDetailCouponValidate.type';

export const schoolDetailCouponEditValidateInitial: SchoolDetailCouponValidateType = {
    name: true,
    description: true,
    usableDate: true,
    limitDate: true,
    presentationConditions: true,
    utilizationConditions: true,
    sortId: true,
}