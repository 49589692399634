import { combineReducers } from 'redux';
import { UiActionTypes } from '../../actions/ui';
import { CommonModalDataType } from './type/CommonModalData.type';
import { commonModalContentInitialize } from './initialize/commonModalContentInitialize';

const apiLoading = (state = false, action: any) => {
    switch (action.type) {
        case UiActionTypes.SET_API_LOADING:
            return action.payload.loading;
        default:
            return state;
    }
};

const commonModalData = (state: CommonModalDataType = commonModalContentInitialize, action: any) => {
    switch (action.type) {
        case UiActionTypes.SET_COMMON_MODAL_SHOW:
            return {
                ...state,
                show: action.payload.show,
                contentType: !action.payload.show ? null : state.contentType,
            };

        case UiActionTypes.SET_COMMON_MODAL_TITLE:
            return {
                ...state,
                title: action.payload.title
            };


        case UiActionTypes.SET_COMMON_MODAL_CONTENT_TYPE:
            return {
                ...state,
                contentType: action.payload.contentType
            };

        case UiActionTypes.SET_DISPLAY_TEXT_MODAL_TEXT:
            return {
                ...state,
                displayModalText: action.payload.text
            };

        default:
            return state;
    }
};

export const ui = combineReducers({
    apiLoading,
    commonModalData
});
