import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import styles from './css/style.module.scss';
import visa from './img/visa.svg';
import master from './img/master.svg';
import americanExpress from './img/americanExpress.svg';
import { setStripeCardNumberElement } from '../../../redux/actions/domain/payment/schoolSubscription';

type InputStripeCreditCardProps = {
    submitButtonStr: string,
    submitButtonFunc: () => void,
    cancelButtonStr: string,
    cancelButtonFunc: () => void,
    contractsFlag: boolean
}

export const InputStripeCreditCard: React.FC<InputStripeCreditCardProps> = (props) => {
    const { submitButtonStr, submitButtonFunc, cancelButtonStr, cancelButtonFunc, contractsFlag } = props;

    const [cardNumberPass, setCardNumberPass] = useState(false);
    const [cvcNumberPass, setCvcNumberPass] = useState(false);
    const [cardExpiry, setCardExpiry] = useState(false);

    const stripeElements = useElements();

    const dispatch = useDispatch();

    useEffect(() => {
        if (cardNumberPass && cvcNumberPass && cardExpiry) {
            if (stripeElements) {
                dispatch(setStripeCardNumberElement(stripeElements.getElement(CardNumberElement)));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardExpiry, cardNumberPass, cvcNumberPass, dispatch]);


    return (
        <>
            <div className={`${styles.inner} ${contractsFlag ? styles["inner--contracts"] : ""}`}>
                <div className={styles.canUse}>
                    <p>ご利用可能なクレジットカード</p>
                    <img src={visa} alt='visa' />
                    <img src={master} alt='master' />
                    <img src={americanExpress} alt='americanExpress' />
                </div>
                <div className={`${styles.label} ${styles.require}`}>クレジットカード番号</div>
                <div className={styles.inputCardWrap}>
                    <CardNumberElement
                        className={`${styles.input} ${styles['input--number']}`}
                        id='creditCardNumber'
                        onChange={(element) => {
                            if (!element.complete && !element.error) {
                                setCardNumberPass(false);
                            } else if (element.complete && !element.error) {
                                setCardNumberPass(true);
                            }
                        }}
                    />
                    <CardCvcElement
                        className={`${styles.input} ${styles['input--securityCode']}`}
                        id='securityCode'
                        onChange={(element) => {
                            if (!element.complete && !element.error) {
                                setCvcNumberPass(false);
                            } else if (element.complete && !element.error) {
                                setCvcNumberPass(true);
                            }
                        }}
                    />
                </div>
                <div className={styles.note}>※ハイフンなし</div>
                <div className={`${styles.label} ${styles.require}`}>有効期限</div>
                <CardExpiryElement
                    className={styles.input}
                    id='creditCardExpiry'
                    onChange={(element) => {
                        if (!element.complete && !element.error) {
                            setCardExpiry(false);
                        } else if (element.complete && !element.error) {
                            setCardExpiry(true);
                        }
                    }}
                />
            </div>
            <button type='button' className={`${styles.greenBtn} ${!(cardNumberPass && cvcNumberPass && cardExpiry) ? styles.disable : ''} ${contractsFlag ? styles["greenBtn--contracts"] : ""}`} onClick={async () => {
                submitButtonFunc();
            }}>
                {submitButtonStr}
            </button>
            {!contractsFlag &&
            <button type='button' className={styles.cancel} onClick={() => cancelButtonFunc()}>
                {cancelButtonStr}
            </button>
            }
        </>);
};