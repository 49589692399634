import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import styles from './css/styles.module.scss';
import { BudgetSetting } from './BudgetSetting';
import { Explanation } from './Explanation';
import { Complete } from './Complete';
import { CreditCardSetting } from './CreditCardSetting';
import { Confirmation } from './Confirmation';
import { RootState } from '../../../../redux/store';
import { setPaymentModalStop } from '../../../../redux/actions/domain/payment/schoolSubscription';
import { stripePromise } from '../../../../util/helper/stipe';

export const StartPaidPlan: React.FC = () => {
    const clientSelector = useSelector((store: RootState) => store.rootReducer.domain.client);
    const paymentModalStep = useSelector((store: RootState) => store.rootReducer.domain.payment.schoolSubscription.paymentModalStep);
    const schoolSubscriptionSelector = useSelector((store: RootState) => store.rootReducer.domain.payment.schoolSubscription);
    const dispatch = useDispatch();
    const setModalNumber = (n: number) => {
        dispatch(setPaymentModalStop(n));
    };

    // 完了状態
    if (schoolSubscriptionSelector.paymentResult.success) {
        return (
            <div className={styles.wrap}>
                <Complete />
            </div>
        );
    }

    // カード登録済
    if (clientSelector.clientData && clientSelector.clientData.paymentMethodId) {
        return (
            <>
                <div className={styles.wrap} style={paymentModalStep !== 1 ? { display: 'none' } : {}}>
                    <Explanation modalNumber={paymentModalStep} setModalNumber={setModalNumber} />
                </div>
                <div className={styles.wrap} style={paymentModalStep !== 2 ? { display: 'none' } : {}}>
                    <BudgetSetting modalNumber={paymentModalStep} setModalNumber={setModalNumber} />
                </div>
                <div className={styles.wrap} style={paymentModalStep !== 3 ? { display: 'none' } : {}}>
                    <Confirmation modalNumber={paymentModalStep} setModalNumber={setModalNumber} />
                </div>
            </>
        );
    }

    return (
        <>
            <div className={styles.wrap} style={paymentModalStep !== 1 ? { display: 'none' } : {}}>
                <Explanation modalNumber={paymentModalStep} setModalNumber={setModalNumber} />
            </div>
            <div className={styles.wrap} style={paymentModalStep !== 2 ? { display: 'none' } : {}}>
                <BudgetSetting modalNumber={paymentModalStep} setModalNumber={setModalNumber} />
            </div>
            {schoolSubscriptionSelector.paymentSecret && (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret: schoolSubscriptionSelector.paymentSecret,
                        locale: 'ja',
                    }}>
                    <div className={styles.wrap} style={paymentModalStep !== 3 ? { display: 'none' } : {}}>
                        <CreditCardSetting modalNumber={paymentModalStep} setModalNumber={setModalNumber} />
                    </div>
                    <div className={styles.wrap} style={paymentModalStep !== 4 ? { display: 'none' } : {}}>
                        <Confirmation modalNumber={paymentModalStep} setModalNumber={setModalNumber} />
                    </div>
                </Elements>
            )}
        </>
    );
};
