/* eslint-disable jsx-a11y/click-events-have-key-events */
import React,{useState} from 'react';
import styles from './css/style.module.scss';
import { SchoolDetailData } from '../../../redux/reducers/domain/schools/type/SchoolDetailData.type';

type SchoolDetailTopOverViewProps = {
    schoolData: SchoolDetailData;
};

export const SchoolDetailTopOverView: React.FC<SchoolDetailTopOverViewProps> = (props: SchoolDetailTopOverViewProps) => {
    const { schoolData } = props;
    const [nowIndex, setNowIndex] = useState(0);

    return(
        <>
            <div className={styles.schoolDetailTopOverview}>
                <div className={styles.border} />
                {schoolData.imageUrls[0] && (
                    <div className={styles.schoolDetailTopOverview__main}>
                        <img src={schoolData.imageUrls[nowIndex]} alt="教室の写真" />
                    </div>
                )}

                <div className={styles.schoolDetailTopOverview__thumbnails}>
                    {schoolData.imageUrls.map((data,index) => (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={`images_${index}`}
                            className={`${styles.schoolDetailTopOverview__thumbnail} ${
                                nowIndex === index ? styles.active : ''
                            }`}
                            onClick={()=>setNowIndex(index)}
                        >
                            <img src={data} alt="教室の写真" />
                        </div>
                    ))}
                </div>

                <div className={styles.schoolDetailTopOverview__title}>
                    {schoolData.copy}
                </div>
                <div className={styles.schoolDetailTopOverview__text}>
                    {schoolData.description}
                </div>
            </div>
        </>
    )}