import { SchoolDetailCouponInputType } from '../../type/Coupon/SchoolDetailCouponInput.type';

export const schoolDetailCouponInputInitial: SchoolDetailCouponInputType = {
    name: '',
    description: '',
    usableDate: null,
    limitDate: null,
    presentationConditions: '',
    utilizationConditions: '',
    sortId: 1,
    image: null,
    deletingImage: false,
}