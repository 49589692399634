import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from './img/logo.svg';
import styles from './css/style.module.scss';
import { logOut } from '../../../redux/actions/app';
import { RootState } from '../../../redux/store';
import { newSchoolInputDataInitial } from '../../../redux/reducers/domain/schools/InitialState/New/newSchoolInputDataInitial';
import { setNewSchoolInput, setNewSchoolInputValidate } from '../../../redux/actions/domain/schools/newSchool';
import { newSchoolInputValidateInitial } from '../../../redux/reducers/domain/schools/InitialState/New/newSchoolInputValidateInitial';

export const Header: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    return (
        <div className={styles.header}>
            <div className={styles.header__inner}>
                <div className={styles.header__logo}>
                    <img src={logo} alt="oriori" />
                    <p>子どもの習い事ならoriori</p>
                </div>
                {authToken && (
                    <div className={styles.header__functions}>
                        <button type="button" className={styles.logout} onClick={() => dispatch(logOut())}>
                            ログアウト
                        </button>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        {location.pathname.indexOf('new_') === -1 && (
                            <button
                                className={styles.newSchool}
                                onClick={() => {
                                    dispatch(setNewSchoolInput(newSchoolInputDataInitial));
                                    dispatch(setNewSchoolInputValidate(newSchoolInputValidateInitial));
                                    history.push('/school/new_1');
                                }}
                                type="button">
                                教室・スクール情報を作成
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
