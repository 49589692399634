import { Action } from 'redux';
import { ClientUserTypeEnum } from '../../../reducers/domain/user/enum/ClientUserType.enum';
import { UserActions } from './index';

// ユーザー追加管理

export const setInviteUserInput = (input: {
    email: string,
    type: ClientUserTypeEnum,
    emailReceive: boolean,
    stepSecondFlag: boolean,
}): SetInviteUserInput => ({
    type: UserActions.SET_INVITE_USER_INPUT,
    payload: { input }
});

export interface SetInviteUserInput extends Action {
    type: string;
    payload: {
        input: {
            email: string,
            type: ClientUserTypeEnum,
            emailReceive: boolean,
            stepSecondFlag: boolean,
        };
    };
}

export const setInviteUserResult = (result: {
    flag: boolean,
    reason: string,
}): SetInviteUserResult => ({
    type: UserActions.SET_INVITE_USER_RESULT,
    payload: { result }
});

export interface SetInviteUserResult extends Action {
    type: string;
    payload: {
        result: {
            flag: boolean,
            reason: string,
        };
    };
}

export const postInviteUserInput = (
    token: string, input: {
        email: string,
        type: ClientUserTypeEnum,
        emailReceive: boolean,
    }): PostInviteUserInput => ({
    type: UserActions.POST_INVITE_USER_INPUT,
    payload: { token, input }
});

export interface PostInviteUserInput extends Action {
    type: string;
    payload: {
        token: string,
        input: {
            email: string,
            type: ClientUserTypeEnum,
            emailReceive: boolean,
        };
    };
}

export const setCreateInvitedUserInput = (input: {
    password: string,
    password_confirm: string,
}): SetCreateInvitedUserInput => ({
    type: UserActions.SET_CREATE_INVITED_USER_INPUT,
    payload: { input }
});

export interface SetCreateInvitedUserInput extends Action {
    type: string;
    payload: {
        input: {
            password: string,
            password_confirm: string,
        };
    };
}

export const setCreateInvitedUserValidate = (validate: {
    password: boolean,
    password_confirm: boolean,
}): SetCreateInvitedUserValidate => ({
    type: UserActions.SET_CREATE_INVITED_USER_VALIDATE,
    payload: { validate }
});

export interface SetCreateInvitedUserValidate extends Action {
    type: string;
    payload: {
        validate: {
            password: boolean,
            password_confirm: boolean,
        };
    };
}

export const setCreateInvitedUserResult = (result: {
    flag: boolean,
    reason: string,
}): SetCreateInvitedUserResult => ({
    type: UserActions.SET_CREATE_INVITED_USER_RESULT,
    payload: { result }
});

export interface SetCreateInvitedUserResult extends Action {
    type: string;
    payload: {
        result: {
            flag: boolean,
            reason: string,
        };
    };
}

export const postCreateInvitedUserInput = (
    code: string,
    password: string,
    password_confirm: string,
    name: string
): PostCreateInvitedUserInput => ({
    type: UserActions.POST_CREATE_INVITED_USER_INPUT,
    payload: {
        code,
        password,
        password_confirm,
        name
    }

});

export interface PostCreateInvitedUserInput extends Action {
    type: string;
    payload: {
        code: string,
        password: string,
        password_confirm: string,
        name: string,
    };
}
