import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './css/style.module.scss';
import helpIconImage from './img/help_icon.svg';
import { setCommonModalContentType, setCommonModalShow, setCommonModalTitle, setDisplayTextModalText } from '../../../../redux/actions/ui';
import { CommonModalContentTypeEnum } from '../../../../redux/reducers/ui/enum/CommonModalContentType.enum';

export enum CheckBoxInputType {
    TEXT,
    BUTTON,
}

type CheckBoxInputProps = {
    label: string;
    require: boolean;
    name: string;
    data: Array<{
        id: string;
        value: string;
        uncheckValue: string;
        label: string;
    }>;
    changeReturnFunc: (values: Array<string>) => void;
    buttonType: CheckBoxInputType;
    defaultValue: Array<number>;
    annotation: string;
    displayTextModalData: {
        displayText: string;
        modalText: string;
    } | null;
};

export const CheckBoxInput = (props: CheckBoxInputProps) => {
    const { label, require, name, data, changeReturnFunc, buttonType, defaultValue, annotation, displayTextModalData } = props;
    const [input, setInput] = useState<Array<string>>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setInput(defaultValue.length > 0 ? defaultValue.map((v) => v.toString()) : []);
    }, [defaultValue]);

    return (
        <>
            <div className={`${styles.label} ${require ? styles.require : ''}`}>
                {label}
                {displayTextModalData && (
                    <button
                        className={styles.label__button}
                        onClick={() => {
                            dispatch(setCommonModalShow(true));
                            dispatch(setCommonModalTitle(displayTextModalData.displayText));
                            dispatch(setCommonModalContentType(CommonModalContentTypeEnum.DISPLAY_TEXT));
                            dispatch(setDisplayTextModalText(displayTextModalData.modalText));
                        }}
                        type="button">
                        <img src={helpIconImage} alt={displayTextModalData.displayText} />
                        {displayTextModalData.displayText}
                    </button>
                )}
            </div>

            <div className={styles.wrap}>
                {data.map((d) => (
                    <div key={`${name}_checkbox_${d.id}`} className={styles.row}>
                        <input
                            type="checkbox"
                            value={d.value}
                            id={d.id}
                            name={name}
                            className={styles.input}
                            checked={!!(input && input.find((v) => v === d.value))}
                            onChange={(e) => {
                                let tempInput = input;
                                if (e.target.checked) {
                                    tempInput.push(e.target.value);
                                } else {
                                    tempInput = input.filter((v) => v !== e.target.value);
                                }
                                setInput([...tempInput]);
                                changeReturnFunc(tempInput);
                            }}
                        />
                        <label
                            htmlFor={d.id}
                            className={`${buttonType === CheckBoxInputType.BUTTON ? styles['inputLabel--button'] : styles.inputLabel}`}>
                            {d.label}
                        </label>
                    </div>
                ))}
            </div>

            {annotation !== '' && <div className={styles.annotation}>{annotation}</div>}
        </>
    );
};
