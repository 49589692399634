import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';
import { RootState } from '../../../redux/store';


type WhiteBtnProps = {
    text: string;
    style: any;
    handleClick: any;
    isLink: boolean;
    path: string;
};


export const WhiteBtn: React.FC<WhiteBtnProps> = (props: WhiteBtnProps) => {
    const { text, style, handleClick, isLink, path } = props;
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);

    return (
        <>
            <button
                className={styles.whiteBtn}
                type='button'
                style={style}
                onClick={handleClick}>
                {!isLink ?
                    text 
                    :
                    <Link to={path}>{loadingSelector ? '通信中...' : text}</Link>
                }
            </button>
        </>
    );
};