import { ClientUsersType } from './type/ClientUsers.type';
import { UserActions } from '../../../actions/domain/user';
import { clientUsersInitialize } from './Initialize/clientUsersInitialize';


export const clientUsers = (state = clientUsersInitialize, action: any): ClientUsersType => {
    switch (action.type) {
        case UserActions.SET_CLIENT_USERS:
            return {
                ...state,
                users: action.payload.users,
            }
        default:
            return state;
    }
}