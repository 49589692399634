import { UserActions } from '../../../actions/domain/user';
import { createInviteUserInitialize } from './Initialize/createInviteUserInitialize';
import { CreateInvitedUserType } from './type/CreateInviteUser.type';

export const createUser = (state = createInviteUserInitialize, action: any): CreateInvitedUserType => {
    switch (action.type) {
        case UserActions.SET_CREATE_INVITED_USER_INPUT:
            return {
                ...state,
                input: action.payload.input,
            }
        case UserActions.SET_CREATE_INVITED_USER_VALIDATE:
            return {
                ...state,
                inputValidate: action.payload.validate,
            }
        case UserActions.SET_CREATE_INVITED_USER_RESULT:
            return {
                ...state,
                result: action.payload.result,
            }
        default:
            return state;
    }
}