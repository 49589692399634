import React, { useEffect, useState } from 'react';
import styles from './css/style.module.scss';
import iconArrow from './img/arrrow-down.svg';

type SelectInputProps = {
    label: string | null;
    require: boolean;
    name: string;
    id: string;
    data: Array<{
        value: string;
        label: string;
    }>;
    changeReturnFunc: (text: string, validate: boolean) => void;
    defaultInput: string | null;
    disableSelectText: string | null;
    disable: boolean;
    selectStartIndex: number | null;
    selectEndIndex: number | null;
};

export const SelectInput: React.FC<SelectInputProps> = (props: SelectInputProps) => {
    const { label, require, name, id, data, changeReturnFunc, defaultInput, disableSelectText, disable, selectStartIndex, selectEndIndex } = props;
    const [input, setInput] = useState(defaultInput || null);
    const [, setValidate] = useState(!!defaultInput);

    const [scopedDisable, setScopedDisable] = useState<Array<boolean> | null>(null);

    useEffect(() => {
        setScopedDisable(
            data.map(
                (d, index) =>
                    !(
                        (selectStartIndex === null && selectEndIndex === null) ||
                        (selectStartIndex !== null && selectStartIndex < index) ||
                        (selectEndIndex !== null && selectEndIndex > index)
                    )
            )
        );
        setInput(defaultInput);
        setValidate(!!defaultInput);
    }, [data, defaultInput, selectEndIndex, selectStartIndex]);

    return (
        <>
            {label && <div className={`${styles.label} ${require ? styles.require : ''}`}>{label}</div>}
            <div className={`${styles.wrap} ${disable ? styles.disable : ''}`}>
                <select
                    name={name}
                    id={id}
                    className={`${styles.select} ${!input ? styles.noValue : ''}`}
                    value={input || ''}
                    onChange={(e) => {
                        setInput(e.target.value.toString());
                        setValidate(!!e.target.value.toString());
                        changeReturnFunc(e.target.value.toString(), !!e.target.value.toString());
                    }}>
                    <option value="">{disableSelectText || '選択してください'}</option>
                    {data.map((d, index) => (
                        <option key={`selectKey_${name}_${d.value}`} value={d.value} disabled={scopedDisable ? scopedDisable[index] : false}>
                            {d.label}
                        </option>
                    ))}
                </select>
                <div className={styles.arrow}>
                    <img src={iconArrow} alt="arrow" />
                </div>
            </div>
        </>
    );
};
