import { Action } from 'redux';
import { MyPageActionTypes } from './index';
import { SchoolInquiryDataType } from '../../../../util/type/SchoolInquiryData.type';
// import { InquiryDetailData } from '../../../reducers/domain/schools/type/InquiryDetailData.type';


export const getMyInquiries = (authToken: string, limit: number, page: number): GetMyInquiries => ({
    type: MyPageActionTypes.GET_MY_INQUIRIES,
    payload: { authToken, limit, page }
});

export interface GetMyInquiries extends Action {
    type: string;
    payload: {
        authToken: string,
        limit: number,
        page: number
    };
}

export const setMyInquiries = (inquiries: Array<SchoolInquiryDataType>): SetMyInquiries => ({
    type: MyPageActionTypes.SET_MY_INQUIRIES,
    payload: { inquiries }
});

export interface SetMyInquiries extends Action {
    type: string;
    payload: { inquiries: Array<SchoolInquiryDataType> };
}

export const setMyInquiriesCount = (inquiriesCount: number): SetMyInquiriesCount => ({
    type: MyPageActionTypes.SET_MY_INQUIRIES_COUNT,
    payload: { inquiriesCount }
});

export interface SetMyInquiriesCount extends Action {
    type: string;
    payload: { inquiriesCount: number };
}


export const setMyInquiriesCurrentPage = (currentPage: number): SetMyInquiriesCurrentPage => ({
    type: MyPageActionTypes.SET_MY_INQUIRIES_CURRENT_PAGE,
    payload: { currentPage }
});

export interface SetMyInquiriesCurrentPage extends Action {
    type: string;
    payload: { currentPage: number };
}
