import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../asset/css/newSchool.module.scss';
import { RootState } from '../../../redux/store';
import { checkNewSchoolModalCheck, setNewSchoolInput, setNewSchoolInputValidate } from '../../../redux/actions/domain/schools/newSchool';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { CategorySelect } from '../../../components/SchoolInput/CategorySelect';
import { KeywordPicker } from '../../../components/SchoolInput/KeywordPicker';
import { CheckBoxInput, CheckBoxInputType } from '../../../components/Common/Input/CheckBoxInput';
import { schoolTagMaster } from '../../../util/const/Schools/schoolTagMaster';
import { classStyleMaster } from '../../../util/const/Schools/classStyleMaster';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { NearestStationBusStopPicker } from '../../../components/SchoolInput/NearestStationBusStopPicker';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { NewSchoolHeader } from '../../../components/SchoolInput/NewSchoolHeader';
import { OwnerApplication } from '../../../components/MyPage/OwnerApplication';
import { TabBar } from '../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../components/Common/BreadCrumb';
import { schoolStyleDescriptionText } from '../../../components/Common/CommonModalContent/DisplayText/const/keywordDescriptionText';

export const New_1: React.FC = () => {
    const newSchoolInputStore = useSelector((state: RootState) => state.rootReducer.domain.schools);
    const authToken = useSelector((state: RootState) => state.rootReducer.app.authToken);

    const dispatch = useDispatch();

    useEffect(() => {
        // 控除警告「
        dispatch(checkNewSchoolModalCheck(authToken));
    }, [authToken, dispatch]);

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.wrap}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室を新規作成', path: '/school/new_1' },
                    ]}
                />
                <NewSchoolHeader step={1} />
                <div className={styles.title}>基本情報</div>

                <div className={styles.inputWrap}>
                    <div className={styles.input}>
                        <div className={styles.inputTextWrap}>
                            <TextInput
                                label="教室名"
                                name="name"
                                id="name"
                                require
                                inputType="text"
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation="校舎毎に登録をしてください"
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            name: text,
                                        })
                                    );
                                    dispatch(
                                        setNewSchoolInputValidate({
                                            ...newSchoolInputStore.newSchoolInput.validate,
                                            name: validate,
                                        })
                                    );
                                }}
                                defaultInput={newSchoolInputStore.newSchoolInput.input.name}
                                placeHolder="オリオリ学習塾　目黒校"
                                minLength={1}
                                defaultInputValue={newSchoolInputStore.newSchoolInput.input.name}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <div className={styles.input}>
                        <div className={styles.inputTextWrap}>
                            <TextInput
                                label="教室名フリガナ"
                                name="nameKana"
                                id="nameKana"
                                require
                                inputType="text"
                                validateType={InputValidateTypeEnum.KATAKANA}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            nameKana: text,
                                        })
                                    );
                                    dispatch(
                                        setNewSchoolInputValidate({
                                            ...newSchoolInputStore.newSchoolInput.validate,
                                            nameKana: validate,
                                        })
                                    );
                                }}
                                defaultInput={newSchoolInputStore.newSchoolInput.input.nameKana}
                                placeHolder="オリオリガクシュウジュク　メグロコウ"
                                minLength={1}
                                defaultInputValue={newSchoolInputStore.newSchoolInput.input.nameKana}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <div className={styles.input}>
                        <CategorySelect
                            returnFunc={(value) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        categoryId: Number(value),
                                    })
                                );
                                dispatch(
                                    setNewSchoolInputValidate({
                                        ...newSchoolInputStore.newSchoolInput.validate,
                                        categoryId: !!value,
                                    })
                                );
                            }}
                            defaultInputValue={newSchoolInputStore.newSchoolInput.input.categoryId}
                            annotation="複数カテゴリーを展開している場合には代表的なものを一つご選択ください"
                        />
                    </div>

                    <div className={styles.input}>
                        <KeywordPicker
                            returnFunc={(value: Array<{ value: number; label: string }>) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        keywordIds: value.map((d) => d.value),
                                    })
                                );
                                // キーワードは任意とする
                                // dispatch(
                                //     setNewSchoolInputValidate({
                                //         ...newSchoolInputStore.newSchoolInput.validate,
                                //         keywordIds: value.length !== 0,
                                //     })
                                // );
                            }}
                            defaultValues={newSchoolInputStore.newSchoolInput.input.keywordIds}
                        />
                    </div>
                    <div className={styles.input}>
                        <CheckBoxInput
                            label="習い事形態"
                            name="schoolStyle"
                            require
                            data={classStyleMaster.map((d) => ({
                                id: `classStyle_${d.value}`,
                                value: d.value,
                                uncheckValue: '',
                                label: d.label,
                            }))}
                            changeReturnFunc={(values) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        classStyleIds: values.map((v) => Number(v)),
                                    })
                                );
                                dispatch(
                                    setNewSchoolInputValidate({
                                        ...newSchoolInputStore.newSchoolInput.validate,
                                        classStyleIds: values.length !== 0,
                                    })
                                );
                            }}
                            defaultValue={newSchoolInputStore.newSchoolInput.input.classStyleIds}
                            buttonType={CheckBoxInputType.BUTTON}
                            annotation="※当てはまるもの、全てを選択してください"
                            displayTextModalData={{
                                displayText: '習い事形態とは？',
                                modalText: schoolStyleDescriptionText,
                            }}
                        />
                    </div>

                    <div className={styles.input}>
                        <CheckBoxInput
                            label="教室の特徴"
                            name="schoolTags"
                            require
                            data={schoolTagMaster.map((d) => ({
                                id: `schoolTags_${d.value}`,
                                value: d.value,
                                uncheckValue: '',
                                label: d.label,
                            }))}
                            changeReturnFunc={(values) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        tagIds: values.map((v) => Number(v)),
                                    })
                                );
                                dispatch(
                                    setNewSchoolInputValidate({
                                        ...newSchoolInputStore.newSchoolInput.validate,
                                        tagIds: values.length !== 0,
                                    })
                                );
                            }}
                            defaultValue={newSchoolInputStore.newSchoolInput.input.tagIds}
                            buttonType={CheckBoxInputType.BUTTON}
                            annotation="※当てはまるもの、全てを選択してください"
                            displayTextModalData={null}
                        />
                    </div>

                    <div className={styles.input}>
                        <NearestStationBusStopPicker
                            returnFunc={(value: { value: number; onFoot: number; label: string } | null, isBusStop: boolean) => {
                                if (!isBusStop) {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            nearestStation: value
                                                ? {
                                                    id: value.value,
                                                    onFoot: value.onFoot,
                                                    label: value.label,
                                                }
                                                : null,
                                            nearestBusstop: null,
                                        })
                                    );
                                } else {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            nearestStation: null,
                                            nearestBusstop: value
                                                ? {
                                                    id: value.value,
                                                    onFoot: value.onFoot,
                                                    label: value.label,
                                                }
                                                : null,
                                        })
                                    );
                                }
                            }}
                        />
                    </div>

                    <div className={styles.buttonWrap}>
                        <GreenBtn
                            text="次へ　1/ 3"
                            style={
                                [
                                    newSchoolInputStore.newSchoolInput.validate.name,
                                    newSchoolInputStore.newSchoolInput.validate.nameKana,
                                    newSchoolInputStore.newSchoolInput.validate.categoryId,
                                    newSchoolInputStore.newSchoolInput.validate.keywordIds,
                                    newSchoolInputStore.newSchoolInput.validate.classStyleIds,
                                    newSchoolInputStore.newSchoolInput.validate.tagIds,
                                    newSchoolInputStore.newSchoolInput.validate.nearestStation,
                                    newSchoolInputStore.newSchoolInput.validate.nearestBusstop,
                                ].filter((flag) => !flag).length > 0
                                    ? {
                                        background: '#c4c4c4',
                                        pointerEvents: 'none',
                                    }
                                    : {}
                            }
                            handleClick={() => false}
                            isLink
                            path="/school/new_2"
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
