import React from 'react';
import styles from './css/style.module.scss';

type DataListProps = {
    data: Array<{
        title: string,
        text: string,
    }>,
    id: string,
    longFlag: boolean,
}

export const DataList: React.FC<DataListProps> = (props) => {
    const { data, id, longFlag } = props;

    return <div className={`${styles.wrap} ${ longFlag ? styles["wrap--withLongTitle"] : ""}`}>
        <ul className={styles.list}>
            {data.map((d, index) =>
                // eslint-disable-next-line react/no-array-index-key
                (<li key={`${id}_${index}`} className={styles.listRow}>
                    <div className={styles.listRow__title}>{d.title}：</div>
                    <div className={styles.listRow__text}>{d.text}</div>
                </li>)
            )}
        </ul>
    </div>;
};