import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SchoolDetailParamTypes } from '../type/SchoolDetaillParam.type';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { Header } from '../../../../components/Common/Header';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { RootState } from '../../../../redux/store';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import { getSchoolCourseList } from '../../../../redux/actions/domain/schools/schoolCourse';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolPageExplanation } from '../../../../components/Schools/SchoolPageExplanation';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import { SchoolCourseCard } from '../../../../components/Schools/SchoolCourseCard';
import { SchoolInstructorCard } from '../../../../components/Schools/SchoolInstructorCard';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { SchoolDetailCourseType } from '../../../../redux/reducers/domain/schools/type/Course/SchoolDetailCourse.type';
import {
    getSchoolInstructorList, setSchoolEditInstructorIndex, setSchoolEditInstructorInput,
    setSchoolNewInstructorFlag
} from '../../../../redux/actions/domain/schools/schoolinstructor';
import { SchoolDetailInstructorType } from '../../../../redux/reducers/domain/schools/type/Instructor/SchoolDetailInstructor.type';


export const SchoolDetailCourseList: React.FC = () => {
    const { schoolId } = useParams<SchoolDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolCourseStore: SchoolDetailCourseType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailCourse);
    const schoolInstructorStore: SchoolDetailInstructorType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailInstructor);
    const dispatch = useDispatch();

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(getSchoolCourseList(authToken, Number(schoolId)));
        dispatch(getSchoolInstructorList(authToken, Number(schoolId)));
        dispatch(setSchoolNewInstructorFlag(false));
        dispatch(setSchoolEditInstructorIndex(null));
        dispatch(setSchoolEditInstructorInput(null));
    }, [authToken, dispatch, schoolId]);

    if (schoolStore.schoolDetailData !== null
    ) {
        return (<>
            <Header />
            <OwnerApplication />
            <TabBar currentPage='/school' />
            <div className={styles.schoolContainer}>
                <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, {
                    text: `${schoolStore.schoolDetailData.name}`,
                    path: `/school/${schoolId}/course`
                }]} />
                <SchoolHeader data={schoolStore.schoolDetailData} />
                <SchoolTabBar currentPage={`/school/${schoolId}/course`} schoolId={schoolId} />
                <SchoolPageExplanation title='コース' textDom={<>各コースの詳細を追加、編集します。</>} btnText='＋ コースを追加する'
                    path={`/school/${schoolId}/course/new`} />
                <SchoolH3 editable={!schoolStore.noticeEditFlag} otherEditPlace={null}
                    ButtonFunc={null} text='コース一覧' isShow={null} />
                {schoolCourseStore && (
                    <div>
                        {schoolCourseStore.list?.map((d, index) =>
                            <SchoolCourseCard
                                key={`course_id_${d.id}`}
                                schoolId={schoolId}
                                schoolCourseData={d}
                                authToken={authToken}
                                index={index}
                                listLength={Number(schoolCourseStore.list?.length)}
                            />
                        )}
                    </div>
                )}
                <SchoolH3 editable={!schoolStore.noticeEditFlag} otherEditPlace={null}
                    ButtonFunc={null} text='講師一覧' isShow={null} />
                {schoolInstructorStore.list && schoolInstructorStore.list.length > 0 && (<div>
                    {schoolInstructorStore.list.map((d, index) =>
                        <SchoolInstructorCard key={`instructor_id_${d.id}`} data={d} newFlag={false} index={index}
                            schoolId={schoolId} />
                    )}
                </div>)}
                {schoolInstructorStore.newFlag &&
                <SchoolInstructorCard data={null} newFlag index={null} schoolId={schoolId} />
                }
                {!schoolInstructorStore.newFlag &&
                <button type='button' className={styles.schoolCourseListAddInstructor}
                    onClick={() => dispatch(setSchoolNewInstructorFlag(true))}>＋ 講師を追加する</button>
                }
            </div>
            <Footer />
        </>);
    }
    return <>
        <Header />
        <OwnerApplication />
        <TabBar currentPage='/school' />
        <div className={styles.schoolContainer}>
            <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, { text: '教室名', path: '' }]} />
        </div>
        <Footer />
    </>;
};
