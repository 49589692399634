import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import styles from './css/styles.module.scss';
import { RootState } from '../../../../../redux/store';
import { INQUIRY_PRICE } from '../../../../../util/const/inquiryPrice';
import { postPaymentMethodToStripe, postStartSubscription } from '../../../../../redux/actions/domain/payment/schoolSubscription';
import { stripePromise } from '../../../../../util/helper/stipe';

type ConfirmationProps = {
    modalNumber: any;
    setModalNumber: any;
};

export const Confirmation: React.FC<ConfirmationProps> = (props: ConfirmationProps) => {
    const { modalNumber, setModalNumber } = props;
    const clientSelector = useSelector((state: RootState) => state.rootReducer.domain.client);
    const paymentSelector = useSelector((store: RootState) => store.rootReducer.domain.payment);
    const schoolSubscriptionSelector = useSelector((store: RootState) => store.rootReducer.domain.payment.schoolSubscription);
    const authToken = useSelector((store: RootState) => store.rootReducer.app.authToken);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);

    const dispatch = useDispatch();
    return (
        <>
            <table className={styles.confirmation}>
                <tbody>
                    <tr>
                        <th>ご契約スクール名</th>
                        <td>
                            {paymentSelector.schoolSubscription.paymentTargetSchool
                                ? paymentSelector.schoolSubscription.paymentTargetSchool.name
                                : ''}
                        </td>
                    </tr>
                    <tr>
                        <th>月額ご利用料金</th>
                        <td>
                            <div className={styles.tdInner}>
                                <div>問い合わせ料</div>
                                <div className={styles.tdInner__right}>
                                    ¥{INQUIRY_PRICE.toLocaleString()}
                                    <span className={styles.inquiryNum}>x 問い合わせ数</span>
                                    {paymentSelector.schoolSubscription.budgetLimit && (
                                        <span className={styles.inquiryLimit}>
                                            (一か月間の上限:{paymentSelector.schoolSubscription.budgetLimit / INQUIRY_PRICE}件/¥
                                            {paymentSelector.schoolSubscription.budgetLimit.toLocaleString()})
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className={styles.tdInner}>
                                <div>システム利用料</div>
                                <div className={styles.tdInner__right}>¥{INQUIRY_PRICE.toLocaleString()}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>お支払い方法</th>
                        {clientSelector.clientData?.paymentMethodId &&
                        clientSelector.clientData?.cardLast4 &&
                        clientSelector.clientData?.expYear &&
                        clientSelector.clientData?.expMonth ? (
                                <td>**** **** **** {clientSelector.clientData.cardLast4}</td>
                            ) : (
                                <td>クレジットカード</td>
                            )}
                    </tr>
                </tbody>
            </table>

            {schoolSubscriptionSelector.paymentResult.error !== null && (
                <div className={styles.error}>{schoolSubscriptionSelector.paymentResult.error.text}</div>
            )}

            {schoolSubscriptionSelector.paymentSecret ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret: schoolSubscriptionSelector.paymentSecret,
                        locale: 'ja',
                    }}>
                    {/* eslint-disable-next-line @typescript-eslint/no-use-before-define*/}
                    <StripeSubmitButton />
                </Elements>
            ) : (
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <button
                    type="button"
                    className={styles.greenBtn}
                    onClick={() => {
                        // サブスクリプション登録のみ
                        dispatch(
                            postStartSubscription(
                                authToken,
                                paymentSelector.schoolSubscription.paymentTargetSchool
                                    ? paymentSelector.schoolSubscription.paymentTargetSchool.id.toString()
                                    : '',
                                paymentSelector.schoolSubscription.budgetLimit
                            )
                        );
                    }}>
                    {loadingSelector ? '通信中...' : 'この内容で有料掲載を開始'}
                </button>
            )}
            <button type="button" className={styles.cancel} onClick={() => setModalNumber(modalNumber - 1)}>
                ひとつ前に戻る
            </button>
        </>
    );
};

const StripeSubmitButton: React.FC = () => {
    const stripe = useStripe();
    const stripeElements = useElements();

    const paymentSelector = useSelector((store: RootState) => store.rootReducer.domain.payment);
    const authToken = useSelector((store: RootState) => store.rootReducer.app.authToken);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    return (
        <button
            type="button"
            className={styles.greenBtn}
            onClick={() => {
                if (paymentSelector.schoolSubscription.paymentTargetSchool) {
                    if (stripe && stripeElements && paymentSelector.schoolSubscription.cardNumberElement) {
                        // カード登録と、サブスクリプション登録を一括で行う
                        dispatch(
                            postPaymentMethodToStripe(
                                stripe,
                                stripeElements,
                                paymentSelector.schoolSubscription.paymentSecret,
                                paymentSelector.schoolSubscription.cardNumberElement,
                                authToken,
                                !paymentSelector.schoolSubscription.cardNumberElement,
                                paymentSelector.schoolSubscription.paymentTargetSchool.id.toString(),
                                paymentSelector.schoolSubscription.budgetLimit
                            )
                        );
                    } else {
                        // サブスクリプション登録のみ
                        dispatch(
                            postStartSubscription(
                                authToken,
                                paymentSelector.schoolSubscription.paymentTargetSchool.id.toString(),
                                paymentSelector.schoolSubscription.budgetLimit
                            )
                        );
                    }
                }
            }}>
            {loadingSelector ? '通信中...' : 'この内容で有料掲載を開始'}
        </button>
    );
};
