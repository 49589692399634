import { NewSchoolInputDataType } from '../../type/New/NewSchoolInputData.type';

export const newSchoolInputDataInitial: NewSchoolInputDataType = {
    name: '',
    nameKana: '',
    copy: '',
    addressDetail: '',
    nearestStationText: null,
    costAverageFrom: null,
    costAverageTo: null,
    description: '',
    homepageUrl: null,
    twitterUrl: null,
    instagramUrl: null,
    facebookUrl: null,
    lineUrl: null,
    establishmentDate: '',
    questions: null,
    clientId: null,
    prefectureId: 0,
    townId: 0,
    streetId: 0,
    note: '',
    scheduleWeekSchedule: [],
    nearestStation: null,
    nearestBusstop: null,
    categoryId: 0,
    keywordIds: [],
    tagIds: [],
    stationIds: [],
    busstopIds: [],
    classStyleIds: [],
    targetAgeIds: [],
    withoutAddress: false,
    deleteImageIds: [],
    withTargetUniversity: false,
    withTargetWorking: false,
};
