/**
 * クライアントユーザー種別
 */
export enum ClientUserTypeEnum {
    // 代表者
    Owner = 1,
    // 管理者
    Admin = 2,
    // 一般ユーザー
    User = 3
}
