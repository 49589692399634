import { axiosBase } from './index';
import { EditClientInputType } from '../../redux/reducers/domain/settings/type/EditClientInput.type';

export class ClientApi {
    // 新規登録
    static async postClientEntry(email: string, password: string, passwordConfirm: string) {
        return axiosBase.post('/client/entry', {
            email,
            password,
            password_confirm: passwordConfirm,
        });
    }

    // メール承認
    static async postClientApprove(code: string) {
        return axiosBase.post('/client/approve', { code });
    }

    // ログイン
    static async postClientLogin(email: string, password: string) {
        return axiosBase.post('/client/login', {
            email,
            password,
        });
    }

    // パスワード再発行依頼
    static async postOfferReissuePassword(email: string) {
        return axiosBase.post('/client/password/offer', { email });
    }

    // パスワード再発行
    static async postReissuePassword(token: string, password: string, passwordConfirm: string) {
        return axiosBase.post('/client/password/reissue', { token, password, password_confirm: passwordConfirm });
    }

    static async putClientInitial(token: string, name: string, type: number, representativeName: string, phone: string, addressDetail: string) {
        return axiosBase.put(
            '/client/initial',
            {
                name,
                type,
                representativeName,
                phone,
                addressDetail,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    // クライアント情報取得
    static async getClient(token: string) {
        return axiosBase.get('/client', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    // クライアント情報更新
    static async putClient(token: string, data: EditClientInputType) {
        return axiosBase.put('/client', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
