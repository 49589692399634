import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';

type SchoolPageExplanationProps = {
    title: string;
    textDom: ReactElement;
    btnText: string | null;
    path:string | null;
};

export const SchoolPageExplanation: React.FC<SchoolPageExplanationProps> = (props: SchoolPageExplanationProps) => {
    const {title, textDom, btnText, path} = props;
    
    return(
        <>
            <div className={styles.schoolPageExplanation}>
                <div className={styles.schoolPageExplanation__title}>{title}</div>
                <div className={styles.schoolPageExplanation__text}>{textDom}</div>
                {btnText && path &&
                    <Link to={path}>{btnText}</Link>
                }
            </div>
        </>
    )}