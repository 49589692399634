import { SchoolDetailCouponValidateType } from '../../type/Coupon/SchoolDetailCouponValidate.type';

export const schoolDetailCouponValidateInitial: SchoolDetailCouponValidateType = {
    name: false,
    description: true,
    usableDate: true,
    limitDate: false,
    presentationConditions: true,
    utilizationConditions: true,
    sortId: true,
}