import React, { useEffect, useState } from 'react';
import 'moment/locale/ja';
import moment from 'moment';
import styles from './css/style.module.scss';
import iconArrow from './img/arrrow-down.svg';

const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

type DateInputProps = {
    onChangeFunc: (data: Date | null) => void,
    onChangeValidate: (validate: boolean) => void,
    defaultVal: Date | null,
    id: string,
}

export const DateInput: React.FC<DateInputProps> = (props) => {
    const {
        onChangeFunc,
        onChangeValidate,
        defaultVal,
        id
    } = props;

    // 日付データ
    const years = range(2021, 2030, 1).map(v => ({ value: v.toString(), label: v.toString() }));
    const months = range(1, 12, 1).map(v => ({ value: v.toString().padStart(2, '0'), label: v.toString() }));
    const [days, setDays] = useState<Array<{ value: string, label: string }>>([]);
    // 選択
    const [yearValue, setYearValue] = useState<string>(defaultVal ? moment(defaultVal).format('YYYY-MM-DD').split('-')[0] : '');
    const [monthValue, setMonthValue] = useState<string>(defaultVal ? moment(defaultVal).format('YYYY-MM-DD').split('-')[1] : '');
    const [dayValue, setDayValue] = useState<string>(defaultVal ? moment(defaultVal).format('YYYY-MM-DD').split('-')[2] : '');

    useEffect(() => {
        if (Number(monthValue) === 1
            || Number(monthValue) === 3
            || Number(monthValue) === 5
            || Number(monthValue) === 7
            || Number(monthValue) === 8
            || Number(monthValue) === 10
            || Number(monthValue) === 12) {
            setDays(range(1, 31, 1).map(v => ({ value: v.toString().padStart(2, '0'), label: v.toString() })));
        } else if (Number(monthValue) !== 2) {
            if (Number(dayValue) > 31) setDayValue('1');
            setDays(range(1, 30, 1).map(v => ({ value: v.toString().padStart(2, '0'), label: v.toString() })));
        } else if (moment([Number(yearValue)]).isLeapYear()) {
            if (Number(dayValue) > 29) setDayValue('1');
            setDays(range(1, 29, 1).map(v => ({ value: v.toString().padStart(2, '0'), label: v.toString() })));
        } else {
            if (Number(dayValue) > 28) setDayValue('1');
            setDays(range(1, 28, 1).map(v => ({ value: v.toString().padStart(2, '0'), label: v.toString() })));
        }

        if ((yearValue !== '' && monthValue !== '' && dayValue !== '')
            && moment(`${yearValue}-${monthValue}-${dayValue}`, 'YYYY-MM-DD').isValid()) {
            onChangeFunc(new Date(`${yearValue}-${monthValue}-${dayValue}`));
            onChangeValidate(true);
        } else {
            onChangeFunc(null);
            onChangeValidate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearValue, monthValue, dayValue]);

    return (
        <div className={styles.wrap}>
            <div className={styles.innerWrap}>
                <div className={styles.firstSelectWrap}>
                    <select name={`dateInputYears_${id}`} id={`dateInputYears_${id}`} className={styles.select}
                        onChange={(e) => setYearValue(e.target.value)}
                        tabIndex={-1}><option key="year_none" >----</option>
                        {years.map(v => <option key={`year_${v.label}`} value={v.value}
                            selected={yearValue === v.value}>{v.label}</option>)}
                    </select>
                    <div className={styles.arrow}>
                        <img src={iconArrow} alt='arrow' />
                    </div>
                </div>
                <span className={styles.selectText}>年</span>
                <div className={styles.selectWrap}>
                    <select name={`dateInputMonth_${id}`} id={`dateInputMonth_${id}`} className={styles.select}
                        onChange={(e) => setMonthValue(e.target.value)}
                        tabIndex={-1}><option key="month_none" >----</option>
                        {months.map(v => <option key={`month_${v.label}`} value={v.value}
                            selected={monthValue === v.value}>{v.label}</option>)}
                    </select>
                    <div className={styles.arrow}>
                        <img src={iconArrow} alt='arrow' />
                    </div>
                </div>
                <span className={styles.selectText}>月</span>
                <div className={styles.selectWrap}>
                    <select name={`dateInputDay_${id}`} id={`dateInputDay_${id}`} className={styles.select}
                        onChange={(e) => setDayValue(e.target.value)}
                        tabIndex={-1}><option key="day_none" >----</option>
                        {days.map(v => <option key={`day_${v.label}`} value={v.value}
                            selected={dayValue === v.value}>{v.label}</option>)}
                    </select>
                    <div className={styles.arrow}>
                        <img src={iconArrow} alt='arrow' />
                    </div>
                </div>
                <span className={styles.selectText}>日</span>
            </div>
        </div>);
};