import { NewSchoolInputValidateType } from '../../type/New/NewSchoolInputValidate.type';

export const editSchoolIndexValidateInitial: NewSchoolInputValidateType = {
    name: true,
    nameKana: true,
    copy: true,
    addressDetail: true,
    nearestStationText: true, // 任意
    costAverageFrom: true, // 任意
    costAverageTo: true, // 任意
    description: true,
    homepageUrl: true, // 任意
    twitterUrl: true, // 任意
    instagramUrl: true, // 任意
    facebookUrl: true, // 任意
    lineUrl: true, // 任意
    establishmentDate: true, // 任意
    questions: true, // 任意
    prefectureId: true,
    townId: true,
    streetId: true,
    note: true,
    scheduleWeekSchedule: true,
    nearestStation: true, // 任意
    nearestBusstop: true, // 任意
    categoryId: true,
    keywordIds: true,
    tagIds: true,
    stationIds: true,
    busstopIds: true,
    classStyleIds: true,
    targetAgeIds: true,
    realSchool: true,
};