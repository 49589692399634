import { Action } from 'redux';
import { UserActions } from './index';

export const setChangeClientUserEmailOfferResult = (flag: boolean, reason: string): SetChangeClientUserEmailOfferResult => ({
    type: UserActions.SET_CHANGE_CLIENT_USER_EMAIL_OFFER_RESULT,
    payload: {
        result: {
            flag,
            reason
        }
    }
});

export interface SetChangeClientUserEmailOfferResult extends Action {
    type: string;
    payload: {
        result: {
            flag: boolean,
            reason: string,
        };
    };
}


export const postChangeClientUserEmailOffer = (token: string, email: string): PostChangeClientUserEmailOffer => ({
    type: UserActions.POST_CHANGE_CLIENT_USER_EMAIL_OFFER,
    payload: {
        token,
        email
    }
});

export interface PostChangeClientUserEmailOffer extends Action {
    type: string;
    payload: {
        token: string,
        email: string,
    };
}

export const postChangeClientUserEmailCode = (
    authToken: string,
    token: string
): PostChangeClientUserEmailCode => ({
    type: UserActions.POST_CHANGE_CLIENT_USER_EMAIL_REGISTER_CODE,
    payload: {
        authToken,
        token,
    }
});

export interface PostChangeClientUserEmailCode extends Action {
    type: string;
    payload: {
        authToken: string,
        token: string
    };
}

export const setChangeClientUserEmailResult = (result: {
    success: boolean,
}): SetChangeClientUserEmailResult => ({
    type: UserActions.SET_CHANGE_CLIENT_USER_EMAIL_REGISTER_RESULT,
    payload: { result }
});

export interface SetChangeClientUserEmailResult extends Action {
    type: string;
    payload: {
        result: {
            success: boolean,
        }
    };
}