import { Action } from 'redux';
import { ClientActionTypes } from './index';
import { signUpInputInitial } from '../../../reducers/domain/client/InitialState/signUpInputInitial';
import { SignUpInputType } from '../../../reducers/domain/client/type/signUpInput.type';

// 新規登録
export const setSignUpEmail = (email: string): SetSignUpEmail => ({
    type: ClientActionTypes.SET_SIGN_UP_EMAIL,
    payload: { email },
});

export interface SetSignUpEmail extends Action {
    type: string;
    payload: { email: string };
}

export const setSignUpPassword = (password: string): SetSignUpPassword => ({
    type: ClientActionTypes.SET_SIGN_UP_PASSWORD,
    payload: { password },
});

export interface SetSignUpPassword extends Action {
    type: string;
    payload: { password: string };
}

export const setSignUpPasswordConfirm = (passwordConfirm: string): SetSignUpPasswordConfirm => ({
    type: ClientActionTypes.SET_SIGN_UP_PASSWORD_CONFIRM,
    payload: { passwordConfirm },
});

export interface SetSignUpPasswordConfirm extends Action {
    type: string;
    payload: { passwordConfirm: string };
}

export const setSignUpEmailValidate = (flag: boolean): SetSignUpEmailValidate => ({
    type: ClientActionTypes.SET_SIGN_UP_EMAIL_VALIDATE,
    payload: { flag },
});

export interface SetSignUpEmailValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setSignUpPasswordValidate = (flag: boolean): SetSignUpPasswordValidate => ({
    type: ClientActionTypes.SET_SIGN_UP_PASSWORD_VALIDATE,
    payload: { flag },
});

export interface SetSignUpPasswordValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setSignUpPasswordConfirmValidate = (flag: boolean): SetSignUpPasswordConfirmValidate => ({
    type: ClientActionTypes.SET_SIGN_UP_PASSWORD_CONFIRM_VALIDATE,
    payload: { flag },
});

export interface SetSignUpPasswordConfirmValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setSignUpResult = (result: { sendEmail: string; flag: boolean; reason: string }): SetSignUpResult => ({
    type: ClientActionTypes.SET_SIGN_UP_RESULT,
    payload: { result },
});

export interface SetSignUpResult extends Action {
    type: string;
    payload: { result: { sendEmail: string; flag: boolean; reason: string } };
}

export const postSignUp = (email: string, password: string, passwordConfirm: string): PostSignUp => ({
    type: ClientActionTypes.POST_SIGN_UP,
    payload: {
        email,
        password,
        passwordConfirm,
    },
});

export interface PostSignUp extends Action {
    type: string;
    payload: {
        email: string;
        password: string;
        passwordConfirm: string;
    };
}

export const postSignUpRegisterCode = (code: string): PostSignUpRegisterCode => ({
    type: ClientActionTypes.POST_SIGN_UP_REGISTER_CODE,
    payload: {
        code,
    },
});

export interface PostSignUpRegisterCode extends Action {
    type: string;
    payload: {
        code: string;
    };
}

export const setSignUpRegisterResult = (result: { loading: boolean; success: boolean }): SetSignUpRegisterResult => ({
    type: ClientActionTypes.SET_SIGN_UP_REGISTER_RESULT,
    payload: { result },
});

export interface SetSignUpRegisterResult extends Action {
    type: string;
    payload: {
        result: {
            loading: boolean;
            success: boolean;
        };
    };
}

export const resetSignUpInput = (): ResetSignUpInput => ({
    type: ClientActionTypes.RESET_SIGN_UP_INPUT,
    payload: { initial: signUpInputInitial },
});

export interface ResetSignUpInput extends Action {
    type: string;
    payload: { initial: SignUpInputType };
}
