import React from 'react';
import _ from 'underscore';
import { DateRequestType } from '../../../util/type/DateRequest.type';
import styles from './css/style.module.scss';
import { SelectInput } from '../../Common/Input/SelectInput';
import { scheduleTimeMaster } from '../../../util/const/Schools/scheduleTimeMaster';

type WeekScheduleInputProps = {
    name: string;
    label: string;
    defaultValue: Array<DateRequestType>;
    returnFunc: (data: Array<DateRequestType>) => void;
};

/*
    export type DateRequestType = {
    day: number; // 曜日（0:日〜6:土）
    from: string; // '時間（mm:ss）
    to: string; // '時間（mm:ss）
 */

const days = [
    { label: '月', id: 1 },
    { label: '火', id: 2 },
    { label: '水', id: 3 },
    { label: '木', id: 4 },
    { label: '金', id: 5 },
    { label: '土', id: 6 },
    { label: '日', id: 7 },
];

export const WeekScheduleInput: React.FC<WeekScheduleInputProps> = (props) => {
    const { defaultValue, returnFunc, label, name } = props;

    return (
        <>
            {label && <div className={styles.label}>開講時間</div>}
            <ul className={styles.innerWrap}>
                {days.map((days, index) => (
                    <li key={`duyStr_${days.id}`}>
                        <div className={styles.innerLabel}>{days.label}</div>
                        <div className={styles.radioInputWrap}>
                            <div className={styles.radioWrap}>
                                <input
                                    type="radio"
                                    value={1}
                                    id={`day_type_${name}_${days.id}_true`}
                                    name={`day_type_${name}_${days.id}`}
                                    className={styles.radioInput}
                                    defaultChecked={defaultValue.some((d) => d.day === index)}
                                    onClick={() => {
                                        defaultValue.push({
                                            day: index, // 曜日（0:日〜6:土）
                                            from: '', // '時間（mm:ss）
                                            to: '', // '時間（mm:ss）
                                        });
                                        returnFunc([...defaultValue]);
                                    }}
                                />
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor={`day_type_${name}_${days.id}_true`} className={styles.radioLabel}>
                                    開校日
                                </label>
                            </div>
                            <div className={styles.radioWrap}>
                                <input
                                    type="radio"
                                    value={2}
                                    id={`day_type_${name}_${days.id}_false`}
                                    name={`day_type_${name}_${days.id}`}
                                    className={styles.radioInput}
                                    defaultChecked={!defaultValue.some((d) => d.day === index)}
                                    onClick={() => {
                                        returnFunc([...defaultValue.filter((d) => d.day !== index)]);
                                    }}
                                />
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor={`day_type_${name}_${days.id}_false`} className={styles.radioLabel}>
                                    休校日
                                </label>
                            </div>
                        </div>

                        <div className={styles.timeWrapper}>
                            <div className={styles.timeWrapper__time}>
                                <SelectInput
                                    label=""
                                    require={false}
                                    name={`day_type_${name}_${days.id}_from`}
                                    id={`day_type_${name}_${days.id}_from`}
                                    data={scheduleTimeMaster}
                                    changeReturnFunc={(text: string) => {
                                        const targetIndex = defaultValue.findIndex((d) => d.day === index);
                                        if (targetIndex > -1) {
                                            const newVal: DateRequestType = defaultValue[targetIndex];
                                            newVal.from = text;
                                            defaultValue[targetIndex] = newVal;
                                            returnFunc([...defaultValue]);
                                        }
                                    }}
                                    defaultInput={(() => {
                                        const targetIndex = defaultValue.findIndex((d) => d.day === index);
                                        if (targetIndex > -1) {
                                            const newVal: DateRequestType = defaultValue[targetIndex];
                                            return newVal.from;
                                        }
                                        return '';
                                    })()}
                                    disableSelectText="未選択"
                                    disable={!defaultValue.some((d) => d.day === index)}
                                    selectStartIndex={null}
                                    selectEndIndex={(() => {
                                        const targetIndex = defaultValue.findIndex((d) => d.day === index);
                                        if (targetIndex > -1) {
                                            const newVal: DateRequestType = defaultValue[targetIndex];
                                            if (newVal !== null && newVal.to) {
                                                return _.findLastIndex(
                                                    scheduleTimeMaster,
                                                    (v) => Number(v.value.split(':').join('')) <= Number(newVal.to.split(':').join(''))
                                                );
                                            }
                                        }
                                        return null;
                                    })()}
                                />
                            </div>
                            <div className={styles.timeWrapper__timeCenter}>ー</div>
                            <div className={styles.timeWrapper__time}>
                                <SelectInput
                                    label=""
                                    require={false}
                                    name={`day_type_${name}_${days.id}_to`}
                                    id={`day_type_${name}_${days.id}_to`}
                                    data={scheduleTimeMaster}
                                    changeReturnFunc={(text: string) => {
                                        const targetIndex = defaultValue.findIndex((d) => d.day === index);
                                        if (targetIndex > -1) {
                                            const newVal: DateRequestType = defaultValue[targetIndex];
                                            newVal.to = text;
                                            defaultValue[targetIndex] = newVal;
                                            returnFunc([...defaultValue]);
                                        }
                                    }}
                                    defaultInput={(() => {
                                        const targetIndex = defaultValue.findIndex((d) => d.day === index);
                                        if (targetIndex > -1) {
                                            const newVal: DateRequestType = defaultValue[targetIndex];
                                            return newVal.to;
                                        }
                                        return '';
                                    })()}
                                    disableSelectText="未選択"
                                    disable={!defaultValue.some((d) => d.day === index)}
                                    selectStartIndex={(() => {
                                        const targetIndex = defaultValue.findIndex((d) => d.day === index);
                                        if (targetIndex > -1) {
                                            const newVal: DateRequestType = defaultValue[targetIndex];
                                            if (newVal !== null && newVal.from) {
                                                return _.findIndex(
                                                    scheduleTimeMaster,
                                                    (v) => Number(v.value.split(':').join('')) >= Number(newVal.from.split(':').join(''))
                                                );
                                            }
                                        }
                                        return null;
                                    })()}
                                    selectEndIndex={null}
                                />
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};
