import { clientInputInitial } from './InitialState/clientInputInitial';
import { ClientInputType } from './type/clientInput.type';
import { ClientActionTypes } from '../../../actions/domain/client';

export const clientInput = (state = clientInputInitial, action: any): ClientInputType => {
    switch (action.type) {
        // input
        case ClientActionTypes.SET_CLIENT_INPUT_CLIENT_TYPE:
            return {
                ...state,
                clientType: action.payload.clientType,
            };
        case ClientActionTypes.SET_CLIENT_INPUT_NAME:
            return {
                ...state,
                name: action.payload.name,
            };
        case ClientActionTypes.SET_CLIENT_INPUT_REPRESENTATIVE_NAME:
            return {
                ...state,
                representativeName: action.payload.representativeName,
            };
        case ClientActionTypes.SET_CLIENT_INPUT_PHONE:
            return {
                ...state,
                phone: action.payload.phone,
            };
        case ClientActionTypes.SET_CLIENT_INPUT_ADDRESS:
            return {
                ...state,
                address: action.payload.address,
            };
        // validation
        case ClientActionTypes.SET_CLIENT_INPUT_CLIENT_TYPE_VALIDATE:
            return {
                ...state,
                clientTypeValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_CLIENT_INPUT_NAME_VALIDATE:
            return {
                ...state,
                nameValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_CLIENT_INPUT_REPRESENTATIVE_NAME_VALIDATE:
            return {
                ...state,
                representativeNameValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_CLIENT_INPUT_PHONE_VALIDATE:
            return {
                ...state,
                phoneValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_CLIENT_INPUT_ADDRESS_VALIDATE:
            return {
                ...state,
                addressValidate: action.payload.flag,
            };
        // result
        case ClientActionTypes.SET_CLIENT_INPUT_RESULT:
            return {
                ...state,
                result: action.payload.result,
            };
        default:
            return state;
    }
};
