import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';
import { SchoolCourseData } from '../../../util/type/School/SchoolCourseData.type';
import {
    patchSchoolCoursePublish,
    patchSchoolCourseUnPublish
} from '../../../redux/actions/domain/schools/schoolCourse';
import { SchoolCardControlList } from '../SchoolCardControlList';

type SchoolCourseCardProps = {
    authToken: string,
    index: number,
    listLength: number,
    schoolId: string;
    schoolCourseData: SchoolCourseData ;
};

export const SchoolCourseCard: React.FC<SchoolCourseCardProps> = (props: SchoolCourseCardProps) => {
    const {authToken, index, listLength, schoolId,schoolCourseData} = props;
    const [menuButtonData, setMenuButtonData] = useState<Array<{
        id: string,
        label: string,
        returnFunc: () => void,
    }>>([])
    const dispatch = useDispatch();

    useEffect(() => {
        const menuButtonData =[];
        if (index + 1 !== 1) {
            menuButtonData.push({
                id: `${schoolCourseData.id}_SchoolCourseCard_menu_button_up`,
                label: `１つ上に移動`,
                returnFunc: () => {
                    // dispatch(putSchoolCourseEditData(authToken, ))
                }
            })
        }

        if (index + 1 !== listLength) {
            menuButtonData.push({
                id: `${schoolCourseData.id}_SchoolCourseCard_menu_button_down`,
                label: `１つ下に移動`,
                returnFunc: () => {
                    // dispatch(putSchoolCourseEditData(authToken, ))
                }
            })
        }

        menuButtonData.push({
            id: `${schoolCourseData.id}_SchoolCourseCard_menu_button_change`,
            label: schoolCourseData.showStatus ? '非公開' : '公開',
            returnFunc: () => {
                if (schoolCourseData.showStatus) {
                    dispatch(patchSchoolCourseUnPublish(authToken, schoolId, schoolCourseData.id.toString(), true ))
                } else {
                    dispatch(patchSchoolCoursePublish(authToken, schoolId, schoolCourseData.id.toString(), true ))
                }
            }
        })

        setMenuButtonData([...menuButtonData]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolCourseData]);

    
    return(
        <div className={styles.wrap}>
            <Link to={`/school/${schoolId}/course/${schoolCourseData.id}`} className={styles.schoolCourseCard}>
                <div className={styles.schoolCourseCard__head}>
                    <div className={`${styles.schoolCourseCard__status} ${!schoolCourseData.showStatus ? styles["schoolCourseCard__status--hidden"] : ""}`}>{ schoolCourseData.showStatus ? "公開中" :"非公開"} </div>
                    <div className={styles.schoolCourseCard__title}>{schoolCourseData.name}</div>
                </div>
                <div className={styles.schoolCourseCard__info}>対象年齢：{schoolCourseData.schoolTargetAge[0] ? schoolCourseData.schoolTargetAge[0].text : '' }〜{schoolCourseData.schoolTargetAge[1] ? schoolCourseData.schoolTargetAge[1].text : ''}</div>
                <div className={styles.schoolCourseCard__info}>受講条件：{schoolCourseData.requirements}</div>
            </Link>
            <SchoolCardControlList
                inputList={menuButtonData}
            />
        </div>
    )}