export const PaymentActionTypes = {
    SET_PAYMENT_MODAL_STEP: 'domain/payment/set_payment_modal_step',
    POST_PAYMENT_SET_UP: 'domain/payment/post_payment_set_up',
    SET_PAYMENT_INTENT_SECRET: 'domain/payment/set_payment_intent_secret',
    SET_NEW_PAYMENT_METHOD_ID: 'domain/payment/set_new_payment_method_id',
    PUT_PAYMENT_METHOD_ID: 'domain/payment/put_payment_method_id',
    SET_BUDGET_LIMIT: 'domain/payment/set_budget_limit',
    SET_PAYMENT_TARGET_SCHOOL: 'domain/payment/set_payment_target_school',
    POST_START_SUBSCRIPTION: 'domain/payment/post_start_subscription',
    SET_STRIPE_CARD_NUMBER_ELEMENT: 'domain/payment/set_stripe_card_number_element',
    POST_PAYMENT_METHOD_TO_STRIPE: 'domain/payment/post_payment_method_to_stripe',
    SET_PAYMENT_RESULT: 'domain/payment/set_payment_result',
    INIT_PAYMENT_STORE: 'domain/payment/init_payment_store',
    POST_CANCEL_SUBSCRIPTION: 'domain/payment/post_cancel_subscription',
};
