export const ClientActionTypes = {
    SET_SIGN_UP_EMAIL: 'domain/client/set_sign_up_email',
    SET_SIGN_UP_PASSWORD: 'domain/client/set_sign_up_password',
    SET_SIGN_UP_PASSWORD_CONFIRM: 'domain/client/set_sign_up_password_confirm',
    SET_SIGN_UP_EMAIL_VALIDATE: 'domain/client/set_sign_up_email_validate',
    SET_SIGN_UP_PASSWORD_VALIDATE: 'domain/client/set_sign_up_password_validate',
    SET_SIGN_UP_PASSWORD_CONFIRM_VALIDATE: 'domain/client/set_sign_up_password_confirm_validate',
    SET_SIGN_UP_RESULT: 'domain/client/set_sign_up_password_result',
    POST_SIGN_UP: 'domain/client/post_sign_up',
    POST_SIGN_UP_REGISTER_CODE: 'domain/client/post_sign_up_register_code',
    RESET_SIGN_UP_INPUT: 'domain/client/reset_sign_up_input',
    SET_SIGN_UP_REGISTER_RESULT: 'domain/client/set_sign_up_register_result',
    SET_SIGN_IN_EMAIL: 'domain/client/set_sign_in_email',
    SET_SIGN_IN_PASSWORD: 'domain/client/set_sign_in_password',
    SET_SIGN_IN_EMAIL_VALIDATE: 'domain/client/set_sign_in_email_validate',
    SET_SIGN_IN_PASSWORD_VALIDATE: 'domain/client/set_sign_in_password_validate',
    SET_SIGN_IN_RESULT: 'domain/client/set_sign_in_password_result',
    RESET_SIGN_IN_INPUT: 'domain/client/reset_sign_in_input',
    GET_CLIENT: 'domain/client/get_client',
    SET_CLIENT_DATA: 'domain/client/set_client_data',
    SET_CLIENT_INPUT_CLIENT_TYPE: 'domain/client/set_client_input_client_type',
    SET_CLIENT_INPUT_NAME: 'domain/client/set_client_input_name',
    SET_CLIENT_INPUT_REPRESENTATIVE_NAME: 'domain/client/set_client_input_representative_name',
    SET_CLIENT_INPUT_PHONE: 'domain/client/set_client_input_phone',
    SET_CLIENT_INPUT_ADDRESS: 'domain/client/set_client_input_address',
    SET_CLIENT_INPUT_CLIENT_TYPE_VALIDATE: 'domain/client/set_client_input_client_type_validate',
    SET_CLIENT_INPUT_NAME_VALIDATE: 'domain/client/set_client_input_name_validate',
    SET_CLIENT_INPUT_REPRESENTATIVE_NAME_VALIDATE: 'domain/client/set_client_input_representative_name_validate',
    SET_CLIENT_INPUT_PHONE_VALIDATE: 'domain/client/set_client_input_phone_validate',
    SET_CLIENT_INPUT_ADDRESS_VALIDATE: 'domain/client/set_client_input_address_validate',
    SET_CLIENT_INPUT_RESULT: 'domain/client/set_client_input_result',
    PUT_CLIENT_INPUT: 'domain/client/put_client_input',
    SET_PASSWORD_REISSUE_OFFER_EMAIL: 'domain/client/set_password_reissue_offer_email',
    SET_PASSWORD_REISSUE_OFFER_EMAIL_VALIDATE: 'domain/client/set_password_reissue_offer_email_validate',
    POST_PASSWORD_REISSUE_OFFER_EMAIL: 'domain/client/post_password_reissue_offer_email',
    SET_PASSWORD_REISSUE_OFFER_RESULT: 'domain/client/set_password_reissue_offer_result',
    RESET_PASSWORD_REISSUE_OFFER: 'domain/client/reset_password_reissue_offer',
    SET_PASSWORD_REGISTER_CODE: 'domain/client/set_password_register_code',
    SET_PASSWORD_REGISTER_PASSWORD: 'domain/client/set_password_register_password',
    SET_PASSWORD_REGISTER_PASSWORD_VALIDATE: 'domain/client/set_password_register_password_validate',
    SET_PASSWORD_REGISTER_PASSWORD_CONFIRM: 'domain/client/set_password_register_password_confirm',
    SET_PASSWORD_REGISTER_PASSWORD_CONFIRM_VALIDATE: 'domain/client/set_password_register_password_confirm_validate',
    SET_PASSWORD_REGISTER_PASSWORD_RESULT: 'domain/client/set_password_register_password_result',
    POST_PASSWORD_REGISTER: 'domain/client/post_password_register',
};
