import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './css/styles.module.scss';
import { setCommonModalShow } from '../../../../../redux/actions/ui';
import explain from "./img/explain.png";

type ExplanationProps = {
    modalNumber: any;
    setModalNumber: any;
};

export const Explanation: React.FC<ExplanationProps> = (props:ExplanationProps) => {
    const {modalNumber,setModalNumber} = props;
    const dispatch = useDispatch();

    return(
        <>
            <div className={styles.title}>
                有料掲載の内容を設定
            </div>
            <div className={styles.borderWrap}>
                予算の設定を決め、ご契約内容を確認後、有料掲載が開始されます。<br/>
                無料プラン、有料プランの詳しい比較内容は下部の表を参照してください。
            </div>
            <button type="button" className={styles.greenBtn} onClick={()=>setModalNumber(modalNumber + 1)}>
                予算の設定へ
            </button>
            <img className={styles.image} src={explain} alt="比較表"/>
            <button type="button" className={styles.greenBtn} onClick={()=>setModalNumber(modalNumber + 1)}>
                予算の設定へ
            </button>
            <div className={styles.cancelWrap}>
                <button type="button" className={styles.cancel} onClick={() => {
                    dispatch(setCommonModalShow(false))
                }}>
                キャンセル
                </button>
            </div>
        </>
    )
};