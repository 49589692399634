import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { SchoolDetailParamTypes } from '../type/SchoolDetaillParam.type';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { Header } from '../../../../components/Common/Header';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { RootState } from '../../../../redux/store';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolPageExplanation } from '../../../../components/Schools/SchoolPageExplanation';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import { TextInput } from '../../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../../components/Common/Input/TextInput/InputValidateType.enum';
import { postNewSchoolCourse, setSchoolNewCourseInput, setSchoolNewCourseValidate } from '../../../../redux/actions/domain/schools/schoolCourse';
import { SchoolDetailCourseType } from '../../../../redux/reducers/domain/schools/type/Course/SchoolDetailCourse.type';
import { ImageUploader, ImageUploaderSize } from '../../../../components/SchoolInput/ImageUploader';
import { TextArea } from '../../../../components/Common/Input/TextArea';
import { WeekScheduleInput } from '../../../../components/SchoolInput/WeekScheduleInput';
import { TargetAgeInput } from '../../../../components/SchoolInput/TargetAgeInput';
import { schoolDetailCourseNewInitial } from '../../../../redux/reducers/domain/schools/InitialState/Course/schoolDetailCourseNewInitial';
import { schoolDetailCourseNewValidateInitial } from '../../../../redux/reducers/domain/schools/InitialState/Course/schoolDetailCourseNewValidateInitial';
import { CourseFeesInput } from '../../../../components/SchoolInput/CourseFeesInput';
import { CourseTimeInput } from '../../../../components/SchoolInput/CourseTimeInput';
import { SchoolDetailInstructorType } from '../../../../redux/reducers/domain/schools/type/Instructor/SchoolDetailInstructor.type';
import { GreenBtn } from '../../../../components/Common/GreenBtn';

export const SchoolDetailCourseNew: React.FC = () => {
    const { schoolId } = useParams<SchoolDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolCourseStore: SchoolDetailCourseType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailCourse);
    const schoolInstructorStore: SchoolDetailInstructorType = useSelector(
        (state: RootState) => state.rootReducer.domain.schools.SchoolDetailInstructor
    );
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(setSchoolNewCourseInput(schoolDetailCourseNewInitial));
        dispatch(setSchoolNewCourseValidate(schoolDetailCourseNewValidateInitial));
    }, [authToken, dispatch, schoolId]);

    if (schoolStore.schoolDetailData !== null) {
        return (
            <>
                <Header />
                <OwnerApplication />
                <TabBar currentPage="/school" />
                <div className={styles.schoolContainer}>
                    <BreadCrumb
                        data={[
                            { text: '教室一覧', path: '/school' },
                            { text: '教室名', path: '' },
                        ]}
                    />
                    <SchoolHeader data={schoolStore.schoolDetailData} />
                    <SchoolTabBar currentPage={`/school/${schoolId}/course`} schoolId={schoolId} />
                    <SchoolPageExplanation
                        title="コース"
                        textDom={<Link to={`/school/${schoolId}/course`}>&lt;追加をしないで、一覧に戻る</Link>}
                        btnText={null}
                        path={null}
                    />
                    <SchoolH3 editable={false} otherEditPlace={null} ButtonFunc={null} text="コース詳細" isShow={null} />
                    <div className={styles.schoolInfoWrap}>
                        <div className={styles.editInputWrap}>
                            <div className={styles['editInputLabel--require']}>コース名</div>
                            <TextInput
                                label=""
                                name="courseName"
                                id="courseName"
                                require
                                inputType="text"
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            name: text,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCourseValidate({
                                            ...schoolCourseStore.newValidate,
                                            name: validate,
                                        })
                                    );
                                }}
                                defaultInput={schoolCourseStore.new.name}
                                placeHolder="初心者向けプログラミング初級"
                                minLength={1}
                                defaultInputValue={schoolCourseStore.new.name}
                                disabled={false}
                            />
                        </div>

                        <div className={styles['editInputLabel--option']}>メイン画像</div>
                        <div className={styles.editInputWrap}>
                            <ImageUploader
                                uiSize={ImageUploaderSize.BIG}
                                identificationTitle={null}
                                identificationText={null}
                                defaultImageUrl={null}
                                returnFunc={(file) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            image: file,
                                        })
                                    );
                                }}
                            />
                            <div className={styles.description}>画像サイズ：10MBまで タテヨコ比目安：11:18</div>
                        </div>

                        <div className={styles['editInputLabel--require']}>スケジュール</div>
                        <div className={styles.editInputWrap}>
                            <WeekScheduleInput
                                defaultValue={schoolCourseStore.new.scheduleWeekSchedule}
                                returnFunc={(data) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            scheduleWeekSchedule: data,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCourseValidate({
                                            ...schoolCourseStore.newValidate,
                                            scheduleWeekSchedule: !data.find((d) => d !== null && (d.to === '' || d.from === '')),
                                        })
                                    );
                                }}
                                label=""
                                name="schoolCourseScheduleWeekSchedule"
                            />
                        </div>

                        <div className={styles['editInputLabel--option']}>対象年齢</div>
                        <div className={styles.editInputWrap}>
                            <TargetAgeInput
                                defaultValue={schoolCourseStore.new.targetAgeIds}
                                returnFunc={(data) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            targetAgeIds: data,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCourseValidate({
                                            ...schoolCourseStore.newValidate,
                                            targetAgeIds: true,
                                        })
                                    );
                                }}
                            />
                        </div>
                        <div className={styles['editInputLabel--option']}>受講条件</div>
                        <div className={styles.editInputWrap}>
                            <TextInput
                                label=""
                                name="courseRequirements"
                                id="courseRequirements"
                                require={false}
                                inputType="text"
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            requirements: text,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCourseValidate({
                                            ...schoolCourseStore.newValidate,
                                            requirements: validate,
                                        })
                                    );
                                }}
                                defaultInput={schoolCourseStore.new.requirements}
                                placeHolder="〇〇コースを終了している方"
                                minLength={1}
                                defaultInputValue={schoolCourseStore.new.requirements}
                                disabled={false}
                            />
                        </div>
                        <div className={styles['editInputLabel--option']}>コース説明</div>
                        <div className={styles.editInputWrap}>
                            <TextArea
                                label=""
                                name="courseDescription"
                                id="courseDescription"
                                require={false}
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation={null}
                                changeReturnFunc={(text, validate) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            description: text,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCourseValidate({
                                            ...schoolCourseStore.newValidate,
                                            description: validate,
                                        })
                                    );
                                }}
                                defaultInput={schoolCourseStore.new.description}
                                placeHolder={`このコースは、総合的な英語力や表現力を楽しく身に付けられるコースです。
ネイティブ講師（外国人）とバイリンガル講師（日本人）のレッスンで、英語の４技能である「聞く・話す・読む・書く」を総合的に伸ばします。小さなお子さまでも90分間楽しく学べるよう、様々な要素をバランスよく取り入れてレッスンを行います。

【おすすめポイント１：お子さまのペースに合わせた無理のないレッスン】
座学で教えるだけではなく、インタラクティブなコミュニケーションを行い、お子さまを飽きさせないようにしています。

【おすすめポイント２：お子さまに人気の教材】
オリジナルの教材を使ってレッスンを行います。単元を修了するとシールが貰えるためお子さまも高いモチベーションでレッスンを受けてくれます。

まずは無料体験レッスンを受講してみてください♪`}
                                forReview={false}
                                forInquiry={false}
                                countFlag
                                maxLength={1000}
                                minLength={null}
                                height={341}
                            />
                        </div>
                        <div className={styles['editInputLabel--option']}>料金・費用について</div>
                        <div className={styles.editInputWrap}>
                            <CourseFeesInput
                                returnFunc={(input, validate) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            membershipFee: input.membershipFee,
                                            annualFee: input.annualFee,
                                            monthlyFee: input.monthlyFee,
                                            textFee: input.textFee,
                                            feeRemark: input.feeRemark,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCourseValidate({
                                            ...schoolCourseStore.newValidate,
                                            membershipFee: validate.membershipFee,
                                            annualFee: validate.annualFee,
                                            monthlyFee: validate.monthlyFee,
                                            textFee: validate.textFee,
                                            feeRemark: validate.feeRemark,
                                        })
                                    );
                                }}
                                defaultInput={{
                                    membershipFee: schoolCourseStore.new.membershipFee,
                                    annualFee: schoolCourseStore.new.annualFee,
                                    monthlyFee: schoolCourseStore.new.monthlyFee,
                                    textFee: schoolCourseStore.new.textFee,
                                    feeRemark: schoolCourseStore.new.feeRemark,
                                }}
                                defaultValidate={{
                                    membershipFee: schoolCourseStore.newValidate.membershipFee,
                                    annualFee: schoolCourseStore.newValidate.annualFee,
                                    monthlyFee: schoolCourseStore.newValidate.monthlyFee,
                                    textFee: schoolCourseStore.newValidate.textFee,
                                    feeRemark: schoolCourseStore.newValidate.feeRemark,
                                }}
                            />
                        </div>
                        <div className={styles['editInputLabel--option']}>受講について</div>
                        <div className={styles.editInputWrap}>
                            <CourseTimeInput
                                defaultValue={{
                                    from: schoolCourseStore.new.timeMinutesFrom,
                                    to: schoolCourseStore.new.timeMinutesTo,
                                    timeRemarks: schoolCourseStore.new.timeRemarks,
                                }}
                                returnFunc={(from, to) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            timeMinutesFrom: from,
                                            timeMinutesTo: to,
                                        })
                                    );
                                }}
                                returnFuncRemarks={(text, validate) => {
                                    dispatch(
                                        setSchoolNewCourseInput({
                                            ...schoolCourseStore.new,
                                            timeRemarks: text,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCourseValidate({
                                            ...schoolCourseStore.newValidate,
                                            timeRemarks: validate,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <SchoolH3 editable={false} otherEditPlace={null} ButtonFunc={null} text="講師紹介" isShow={null} />
                    <div className={styles.schoolInfoWrap}>
                        <ul className={styles.schoolCourseDetailInstructorInputList}>
                            {schoolInstructorStore.list?.map((d) => (
                                <li className={styles.schoolCourseDetailInstructorInputListLow} key={d.id}>
                                    <input
                                        type="checkbox"
                                        id={`coach_input_row_${d.id}`}
                                        value={d.id}
                                        checked={schoolCourseStore.new.instructorIds.some((val) => val === d.id)}
                                        onChange={(e) => {
                                            const newArray = schoolCourseStore.new.instructorIds.filter((val) => val !== Number(d.id));
                                            if (e.target.checked) {
                                                if (schoolCourseStore.new.instructorIds.length === 3) return;
                                                newArray.push(d.id);
                                            }
                                            dispatch(
                                                setSchoolNewCourseInput({
                                                    ...schoolCourseStore.new,
                                                    instructorIds: [...newArray],
                                                })
                                            );
                                        }}
                                    />
                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                    <label htmlFor={`coach_input_row_${d.id}`} className={styles.inputLabel} />
                                    {d.imageUrl && (
                                        <div className={styles.schoolCourseDetailInstructorInputListImage}>
                                            <img src={d.imageUrl} alt="" />
                                        </div>
                                    )}
                                    <div className={styles.schoolCourseDetailInstructorInputListText}>
                                        <div>名前：{d.name}</div>
                                        <div>{d.description}</div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className={styles.courseInstructorDescription}>講師は３人まで選択可能です。</div>
                        <div className={styles.courseInstructorDescription}>
                            講師の追加、削除編集は
                            <Link className={styles.linkText} to={`/school/${schoolId}/instructor/`}>
                                コース一覧
                            </Link>
                            から行ってください。
                        </div>
                        <div className={styles.buttonWrap}>
                            <GreenBtn
                                text="コースを作成・公開する"
                                style={
                                    Object.entries(schoolCourseStore.newValidate).some((d) => !d[1])
                                        ? { background: '#dcdcdc', pointerEvents: 'none' }
                                        : {}
                                }
                                handleClick={() => {
                                    dispatch(postNewSchoolCourse(authToken, schoolId, schoolCourseStore.new, true));
                                }}
                                isLink={false}
                                path=""
                            />
                        </div>
                        <div className={styles.buttonWrap}>
                            <button
                                type="button"
                                className={styles.linkText}
                                style={
                                    Object.entries(schoolCourseStore.newValidate).some((d) => !d[1])
                                        ? { color: '#dcdcdc', pointerEvents: 'none' }
                                        : {}
                                }
                                onClick={() => {
                                    dispatch(postNewSchoolCourse(authToken, schoolId, schoolCourseStore.new, false));
                                }}>
                                {loadingSelector ? '通信中...' : '下書き保存する（非公開）'}
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.schoolContainer}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室名', path: '' },
                    ]}
                />
            </div>
            <Footer />
        </>
    );
};
