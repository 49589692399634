import { AxiosResponse } from 'axios';
import { axiosBase } from './index';
import { ClientDataType } from '../../redux/reducers/domain/client/type/clientData.type';

export class PaymentApi {
    // クライアントペイメント用意
    static async postClientPaymentSetup(token: string): Promise<
    AxiosResponse<{
        secret: string;
    }>
    > {
        return axiosBase.post(
            '/client/payment/setup',
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    // クライアントペイメントメソッド更新
    static async putUpdateClientPaymentMethodId(
        token: string,
        paymentMethodId: string
    ): Promise<
        AxiosResponse<{
            client: ClientDataType;
        }>
        > {
        return axiosBase.put(
            '/client/payment',
            {
                paymentMethodId,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    // スクールサブスクリプション開始
    static async postClientSubscription(token: string, schoolId: string, budgetLimit: number | null): Promise<AxiosResponse<null>> {
        return axiosBase.post(
            'client/subscription',
            budgetLimit !== null
                ? {
                    schoolId,
                    budgetLimit,
                }
                : {
                    schoolId,
                },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    // スクールサブスクリプションキャンセル
    static async postCancelClientSubscription(token: string, schoolId: string): Promise<AxiosResponse<null>> {
        return axiosBase.post(
            'client/subscription/cancel',
            { schoolId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }
}
