import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { SchoolDetailParamTypes } from '../type/SchoolDetaillParam.type';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { Header } from '../../../../components/Common/Header';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { RootState } from '../../../../redux/store';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { SchoolDetailCouponType } from '../../../../redux/reducers/domain/schools/type/Coupon/SchoolDetailCoupon.type';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import { postNewSchoolCoupon, setSchoolNewCouponInput, setSchoolNewCouponValidate } from '../../../../redux/actions/domain/schools/schoolCoupon';
import { schoolDetailCouponInputInitial } from '../../../../redux/reducers/domain/schools/InitialState/Coupon/schoolDetailCouponInputInitial';
import { schoolDetailCouponValidateInitial } from '../../../../redux/reducers/domain/schools/InitialState/Coupon/schoolDetailCouponValidateInitial';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolPageExplanation } from '../../../../components/Schools/SchoolPageExplanation';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import { ImageUploader, ImageUploaderSize } from '../../../../components/SchoolInput/ImageUploader';
import { TextInput } from '../../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../../components/Common/Input/TextInput/InputValidateType.enum';
import { TextArea } from '../../../../components/Common/Input/TextArea';
import { GreenBtn } from '../../../../components/Common/GreenBtn';
import { DateInput } from '../../../../components/SchoolInput/DateInput';

export const SchoolDetailCouponNew: React.FC = () => {
    const { schoolId } = useParams<SchoolDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolCouponStore: SchoolDetailCouponType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailCoupon);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(setSchoolNewCouponInput(schoolDetailCouponInputInitial));
        dispatch(setSchoolNewCouponValidate(schoolDetailCouponValidateInitial));
    }, [authToken, dispatch, schoolId]);

    if (schoolStore.schoolDetailData !== null) {
        return (
            <>
                <Header />
                <OwnerApplication />
                <TabBar currentPage="/school" />
                <div className={styles.schoolContainer}>
                    <BreadCrumb
                        data={[
                            { text: '教室一覧', path: '/school' },
                            { text: '教室名', path: '' },
                        ]}
                    />
                    <SchoolHeader data={schoolStore.schoolDetailData} />
                    <SchoolTabBar currentPage={`/school/${schoolId}/coupon`} schoolId={schoolId} />
                    <SchoolPageExplanation
                        title="コース"
                        textDom={<Link to={`/school/${schoolId}/coupon`}>&lt;クーポン一覧に戻る</Link>}
                        btnText={null}
                        path={null}
                    />
                    <SchoolH3 editable={false} otherEditPlace={null} ButtonFunc={null} text="新規クーポン詳細" isShow={false} />
                    <div className={styles.schoolInfoWrap}>
                        <div className={styles.editInputWrap}>
                            <div className={styles['editInputLabel--require']}>クーポン名</div>
                            <TextInput
                                label=""
                                name="couponName"
                                id="couponName"
                                require
                                inputType="text"
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setSchoolNewCouponInput({
                                            ...schoolCouponStore.new,
                                            name: text,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCouponValidate({
                                            ...schoolCouponStore.newValidate,
                                            name: validate,
                                        })
                                    );
                                }}
                                defaultInput={schoolCouponStore.new.name}
                                placeHolder="クーポン名を記入"
                                minLength={1}
                                defaultInputValue={schoolCouponStore.new.name}
                                disabled={false}
                            />
                        </div>
                        <div className={styles.editInputWrap}>
                            <div className={styles['editInputLabel--option']}>提示条件</div>
                            <TextInput
                                label=""
                                name="couponPresentationConditions"
                                id="couponPresentationConditions"
                                require={false}
                                inputType="text"
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setSchoolNewCouponInput({
                                            ...schoolCouponStore.new,
                                            presentationConditions: text,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCouponValidate({
                                            ...schoolCouponStore.newValidate,
                                            presentationConditions: validate,
                                        })
                                    );
                                }}
                                defaultInput={schoolCouponStore.new.presentationConditions}
                                placeHolder="提示条件を記入"
                                minLength={1}
                                defaultInputValue={schoolCouponStore.new.presentationConditions}
                                disabled={false}
                            />
                        </div>
                        <div className={styles.editInputWrap}>
                            <div className={styles['editInputLabel--option']}>利用条件</div>
                            <TextInput
                                label=""
                                name="couponUtilizationConditions"
                                id="couponUtilizationConditions"
                                require={false}
                                inputType="text"
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setSchoolNewCouponInput({
                                            ...schoolCouponStore.new,
                                            utilizationConditions: text,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCouponValidate({
                                            ...schoolCouponStore.newValidate,
                                            utilizationConditions: validate,
                                        })
                                    );
                                }}
                                defaultInput={schoolCouponStore.new.utilizationConditions}
                                placeHolder="利用条件を記入"
                                minLength={1}
                                defaultInputValue={schoolCouponStore.new.utilizationConditions}
                                disabled={false}
                            />
                        </div>

                        <div className={styles.editInputWrap}>
                            <div className={styles['editInputLabel--require']}>利用期限</div>
                            <DateInput
                                id="newCouponLimitDate"
                                onChangeFunc={(data) => {
                                    dispatch(
                                        setSchoolNewCouponInput({
                                            ...schoolCouponStore.new,
                                            limitDate: data,
                                        })
                                    );
                                }}
                                onChangeValidate={(validate) => {
                                    dispatch(
                                        setSchoolNewCouponValidate({
                                            ...schoolCouponStore.newValidate,
                                            limitDate: validate,
                                        })
                                    );
                                }}
                                defaultVal={schoolCouponStore.new.limitDate}
                            />
                        </div>

                        <div className={styles.editInputWrap}>
                            <div className={styles['editInputLabel--option']}>利用可能開始日</div>
                            <DateInput
                                id="newCouponUsableDateDate"
                                onChangeFunc={(data) => {
                                    dispatch(
                                        setSchoolNewCouponInput({
                                            ...schoolCouponStore.new,
                                            usableDate: data,
                                        })
                                    );
                                }}
                                onChangeValidate={(validate) => {
                                    dispatch(
                                        setSchoolNewCouponValidate({
                                            ...schoolCouponStore.newValidate,
                                            usableDate: validate,
                                        })
                                    );
                                }}
                                defaultVal={schoolCouponStore.new.usableDate}
                            />
                        </div>

                        <div className={styles['editInputLabel--option']}>メイン画像</div>
                        <div className={styles.editInputWrap}>
                            <ImageUploader
                                uiSize={ImageUploaderSize.BIG}
                                identificationTitle={null}
                                identificationText={null}
                                defaultImageUrl={null}
                                returnFunc={(file) => {
                                    dispatch(
                                        setSchoolNewCouponInput({
                                            ...schoolCouponStore.new,
                                            image: file,
                                        })
                                    );
                                }}
                            />
                            <div className={styles.description}>画像サイズ：10MBまで タテヨコ比目安：11:18</div>
                        </div>

                        <div className={styles['editInputLabel--option']}>クーポン説明</div>
                        <div className={styles.editInputWrap}>
                            <TextArea
                                label=""
                                name="newCouponTitle"
                                id="newCouponTitle"
                                require
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation={null}
                                changeReturnFunc={(text, validate) => {
                                    dispatch(
                                        setSchoolNewCouponInput({
                                            ...schoolCouponStore.new,
                                            description: text,
                                        })
                                    );
                                    dispatch(
                                        setSchoolNewCouponValidate({
                                            ...schoolCouponStore.newValidate,
                                            description: validate,
                                        })
                                    );
                                }}
                                defaultInput={schoolCouponStore.new.description}
                                placeHolder="クーポンの説明を記入"
                                forReview={false}
                                forInquiry={false}
                                countFlag
                                maxLength={500}
                                minLength={1}
                                height={200}
                            />
                        </div>

                        <div className={styles.buttonWrap}>
                            <GreenBtn
                                text="クーポンを作成・公開する"
                                style={
                                    Object.entries(schoolCouponStore.newValidate).some((d) => !d[1])
                                        ? { background: '#dcdcdc', pointerEvents: 'none' }
                                        : {}
                                }
                                handleClick={() => {
                                    dispatch(postNewSchoolCoupon(authToken, schoolId, schoolCouponStore.new, true));
                                }}
                                isLink={false}
                                path=""
                            />
                        </div>
                        <div className={styles.buttonWrap}>
                            <button
                                type="button"
                                className={styles.linkText}
                                style={
                                    Object.entries(schoolCouponStore.newValidate).some((d) => !d[1])
                                        ? { color: '#dcdcdc', pointerEvents: 'none' }
                                        : {}
                                }
                                onClick={() => {
                                    dispatch(postNewSchoolCoupon(authToken, schoolId, schoolCouponStore.new, false));
                                }}>
                                {loadingSelector ? '通信中...' : '下書き保存する（非公開）'}
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.schoolContainer}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室名', path: '' },
                    ]}
                />
            </div>
            <Footer />
        </>
    );
};
