import { Action } from 'redux';
import { SchoolInquiryDataType } from '../../../../util/type/SchoolInquiryData.type';

export const InquiryActionTypes = {
    GET_INQUIRY_DETAIL: 'domain/inquiry/get_inquiry_detail',
    SET_INQUIRY_DETAIL: 'domain/inquiry/set_inquiry_detail',
}

export const getInquiryDetail = (token: string, inquiryId: number): GetInquiryDetail => ({
    type: InquiryActionTypes.GET_INQUIRY_DETAIL,
    payload: {
        token,
        inquiryId
    }
});

export interface GetInquiryDetail extends Action {
    type: string;
    payload: {
        token: string,
        inquiryId: number,
    };
}

export const setInquiryDetail = (data: SchoolInquiryDataType | null): SetInquiryDetail => ({
    type: InquiryActionTypes.SET_INQUIRY_DETAIL,
    payload: { data }
});

export interface SetInquiryDetail extends Action {
    type: string;
    payload: {
        data: SchoolInquiryDataType | null,
    };
}
