import { Action } from 'redux';
import { ClientType } from '../../../reducers/domain/client/enum/clientType.enum';
import { ClientResponseType } from '../../../../util/type/ClientResponse.type';
import { ClientInputType } from '../../../reducers/domain/client/type/clientInput.type';
import { ClientActionTypes } from './index';

// クライアント情報取得
export const getClient = (authToken: string): GetClient => ({
    type: ClientActionTypes.GET_CLIENT,
    payload: { authToken },
});

export interface GetClient extends Action {
    type: string;
    payload: {
        authToken: string;
    };
}

// クライアント情報保持
export const setClientData = (data: ClientResponseType): SetClientData => ({
    type: ClientActionTypes.SET_CLIENT_DATA,
    payload: { data },
});

export interface SetClientData extends Action {
    type: string;
    payload: {
        data: ClientResponseType;
    };
}

// クライアント情報入力
export const setClientInputClientType = (clientType: ClientType): SetClientInputClientType => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_CLIENT_TYPE,
    payload: { clientType },
});

export interface SetClientInputClientType extends Action {
    type: string;
    payload: { clientType: ClientType | null };
}

export const setClientInputName = (name: string): SetClientInputName => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_NAME,
    payload: { name },
});

export interface SetClientInputName extends Action {
    type: string;
    payload: { name: string };
}

export const setClientInputRepresentativeName = (representativeName: string): SetClientInputRepresentativeName => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_REPRESENTATIVE_NAME,
    payload: { representativeName },
});

export interface SetClientInputRepresentativeName extends Action {
    type: string;
    payload: { representativeName: string };
}

export const setClientInputPhone = (phone: string): SetClientInputPhone => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_PHONE,
    payload: { phone },
});

export interface SetClientInputPhone extends Action {
    type: string;
    payload: { phone: string };
}

export const setClientInputAddress = (address: string): SetClientInputAddress => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_ADDRESS,
    payload: { address },
});

export interface SetClientInputAddress extends Action {
    type: string;
    payload: { address: string };
}

export const setClientInputClientTypeValidate = (flag: boolean): SetClientInputClientTypeValidate => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_CLIENT_TYPE_VALIDATE,
    payload: { flag },
});

export interface SetClientInputClientTypeValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setClientInputNameValidate = (flag: boolean): SetClientInputNameValidate => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_NAME_VALIDATE,
    payload: { flag },
});

export interface SetClientInputNameValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setClientInputRepresentativeNameValidate = (flag: boolean): SetClientInputRepresentativeNameValidate => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_REPRESENTATIVE_NAME_VALIDATE,
    payload: { flag },
});

export interface SetClientInputRepresentativeNameValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setClientInputPhoneValidate = (flag: boolean): SetClientInputPhoneValidate => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_PHONE_VALIDATE,
    payload: { flag },
});

export interface SetClientInputPhoneValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setClientInputAddressValidate = (flag: boolean): SetClientInputAddressValidate => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_ADDRESS_VALIDATE,
    payload: { flag },
});

export interface SetClientInputAddressValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setClientInputResult = (result: { flag: boolean; reason: string }): SetClientInputResult => ({
    type: ClientActionTypes.SET_CLIENT_INPUT_RESULT,
    payload: { result },
});

export interface SetClientInputResult extends Action {
    type: string;
    payload: {
        result: {
            flag: boolean;
            reason: string;
        };
    };
}

export const putClientInput = (authToken: string, clientInput: ClientInputType): PutClientInput => ({
    type: ClientActionTypes.PUT_CLIENT_INPUT,
    payload: {
        clientInput,
        authToken,
    },
});

export interface PutClientInput extends Action {
    type: string;
    payload: {
        clientInput: ClientInputType;
        authToken: string;
    };
}
