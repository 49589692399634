import { Action } from 'redux';
import { SchoolsActionTypes } from './index';
import { SchoolDetail } from '../../../../util/type/SchoolDetail.type';

export const getSchoolDetail = (token: string, schoolId: number): GetSchoolDetail => ({
    type: SchoolsActionTypes.GET_SCHOOL_DETAIL,
    payload: { schoolId, token }
});

export interface GetSchoolDetail extends Action {
    type: string;
    payload: {
        schoolId: number,
        token: string,
    };
}

export const resetEditFlag = (): ResetEditFlag =>({
    type: SchoolsActionTypes.RESET_SCHOOL_EDIT_FLAG,
})

export interface ResetEditFlag extends Action {
    type: string;
}

export const setSchoolDetail = (schoolDetailData: SchoolDetail | null): SetSchoolDetail => ({
    type: SchoolsActionTypes.SET_SCHOOL_DETAIL,
    payload: { schoolDetailData }
});

export interface SetSchoolDetail extends Action {
    type: string;
    payload: {
        schoolDetailData: SchoolDetail | null
    };
}
