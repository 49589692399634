import React, { useEffect } from 'react';
import styles from './css/styles.module.scss';
import searchIconImage from './img/search.svg';
import { useNearestStationBusStopPicker } from './hooks/useNearestStationBusStopPicker';
import { SchoolNearestSpotType } from '../../../util/type/SchoolNearestSpot.type';

type NearestStationBusStopPickerProps = {
    returnFunc: (value: { value: number; onFoot: number; label: string } | null, isBusStop: boolean) => void;
    defaultData?: {
        nearestStation: SchoolNearestSpotType | null;
        nearestBusstop: SchoolNearestSpotType | null;
    };
};

export const NearestStationBusStopPicker: React.FC<NearestStationBusStopPickerProps> = (props) => {
    const { returnFunc, defaultData } = props;

    const [state, callback] = useNearestStationBusStopPicker(defaultData);

    useEffect(() => {
        if (state.selectData) {
            returnFunc({ value: state.selectData.value, onFoot: state.onFoot, label: state.selectData.label }, state.selectData.isBusStop);
        } else {
            returnFunc(null, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selectData]);

    return (
        <div className={styles.wrap}>
            <div className={styles.label}>最寄りの駅・バス停</div>

            <div className={styles.selected}>
                {state.selectData === null ? (
                    <div className={styles.noSelected}>下部から最寄りの駅・バス停を選択してください</div>
                ) : (
                    <ul className={styles.selectedList}>
                        <li>
                            <button
                                type="button"
                                onClick={() => {
                                    callback.setSelectData(null);
                                }}>
                                {state.selectData.label} ×
                            </button>
                        </li>
                    </ul>
                )}
            </div>

            <div className={styles.pickerWrap}>
                <div className={styles.textInput}>
                    <img src={searchIconImage} alt="search" />
                    <input
                        type="text"
                        value={state.searchText}
                        onChange={async (event) => {
                            callback.setSearchText(event.target.value);
                            await callback.getKeywordSuggest(event.target.value);
                        }}
                        placeholder="最寄りの駅・バス停を入力"
                    />
                </div>

                <ul className={styles.picker}>
                    {state.keywords.map((d) => (
                        <li key={`suggest_${d.id}`}>
                            <button
                                type="button"
                                onClick={() => {
                                    callback.setSelectData({
                                        value: d.id,
                                        label: d.text,
                                        isBusStop: d.isBusStop,
                                    });
                                    if (state.onFoot) {
                                        returnFunc({ value: d.id, onFoot: state.onFoot, label: d.text }, d.isBusStop);
                                    } else {
                                        returnFunc(null, d.isBusStop);
                                    }
                                }}>
                                {d.text}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            <div className={styles.annotation}>※上部の入力エリアで、駅名・バス停名をサジェストし、選択してください</div>

            <div className={styles.onFootWrap}>
                徒歩
                <input
                    className={styles.onFootWrap__input}
                    type="text"
                    value={state.onFoot ? state.onFoot : 0}
                    disabled={state.selectData === null}
                    onChange={(e) => {
                        callback.setOnFoot(Number(e.target.value) ? Number(e.target.value) : 0);
                        if (state.selectData)
                            returnFunc(
                                {
                                    value: state.selectData.value,
                                    onFoot: Number(e.target.value) ? Number(e.target.value) : 0,
                                    label: state.selectData.label,
                                },
                                state.selectData.isBusStop
                            );
                    }}
                />
                分
            </div>
        </div>
    );
};
