export const schoolCourseTimeMaster: Array<{ value: string; label: string }> = [
    { value: '10', label: '10分' },
    { value: '20', label: '20分' },
    { value: '30', label: '30分' },
    { value: '40', label: '40分' },
    { value: '50', label: '50分' },
    { value: '60', label: '60分' },
    { value: '90', label: '90分' },
    { value: '120', label: '120分' },
    { value: '180', label: '180分' },
];
