import { SettingsActions } from '../../../actions/domain/settings';
import { SendResultDataType } from './type/SendResultData.type';


export const SendDocuments = (state: SendResultDataType = {sendResult: null}, action: any) => {
    switch (action.type) {

        case SettingsActions.SET_SEND_DOCUMENTS_RESULT:
            return {
                ...state,
                sendResult: action.payload.result
            }
        default:
            return state;
    }
};