import { CreateInvitedUserType } from '../type/CreateInviteUser.type';

export const createInviteUserInitialize: CreateInvitedUserType = {
    input: {
        password: '',
        password_confirm: '',
    },
    inputValidate: {
        password: false,
        password_confirm: false,
    },
    result: {
        flag: false,
        reason: '',
    }
}