import React, { useRef, useState } from 'react';
import styles from './css/style.module.scss';

export enum ImageUploaderSize {
    BIG = 1,
    SMALL,
    ExBIG,
    MEDIUM,
    IDENTIFICATION,
}

type ImageUploaderProps = {
    uiSize: ImageUploaderSize,
    defaultImageUrl: string | null,
    returnFunc: (file: File | null, deleteFlag: boolean) => void,
    identificationTitle:string | null
    identificationText:Array<string> | null,
}

export const ImageUploader: React.FC<ImageUploaderProps> = (props) => {
    const {uiSize, defaultImageUrl, returnFunc, identificationTitle, identificationText} = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const [imageUrl, setImageUrl] = useState(defaultImageUrl);

    return (
        <div className={`${styles.wrap} ${imageUrl && styles["wrap--change"]} ${uiSize === ImageUploaderSize.SMALL ? styles.smallWrap : ''} ${uiSize === ImageUploaderSize.ExBIG  ? styles.exBigWrap : ''} ${uiSize === ImageUploaderSize.MEDIUM  ? styles.mediumWrap : ''} ${uiSize === ImageUploaderSize.IDENTIFICATION ? styles.identificationWrap : ''}`}>
            {!imageUrl && 
                <>
                    <div className={styles.inner}>
                        {(uiSize === ImageUploaderSize.BIG || uiSize === ImageUploaderSize.ExBIG) && (<div className={`${styles.mainText}  ${uiSize === ImageUploaderSize.ExBIG  ? styles.exBigMainText : ''}`}>
                                    メインになる画像を{uiSize !== ImageUploaderSize.ExBIG && (<br/>)}
                                    挿入してください
                        </div>)}
                        {uiSize === ImageUploaderSize.IDENTIFICATION  && 
                            <>
                                <div className={`${styles.hoge}  ${styles.identificationTitle}`}>
                                    {identificationTitle}
                                </div>
                                <div className={`${styles.hoge}  ${styles.identificationText}`}>
                                    {identificationText?.map((d)=>
                                        <div key={d}>{d}</div>
                                    )}
                                </div>
                            </>
                        }

                        <div className={`${styles.buttonWrap} ${uiSize === ImageUploaderSize.SMALL ? styles.smallButtonWrap : ''} ${uiSize === ImageUploaderSize.MEDIUM  ? styles.mediumButtonWrap : ''} ${uiSize === ImageUploaderSize.IDENTIFICATION  ? styles.identificationButtonWrap : ''}`}>
                            <button type="button" onClick={() => {
                                const input = inputRef.current;
                                if (input) input.click();
                            }}>
                                {uiSize === ImageUploaderSize.IDENTIFICATION ?
                                    <>
                                    画像をアップロード
                                    </>
                                    :
                                    <>
                                    画像を挿入
                                    </>
                                }
                            </button>
                        </div>
                        

                        <input
                            hidden
                            ref={inputRef}
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                    const targetFile = e.target.files[0];
                                    if (targetFile) {
                                        // eslint-disable-next-line no-alert
                                        if (targetFile.size > 10485760) { // 10MB
                                            // eslint-disable-next-line no-alert
                                            alert('画像が大きすぎます。10MBまで使用できます。');

                                        } else {
                                            // サムネ表示
                                            setImageUrl(window.URL.createObjectURL(targetFile));
                                            // 親にFileを返す
                                            returnFunc(targetFile, false);
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </>
            }
            {imageUrl && (
                <>
                    {uiSize === ImageUploaderSize.IDENTIFICATION ?
                        <div className={styles.inner}>
                            <div className={styles.uploadedText}>
                            アップロード済み
                            </div>
                        </div>
                        :
                        <div className={styles.bg}>
                            <img src={imageUrl} alt='' />
                        </div>
                    }
                    <div className={`${styles.buttonWrap} ${styles["buttonWrap--change"]} ${uiSize === ImageUploaderSize.SMALL ? styles.smallButtonWrap : ''} ${uiSize === ImageUploaderSize.MEDIUM  ? styles.mediumButtonWrap : ''} ${uiSize === ImageUploaderSize.IDENTIFICATION ? styles.identificationButtonWrap : ''}`}>
                        <button type="button" onClick={() => {
                            const input = inputRef.current;
                            if (input) input.click();
                        }}>画像を変更</button>
                    </div>
                    <div className={`${styles.deleteButtonWrap} ${uiSize === ImageUploaderSize.SMALL ? styles.smallDeleteButtonWrap : ''} ${uiSize === ImageUploaderSize.MEDIUM  ? styles.mediumDeleteButtonWrap : ''} ${uiSize === ImageUploaderSize.IDENTIFICATION ? styles.identificationDeleteButtonWrap : ''}`}>
                        <button type="button" onClick={() => {
                            setImageUrl(null);
                            returnFunc(null, !!defaultImageUrl);
                            return false;
                        }}>削除</button>
                    </div>

                    <input
                        hidden
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                const targetFile = e.target.files[0];
                                if (targetFile) {
                                    // eslint-disable-next-line no-alert
                                    if (targetFile.size > 10485760) { // 10MB
                                        // eslint-disable-next-line no-alert
                                        alert('画像が大きすぎます。10MBまで使用できます。');

                                    } else {
                                        // サムネ表示
                                        setImageUrl(window.URL.createObjectURL(targetFile));
                                        // 親にFileを返す
                                        returnFunc(targetFile, false);
                                    }
                                }
                            }
                        }}
                    />
                </>
            )}
        </div>
    )
}