import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { Header } from '../../components/Common/Header';
import { OwnerApplication } from '../../components/MyPage/OwnerApplication';
import { TabBar } from '../../components/MyPage/TabBar';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import { Footer } from '../../components/Common/Footer';
import styles from '../../asset/css/inquiryCommon.module.scss';
import { InquiryType } from '../../redux/reducers/domain/inquiry/type/Inquiry.type';
import { getInquiryDetail, setInquiryDetail } from '../../redux/actions/domain/inquiry';
import { InquiryDetailParamType } from './type/InquiryDetailParam.type';
import { getClient } from '../../redux/actions/domain/client/client';
import flowImg from './img/flow_img.png';
import { targetAgeMaster } from '../../util/const/Schools/targetAgeMaster';
import { childGenderMaster } from '../../util/const/Schools/childGenderMaster';
import { scheduleDaysMaster } from '../../util/const/Schools/scheduleDaysMaster';

export const InquiriesDetail: React.FC = () => {
    const { inquiryId } = useParams<InquiryDetailParamType>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const inquiryStore: InquiryType = useSelector((state: RootState) => state.rootReducer.domain.inquiry.inquiryDetail);
    const MyPageState = useSelector((state: RootState) => state.rootReducer.domain.myPage);
    const dispatch = useDispatch();
    const [selected, setselected] = useState('');

    useEffect(() => {
        // 一旦リセット
        dispatch(getClient(authToken));
        dispatch(setInquiryDetail(null));
        dispatch(getInquiryDetail(authToken, Number(inquiryId)));
    }, [authToken, dispatch, inquiryId]);

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setselected(e.toString);
    };

    const inquiryData = inquiryStore.detail;
    const school = MyPageState.myPageData.schools.find((v) => v.id === inquiryData?.schoolId);

    if (inquiryData !== null && school) {
        return (
            <>
                <Header />
                <OwnerApplication />
                <TabBar currentPage="/inquiry" />
                <div className={`${styles.inquiryContainer} ${styles.inquiryDetail}`}>
                    <BreadCrumb
                        data={[
                            { text: 'マイページ', path: '/mypage' },
                            { text: 'お問い合わせ一覧', path: '/inquiry' },
                        ]}
                    />
                    <h2>お問い合わせ詳細</h2>
                    <div className={styles.inquiryDetail__head}>
                        {school.imageUrls[0] && <img src={school.imageUrls[0]} alt="スクール画像" className={styles.inquiryDetail__mainImg} />}
                        <div
                            className={
                                school.imageUrls[0]
                                    ? styles.inquiryDetail__textWrap
                                    : `${styles.inquiryDetail__textWrap} ${styles['inquiryDetail__textWrap--noImg']}`
                            }>
                            <div className={styles.inquiryDetail__title}>{school.name}</div>
                            <div className={styles.inquiryDetail__place}>
                                【{school.prefecture?.nameJp}】{school.town?.nameJp} | {school.schoolCategory?.text}
                            </div>
                        </div>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <th>日付</th>
                                <td>
                                    {new Date(inquiryData.createdAt).getFullYear()}/{new Date(inquiryData.createdAt).getMonth()}/
                                    {new Date(inquiryData.createdAt).getDate()}
                                </td>
                            </tr>
                            <tr>
                                <th>内容</th>
                                <td>
                                    {inquiryData.type === 1 && '資料請求'}
                                    {inquiryData.type === 2 && '見学、体験希望'}
                                    {inquiryData.type === 3 && '申し込み'}
                                    {inquiryData.type === 99 && 'その他問合せ'}
                                </td>
                            </tr>
                            {inquiryData.dateRequest.length !== 0 && (
                                <tr>
                                    <th>見学体験希望日</th>
                                    <td>
                                        第１希望：{scheduleDaysMaster.find((d) => d.value === inquiryData.dateRequest[0].day.toString())?.label}&nbsp;
                                        {inquiryData.dateRequest[0].from}-{inquiryData.dateRequest[0].to}
                                        <br />
                                        {inquiryData.dateRequest[1] && (
                                            <>
                                                第２希望：
                                                {scheduleDaysMaster.find((d) => d.value === inquiryData.dateRequest[1].day.toString())?.label}&nbsp;
                                                {inquiryData.dateRequest[1].from}-{inquiryData.dateRequest[1].to}
                                                <br />
                                            </>
                                        )}
                                        {inquiryData.dateRequest[2] && (
                                            <>
                                                第３希望：
                                                {scheduleDaysMaster.find((d) => d.value === inquiryData.dateRequest[2].day.toString())?.label}&nbsp;
                                                {inquiryData.dateRequest[2].from}-{inquiryData.dateRequest[2].to}
                                                <br />
                                            </>
                                        )}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th>質問等</th>
                                <td>{inquiryData.freeWord}</td>
                            </tr>
                            <tr>
                                <th>生徒情報</th>
                                <td>
                                    {inquiryData.childName}
                                    <br />
                                    {targetAgeMaster.find((d) => d.value === inquiryData.childTargetAgeId.toString())?.label}
                                    <br />
                                    性別：{childGenderMaster.find((d) => d.value === inquiryData.childGender.toString())?.label}
                                </td>
                            </tr>
                            <tr>
                                <th>申込者情報</th>
                                <td>
                                    {inquiryData.applicantName}
                                    <br />
                                    {inquiryData.applicantRelation === 1 && '父'}
                                    {inquiryData.applicantRelation === 2 && '母'}
                                    {inquiryData.applicantRelation === 99 && 'その他'}
                                </td>
                            </tr>
                            <tr>
                                <th>電話番号</th>
                                <td>{inquiryData.phone}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={styles.inquiryDetail__replay}>
                        <div className={styles.inquiryDetail__replayTitle}>お問い合わせ返信先</div>
                        <div className={styles.inquiryDetail__replayContent}>
                            <div className={styles.inquiryDetail__replayEmail}>{inquiryData.email}</div>
                            <div className={styles.inquiryDetail__replayName}>{inquiryData.applicantName} 様</div>
                        </div>
                        <p>
                            返信先メールアドレスに、回答をご返信ください。
                            <br />
                            また、返信の際には下記のテンプレートも参考にできます。
                        </p>
                    </div>
                    <h2>テンプレート</h2>
                    <h3>テンプレートを選択</h3>
                    <select className={styles.sort__selectBoxWrap} value={selected} onChange={handleChange}>
                        <option className={styles.sort__defaultOption} value="">
                            見学・体験をしたい
                        </option>
                        <option className={styles.sort__defaultOption} value="">
                            教室・スクール名を選択
                        </option>
                        <option className={styles.sort__defaultOption} value="">
                            教室・スクール名を選択
                        </option>
                    </select>
                    <p className={styles.inquiryDetail__info}>
                        テンプレートの内容を選択、編集し、コピーペーストでお使いのメールに貼り付けご使用ください。
                    </p>
                    <h3>メールタイトル</h3>
                    <div className={styles.inquiryDetail__boxWithBorder}>
                        【〇〇スクール】【oriori習い事】からのお問い合わせありがとうございます。
                    </div>

                    <h3>本文</h3>
                    <div className={styles.inquiryDetail__boxWithBorder}>
                        この度は「〇〇スクール」にお問い合わせをいただき、誠にありがとうございます。
                        <br />
                        株式会社○○・担当の××と申します。
                        <br />
                        <br />
                        ご体験、ご見学の希望、誠にありがとうございます。
                        <br />
                        ご希望いただきました○月○日○時に、体験レッスン、ご見学のご予約を承りました。
                        <br />
                        <br />
                        【体験レッスンご案内】
                        <br />
                        ・場所
                        <br />
                        「〇〇スクール&nbsp;代々木校&nbsp;Bスタジオ」
                        <br />
                        住所：XXXXXX XXXXXXXX
                        <br />
                        「oriori習い事」に最寄駅等が記載されております。ぜひ事前にご確認ください。
                        <br />
                        <br />
                        ・服装
                        <br />
                        特に規定はございません。
                        <br />
                        動き易い服装がオススメです。
                        <br />
                        <br />
                        ・所要時間
                        <br />
                        当日は約30分間のレッスンとスクール説明を合わせて、1時間程度の内容になります。
                        <br />
                        <br />
                        ・当日の連絡先
                        <br />
                        03-1234-5678 担当：〇〇
                        <br />
                        <br />
                        ご不明な点がございましたら、こちらのメールにお気軽にご返信ください。
                        <br />
                        <br />
                        それでは当日、〇〇様にお楽しみ頂けるよう、素敵なレッスンを準備してお待ちしております。
                        <br />
                        どうぞお気をつけてご来校くださいませ。
                        <br />
                        <br />
                        <br />
                        ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
                        <br />
                        〇〇スクール
                        <br />
                        佐藤&nbsp;花子
                        <br />
                        <br />
                        住所：東京都○○区○○
                        <br />
                        コーポレートサイトURL：BBB
                        <br />
                        Tel：03-xxxx-xxxx&nbsp;FAX：03-xxxx-xxxx
                        <br />
                        e-mail：xxx.xxx@xxx.xx.xx
                        <br />
                        ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
                    </div>

                    <h3>お問い合わせ返信の流れ</h3>
                    <div className={styles.inquiryDetail__flowImgWrap}>
                        <img className={styles.inquiryDetail__flowImg} src={flowImg} alt="お問い合わせ返信の流れ" />
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/schools" />
            <div className={styles.schoolContainer}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室名', path: '' },
                    ]}
                />
            </div>
            <Footer />
        </>
    );
};
