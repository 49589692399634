import React, { useState } from 'react';
import styles from './css/style.module.scss';
import dot from './img/dot.svg';

type SchoolCardControlListType = {
    inputList: Array<{
        id: string,
        label: string,
        returnFunc: () => void,
    }>,
}

export const SchoolCardControlList: React.FC<SchoolCardControlListType> = (props) => {
    const { inputList } = props;
    const [display, setDisplay] = useState(false);

    return <div className={styles.wrap}>
        <button
            type="button"
            className={styles.menuBtn}
            onClick={() => setDisplay(true)}
        >
            <img src={dot} alt="メニューボタン"/>
        </button>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button type='button' className={`${styles.forButtonBg} ${display ? styles.active : ''}`} onClick={() => setDisplay(false)}/>
        <ul className={`${styles.list} ${display ? styles.active : ''}`}>
            {inputList.map(d => <li key={d.id}>
                <button
                    type='button'
                    onClick={() => {
                        d.returnFunc()
                        setDisplay(false)
                    }}
                >{d.label}</button>
            </li>)}
        </ul>
    </div>;
};
