import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/styles.module.scss';
import { RadioInput } from '../../../Input/RadioInput';
import { setCommonModalShow } from '../../../../../redux/actions/ui';
import { RootState } from '../../../../../redux/store';
import { ClientUserTypeEnum } from '../../../../../redux/reducers/domain/user/enum/ClientUserType.enum';
import { INQUIRY_PRICE } from '../../../../../util/const/inquiryPrice';
import { setBudgetLimit } from '../../../../../redux/actions/domain/payment/schoolSubscription';


type BudgetSettingProps = {
    modalNumber: any;
    setModalNumber: any;
};

export const BudgetSetting: React.FC<BudgetSettingProps> = (props: BudgetSettingProps) => {
    const { modalNumber, setModalNumber } = props;

    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const clientDataSelector = useSelector((state: RootState) => state.rootReducer.domain.client.clientData);
    const clientUserSelector = useSelector((state: RootState) => state.rootReducer.domain.user);
    const dispatch = useDispatch();

    // 設定有無
    const [usingLimit, setUsingLimit] = useState(true);

    // 設定件数
    const [limitInputValue, setLimitInputValue] = useState(1);

    // 自分のユーザー情報を取得
    const { uuid } = JSON.parse(atob(authToken.split('.')[1]));
    const myUserData = clientUserSelector.clientUsers.users.find(d => d.uuid === uuid);

    useEffect(() => {
        if(usingLimit) {
            dispatch(setBudgetLimit(Number(INQUIRY_PRICE * limitInputValue)));
        } else {
            dispatch(setBudgetLimit(null));
        }
    },[usingLimit, limitInputValue, dispatch]);
    
    
    // 暫定、管理者でない人かつクレカ情報登録なしの場合はfalse
    if (clientDataSelector && myUserData) {
        if (!clientDataSelector.paymentMethodId && myUserData.type === ClientUserTypeEnum.User) {
            return (
                <>
                    <div className={styles.title}>お支払方法が登録されていません</div>
                    <div className={styles.borderWrap}>
                        <div className={styles.text}>お支払方法の登録がないため、有料掲載の開始ができません。<br />
                            お支払い情報を登録してください。<br />
                            <br />
                            お支払い情報の登録は、代表者または、管理者が行うことができます。
                        </div>
                    </div>
                    <button type='button' className={`${styles.greenBtn} ${styles['greenBtn--notAdmin']}`}
                        onClick={() => {
                            dispatch(setCommonModalShow(false));
                        }}>
                        このウィンドウを閉じる
                    </button>
                </>);
        }
    }

    return (
        <>
            <div className={styles.inner}>
                <RadioInput
                    label='問い合わせ回数制限'
                    require
                    name='usingLimitRadio'
                    data={[
                        { id: 'do', label: 'する', value: 1 },
                        { id: 'donot', label: 'しない', value: 2 }
                    ]}
                    changeReturnFunc={(text) => setUsingLimit(text === '1')}
                    value={usingLimit ? '1' : '2'}
                />
                <div className={`${styles.upperLimit} ${!usingLimit ? styles.disable : ''}`}>
                    <div className={styles.upperLimit__input}>
                        <div className={`${styles.label} ${styles.require}`}>一か月間の問い合わせ上限数</div>
                        <div className={styles.inputWrap}>
                            <button
                                className={`${styles.countBtn} ${limitInputValue === 1 ? styles.disable : ''}`}
                                type='button'
                                onClick={() => setLimitInputValue(limitInputValue - 1)}>
                                -
                            </button>
                            <input
                                className={styles.input}
                                type='text'
                                name='limitInputValueInput'
                                id='limitInputValueInput'
                                value={limitInputValue}
                                onChange={(e) => {
                                    const val = Number(e.target.value)
                                    setLimitInputValue(val || 1);
                                }}
                                placeholder='1'
                            />
                            <button className={styles.countBtn} type='button'
                                onClick={() => setLimitInputValue(limitInputValue + 1)}>+
                            </button>
                        </div>
                        <div className={styles.note}>※上限を設定することで、予算額が設定されます</div>

                    </div>
                    <div className={styles.upperLimit__explanation}>
                        <div className={styles.upperLimit__explanationTitle}>一か月間の上限予算目安</div>
                        <div
                            className={styles.upperLimit__explanationText}>￥{Number(INQUIRY_PRICE * limitInputValue).toLocaleString()}</div>
                        <div className={styles.upperLimit__explanationNote}>(問い合わせ1件¥3,300(税込) x {limitInputValue}件)</div>
                    </div>
                </div>
            </div>
            <button type='button' className={styles.greenBtn} onClick={() => setModalNumber(modalNumber + 1)}>
                次へ
            </button>
            <button type='button' className={styles.cancel} onClick={() => setModalNumber(modalNumber - 1)}>
                ひとつ前に戻る
            </button>
        </>
    );
};