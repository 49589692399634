import { SignInInputType } from '../type/signInInput.type';

export const signInInputInitial: SignInInputType = {
    email: '',
    password: '',
    emailValidate: false,
    passwordValidate: false,
    result: {
        flag: false,
        reason: '',
    },
};
