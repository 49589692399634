import { SchoolsActionTypes } from '../../../actions/domain/schools';
import { SchoolDetailCouponType } from './type/Coupon/SchoolDetailCoupon.type';
import { schoolDetailCouponInitial } from './InitialState/Coupon/schoolDetailCouponInitial';


export const SchoolDetailCoupon = (state: SchoolDetailCouponType = schoolDetailCouponInitial, action: any) => {
    switch (action.type) {
        case SchoolsActionTypes.SET_SCHOOL_COUPON_LIST:
            return {
                ...state,
                list: action.payload.schoolCouponListData
            };
        case SchoolsActionTypes.SET_SCHOOL_COUPON_LIST_COUNT:
            return {
                ...state,
                listCount: action.payload.schoolCouponListCount
            };

        case SchoolsActionTypes.SET_SCHOOL_COUPON_DETAIL:
            return {
                ...state,
                detail: action.payload.schoolCouponDetailData
            };

        case SchoolsActionTypes.SET_SCHOOL_NEW_COUPON_INPUT:
            return {
                ...state,
                new: action.payload.input
            };

        case SchoolsActionTypes.SET_SCHOOL_NEW_COUPON_VALIDATE:
            return {
                ...state,
                newValidate: action.payload.validate
            };
        case SchoolsActionTypes.SET_SCHOOL_COUPON_EDIT_FLAG:
            return {
                ...state,
                editFlag: action.payload.flag
            };
        case SchoolsActionTypes.SET_SCHOOL_COUPON_EDIT_DATA:
            return {
                ...state,
                edit: action.payload.editData
            };

        case SchoolsActionTypes.SET_SCHOOL_COUPON_EDIT_VALIDATE:
            return {
                ...state,
                editValidate: action.payload.validate
            };


        default:
            return state;
    }
};