import { PasswordReissueOfferType } from './type/passwordReissueOffer.type';
import { passwordReissueOfferInitial } from './InitialState/passwordReissueOfferInitial';
import { ClientActionTypes } from '../../../actions/domain/client';

export const passwordReissueOffer = (state = passwordReissueOfferInitial, action: any): PasswordReissueOfferType => {
    switch (action.type) {
        // input
        case ClientActionTypes.SET_PASSWORD_REISSUE_OFFER_EMAIL:
            return {
                ...state,
                email: action.payload.email,
            };
        // inputValidate
        case ClientActionTypes.SET_PASSWORD_REISSUE_OFFER_EMAIL_VALIDATE:
            return {
                ...state,
                emailValidate: action.payload.flag,
            };
        // result
        case ClientActionTypes.SET_PASSWORD_REISSUE_OFFER_RESULT:
            return {
                ...state,
                result: action.payload.result,
            };
        case ClientActionTypes.RESET_PASSWORD_REISSUE_OFFER:
            return {
                ...state,
                result: action.payload.initial,
            };
        default:
            return state;
    }
};
