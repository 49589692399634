import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './css/styles.module.scss';
import { setCommonModalShow } from '../../../../../redux/actions/ui';
import { initPaymentStore } from '../../../../../redux/actions/domain/payment/schoolSubscription';
import { getSchoolDetail } from '../../../../../redux/actions/domain/schools/schoolDetail';
import { RootState } from '../../../../../redux/store';
import { getMySchools } from '../../../../../redux/actions/domain/mypage/schools';
import { SCHOOL_PAGE_COUNT } from '../../../../../util/const/schoolPageCount';

export const Complete: React.FC = () => {
    const authToken = useSelector((store: RootState) => store.rootReducer.app.authToken);
    const schoolSubscriptionSelector = useSelector((store: RootState) => store.rootReducer.domain.payment.schoolSubscription);

    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <>
            <div className={styles.title}>有料掲載が開始されました</div>
            <div className={styles.borderWrap}>
                <div className={styles.text}>
                    ありがとうございます。
                    <br />
                    正常に決済がされ、有料掲載が開始されました。
                    <br />
                    <br />
                    ・当月の（N）日から有料プラン開始した場合、翌月の（N-1）日まで有料プランが適用されます。（例：９月３日に有料化をした場合、１０月２日まで有料プランが適用される）
                    <br />
                    ・有料プラン適用期間中に有料プランを解約した場合、問い合わせボタンは即時外れます。
                    <br />
                    ・有料プラン適用期間中に解約し、その後再度有料化した場合には、すでに購入されている有料プラン適用期間中であればシステム利用料（3,300円/月）は重複課金されません。ただし、有料期間終了タイミングは初回購入日を元に算出いたします。
                    <br />
                    ・解約希望の場合は、更新日の前日までにステータスを「無料掲載」に変更をする手続きが必要です。
                    <br />
                </div>
            </div>
            <button
                type="button"
                className={styles.greenBtn}
                onClick={() => {
                    // スクール情報の更新
                    if (history.location.pathname.match(/\/school\/\d/) && schoolSubscriptionSelector.paymentTargetSchool) {
                        dispatch(getSchoolDetail(authToken, schoolSubscriptionSelector.paymentTargetSchool.id));
                        // payment関係初期化 モーダルステップ、paymentIntent取得、スクールId設定、新規カード登録情報を初期化
                        dispatch(initPaymentStore());
                    } else {
                        // payment関係初期化 モーダルステップ、paymentIntent取得、スクールId設定、新規カード登録情報を初期化
                        dispatch(initPaymentStore());
                        // スクール詳細以外での購入 暫定的にスクール一覧ページに遷移させる
                        dispatch(getMySchools(authToken, SCHOOL_PAGE_COUNT, 0));
                        history.push('/school');
                    }
                    dispatch(setCommonModalShow(false));
                }}>
                このウィンドウを閉じる
            </button>
        </>
    );
};
