import React from 'react';
import styles from './css/style.module.scss';
import { useRadioInput } from './hooks/useRadioInput';

type RadioInputProps = {
    label: string;
    require: boolean;
    name: string;
    data: Array<{
        id: string;
        value: number;
        label: string;
    }>;
    changeReturnFunc: (text: string, validate: boolean) => void;
    value: string | null;
};

export const RadioInput: React.FC<RadioInputProps> = (props: RadioInputProps) => {
    const { label, require, name, data, changeReturnFunc, value } = props;

    const [inputData, callBack] = useRadioInput(value, require);
    return (
        <>
            <div className={`${styles.label} ${require ? styles.require : ''}`}>{label}</div>
            <div className={styles.wrap}>
                {data.map((d) => (
                    <div key={d.id} className={styles.row}>
                        <input
                            type="radio"
                            value={d.value}
                            id={d.id}
                            name={name}
                            className={styles.radioInput}
                            defaultChecked={inputData.input === d.value.toString()}
                            onClick={() => {
                                const result = callBack.setInputCallBack(d.value.toString());
                                changeReturnFunc(result.data, result.validate);
                            }}
                        />
                        <label htmlFor={d.id} className={styles.inputLabel}>
                            {d.label}
                        </label>
                    </div>
                ))}
            </div>
        </>
    );
};
