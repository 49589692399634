import { AxiosResponse } from 'axios';
import { axiosBase } from './index';
import { SchoolDetailData } from '../../redux/reducers/domain/schools/type/SchoolDetailData.type';
import { SchoolInquiryDataType } from '../type/SchoolInquiryData.type';

export class MyPageApi {
    // スクール一覧取得
    static async getClientSchools(token: string, limit: number, page: number): Promise<AxiosResponse<{
        data: Array<SchoolDetailData>,
        count: number
    }>> {
        return axiosBase.get('/school/admin/schools',
            {
                params: {
                    limit,
                    page
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // お問い合わせ一覧取得
    static async getClientInquiries(token: string, limit: number, page: number): Promise<AxiosResponse<{
        data: Array<SchoolInquiryDataType>,
        count: number
    }>> {
        return axiosBase.get('/school/admin/inquiry',
            {
                params: {
                    limit,
                    page
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

}
