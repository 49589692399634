import { EditClientDataType } from './type/EditClientData.type';
import { editClientDataInitial } from './InitialState/editClientDataInitial';
import { SettingsActions } from '../../../actions/domain/settings';

export const EditClient = (state: EditClientDataType = editClientDataInitial, action: any) => {
    switch (action.type) {

        case SettingsActions.SET_CLIENT_EDIT_INPUT:
            return {
                ...state,
                editInput: action.payload.editInput
            }
        case SettingsActions.SET_CLIENT_EDIT_VALIDATE:
            return {
                ...state,
                editValidate: action.payload.editValidate
            }
        case SettingsActions.SET_CLIENT_EDIT_FLAG:
            return {
                ...state,
                editFlag: action.payload.editFlag
            }
        default:
            return state;
    }
};