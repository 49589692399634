import React, { useState } from 'react';
import { SelectInput } from '../../Common/Input/SelectInput';
import { schoolCourseTimeMaster } from '../../../util/const/Schools/schoolCourseTimeMaster';
import styles from './css/style.module.scss';
import { TextArea } from '../../Common/Input/TextArea';
import { InputValidateTypeEnum } from '../../Common/Input/TextInput/InputValidateType.enum';

type CourseTimeInputProps = {
    defaultValue: { from: number | null; to: number | null; timeRemarks: string };
    returnFunc: (from: number | null, to: number | null) => void;
    returnFuncRemarks: (timeRemarks: string, timeRemarksValidate: boolean) => void;
};

export const CourseTimeInput: React.FC<CourseTimeInputProps> = (props: CourseTimeInputProps) => {
    const { defaultValue, returnFunc, returnFuncRemarks } = props;

    const [fromSelectVal, setFromSelectVal] = useState<number | null>(defaultValue.from);
    const [toSelectVal, setToSelectVal] = useState<number | null>(defaultValue.to);

    return (
        <div className={styles.wrap}>
            <div className={styles.inputRow}>
                <div className={styles.inputColumn}>
                    <div className={styles.inputColumnTitle}>１回あたりの受講時間：</div>
                    <div className={styles.input}>
                        <SelectInput
                            label=""
                            require={false}
                            name="schoolCourseTimeFrom"
                            id="schoolCourseTimeFrom"
                            data={schoolCourseTimeMaster}
                            changeReturnFunc={(text) => {
                                setFromSelectVal(text ? Number(text) : null);
                                returnFunc(text ? Number(text) : null, toSelectVal);
                            }}
                            defaultInput={fromSelectVal ? fromSelectVal.toString() : null}
                            disableSelectText="下限"
                            disable={false}
                            selectStartIndex={null}
                            selectEndIndex={toSelectVal ? schoolCourseTimeMaster.findIndex((d) => d.value === toSelectVal.toString()) - 1 : null}
                        />
                    </div>
                    <div className={styles.inputCenter}>ー</div>
                    <div className={styles.input}>
                        <SelectInput
                            label=""
                            require={false}
                            name="schoolCourseTimeTo"
                            id="schoolCourseTimeTo"
                            data={schoolCourseTimeMaster}
                            changeReturnFunc={(text) => {
                                setToSelectVal(text ? Number(text) : null);
                                returnFunc(fromSelectVal, text ? Number(text) : null);
                            }}
                            defaultInput={toSelectVal ? toSelectVal.toString() : null}
                            disableSelectText="上限"
                            disable={false}
                            selectStartIndex={
                                fromSelectVal ? schoolCourseTimeMaster.findIndex((d) => d.value === fromSelectVal.toString()) - 1 : null
                            }
                            selectEndIndex={null}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.inputColumnTextArea}>
                    <div className={styles.inputColumnTextArea__title}>補足：</div>
                    <div className={styles.inputColumnTextArea__input}>
                        <TextArea
                            label=""
                            name="nearestStationText"
                            id="nearestStationText"
                            require={false}
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text: string, validate: boolean) => {
                                returnFuncRemarks(text, validate);
                            }}
                            defaultInput={defaultValue.timeRemarks}
                            placeHolder="長時間レッスンをご希望の場合にはご相談を承っております。詳細は教室までお問い合わせください。"
                            forReview={false}
                            forInquiry={false}
                            countFlag={false}
                            maxLength={500}
                            minLength={null}
                            height={92}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
