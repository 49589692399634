import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/ja';
import moment from 'moment';
import styles from './css/style.module.scss';
import { SchoolDetailData } from '../../../redux/reducers/domain/schools/type/SchoolDetailData.type';
import { RootState } from '../../../redux/store';
import { patchSchoolPublish, patchSchoolUnPublish } from '../../../redux/actions/domain/schools/schoolIndexEdit';
import { BillingStatus } from '../../../util/enum/BillingStatus.enum';
import {
    initPaymentStore,
    postCancelSubscription,
    postPaymentSetup,
    setPaymentTargetSchool,
} from '../../../redux/actions/domain/payment/schoolSubscription';
import { getClientUser } from '../../../redux/actions/domain/user/clientUser';
import { getClient } from '../../../redux/actions/domain/client/client';
import { setCommonModalContentType, setCommonModalShow, setCommonModalTitle } from '../../../redux/actions/ui';
import { CommonModalContentTypeEnum } from '../../../redux/reducers/ui/enum/CommonModalContentType.enum';
import { SchoolStatusEnum } from '../SchoolHeader';

type SchoolListItemProps = {
    data: SchoolDetailData;
    even: boolean;
    listChecked: boolean;
    listCheckReturnFunk: (id: number, data: SchoolDetailData) => void;
    page: number;
    count: number;
};

export const SchoolListItem: React.FC<SchoolListItemProps> = (props: SchoolListItemProps) => {
    const { data, even, listChecked, listCheckReturnFunk, page, count } = props;
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const dispatch = useDispatch();

    // 表示中かどうかを判定し、その後支払い中かどうかを判定
    const [schoolStatus, setSchoolStatus] = useState<SchoolStatusEnum>(
        data.showStatus
            ? data.billingStatus === BillingStatus.PaidBilling
                ? SchoolStatusEnum.PAYMENT_ACTIVE
                : SchoolStatusEnum.FREE_ACTIVE
            : data.billingStatus === BillingStatus.PaidBilling
                ? SchoolStatusEnum.PAYMENT_INACTIVE
                : SchoolStatusEnum.INACTIVE
    );

    const handleChangeInFree = (e: ChangeEvent<HTMLSelectElement>) => {
        const val: SchoolStatusEnum = Number(e.target.value);
        if (val === SchoolStatusEnum.FREE_ACTIVE) dispatch(patchSchoolPublish(authToken, data.id.toString(), true, page, count));
        if (val === SchoolStatusEnum.INACTIVE) dispatch(patchSchoolUnPublish(authToken, data.id.toString(), true, page, count));
    };

    const handleChangeInPayment = (e: ChangeEvent<HTMLSelectElement>) => {
        const val: SchoolStatusEnum = Number(e.target.value);
        if (val === SchoolStatusEnum.PAYMENT_ACTIVE) {
            dispatch(patchSchoolPublish(authToken, data.id.toString(), true, page, count));
        }
        if (val === SchoolStatusEnum.PAYMENT_INACTIVE) {
            dispatch(patchSchoolUnPublish(authToken, data.id.toString(), true, page, count));
        }
        if (val === SchoolStatusEnum.FREE_ACTIVE) {
            if (
                // eslint-disable-next-line no-alert
                window.confirm(
                    '有料契約をキャンセルします。よろしいでしょうか？(契約締日以前までに再度契約いただければ、再度請求されることはありません。)'
                )
            ) {
                dispatch(postCancelSubscription(authToken, data.id.toString()));
                dispatch(patchSchoolPublish(authToken, data.id.toString(), true, page, count));
            }
        }
        if (val === SchoolStatusEnum.INACTIVE) {
            if (
                // eslint-disable-next-line no-alert
                window.confirm(
                    '有料契約をキャンセルします。よろしいでしょうか？(契約締日以前までに再度契約いただければ、再度請求されることはありません。)'
                )
            ) {
                dispatch(postCancelSubscription(authToken, data.id.toString()));
                dispatch(patchSchoolUnPublish(authToken, data.id.toString(), true, page, count));
            }
        }
    };

    useEffect(() => {
        setSchoolStatus(
            data.showStatus
                ? data.billingStatus === BillingStatus.PaidBilling
                    ? SchoolStatusEnum.PAYMENT_ACTIVE
                    : SchoolStatusEnum.FREE_ACTIVE
                : data.billingStatus === BillingStatus.PaidBilling
                    ? SchoolStatusEnum.PAYMENT_INACTIVE
                    : SchoolStatusEnum.INACTIVE
        );
    }, [data]);
    return (
        <>
            <tr className={even ? styles.even : ''}>
                <td className={styles.name}>
                    <input
                        className={styles.schoolListCheckBox}
                        type="checkbox"
                        id={`target_${data.id}`}
                        checked={listChecked}
                        onChange={() => listCheckReturnFunk(data.id, data)}
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className={styles.schoolListLabel} htmlFor={`target_${data.id}`}>
                        <Link to={`/school/${data.id}`}>{data.name}</Link>
                    </label>
                </td>
                <td className={styles.place}>
                    {data.prefecture?.nameJp}
                    {data.town?.nameJp}
                    {data.street?.nameJp}
                    {data.addressDetail}
                </td>
                <td className={styles.update}>{moment(data.updatedAt).format('YYYY/MM/DD')}</td>
                <td className={styles.state}>
                    <div
                        className={
                            data.showStatus ? `${styles.state__selectBoxWrap} ${styles['state__selectBoxWrap--active']}` : styles.state__selectBoxWrap
                        }>
                        {data.billingStatus === BillingStatus.PaidBilling ? (
                            <select className={styles.state__selectBox} value={schoolStatus} onChange={handleChangeInPayment}>
                                <option className={styles.state__active} value={SchoolStatusEnum.PAYMENT_ACTIVE}>
                                    有料:掲載中
                                </option>
                                {/* <option className={styles.state__deactivate} value={SchoolStatusEnum.PAYMENT_INACTIVE}>*/}
                                {/*    有料:掲載停止*/}
                                {/* </option>*/}
                                <option className={styles.state__active} value={SchoolStatusEnum.FREE_ACTIVE}>
                                    無料:掲載中
                                </option>
                                <option className={styles.state__deactivate} value={SchoolStatusEnum.INACTIVE}>
                                    無料:掲載停止
                                </option>
                            </select>
                        ) : (
                            <select className={styles.state__selectBox} value={schoolStatus} onChange={handleChangeInFree}>
                                <option className={styles.state__active} value={SchoolStatusEnum.FREE_ACTIVE}>
                                    掲載中
                                </option>
                                <option className={styles.state__deactivate} value={SchoolStatusEnum.INACTIVE}>
                                    掲載停止
                                </option>
                            </select>
                        )}
                    </div>
                </td>
                {/* 暫定 */}
                {data.billingStatus === BillingStatus.PaidBilling ? (
                    <>
                        <td className={styles.numberOfInquiry}>{data.sumPrice ? `${data.sumPrice / 3300}` : '0'}件</td>
                        <td className={styles.cost}>¥{data.sumPrice ? `${data.sumPrice.toLocaleString()}` : '0'}/月</td>
                        <td className={styles.budget}>{data.budgetLimit ? `¥${data.budgetLimit.toLocaleString()}/月` : 'なし'}</td>
                    </>
                ) : (
                    <td className={styles.paymentLink} colSpan={3}>
                        お問い合わせを増やしたい方は
                        <button
                            type="button"
                            onClick={() => {
                                // payment関係初期化 モーダルステップ、paymentIntent取得、スクールId設定、新規カード登録情報を初期化
                                dispatch(initPaymentStore());
                                dispatch(postPaymentSetup(authToken));
                                dispatch(setPaymentTargetSchool(data));
                                // ユーザーデータを更新
                                dispatch(getClientUser(authToken));
                                // クライアントデータを更新
                                dispatch(getClient(authToken));

                                dispatch(setCommonModalShow(true));
                                dispatch(setCommonModalTitle('有料掲載の開始'));
                                dispatch(setCommonModalContentType(CommonModalContentTypeEnum.START_PAID_PLAN));
                            }}>
                            こちら
                        </button>
                    </td>
                )}
            </tr>
        </>
    );
};
