import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getClient } from '../../redux/actions/domain/client/client';
import styles from '../../asset/css/myPageCommon.module.scss';
import myPageStyles from './css/styles.module.scss';
import { Header } from '../../components/Common/Header';
import { Footer } from '../../components/Common/Footer';
import { OwnerApplication } from '../../components/MyPage/OwnerApplication';
import { TabBar } from '../../components/MyPage/TabBar';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import { ZeroSchool } from '../../components/MyPage/ZeroSchool';
import { ZeroInquiry } from '../../components/MyPage/ZeroInquiry';
import { InquiryCard } from '../../components/Common/InquiryCard';
import { SchoolList } from '../../components/Schools/SchoolList';
import { getMySchools } from '../../redux/actions/domain/mypage/schools';
import { ShowMoreBtn } from '../../components/Common/ShowMoreBtn';
import { getMyInquiries } from '../../redux/actions/domain/mypage/inquiry';
import { setNewSchoolInput, setNewSchoolInputValidate } from '../../redux/actions/domain/schools/newSchool';
import { newSchoolInputDataInitial } from '../../redux/reducers/domain/schools/InitialState/New/newSchoolInputDataInitial';
import { newSchoolInputValidateInitial } from '../../redux/reducers/domain/schools/InitialState/New/newSchoolInputValidateInitial';
import flow from './img/flow.png';

export const MyPage: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const MyPageState = useSelector((state: RootState) => state.rootReducer.domain.myPage);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // client情報の取得
        dispatch(getClient(authToken));
        // マイスクール情報取得 10件取得
        dispatch(getMySchools(authToken, 10, 0));
        // お問い合わせ情報取得 10件取得
        dispatch(getMyInquiries(authToken, 10, 0));
    }, [authToken, dispatch, history]);

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/mypage" />
            <div className={styles.myPageContainer}>
                <BreadCrumb data={[{ text: 'マイページ', path: '/mypage' }]} />
                {MyPageState.myPageData.schools.length === 0 && (
                    <>
                        <h2>教室({MyPageState.myPageData.schoolsCount})</h2>
                        <ZeroSchool
                            handleClick={() => {
                                dispatch(setNewSchoolInput(newSchoolInputDataInitial));
                                dispatch(setNewSchoolInputValidate(newSchoolInputValidateInitial));
                                history.push('/school/new_1');
                            }}
                        />
                        <div className={myPageStyles.flow}>
                            <div className={myPageStyles.flow__title}>教室掲載から集客までの流れ</div>
                            <img className={myPageStyles.flow__img} src={flow} alt="流れ" />
                        </div>
                    </>
                )}
                {MyPageState.myPageData.schools.length !== 0 && (
                    <>
                        <h2>教室({MyPageState.myPageData.schoolsCount})</h2>
                        <SchoolList schoolData={MyPageState.myPageData.schools} isSchoolListPage={false} page={0} count={10} />
                        <ShowMoreBtn text="スクール一覧" onClick={() => history.push('/school')} />
                    </>
                )}
                <h2>最近のお問い合わせ</h2>
                {MyPageState.myPageData.inquiries.length !== 0 ? (
                    <div>
                        <ul>
                            {MyPageState.myPageData.inquiries.map((d) => (
                                <InquiryCard key={`inquiry_id_${d.id}`} schoolData={MyPageState.myPageData.schools} inquiryData={d} />
                            ))}
                        </ul>
                        <ShowMoreBtn text="お問い合わせ一覧" onClick={() => history.push('/inquiry')} />
                    </div>
                ) : (
                    <ZeroInquiry />
                )}
            </div>
            <Footer />
        </>
    );
};
