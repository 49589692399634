import { signInInputInitial } from './InitialState/signInInputInitial';
import { SignInInputType } from './type/signInInput.type';
import { ClientActionTypes } from '../../../actions/domain/client';

export const signInInput = (state = signInInputInitial, action: any): SignInInputType => {
    switch (action.type) {
        case ClientActionTypes.SET_SIGN_IN_EMAIL:
            return {
                ...state,
                email: action.payload.email,
            };
        case ClientActionTypes.SET_SIGN_IN_PASSWORD:
            return {
                ...state,
                password: action.payload.password,
            };
        case ClientActionTypes.SET_SIGN_IN_EMAIL_VALIDATE:
            return {
                ...state,
                emailValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_SIGN_IN_PASSWORD_VALIDATE:
            return {
                ...state,
                passwordValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_SIGN_IN_RESULT:
            return {
                ...state,
                result: action.payload.result,
            };
        case ClientActionTypes.RESET_SIGN_IN_INPUT:
            return {
                ...state,
                result: action.payload.initial,
            };
        default:
            return state;
    }
};
