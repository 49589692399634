import { useCallback, useState } from 'react';

export const useRadioInput = (
    defaultInput: string | null,
    require: boolean
): [
        { input: string },
        {
            setInputCallBack: (data: string) => { data: string; validate: boolean };
        }
    ] => {
    const [input, setInput] = useState(defaultInput !== null ? defaultInput : '');

    const setInputCallBack = useCallback(
        (data: string) => {
            const validated = !require || !!data;
            setInput(data);

            return {
                data,
                validate: validated,
            };
        },
        [require]
    );

    return [{ input }, { setInputCallBack }];
};
