import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './css/style.module.scss';
import { RootState } from '../../../redux/store';

type GreenBtnProps = {
    text: string;
    style: React.CSSProperties | null;
    handleClick: any;
    isLink: boolean;
    path: string;
};

export const GreenBtn: React.FC<GreenBtnProps> = (props: GreenBtnProps) => {
    const { text, style, handleClick, isLink, path } = props;
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);

    return (
        <>
            {!isLink ?
                <button
                    className={styles.greenBtn}
                    type='button'
                    style={style !== null ? style : {}}
                    onClick={handleClick}>
                    {loadingSelector ? '通信中...' : text}
                </button>
                : <Link className={styles.greenBtn} style={style !== null ? style : {}} to={path}>{loadingSelector ? '通信中...' : text}</Link>
            }

        </>
    );
};