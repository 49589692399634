import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { Header } from '../../../../components/Common/Header';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { RootState } from '../../../../redux/store';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { SchoolDetailBlogType } from '../../../../redux/reducers/domain/schools/type/Blog/SchoolDetailBlog.type';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import {
    getSchoolBlogDetail, patchSchoolBlogPublish, patchSchoolBlogUnPublish, putSchoolBlogEditData,
    setSchoolBlogEditData,
    setSchoolBlogEditFlag, setSchoolBlogEditValidate
} from '../../../../redux/actions/domain/schools/schoolBlog';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import { GreenBtn } from '../../../../components/Common/GreenBtn';
import { WhiteBtn } from '../../../../components/Common/WhiteBtn';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { ImageUploader, ImageUploaderSize } from '../../../../components/SchoolInput/ImageUploader';
import { SchoolDetailBlogInputType } from '../../../../redux/reducers/domain/schools/type/Blog/SchoolDetailBlogInput.type';
import { InputValidateTypeEnum } from '../../../../components/Common/Input/TextInput/InputValidateType.enum';
import { TextArea } from '../../../../components/Common/Input/TextArea';

type SchoolBlogDetailParamTypes = {
    schoolId: string,
    blogId: string,
}

export const SchoolDetailBlogDetail: React.FC = () => {
    const { schoolId, blogId } = useParams<SchoolBlogDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolBlogStore: SchoolDetailBlogType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailBlog);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(getSchoolBlogDetail(authToken, Number(blogId)));
    }, [authToken, dispatch, schoolId, blogId]);

    if (schoolStore.schoolDetailData !== null
        && schoolBlogStore.detail !== null
        && schoolBlogStore.edit !== null
        && schoolBlogStore.editValidate !== null
    ) {
        const blog = schoolBlogStore.detail;
        return (<>
            <Header />
            <OwnerApplication />
            <TabBar currentPage='/school' />
            <div className={styles.schoolContainer}>
                <BreadCrumb data={[{ text: '教室一覧', path: '/school' },{ text: `${schoolStore.schoolDetailData.name}`, path: `/school/${schoolId}/blog/${blogId}` }]}  />
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <SchoolHeader data={schoolStore.schoolDetailData} />
                <SchoolTabBar currentPage={`/school/${schoolId}/blog`} schoolId={schoolId} />
                <Link to={`/school/${schoolId}/blog`}>
                    <a className={styles.schoolDetailBackBtn}>&lt;ブログ一覧に戻る</a>
                </Link>
                <SchoolH3 otherEditPlace={null}
                    text="ブログ詳細"
                    editable={!schoolBlogStore.editFlag}
                    ButtonFunc={() => {
                        if (!schoolBlogStore.editFlag) {
                            dispatch(setSchoolBlogEditFlag(true))
                        } else {
                            dispatch(setSchoolBlogEditFlag(false));
                            dispatch(getSchoolDetail(authToken, Number(schoolId)));
                            dispatch(getSchoolBlogDetail(authToken, Number(blogId)));
                        }
                    }}
                    isShow={schoolBlogStore.detail.showStatus}
                />
                {!schoolBlogStore.editFlag ? (
                    <div className={styles.schoolInfoWrap}>
                        <div className={styles.schoolInfo}>
                            <div className={styles.schoolInfo__title}>メイン画像</div>
                            {blog.imageUrl && (
                                <div className={styles.schoolBlogDetailImgWrapper}>
                                    <img src={blog.imageUrl} alt="schoolImage" />
                                </div>
                            )}
                        </div>
                        <div className={styles.schoolInfo}>
                            <div className={styles.schoolInfo__title}>ブログタイトル</div>
                            <div className={styles.schoolBlogDetailTitle}>{blog.title}</div>
                        </div>
                        <div className={styles.schoolInfo}>
                            <div className={styles.schoolInfo__title}>本文</div>
                            <div className={styles.schoolBlogDetailText}>
                                {blog.text.split(/\n/).map((t, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <p key={`blog_text_${index}`}>{t}</p>
                                ))}</div>
                        </div>
                        <div className={styles.schoolBlogDetailDraft}>下書き：{new Date(blog.updatedAt).getFullYear()}/{new Date(blog.updatedAt).getMonth()}/{new Date(blog.updatedAt).getDate()}</div>
                        <Link to={`/school/${schoolId}/blog`}>
                            <a className={`${styles.schoolDetailBackBtn} ${styles["schoolDetailBackBtn--bottom"]}`}>&lt;ブログ一覧に戻る</a>
                        </Link>
                        <div className={styles.schoolDetailBtnWrap}>
                            <GreenBtn
                                text='公開する'
                                style={{}}
                                handleClick={() => {
                                    dispatch(patchSchoolBlogPublish(authToken, schoolId, blogId, true))
                                    history.push(`/school/${schoolId}/blog`);
                                }}
                                isLink={false}
                                path=''
                            />
                            <WhiteBtn
                                style={null}
                                text="非公開にする"
                                handleClick={()=>{
                                    dispatch(patchSchoolBlogUnPublish(authToken, schoolId, blogId, true))
                                    history.push(`/school/${schoolId}/blog`);
                                }}
                                isLink={false}
                                path="/"
                            />
                        </div>
                    </div>) : (
                    <div className={styles.schoolInfoWrap}>

                        <div className={styles.editInputLabel}>メイン画像</div>
                        <div className={styles.editInputWrap}>
                            <ImageUploader
                                uiSize={ImageUploaderSize.ExBIG}
                                identificationTitle={null}
                                identificationText={null}
                                defaultImageUrl={schoolBlogStore.detail.imageUrl}
                                returnFunc={(file, deleteFlag) => {
                                    dispatch(setSchoolBlogEditData({
                                        ...schoolBlogStore.edit as SchoolDetailBlogInputType,
                                        image: file,
                                        deletingImage: deleteFlag,
                                    }));
                                }}
                            />
                        </div>
                        <div className={styles.editInputLabel}>ブログタイトル</div>
                        <div className={styles.editInputWrap}>
                            <TextArea
                                label=''
                                name='editBlogTitle'
                                id='editBlogTitle'
                                require
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation={null}
                                changeReturnFunc={(text, validate) => {
                                    dispatch(setSchoolBlogEditData({
                                        ...schoolBlogStore.edit as SchoolDetailBlogInputType,
                                        title: text
                                    }));
                                    dispatch(setSchoolBlogEditValidate({
                                        ...schoolBlogStore.editValidate,
                                        title: validate
                                    }));
                                }}
                                defaultInput={schoolBlogStore.edit.title}
                                placeHolder='ブログタイトル（80文字以内）'
                                forReview={false}
                                forInquiry={false}
                                countFlag
                                maxLength={80}
                                minLength={1}
                                height={92}
                            />
                        </div>

                        <div className={styles.editInputLabel}>本文</div>
                        <TextArea
                            label=''
                            name='editBlogText'
                            id='editBlogText'
                            require
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text, validate) => {
                                dispatch(setSchoolBlogEditData({
                                    ...schoolBlogStore.edit as SchoolDetailBlogInputType,
                                    text
                                }));
                                dispatch(setSchoolBlogEditValidate({
                                    ...schoolBlogStore.editValidate,
                                    text: validate
                                }));
                            }}
                            defaultInput={schoolBlogStore.edit.text}
                            placeHolder='本文'
                            forReview={false}
                            forInquiry={false}
                            countFlag
                            maxLength={2000}
                            minLength={1}
                            height={721}
                        />

                        <button
                            type='button'
                            className={`${styles.editInputButton} ${Object.entries(schoolStore.schoolDetailEditValidate).some(d => !d[1]) ? styles['editInputButton--disable'] : ''}`}
                            onClick={() => {
                                if (schoolStore.schoolDetailData) {
                                    dispatch(putSchoolBlogEditData(authToken, blogId.toString(), schoolBlogStore.edit as SchoolDetailBlogInputType,));
                                }
                            }}>
                            {loadingSelector ? '通信中...' : '保存する'}
                        </button>
                    </div>
                )}
            </div>
            <Footer />
        </>);
    }
    return <>
        <Header />
        <OwnerApplication />
        <TabBar currentPage='/school' />
        <div className={styles.schoolContainer}>
            <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, { text: '教室名', path: '' }]} />
        </div>
        <Footer />
    </>;
};
