import { SchoolDetailCourseType } from '../../type/Course/SchoolDetailCourse.type';
import { schoolDetailCourseNewInitial } from './schoolDetailCourseNewInitial';
import { schoolDetailCourseNewValidateInitial } from './schoolDetailCourseNewValidateInitial';
import { schoolDetailCourseEditValidateInitial } from './schoolDetailCourseEditValidateInitial';

export const schoolDetailCourseInitial: SchoolDetailCourseType = {
    list: null,
    detail: null,
    new: schoolDetailCourseNewInitial,
    newValidate: schoolDetailCourseNewValidateInitial,
    editFlag: false,
    edit: null,
    editValidate: schoolDetailCourseEditValidateInitial,
}
