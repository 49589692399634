import { ClientActionTypes } from '../../../actions/domain/client';
import { SignUpRegisterType } from './type/signUpRegister.type';
import { signUpRegisterInitial } from './InitialState/signUpRegisterInitial';

export const signUpRegister = (state = signUpRegisterInitial, action: any): SignUpRegisterType => {
    switch (action.type) {
        case ClientActionTypes.SET_SIGN_UP_REGISTER_RESULT:
            return {
                success: action.payload.result.success,
            };
        default:
            return state;
    }
};
