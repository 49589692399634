import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SchoolDetailParamTypes } from '../type/SchoolDetaillParam.type';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { Header } from '../../../../components/Common/Header';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { SchoolDetailBlogType } from '../../../../redux/reducers/domain/schools/type/Blog/SchoolDetailBlog.type';
import { RootState } from '../../../../redux/store';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import {
    postNewSchoolBlog,
    setSchoolNewBlogInput,
    setSchoolNewBlogValidate
} from '../../../../redux/actions/domain/schools/schoolBlog';
import { schoolDetailBlogInputInitial } from '../../../../redux/reducers/domain/schools/InitialState/Blog/schoolDetailBlogInputInitial';
import { schoolDetailBlogValidateInitial } from '../../../../redux/reducers/domain/schools/InitialState/Blog/schoolDetailBlogValidateInitial';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolPageExplanation } from '../../../../components/Schools/SchoolPageExplanation';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import { ImageUploader, ImageUploaderSize } from '../../../../components/SchoolInput/ImageUploader';
import { TextArea } from '../../../../components/Common/Input/TextArea';
import { InputValidateTypeEnum } from '../../../../components/Common/Input/TextInput/InputValidateType.enum';
import { GreenBtn } from '../../../../components/Common/GreenBtn';

export const SchoolDetailBlogNew:  React.FC = () => {
    const { schoolId } = useParams<SchoolDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolBlogStore: SchoolDetailBlogType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailBlog);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(setSchoolNewBlogInput(schoolDetailBlogInputInitial));
        dispatch(setSchoolNewBlogValidate(schoolDetailBlogValidateInitial));
    }, [authToken, dispatch, schoolId]);


    if (schoolStore.schoolDetailData !== null) {
        return (<>
            <Header />
            <OwnerApplication />
            <TabBar currentPage='/school' />
            <div className={styles.schoolContainer}>
                <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, { text: '教室名', path: '' }]} />
                <SchoolHeader data={schoolStore.schoolDetailData} />
                <SchoolTabBar currentPage={`/school/${schoolId}/blog`} schoolId={schoolId} />
                <SchoolPageExplanation title='コース'
                    textDom={<Link to={`/school/${schoolId}/course`}>&lt;ブログ一覧に戻る</Link>}
                    btnText={null} path={null}/>
                <SchoolH3 editable={false} otherEditPlace={null} ButtonFunc={null} text='新規記事作成' isShow={false}/>
                <div className={styles.schoolInfoWrap}>
                    <div className={styles.editInputWrap}>
                        <div className={styles.editInputLabel}>メイン画像</div>
                        <ImageUploader
                            uiSize={ImageUploaderSize.ExBIG}
                            identificationTitle={null}
                            identificationText={null}
                            defaultImageUrl={null}
                            returnFunc={(file) => {
                                dispatch(setSchoolNewBlogInput({
                                    ...schoolBlogStore.new,
                                    image: file
                                }));
                            }}
                        />
                    </div>

                    <div className={styles.editInputLabel}>ブログタイトル</div>
                    <div className={styles.editInputWrap}>
                        <TextArea
                            label=''
                            name='newBlogTitle'
                            id='newBlogTitle'
                            require
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text, validate) => {
                                dispatch(setSchoolNewBlogInput({
                                    ...schoolBlogStore.new,
                                    title: text
                                }));
                                dispatch(setSchoolNewBlogValidate({
                                    ...schoolBlogStore.newValidate,
                                    title: validate
                                }));
                            }}
                            defaultInput={schoolBlogStore.new.title}
                            placeHolder='ブログタイトル（80文字以内）'
                            forReview={false}
                            forInquiry={false}
                            countFlag
                            maxLength={80}
                            minLength={1}
                            height={92}
                        />
                    </div>

                    <div className={styles.editInputLabel}>本文</div>
                    <div className={styles.editInputWrap}>
                        <TextArea
                            label=''
                            name='newBlogText'
                            id='newBlogText'
                            require
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text, validate) => {
                                dispatch(setSchoolNewBlogInput({
                                    ...schoolBlogStore.new,
                                    text,
                                }));
                                dispatch(setSchoolNewBlogValidate({
                                    ...schoolBlogStore.newValidate,
                                    text: validate
                                }));
                            }}
                            defaultInput={schoolBlogStore.new.text}
                            placeHolder='本文'
                            forReview={false}
                            forInquiry={false}
                            countFlag
                            maxLength={2000}
                            minLength={1}
                            height={721}
                        />
                    </div>

                    <div className={styles.buttonWrap}>
                        <GreenBtn
                            text='公開する'
                            style={Object.entries(schoolBlogStore.newValidate).some(d => !d[1]) ? {background: '#dcdcdc', pointerEvents: 'none'} : {}}
                            handleClick={() => {
                                dispatch(postNewSchoolBlog(authToken, schoolId, schoolBlogStore.new, true))
                            }}
                            isLink={false}
                            path=''
                        />
                    </div>
                    <div className={styles.buttonWrap}>
                        <button
                            type="button"
                            className={styles.linkText}
                            style={Object.entries(schoolBlogStore.newValidate).some(d => !d[1]) ? {color: '#dcdcdc', pointerEvents: 'none'} : {}}
                            onClick={() => {
                                dispatch(postNewSchoolBlog(authToken, schoolId, schoolBlogStore.new, false))
                            }}
                        >
                            {loadingSelector ? '通信中...' : '下書き保存する（非公開）'}
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>);
    }
    return (<>
        <Header />
        <OwnerApplication />
        <TabBar currentPage='/school' />
        <div className={styles.schoolContainer}>
            <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, { text: '教室名', path: '' }]} />
        </div>
        <Footer />
    </>);
};
