import React from 'react';
import styles from './css/style.module.scss';

type ShowMoreBtnBtnProps = {
    text: string,
    onClick: () => void;
}

export const ShowMoreBtn: React.FC<ShowMoreBtnBtnProps> = (props: ShowMoreBtnBtnProps) => {
    const {text, onClick} = props;

    return <button type="button" onClick={onClick} className={styles.showMoreBtn}>
        {text}
    </button>
}