import React, { useEffect } from 'react';
import styles from './css/style.module.scss';
import { useTextInput } from './hooks/useTextInput';
import { InputValidateTypeEnum } from './InputValidateType.enum';

type TextInputProps = {
    label: string;
    name: string;
    id: string;
    require: boolean;
    inputType: string;
    validateType: InputValidateTypeEnum;
    annotation: string | null;
    changeReturnFunc: (text: string, validate: boolean) => void;
    defaultInput: string | null;
    placeHolder: string | null;
    minLength: number | null;
    defaultInputValue: string | null;
    disabled: boolean
};

export const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
    const {
        label,
        name,
        id,
        require,
        inputType,
        validateType,
        annotation,
        changeReturnFunc,
        defaultInput,
        placeHolder,
        minLength,
        defaultInputValue,
        disabled,
    } = props;
    const [inputData, callBack] = useTextInput(defaultInput, require, validateType, minLength);

    useEffect(() => {
        if (defaultInputValue) {
            callBack.setTextCallBack(defaultInputValue || '');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultInputValue]);

    return (
        <>
            <div className={styles.wrap}>
                {label && (<div className={`${styles.label} ${require ? styles.require : ''}`}>{label}</div>)}
                <input
                    step="any"
                    className={`${styles.input} ${inputData.errorText ? styles.error : ''} `}
                    type={inputType}
                    name={name}
                    id={id}
                    value={inputData.input}
                    onChange={(e) => {
                        const result = callBack.setTextCallBack(e.target.value);
                        changeReturnFunc(result.data, result.validate);
                    }}
                    placeholder={placeHolder || ''}
                    disabled={disabled}
                />
                {annotation && <div className={styles.annotation}>{annotation}</div>}
                {inputData.errorText && <div className={styles.errorText}>{inputData.errorText}</div>}
            </div>
        </>
    );
};
