import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../asset/css/schoolCommon.module.scss';
import { SchoolDetailParamTypes } from './type/SchoolDetaillParam.type';
import { OwnerApplication } from '../../../components/MyPage/OwnerApplication';
import { TabBar } from '../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../components/Common/BreadCrumb';
import { Footer } from '../../../components/Common/Footer';
import { Header } from '../../../components/Common/Header';
import { SchoolHeader } from '../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../components/Schools/SchoolTabBar';
import { SchoolPageExplanation } from '../../../components/Schools/SchoolPageExplanation';
import { SchoolH3 } from '../../../components/Schools/SchoolH3';
import { SchoolDetailInfo } from '../../../components/Schools/SchoolDetailInfo';
import { SchoolDetailTopNotice } from '../../../components/Schools/SchoolDetailTopNotice';
import { SchoolDetailTopQa } from '../../../components/Schools/SchoolDetailTopQa';
import { SchoolDetailTopOverView } from '../../../components/Schools/SchoolDetailTopOverView';
import { RootState } from '../../../redux/store';
import { getSchoolDetail } from '../../../redux/actions/domain/schools/schoolDetail';
import {
    putSchoolDetailEditData, setSchoolDetailEditData, setSchoolDetailEditValidate
} from '../../../redux/actions/domain/schools/schoolIndexEdit';
import { CurrentSchoolDataType } from '../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import orioriCoupon from '../../../asset/img/orioir-coupon.png';
import orioriReview from '../../../asset/img/orioir-review.png';
import orioriCourse from '../../../asset/img/oriori-course.png';
import {
    setSchoolTopImages,
    setSchoolTopImagesEditFlag,
    setSchoolTopNoticeEditFlag, setSchoolTopQaEditFlag
} from '../../../redux/actions/domain/schools/schoolTopEdit';
import { TextArea } from '../../../components/Common/Input/TextArea';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { CreateSchoolDataType } from '../../../util/type/CreateSchoolData.type';
import { ImageUploader, ImageUploaderSize } from '../../../components/SchoolInput/ImageUploader';
import { SchoolQuestionType } from '../../../util/type/SchoolQuestion.type';
import { schoolDetailEditImagesInitial } from '../../../redux/reducers/domain/schools/InitialState/Top/schoolDetailEditImagesInitial';

export const SchoolDetailTop: React.FC = () => {
    const { schoolId } = useParams<SchoolDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);


    const dispatch = useDispatch();

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(setSchoolTopNoticeEditFlag(false));
        dispatch(setSchoolTopImagesEditFlag(false));
        dispatch(setSchoolTopQaEditFlag(false));
        dispatch(setSchoolTopImages(schoolDetailEditImagesInitial));
    }, [authToken, dispatch, schoolId]);

    if (schoolStore.schoolDetailData !== null
        && schoolStore.schoolDetailEditData !== null
        && schoolStore.schoolDetailEditValidate !== null
    ) {
        return (<>
            <Header />
            <OwnerApplication />
            <TabBar currentPage='/school' />
            <div className={styles.schoolContainer}>
                <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, {
                    text: `${schoolStore.schoolDetailData.name}`,
                    path: `school/${schoolId}/top`
                }]} />
                <SchoolHeader data={schoolStore.schoolDetailData} />
                <SchoolTabBar currentPage={`/school/${schoolId}/top`} schoolId={schoolId} />
                <SchoolPageExplanation title='教室トップ' textDom={<>oriori習い事内の教室情報topの情報を編集、追加します。</>} btnText={null}
                    path={null} />
                <SchoolH3 editable={!schoolStore.noticeEditFlag} otherEditPlace={null}
                    ButtonFunc={() => {
                        if (!schoolStore.noticeEditFlag) {
                            dispatch(setSchoolTopNoticeEditFlag(true));
                        } else {
                            dispatch(setSchoolTopNoticeEditFlag(false));
                            dispatch(getSchoolDetail(authToken, Number(schoolId)));
                            dispatch(setSchoolTopImages(schoolDetailEditImagesInitial));
                        }
                    }} text='お知らせ'
                    isShow={null}
                />
                {!schoolStore.noticeEditFlag ? (
                    <div className={styles.schoolDetailTopContentWrap}>
                        <SchoolDetailTopNotice text={schoolStore.schoolDetailData.note} />
                    </div>
                ) : (<div className={styles.schoolInfoWrap}>
                    <div className={styles.editInputLabel}>お知らせ</div>
                    <TextArea
                        label=''
                        name='noticeText'
                        id='noticeText'
                        require={false}
                        validateType={InputValidateTypeEnum.TEXT}
                        annotation={null}
                        changeReturnFunc={(text, validate) => {
                            dispatch(setSchoolDetailEditData({
                                ...schoolStore.schoolDetailEditData as CreateSchoolDataType,
                                note: text
                            }));
                            dispatch(setSchoolDetailEditValidate({
                                ...schoolStore.schoolDetailEditValidate,
                                note: validate
                            }));
                        }}
                        defaultInput={schoolStore.schoolDetailEditData.note}
                        placeHolder='東京都からの時短要請に伴い、3月21日(日)迄、時間を以下の通り変更させて頂きます。

・ダミーテキスト
・ダミーテキスト'
                        forReview={false}
                        forInquiry={false}
                        countFlag
                        maxLength={500}
                        minLength={null}
                        height={174}
                    />
                    <button
                        type='button'
                        className={`${styles.editInputButton} ${Object.entries(schoolStore.schoolDetailEditValidate).some(d => !d[1]) ? styles['editInputButton--disable'] : ''}`}
                        onClick={() => {
                            if (schoolStore.schoolDetailData) {
                                dispatch(putSchoolDetailEditData(authToken, schoolId.toString(), schoolStore.schoolDetailEditData as CreateSchoolDataType, schoolStore.schoolDetailEditImages));
                            }
                        }}>
                        {loadingSelector ? '通信中...' : '保存する'}
                    </button>
                </div>)}
                <SchoolH3 editable={!schoolStore.imagesEditFlag} otherEditPlace={null}
                    ButtonFunc={() => {
                        if (!schoolStore.imagesEditFlag) {
                            dispatch(setSchoolTopImagesEditFlag(true));
                        } else {
                            dispatch(setSchoolTopImagesEditFlag(false));
                            dispatch(getSchoolDetail(authToken, Number(schoolId)));
                            dispatch(setSchoolTopImages(schoolDetailEditImagesInitial));
                        }
                    }} text='イメージ画像・教室紹介'
                    isShow={null}
                />
                {!schoolStore.imagesEditFlag ? (<div className={styles.schoolDetailTopContentWrap}>
                    <SchoolDetailTopOverView schoolData={schoolStore.schoolDetailData} />
                </div>
                ) : (<div className={styles.schoolEditWrap}>
                    <div className={styles.editInputLabel}>メイン画像</div>
                    <div className={styles.editInputWrap}>
                        <ImageUploader
                            uiSize={ImageUploaderSize.BIG}
                            identificationTitle={null}
                            identificationText={null}
                            defaultImageUrl={schoolStore.schoolDetailData.imageUrls[0] ? schoolStore.schoolDetailData.imageUrls[0] : null}
                            returnFunc={(file, deleteFlag) => {
                                if (deleteFlag) {
                                    const data: CreateSchoolDataType = schoolStore.schoolDetailEditData as CreateSchoolDataType;
                                    const newData = data.deleteImageIds.filter(d => d !== 0);
                                    newData.push(0);
                                    if (data) {
                                        dispatch(setSchoolDetailEditData({
                                            ...schoolStore.schoolDetailEditData as CreateSchoolDataType,
                                            deleteImageIds: [...newData]
                                        }));
                                    }
                                }
                                dispatch(setSchoolTopImages({
                                    ...schoolStore.schoolDetailEditImages,
                                    image0: file
                                }));
                            }}
                        />
                    </div>
                    <div className={styles.editInputLabel}>その他画像</div>
                    <div className={styles.editImageListWrap}>
                        {new Array(9).fill('').map((d, index) => {
                            const detailData = schoolStore.schoolDetailData;
                            // eslint-disable-next-line react/no-array-index-key
                            return <ImageUploader key={`imageInput_${index}`}
                                uiSize={ImageUploaderSize.SMALL}
                                identificationTitle={null}
                                identificationText={null}
                                defaultImageUrl={detailData && detailData.imageUrls[index + 1] ? detailData.imageUrls[index + 1] : null}
                                returnFunc={(file, deleteFlag) => {
                                    if (deleteFlag) {
                                        const data: CreateSchoolDataType = schoolStore.schoolDetailEditData as CreateSchoolDataType;
                                        const newData = data.deleteImageIds.filter(d => d !== index + 1);
                                        newData.push(index + 1);
                                        if (data) {
                                            dispatch(setSchoolDetailEditData({
                                                ...schoolStore.schoolDetailEditData as CreateSchoolDataType,
                                                deleteImageIds: [...newData]
                                            }));
                                        }
                                    }
                                    if (index === 0) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image1: file
                                        }));
                                    }
                                    if (index === 1) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image2: file
                                        }));
                                    }
                                    if (index === 2) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image3: file
                                        }));
                                    }
                                    if (index === 3) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image4: file
                                        }));
                                    }
                                    if (index === 4) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image5: file
                                        }));
                                    }
                                    if (index === 5) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image6: file
                                        }));
                                    }
                                    if (index === 6) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image7: file
                                        }));
                                    }
                                    if (index === 7) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image8: file
                                        }));
                                    }
                                    if (index === 8) {
                                        dispatch(setSchoolTopImages({
                                            ...schoolStore.schoolDetailEditImages,
                                            image9: file
                                        }));
                                    }
                                }}
                            />;
                        })}
                    </div>
                    <div className={styles.editInputLabel}>キャッチコピー</div>
                    <div className={styles.editInputWrap}>
                        <TextArea
                            label=''
                            name='copy'
                            id='copy'
                            require={false}
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text, validate) => {
                                dispatch(setSchoolDetailEditData({
                                    ...schoolStore.schoolDetailEditData as CreateSchoolDataType,
                                    copy: text
                                }));
                                dispatch(setSchoolDetailEditValidate({
                                    ...schoolStore.schoolDetailEditValidate,
                                    copy: validate
                                }));
                            }}
                            defaultInput={schoolStore.schoolDetailEditData.copy}
                            placeHolder='ニュージーランド式メソッドで教えます。新しいプログラミング体験。ニュージーランド式メソッドで教えます。新しいプログラミング体験。ニュージーランド式メソッドで教えます。'
                            forReview={false}
                            forInquiry={false}
                            countFlag
                            maxLength={500}
                            minLength={null}
                            height={174}
                        />
                    </div>
                    <div className={styles.editInputLabel}>教室紹介文</div>
                    <div className={styles.editInputWrap}>
                        <TextArea
                            label=''
                            name='copy'
                            id='copy'
                            require={false}
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text, validate) => {
                                dispatch(setSchoolDetailEditData({
                                    ...schoolStore.schoolDetailEditData as CreateSchoolDataType,
                                    description: text
                                }));
                                dispatch(setSchoolDetailEditValidate({
                                    ...schoolStore.schoolDetailEditValidate,
                                    description: validate
                                }));
                            }}
                            defaultInput={schoolStore.schoolDetailEditData.description}
                            placeHolder='ニュージーランド式メソッドで教えます。新しいプログラミング体験。ニュージーランド式メソッドで教えます。新しいプログラミング体験。ニュージーランド式メソッドで教えます。'
                            forReview={false}
                            forInquiry={false}
                            countFlag
                            maxLength={3000}
                            minLength={null}
                            height={341}
                        />
                    </div>
                    <button
                        type='button'
                        className={`${styles.editInputButton} ${Object.entries(schoolStore.schoolDetailEditValidate).some(d => !d[1]) ? styles['editInputButton--disable'] : ''}`}
                        onClick={() => {
                            if (schoolStore.schoolDetailData) {
                                dispatch(putSchoolDetailEditData(authToken, schoolId.toString(), schoolStore.schoolDetailEditData as CreateSchoolDataType, schoolStore.schoolDetailEditImages));
                            }
                        }}>
                        {loadingSelector ? '通信中...' : '保存する'}
                    </button>
                </div>
                )}

                <SchoolH3 editable={!schoolStore.advancedEditFlag}
                    otherEditPlace={{ text: 'コースタブ', path: `/school/${schoolId}/course` }}
                    ButtonFunc={null} text='コース'
                    isShow={null}
                />
                <div className={styles.schoolDetailTopCard}>
                    <div className={styles.schoolDetailTopCard__textWrap}>
                        <div className={styles.schoolDetailTopCard__title}>コースが表示されます。</div>
                        <div className={styles.schoolDetailTopCard__text}>※このエリアの情報は、コースタブからも編集できます</div>
                        <button type='button' className={styles.schoolDetailTopCard__btn}>コースを編集する</button>
                    </div>
                    <div className={styles.schoolDetailTopCard__imgWrap}>
                        <div className={styles.schoolDetailTopCard__imgBg}>
                            <img className={styles.schoolDetailTopCard__img} src={orioriCourse} alt='コース表示イメージ' />
                            <div className={styles.schoolDetailTopCard__imgText}>※コース表示イメージ</div>
                        </div>
                    </div>
                </div>
                <SchoolH3 editable={!schoolStore.advancedEditFlag} otherEditPlace={null}
                    ButtonFunc={null} text='口コミ' isShow={null} />
                <div className={styles.schoolDetailTopCard}>
                    <div className={styles.schoolDetailTopCard__textWrap}>
                        <div className={styles.schoolDetailTopCard__title}>口コミが表示されます。</div>
                        <div
                            className={styles.schoolDetailTopCard__text}>※不適切な口コミを見つけた場合、<span>該当する口コミの「問題のある口コミを報告」</span>からお知らせください
                        </div>
                    </div>
                    <div className={styles.schoolDetailTopCard__imgWrap}>
                        <div className={styles.schoolDetailTopCard__imgBg}>
                            <img className={styles.schoolDetailTopCard__img} src={orioriReview} alt='口コミイメージ' />
                            <div className={styles.schoolDetailTopCard__imgText}>※口コミイメージ</div>
                        </div>
                    </div>
                </div>
                <SchoolH3 editable={!schoolStore.advancedEditFlag}
                    otherEditPlace={{ text: 'クーポンタブ', path: `/school/${schoolId}/coupon` }}
                    ButtonFunc={null} text='クーポン' isShow={null} />
                <div className={styles.schoolDetailTopCard}>
                    <div className={styles.schoolDetailTopCard__textWrap}>
                        <div className={styles.schoolDetailTopCard__title}>クーポンが表示されます。</div>
                        <div className={styles.schoolDetailTopCard__text}>※このエリアの情報は、クーポンタブからも編集できます</div>
                        <button type='button' className={styles.schoolDetailTopCard__btn}>クーポンを編集する</button>
                    </div>
                    <div className={styles.schoolDetailTopCard__imgWrap}>

                        <div className={styles.schoolDetailTopCard__imgBg}>
                            <img className={styles.schoolDetailTopCard__img} src={orioriCoupon} alt='クーポン表示イメージ' />
                            <div className={styles.schoolDetailTopCard__imgText}>※クーポン表示イメージ</div>
                        </div>
                    </div>
                </div>
                <SchoolH3 editable={!schoolStore.advancedEditFlag}
                    otherEditPlace={{ text: '教室情報タブ', path: `/school/${schoolId}` }}
                    ButtonFunc={null} text='詳細情報' isShow={null} />
                <div className={styles.schoolDetailTopContentWrap}>
                    <SchoolDetailInfo school={schoolStore.schoolDetailData} />
                </div>

                <SchoolH3 editable={!schoolStore.qaEditFlag} otherEditPlace={null}
                    ButtonFunc={() => {
                        if (!schoolStore.qaEditFlag) {
                            dispatch(setSchoolTopQaEditFlag(true));
                        } else {
                            dispatch(setSchoolTopQaEditFlag(false));
                            dispatch(getSchoolDetail(authToken, Number(schoolId)));
                            dispatch(setSchoolTopImages(schoolDetailEditImagesInitial));
                        }
                    }} text='よくある質問' isShow={null} />
                {!schoolStore.qaEditFlag ? (<>
                    {schoolStore.schoolDetailData.questions && schoolStore.schoolDetailData.questions.length > 0 && (
                        <div className={styles.schoolDetailTopContentWrap}>
                            <SchoolDetailTopQa qa={schoolStore.schoolDetailData.questions} />
                        </div>
                    )}
                </>) : (
                    // eslint-disable-next-line react/no-array-index-key
                    <>{new Array(3).fill('').map((d, index) => (<div key={`qa_input_${index}`}>
                        <div className={styles.editInputLabel}>よくある質問{index + 1}</div>
                        <div className={styles.editInputQaWrap}>
                            <TextArea
                                label=''
                                name={`qa_${index + 1}`}
                                id={`qa_${index + 1}`}
                                require={false}
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation={null}
                                changeReturnFunc={(text, validate) => {
                                    // 入力データ生成
                                    const targetQa: SchoolQuestionType = {
                                        question: text,
                                        answer: schoolStore.schoolDetailEditData
                                            && schoolStore.schoolDetailEditData.questions
                                            && schoolStore.schoolDetailEditData.questions[index] ?
                                            schoolStore.schoolDetailEditData.questions[index].answer : ''
                                    };
                                        // 既存データの有無によって、何を書き換えるか
                                    let targetQaArray: Array<SchoolQuestionType> = [];
                                    if (schoolStore.schoolDetailEditData) {
                                        if (!schoolStore.schoolDetailEditData.questions) {
                                            // questions自体がない
                                            targetQaArray = [targetQa];
                                        } else if (!schoolStore.schoolDetailEditData.questions[index]) {
                                            // questions自体はあるが、index部分のデータがない
                                            targetQaArray = schoolStore.schoolDetailEditData.questions;
                                            targetQaArray.push(targetQa);
                                        } else {
                                            // questions自体はある、index部分のデータもある
                                            targetQaArray = schoolStore.schoolDetailEditData.questions;
                                            targetQaArray[index] = targetQa;
                                        }
                                    }
                                    dispatch(setSchoolDetailEditData({
                                        ...schoolStore.schoolDetailEditData as CreateSchoolDataType,
                                        questions: targetQaArray
                                    }));
                                    dispatch(setSchoolDetailEditValidate({
                                        ...schoolStore.schoolDetailEditValidate,
                                        questions: validate
                                    }));
                                }}
                                defaultInput={schoolStore.schoolDetailEditData
                                    && schoolStore.schoolDetailEditData.questions
                                    && schoolStore.schoolDetailEditData.questions[index] ?
                                    schoolStore.schoolDetailEditData.questions[index].question : ''}
                                placeHolder='よくある質問を入力'
                                forReview={false}
                                forInquiry={false}
                                countFlag
                                maxLength={500}
                                minLength={null}
                                height={174}
                            />
                        </div>
                        <div className={styles.editInputLabel}>回答</div>
                        <div className={styles.editInputQaAnswerWrap}>
                            <TextArea
                                label=''
                                name={`qa_answer_${index + 1}`}
                                id={`qa_answer_${index + 1}`}
                                require={false}
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation={null}
                                changeReturnFunc={(text, validate) => {
                                    // 入力データ生成
                                    const targetQa: SchoolQuestionType = {
                                        question: schoolStore.schoolDetailEditData
                                            && schoolStore.schoolDetailEditData.questions
                                            && schoolStore.schoolDetailEditData.questions[index] ?
                                            schoolStore.schoolDetailEditData.questions[index].question : '',
                                        answer: text
                                    };
                                        // 既存データの有無によって、何を書き換えるか
                                    let targetQaArray: Array<SchoolQuestionType> = [];
                                    if (schoolStore.schoolDetailEditData) {
                                        if (!schoolStore.schoolDetailEditData.questions) {
                                            // questions自体がない
                                            targetQaArray = [targetQa];
                                        } else if (!schoolStore.schoolDetailEditData.questions[index]) {
                                            // questions自体はあるが、index部分のデータがない
                                            targetQaArray = schoolStore.schoolDetailEditData.questions;
                                            targetQaArray.push(targetQa);
                                        } else {
                                            // questions自体はある、index部分のデータもある
                                            targetQaArray = schoolStore.schoolDetailEditData.questions;
                                            targetQaArray[index] = targetQa;
                                        }
                                    }
                                    dispatch(setSchoolDetailEditData({
                                        ...schoolStore.schoolDetailEditData as CreateSchoolDataType,
                                        questions: targetQaArray
                                    }));
                                    dispatch(setSchoolDetailEditValidate({
                                        ...schoolStore.schoolDetailEditValidate,
                                        questions: validate
                                    }));
                                }}
                                defaultInput={schoolStore.schoolDetailEditData
                                    && schoolStore.schoolDetailEditData.questions
                                    && schoolStore.schoolDetailEditData.questions[index] ?
                                    schoolStore.schoolDetailEditData.questions[index].answer : ''}
                                placeHolder='よくある質問を入力'
                                forReview={false}
                                forInquiry={false}
                                countFlag
                                maxLength={500}
                                minLength={null}
                                height={174}
                            />
                        </div>
                    </div>)
                    )}
                    <button
                        type='button'
                        className={`${styles.editInputButton} ${Object.entries(schoolStore.schoolDetailEditValidate).some(d => !d[1]) ? styles['editInputButton--disable'] : ''}`}
                        onClick={() => {
                            if (schoolStore.schoolDetailData) {
                                dispatch(putSchoolDetailEditData(authToken, schoolId.toString(), schoolStore.schoolDetailEditData as CreateSchoolDataType, schoolStore.schoolDetailEditImages));
                            }
                        }}>
                        {loadingSelector ? '通信中...' : '保存する'}
                    </button>
                    </>)}
            </div>
            <Footer />
        </>);
    }
    return (<>
        <Header />
        <OwnerApplication />
        <TabBar currentPage='/schools' />
        <div className={styles.schoolContainer}>
            <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, { text: '教室名', path: '' }]} />
        </div>
        <Footer />
    </>);
};
