import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as queryString from 'querystring';
import { RootState } from '../../redux/store';
import { getClient } from '../../redux/actions/domain/client/client';
import styles from '../../asset/css/myPageCommon.module.scss';
import { Header } from '../../components/Common/Header';
import { Footer } from '../../components/Common/Footer';
import { ShowMoreBtn } from '../../components/Common/ShowMoreBtn';
import { PageNation, TargetPage } from '../../components/Common/PageNation';
import { OwnerApplication } from '../../components/MyPage/OwnerApplication';
import { TabBar } from '../../components/MyPage/TabBar';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import { SchoolList } from '../../components/Schools/SchoolList';
import { getMySchools, setMySchoolsCurrentPage } from '../../redux/actions/domain/mypage/schools';
import { SCHOOL_PAGE_COUNT } from '../../util/const/schoolPageCount';

export const SchoolListPage: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const MyPageState = useSelector((state: RootState) => state.rootReducer.domain.myPage);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const query = queryString.parse(search.replace('?', ''));
    const page = query.page ? Number(query.page) : 0;

    useEffect(() => {
        // client情報の取得
        dispatch(setMySchoolsCurrentPage(page));
        dispatch(getClient(authToken));
        // マイスクール情報取得
        dispatch(getMySchools(authToken, SCHOOL_PAGE_COUNT, page));
    }, [authToken, dispatch, history, page]);

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.myPageContainer}>
                <BreadCrumb
                    data={[
                        { text: 'マイページ', path: '/mypage' },
                        { text: '教室一覧', path: '/school' },
                    ]}
                />
                {MyPageState.myPageData.schools.length !== 0 && (
                    <>
                        <h2>教室({MyPageState.myPageData.schoolsCount})</h2>
                        <SchoolList schoolData={MyPageState.myPageData.schools} isSchoolListPage page={page} count={SCHOOL_PAGE_COUNT} />
                    </>
                )}

                {page < Math.floor(MyPageState.myPageData.schoolsCount / SCHOOL_PAGE_COUNT) && (
                    <ShowMoreBtn text={`次の${SCHOOL_PAGE_COUNT}件を見る`} onClick={() => history.push(`/school?page=${page + 1}`)} />
                )}
                <PageNation page={page} dataLength={MyPageState.myPageData.schoolsCount} path="/school" targetPage={TargetPage.SCHOOL} />
            </div>
            <Footer />
        </>
    );
};
