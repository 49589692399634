import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from '../../../asset/css/newSchool.module.scss';
import { RootState } from '../../../redux/store';
import { NewSchoolHeader } from '../../../components/SchoolInput/NewSchoolHeader';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { AddressInput } from '../../../components/SchoolInput/AddressInput';
import { setNewSchoolInput, setNewSchoolInputValidate } from '../../../redux/actions/domain/schools/newSchool';
import { TextArea } from '../../../components/Common/Input/TextArea';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { WeekScheduleInput } from '../../../components/SchoolInput/WeekScheduleInput';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { OwnerApplication } from '../../../components/MyPage/OwnerApplication';
import { TabBar } from '../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../components/Common/BreadCrumb';

export const New_2: React.FC = () => {
    const newSchoolInputStore = useSelector((state: RootState) => state.rootReducer.domain.schools);
    const dispatch = useDispatch();
    //
    // useEffect(() => {
    //
    //
    // }, []);

    // バリデーション ステップ1の入力内容が未完了
    if (
        [
            newSchoolInputStore.newSchoolInput.validate.name,
            newSchoolInputStore.newSchoolInput.validate.nameKana,
            newSchoolInputStore.newSchoolInput.validate.categoryId,
            newSchoolInputStore.newSchoolInput.validate.keywordIds,
            newSchoolInputStore.newSchoolInput.validate.classStyleIds,
            newSchoolInputStore.newSchoolInput.validate.tagIds,
            newSchoolInputStore.newSchoolInput.validate.nearestStation,
            newSchoolInputStore.newSchoolInput.validate.nearestBusstop,
        ].filter((flag) => !flag).length > 0
    ) {
        return <Redirect to="/school/new_1" />;
    }

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.wrap}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室を新規作成', path: '/school/new_2' },
                    ]}
                />
                <NewSchoolHeader step={2} />
                <div className={styles.title}>基本情報</div>
                <div className={styles.inputWrap}>
                    <div className={styles.input}>
                        <AddressInput
                            returnFunc={(selectData) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        withoutAddress: selectData.withoutAddress,
                                        prefectureId: selectData.prefecture ? selectData.prefecture : 0,
                                        townId: selectData.town ? selectData.town : 0,
                                        streetId: selectData.street ? selectData.street : 0,
                                        addressDetail: selectData.addressDetail,
                                    })
                                );
                                dispatch(
                                    setNewSchoolInputValidate({
                                        ...newSchoolInputStore.newSchoolInput.validate,
                                        prefectureId: selectData.withoutAddress ? true : !!selectData.prefecture,
                                        townId: selectData.withoutAddress ? true : !!selectData.town,
                                        streetId: selectData.withoutAddress ? true : !!selectData.street,
                                        addressDetail: selectData.withoutAddress ? true : !!selectData.addressDetail,
                                    })
                                );
                            }}
                            defaultWithoutAddress={newSchoolInputStore.newSchoolInput.input.withoutAddress}
                            defaultPrefectureId={newSchoolInputStore.newSchoolInput.input.prefectureId}
                            defaultTown={newSchoolInputStore.newSchoolInput.input.townId}
                            defaultStreet={newSchoolInputStore.newSchoolInput.input.streetId}
                            defaultAddressDetail={newSchoolInputStore.newSchoolInput.input.addressDetail}
                        />
                    </div>

                    <div className={styles.input}>
                        <TextArea
                            label="交通手段"
                            name="nearestStationText"
                            id="nearestStationText"
                            require={false}
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text, validate) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        nearestStationText: text,
                                    })
                                );
                                dispatch(
                                    setNewSchoolInputValidate({
                                        ...newSchoolInputStore.newSchoolInput.validate,
                                        nearestStationText: validate,
                                    })
                                );
                            }}
                            defaultInput={newSchoolInputStore.newSchoolInput.input.nearestStationText}
                            placeHolder="交通手段を入力
例：JR・東急東横・東急田園都市・東京メトロ各線「渋谷駅」より徒歩10分
JR原宿駅より徒歩10分
小田急電鉄「代々木八幡駅」より徒歩10分"
                            forReview={false}
                            forInquiry={false}
                            countFlag
                            maxLength={500}
                            minLength={null}
                            height={174}
                        />
                    </div>

                    <div className={styles.input}>
                        <WeekScheduleInput
                            defaultValue={newSchoolInputStore.newSchoolInput.input.scheduleWeekSchedule}
                            returnFunc={(data) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        scheduleWeekSchedule: data,
                                    })
                                );
                                dispatch(
                                    setNewSchoolInputValidate({
                                        ...newSchoolInputStore.newSchoolInput.validate,
                                        scheduleWeekSchedule: !data.find((d) => d !== null && (d.to === '' || d.from === '')),
                                    })
                                );
                            }}
                            label="開校時間"
                            name="newSchoolScheduleWeekSchedule"
                        />
                    </div>

                    <div className={styles.buttonWrap}>
                        <GreenBtn
                            text="次へ　2/ 3"
                            style={
                                [
                                    newSchoolInputStore.newSchoolInput.validate.prefectureId,
                                    newSchoolInputStore.newSchoolInput.validate.townId,
                                    newSchoolInputStore.newSchoolInput.validate.streetId,
                                    newSchoolInputStore.newSchoolInput.validate.addressDetail,
                                    newSchoolInputStore.newSchoolInput.validate.nearestStationText,
                                    newSchoolInputStore.newSchoolInput.validate.scheduleWeekSchedule,
                                ].filter((flag) => !flag).length > 0
                                    ? {
                                        background: '#c4c4c4',
                                        pointerEvents: 'none',
                                    }
                                    : {}
                            }
                            handleClick={() => false}
                            isLink
                            path="/school/new_3"
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
