import { Action } from 'redux';
import { SchoolsActionTypes } from './index';
import { CreateSchoolDataType } from '../../../../util/type/CreateSchoolData.type';
import { NewSchoolInputValidateType } from '../../../reducers/domain/schools/type/New/NewSchoolInputValidate.type';
import { SchoolDetailEditImagesType } from '../../../reducers/domain/schools/type/Top/SchoolDetailEditImages.type';

export const setSchoolDetailBaseEditFlag = (flag: boolean): SetSchoolDetailBaseEditFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_DETAIL_BASE_EDIT_FLAG,
    payload: { flag },
});

export interface SetSchoolDetailBaseEditFlag extends Action {
    type: string;
    payload: {
        flag: boolean;
    };
}

export const setSchoolDetailAdvancedEditFlag = (flag: boolean): SetSchoolDetailAdvancedEditFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_DETAIL_ADVANCED_EDIT_FLAG,
    payload: { flag },
});

export interface SetSchoolDetailAdvancedEditFlag extends Action {
    type: string;
    payload: {
        flag: boolean;
    };
}

export const setSchoolDetailEditData = (editData: CreateSchoolDataType | null): SetSchoolDetailBaseEditData => ({
    type: SchoolsActionTypes.SET_SCHOOL_DETAIL_BASE_EDIT_DATA,
    payload: { editData },
});

export interface SetSchoolDetailBaseEditData extends Action {
    type: string;
    payload: {
        editData: CreateSchoolDataType | null;
    };
}

export const setSchoolDetailEditValidate = (schoolDetailEditValidate: NewSchoolInputValidateType): PostSchoolDetailEditValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_DETAIL_BASE_EDIT_VALIDATE,
    payload: { schoolDetailEditValidate },
});

export interface PostSchoolDetailEditValidate extends Action {
    type: string;
    payload: {
        schoolDetailEditValidate: NewSchoolInputValidateType;
    };
}

export const putSchoolDetailEditData = (
    authToken: string,
    schoolId: string,
    editData: CreateSchoolDataType,
    images?: SchoolDetailEditImagesType
): PostSchoolDetailEditData => ({
    type: SchoolsActionTypes.PUT_SCHOOL_DETAIL_EDIT_DATA,
    payload: { authToken, schoolId, editData, images },
});

export interface PostSchoolDetailEditData extends Action {
    type: string;
    payload: {
        authToken: string;
        schoolId: string;
        editData: CreateSchoolDataType;
        images?: SchoolDetailEditImagesType;
    };
}

export const patchSchoolPublish = (
    authToken: string,
    schoolId: string,
    inList: boolean,
    page: number | null,
    count: number | null
): PatchSchoolPublish => ({
    type: SchoolsActionTypes.PATCH_SCHOOL_PUBLISH,
    payload: { authToken, schoolId, inList, page, count },
});

export interface PatchSchoolPublish extends Action {
    type: string;
    payload: {
        authToken: string;
        schoolId: string;
        inList: boolean;
        page: number | null;
        count: number | null;
    };
}

export const patchAllSchoolPublish = (
    authToken: string,
    schoolIds: Array<string>,
    inList: boolean,
    page: number | null,
    count: number | null
): PatchAllSchoolPublish => ({
    type: SchoolsActionTypes.PATCH_ALL_SCHOOLS_PUBLISH,
    payload: { authToken, schoolIds, inList, page, count },
});

export interface PatchAllSchoolPublish extends Action {
    type: string;
    payload: {
        authToken: string;
        schoolIds: Array<string>;
        inList: boolean;
        page: number | null;
        count: number | null;
    };
}

export const patchSchoolUnPublish = (
    authToken: string,
    schoolId: string,
    inList: boolean,
    page: number | null,
    count: number | null
): PatchSchoolUnPublish => ({
    type: SchoolsActionTypes.PATCH_SCHOOL_UN_PUBLISH,
    payload: { authToken, schoolId, inList, page, count },
});

export interface PatchSchoolUnPublish extends Action {
    type: string;
    payload: {
        authToken: string;
        schoolId: string;
        inList: boolean;
        page: number | null;
        count: number | null;
    };
}

export const patchAllSchoolUnPublish = (
    authToken: string,
    schoolIds: Array<string>,
    inList: boolean,
    page: number | null,
    count: number | null
): PatchAllSchoolUnPublish => ({
    type: SchoolsActionTypes.PATCH_ALL_SCHOOLS_UN_PUBLISH,
    payload: { authToken, schoolIds, inList, page, count },
});

export interface PatchAllSchoolUnPublish extends Action {
    type: string;
    payload: {
        authToken: string;
        schoolIds: Array<string>;
        inList: boolean;
        page: number | null;
        count: number | null;
    };
}
