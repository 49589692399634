import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './css/style.module.scss';
import { setNewSchoolInput, setNewSchoolInputValidate } from '../../../redux/actions/domain/schools/newSchool';
import { newSchoolInputDataInitial } from '../../../redux/reducers/domain/schools/InitialState/New/newSchoolInputDataInitial';
import { newSchoolInputValidateInitial } from '../../../redux/reducers/domain/schools/InitialState/New/newSchoolInputValidateInitial';

export const SchoolEditBtn: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <div className={styles.schoolEditBtnWrap}>
            <button
                type="button"
                className={styles.schoolEditBtn}
                onClick={() => {
                    dispatch(setNewSchoolInput(newSchoolInputDataInitial));
                    dispatch(setNewSchoolInputValidate(newSchoolInputValidateInitial));
                    history.push('/school/new_1');
                }}>
                教室・スクール情報を作成
            </button>
        </div>
    );
};
