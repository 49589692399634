import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { Header } from '../../../../components/Common/Header';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import { GreenBtn } from '../../../../components/Common/GreenBtn';
import { WhiteBtn } from '../../../../components/Common/WhiteBtn';
import { RootState } from '../../../../redux/store';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import { SchoolDetailCouponType } from '../../../../redux/reducers/domain/schools/type/Coupon/SchoolDetailCoupon.type';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import {
    getSchoolCouponDetail, patchSchoolCouponPublish, patchSchoolCouponUnPublish,
    putSchoolCouponEditData, setSchoolCouponEditData,
    setSchoolCouponEditFlag, setSchoolCouponEditValidate
} from '../../../../redux/actions/domain/schools/schoolCoupon';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolDetailCouponInputType } from '../../../../redux/reducers/domain/schools/type/Coupon/SchoolDetailCouponInput.type';
import { TextInput } from '../../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../../components/Common/Input/TextInput/InputValidateType.enum';
import { TextArea } from '../../../../components/Common/Input/TextArea';
import { ImageUploader, ImageUploaderSize } from '../../../../components/SchoolInput/ImageUploader';
import { DateInput } from '../../../../components/SchoolInput/DateInput';
import { schoolDetailCouponValidateInitial } from '../../../../redux/reducers/domain/schools/InitialState/Coupon/schoolDetailCouponValidateInitial';

type SchoolCouponDetailParamTypes = {
    schoolId: string,
    couponId: string,
}

export const SchoolDetailCouponDetail: React.FC = () => {
    const { schoolId, couponId } = useParams<SchoolCouponDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolCouponStore: SchoolDetailCouponType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailCoupon);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(getSchoolCouponDetail(authToken, Number(couponId)));
    }, [authToken, dispatch, schoolId, couponId]);

    if (schoolStore.schoolDetailData !== null
        && schoolCouponStore.detail !== null
        && schoolCouponStore.edit !== null
        && schoolCouponStore.editValidate !== null
    ) {
        const coupon = schoolCouponStore.detail;
        return (<>
            <Header />
            <OwnerApplication />
            <TabBar currentPage='/school' />
            <div className={styles.schoolContainer}>
                <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, {
                    text: `${schoolStore.schoolDetailData.name}`,
                    path: `/school/${schoolId}/coupon/${couponId}`
                }]} />
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <SchoolHeader data={schoolStore.schoolDetailData} />
                <SchoolTabBar currentPage={`/school/${schoolId}/coupon`} schoolId={schoolId} />
                <Link to={`/school/${schoolId}/coupon`}>
                    <a className={styles.schoolDetailBackBtn}>&lt;クーポン一覧に戻る</a>
                </Link>
                <SchoolH3 otherEditPlace={null}
                    text={`クーポン${couponId}`}
                    editable={!schoolCouponStore.editFlag}
                    ButtonFunc={() => {
                        if (!schoolCouponStore.editFlag) {
                            dispatch(setSchoolCouponEditFlag(true));
                        } else {
                            dispatch(setSchoolCouponEditFlag(false));
                            dispatch(getSchoolDetail(authToken, Number(schoolId)));
                            dispatch(getSchoolCouponDetail(authToken, Number(couponId)));
                            dispatch(setSchoolCouponEditData(null));
                            dispatch(setSchoolCouponEditValidate(schoolDetailCouponValidateInitial));
                        }
                    }}
                    isShow={schoolCouponStore.detail.showStatus}
                />
                {!schoolCouponStore.editFlag ? (
                    <div className={styles.schoolInfoWrap}>
                        <div className={styles.schoolInfo}>
                            <div className={styles.schoolInfo__title}>クーポン名</div>
                            <div className={styles.schoolInfo__text}>{coupon.name}</div>
                        </div>
                        <div className={styles.schoolInfo}>
                            <div className={styles.schoolInfo__title}>提示条件</div>
                            <div className={styles.schoolInfo__text}>{coupon.presentationConditions}</div>
                        </div>
                        <div className={styles.schoolInfo}>
                            <div className={styles.schoolInfo__title}>利用条件</div>
                            <div className={styles.schoolInfo__text}>{coupon.utilizationConditions}</div>
                        </div>
                        <div className={styles.schoolInfo}>
                            <div className={styles.schoolInfo__title}>メイン画像</div>
                            {coupon.mainImageUrl && (
                                <div className={styles.schoolDetailImgWrapper}>
                                    <img src={coupon.mainImageUrl} alt='schoolImage' />
                                </div>
                            )}
                        </div>
                        <div className={styles.schoolInfo}>
                            <div className={styles.schoolInfo__title}>クーポン説明</div>
                            <div className={styles.schoolInfo__text}>{coupon.description}</div>
                        </div>
                        <Link to={`/school/${schoolId}/coupon`}>
                            <a className={`${styles.schoolDetailBackBtn} ${styles['schoolDetailBackBtn--bottom']}`}>&lt;クーポン一覧に戻る</a>
                        </Link>
                        <div className={styles.schoolDetailBtnWrap}>
                            <GreenBtn
                                text='公開する'
                                style={{}}
                                handleClick={() => {
                                    dispatch(patchSchoolCouponPublish(authToken, schoolId, couponId, true))
                                    history.push(`/school/${schoolId}/coupon`);
                                }}
                                isLink={false}
                                path=''
                            />
                            <WhiteBtn
                                style={null}
                                text='非公開にする'
                                handleClick={() => {
                                    dispatch(patchSchoolCouponUnPublish(authToken, schoolId, couponId, true))
                                    history.push(`/school/${schoolId}/coupon`);
                                }}
                                isLink={false}
                                path='/'
                            />
                        </div>
                    </div>) : (
                    <div className={styles.schoolInfoWrap}>
                        <div className={styles.editInputWrap}>
                            <div className={styles.editInputLabel}>クーポン名</div>
                            <TextInput
                                label=''
                                name='couponName'
                                id='couponName'
                                require
                                inputType='text'
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=''
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(setSchoolCouponEditData({
                                        ...schoolCouponStore.edit as SchoolDetailCouponInputType,
                                        name: text
                                    }));
                                    dispatch(setSchoolCouponEditValidate({
                                        ...schoolCouponStore.editValidate,
                                        name: validate
                                    }));
                                }}
                                defaultInput={schoolCouponStore.edit.name}
                                placeHolder='クーポン名を記入'
                                minLength={1}
                                defaultInputValue={schoolCouponStore.edit.name}
                                disabled={false}
                            />
                        </div>
                        <div className={styles.editInputWrap}>
                            <div className={styles.editInputLabel}>提示条件</div>
                            <TextInput
                                label=''
                                name='couponPresentationConditions'
                                id='couponPresentationConditions'
                                require={false}
                                inputType='text'
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=''
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(setSchoolCouponEditData({
                                        ...schoolCouponStore.edit as SchoolDetailCouponInputType,
                                        presentationConditions: text
                                    }));
                                    dispatch(setSchoolCouponEditValidate({
                                        ...schoolCouponStore.editValidate,
                                        presentationConditions: validate
                                    }));
                                }}
                                defaultInput={schoolCouponStore.edit.presentationConditions}
                                placeHolder='提示条件を記入'
                                minLength={1}
                                defaultInputValue={schoolCouponStore.edit.presentationConditions}
                                disabled={false}
                            />
                        </div>
                        <div className={styles.editInputWrap}>
                            <div className={styles.editInputLabel}>利用条件</div>
                            <TextInput
                                label=''
                                name='couponUtilizationConditions'
                                id='couponUtilizationConditions'
                                require={false}
                                inputType='text'
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=''
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(setSchoolCouponEditData({
                                        ...schoolCouponStore.edit as SchoolDetailCouponInputType,
                                        utilizationConditions: text
                                    }));
                                    dispatch(setSchoolCouponEditValidate({
                                        ...schoolCouponStore.editValidate,
                                        utilizationConditions: validate
                                    }));
                                }}
                                defaultInput={schoolCouponStore.edit.utilizationConditions}
                                placeHolder='利用条件を記入'
                                minLength={1}
                                defaultInputValue={schoolCouponStore.edit.utilizationConditions}
                                disabled={false}
                            />
                        </div>

                        <div className={styles.editInputWrap}>
                            <div className={styles.editInputLabel}>利用期限</div>
                            <DateInput
                                id='editCouponLimitDate'
                                onChangeFunc={(data) => {
                                    dispatch(setSchoolCouponEditData({
                                        ...schoolCouponStore.edit as SchoolDetailCouponInputType,
                                        limitDate: data
                                    }));
                                }}
                                onChangeValidate={(validate) => {
                                    dispatch(setSchoolCouponEditValidate({
                                        ...schoolCouponStore.editValidate,
                                        limitDate: validate
                                    }));
                                }}
                                defaultVal={schoolCouponStore.edit.limitDate}
                            />
                        </div>

                        <div className={styles.editInputWrap}>
                            <div className={styles.editInputLabel}>利用可能開始日</div>
                            <DateInput
                                id='editCouponUsableDateDate'
                                onChangeFunc={(data) => {
                                    dispatch(setSchoolCouponEditData({
                                        ...schoolCouponStore.edit as SchoolDetailCouponInputType,
                                        usableDate: data
                                    }));
                                }}
                                onChangeValidate={(validate) => {
                                    dispatch(setSchoolCouponEditValidate({
                                        ...schoolCouponStore.editValidate,
                                        usableDate: validate
                                    }));
                                }}
                                defaultVal={schoolCouponStore.edit.usableDate}
                            />
                        </div>


                        <div className={styles.editInputLabel}>メイン画像</div>
                        <div className={styles.editInputWrap}>
                            <ImageUploader
                                uiSize={ImageUploaderSize.BIG}
                                identificationTitle={null}
                                identificationText={null}
                                defaultImageUrl={schoolCouponStore.detail.mainImageUrl}
                                returnFunc={(file, deleteFlag) => {
                                    dispatch(setSchoolCouponEditData({
                                        ...schoolCouponStore.edit as SchoolDetailCouponInputType,
                                        image: file,
                                        deletingImage: deleteFlag,
                                    }));
                                }}
                            />
                        </div>

                        <div className={styles.editInputLabel}>クーポン説明</div>
                        <div className={styles.editInputWrap}>
                            <TextArea
                                label=''
                                name='editCouponTitle'
                                id='editCouponTitle'
                                require
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation={null}
                                changeReturnFunc={(text, validate) => {
                                    dispatch(setSchoolCouponEditData({
                                        ...schoolCouponStore.edit as SchoolDetailCouponInputType,
                                        description: text
                                    }));
                                    dispatch(setSchoolCouponEditValidate({
                                        ...schoolCouponStore.editValidate,
                                        description: validate
                                    }));
                                }}
                                defaultInput={schoolCouponStore.edit.description}
                                placeHolder='クーポンの説明を記入'
                                forReview={false}
                                forInquiry={false}
                                countFlag
                                maxLength={500}
                                minLength={1}
                                height={200}
                            />
                        </div>


                        <button
                            type='button'
                            className={`${styles.editInputButton} ${Object.entries(schoolStore.schoolDetailEditValidate).some(d => !d[1]) ? styles['editInputButton--disable'] : ''}`}
                            onClick={() => {
                                if (schoolStore.schoolDetailData) {
                                    dispatch(putSchoolCouponEditData(authToken, couponId.toString(), schoolCouponStore.edit as SchoolDetailCouponInputType));
                                }
                            }}>
                            {loadingSelector ? '通信中...' : '保存する'}
                        </button>
                    </div>
                )}
            </div>
            <Footer />
        </>);
    }
    return <>
        <Header />
        <OwnerApplication />
        <TabBar currentPage='/school' />
        <div className={styles.schoolContainer}>
            <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, { text: '教室名', path: '' }]} />
        </div>
        <Footer />
    </>;
};
