import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/styles.module.scss';
import { TextInput } from '../../Input/TextInput';
import { InputValidateTypeEnum } from '../../Input/TextInput/InputValidateType.enum';
import { postInviteUserInput, setInviteUserInput } from '../../../../redux/actions/domain/user/inviteUser';
import { RootState } from '../../../../redux/store';
import { RadioInput } from '../../Input/RadioInput';
import { ClientUserTypeEnum } from '../../../../redux/reducers/domain/user/enum/ClientUserType.enum';
import { GreenBtn } from '../../GreenBtn';
import { setCommonModalShow } from '../../../../redux/actions/ui';

export const AddNewUser: React.FC = () => {

    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const userSelector = useSelector((state: RootState) => state.rootReducer.domain.user);
    const dispatch = useDispatch();
    const [emailValidate, setEmailValidate] = useState(false);

    if (userSelector.inviteUser.result.flag) {
        return (
            <div className={styles.wrap}>
                <div className={styles.resultTitle}>仮登録メールが送信されました</div>
                <div className={styles.confirmation}>
                    <p className={styles.confirmation__text}>
                        <span>{userSelector.inviteUser.input.email}</span>にユーザー新規仮登録のメールを送信しました。<br />
                        メールに記載されている内容をご確認ください。
                    </p>
                    <div>
                        <div className={styles.confirmation__title}>完了メールが届かない場合</div>
                        <ul className={styles.confirmation__list}>
                            <li>・「@oriori.education」からのメールが迷惑メールフォルダに振り分けられてないかをご確認ください</li>
                            <li>・迷惑メールフォルダ内にもない場合は、<a href='/'>こちら</a>よりお問い合わせください</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.buttonWrap}>
                    <GreenBtn text='このウィンドウを閉じる' style={{}} handleClick={() => dispatch(setCommonModalShow(false))}
                        isLink={false}
                        path='' />
                </div>
            </div>);
    }

    if (!userSelector.inviteUser.input.stepSecondFlag) {
        return (
            <div className={styles.wrap}>
                <div className={styles.inner}>
                    <TextInput
                        label='メールアドレス'
                        name='inviteEmail'
                        id='inviteEmail'
                        require
                        inputType='email'
                        validateType={InputValidateTypeEnum.EMAIL}
                        annotation=''
                        changeReturnFunc={(text, validate) => {
                            dispatch(setInviteUserInput({
                                ...userSelector.inviteUser.input,
                                email: text
                            }));
                            setEmailValidate(validate);
                        }}
                        defaultInput={userSelector.inviteUser.input.email}
                        placeHolder='メールアドレスを入力'
                        minLength={0}
                        defaultInputValue={userSelector.inviteUser.input.email}
                        disabled={false}
                    />
                    <div className={styles.radioWrap}>
                        <RadioInput
                            label='アクセス権限'
                            require={false}
                            name='inviteUserTypeInput'
                            data={[
                                { id: 'inviteUserTypeInput_1', label: '管理者(すべての機能にアクセスできる)', value: 2 },
                                { id: 'inviteUserTypeInput_2', label: '一般(スクール機能アクセスのみ)', value: 3 }
                            ]}
                            changeReturnFunc={(text) => dispatch(setInviteUserInput({
                                ...userSelector.inviteUser.input,
                                type: text === '2' ? ClientUserTypeEnum.Admin : ClientUserTypeEnum.User
                            }))}
                            value={userSelector.inviteUser.input.type === ClientUserTypeEnum.Admin ? '2' : '3'}
                        />
                    </div>
                </div>
                <div className={styles.buttonWrap}>
                    <GreenBtn text='次へ' style={!emailValidate ? {
                        background: '#c4c4c4',
                        pointerEvents: 'none'
                    } : {}} handleClick={() => dispatch(setInviteUserInput({
                        ...userSelector.inviteUser.input,
                        stepSecondFlag: true
                    }))} isLink={false} path='' />
                </div>
                <div className={styles.buttonWrap}>
                    <button type='button' className={styles.cancelButton} onClick={() => {
                        dispatch(setCommonModalShow(false));
                    }}>キャンセルする
                    </button>
                </div
                >
            </div>
        );

    }
    return (
        <div className={styles.wrap}>
            <div className={styles.inner}>
                <div className={styles.title}>メールアドレス</div>
                <div className={styles.text}>{userSelector.inviteUser.input.email}</div>
                <div className={styles.radioWrap}>
                    <RadioInput
                        label='お問合せメールの受信設定'
                        require={false}
                        name='inviteEmailReceive'
                        data={[
                            { id: 'inviteEmailReceive_1', label: 'メールを受け取る（ON）', value: 1 },
                            { id: 'inviteEmailReceive_2', label: 'メールを受け取らない（OFF)', value: 2 }
                        ]}
                        changeReturnFunc={(text) => dispatch(setInviteUserInput({
                            ...userSelector.inviteUser.input,
                            emailReceive: text === '1'
                        }))}
                        value={userSelector.inviteUser.input.email ? '1' : '2'}
                    />
                    <div className={styles.description}>※お問い合わせがあった際に、メールにお知らせを送ります</div>
                </div>
            </div>
            <div className={styles.buttonWrap}>
                <GreenBtn text='新規登録' style={!emailValidate ? {
                    background: '#c4c4c4',
                    pointerEvents: 'none'
                } : {}} handleClick={() => dispatch(postInviteUserInput(authToken, {
                    ...userSelector.inviteUser.input
                }))} isLink={false} path='' />
            </div>
            <div className={styles.buttonWrap}>
                <button type='button' className={styles.cancelButton} onClick={() => {
                    dispatch(setCommonModalShow(false));
                }}>キャンセルする
                </button>
            </div
            >
        </div>
    );
};