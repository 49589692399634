import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/style.module.scss';
import closeBtn from './img/closeBtn.svg';
import { RootState } from '../../../redux/store';
import { setCommonModalShow } from '../../../redux/actions/ui';
import { CommonModalDataType } from '../../../redux/reducers/ui/type/CommonModalData.type';
import { CommonModalContentTypeEnum } from '../../../redux/reducers/ui/enum/CommonModalContentType.enum';
import { AddNewUser } from '../CommonModalContent/AddNewUser';
import { ChangeMailAddress } from '../CommonModalContent/ChangeMailAddress';
import { DisplayText } from '../CommonModalContent/DisplayText';
import { StartPaidPlan } from '../CommonModalContent/StartPaidPlan';
import { SecondSchoolConfirm } from '../CommonModalContent/SecondSchoolConfirm';

export const CommonModal: React.FC = (props) => {
    const { children } = props;
    const commonModalSelector: CommonModalDataType = useSelector((state: RootState) => state.rootReducer.ui.commonModalData);
    const dispatch = useDispatch();

    return (
        <>
            {children}
            <button
                type="button"
                className={`${styles.modalBG} ${commonModalSelector.show ? styles.modalShow : ''}`}
                onClick={() => dispatch(setCommonModalShow(false))}>
                modalBg
            </button>
            <section className={`${styles.modal} ${commonModalSelector.show ? styles.modalShow : ''}`}>
                <div className={styles.modal__header}>
                    <div className={styles.modal__headerTitle}>{commonModalSelector.title}</div>
                    <button className={styles.modal__headerClose} type="button" onClick={() => dispatch(setCommonModalShow(false))}>
                        <img src={closeBtn} alt="closeBtn" />
                    </button>
                </div>
                {commonModalSelector.contentType === CommonModalContentTypeEnum.ADD_NEW_USER && <AddNewUser />}
                {commonModalSelector.contentType === CommonModalContentTypeEnum.CHANGE_MAIL_ADDRESS && <ChangeMailAddress />}
                {commonModalSelector.contentType === CommonModalContentTypeEnum.DISPLAY_TEXT && <DisplayText />}
                {commonModalSelector.contentType === CommonModalContentTypeEnum.START_PAID_PLAN && <StartPaidPlan />}
                {commonModalSelector.contentType === CommonModalContentTypeEnum.SECOND_SCHOOL_CONFIRM && <SecondSchoolConfirm />}
            </section>
        </>
    );
};
