import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../asset/css/accountCommon.module.scss';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { RootState } from '../../../redux/store';
import { PasswordReissueOfferType } from '../../../redux/reducers/domain/client/type/passwordReissueOffer.type';
import {
    postPasswordReissueOfferEmail,
    resetPasswordReissueOffer,
    setPasswordReissueOfferEmail,
    setPasswordReissueOfferEmailValidate,
} from '../../../redux/actions/domain/client/password';
import mainImg from './img/main.png';

export const PasswordReissue: React.FC = () => {
    const passwordReissueSelector = (state: RootState) => state.rootReducer.domain.client.passwordReissueOffer;
    const passwordReissueState: PasswordReissueOfferType = useSelector(passwordReissueSelector);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // リセット
        dispatch(resetPasswordReissueOffer());
    }, [dispatch, history]);

    if (passwordReissueState.result.flag) {
        return (
            <>
                <Header />
                <div className={styles.authContainer}>
                    <h1>パスワード再発行メールが送信されました</h1>
                    <div className={styles.confirmation}>
                        <p className={styles.confirmation__text}>
                            <span>{passwordReissueState.result.sendEmail}</span>に再発行のメールを送信しました。
                            <br />
                            メールをご確認ください。
                        </p>
                        <div className={styles.confirmation__sub}>
                            <div className={styles.confirmation__subImage}>
                                <img src={mainImg} alt="" />
                            </div>
                            <div className={styles.confirmation__subText}>
                                <div className={styles.confirmation__title}>再発行メールが届かない場合</div>
                                <ul className={styles.confirmation__list}>
                                    <li>・「@oriori.education」からのメールが迷惑メールフォルダに振り分けられてないかをご確認ください</li>
                                    <li>
                                        ・迷惑メールフォルダ内にもない場合は、<a href="/">こちら</a>よりお問い合わせください
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <div className={styles.authContainer}>
                <h1>パスワード再発行</h1>
                <div className={styles.grayBg}>
                    <TextInput
                        label="ご登録のメールアドレス"
                        name="email"
                        id="email"
                        require
                        inputType="text"
                        validateType={InputValidateTypeEnum.EMAIL}
                        annotation=""
                        changeReturnFunc={(text, validate) => {
                            dispatch(setPasswordReissueOfferEmail(text));
                            dispatch(setPasswordReissueOfferEmailValidate(validate));
                        }}
                        defaultInput=""
                        placeHolder="メールアドレスを入力"
                        minLength={0}
                        defaultInputValue={passwordReissueState.email}
                        disabled={false}
                    />
                    <ul className={styles.grayBg__list}>
                        <li>１.ご登録のメールアドレスに再発行メールをお送りします。</li>
                        <li>２.再発行メールに記載されたURLから再発行の手続きしてください。</li>
                    </ul>
                </div>

                <GreenBtn
                    text={loadingSelector ? '送信中...' : '再発行メールを送信'}
                    style={null}
                    handleClick={() => {
                        if (passwordReissueState.emailValidate) {
                            dispatch(postPasswordReissueOfferEmail(passwordReissueState.email));
                        }
                    }}
                    isLink={false}
                    path=""
                />
                <div>{passwordReissueState.result.reason}</div>

                <div className={styles.links}>
                    <div className={styles.link}>
                        <Link to="/">ログインページへ</Link>
                    </div>
                    <div className={styles.link}>
                        <Link to="#">お問い合わせ</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
