import { ClientInputType } from '../type/clientInput.type';

export const clientInputInitial: ClientInputType = {
    clientType: null,
    name: '',
    representativeName: '',
    phone: '',
    address: '',
    clientTypeValidate: false,
    nameValidate: false,
    representativeNameValidate: false,
    phoneValidate: false,
    addressValidate: false,
    result: {
        flag: false,
        reason: '',
    },
};
