import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from '../../../asset/css/accountCommon.module.scss';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { WhiteBtn } from '../../../components/Common/WhiteBtn';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { RootState } from '../../../redux/store';
import { PasswordRegisterType } from '../../../redux/reducers/domain/client/type/passwordRegisterType';
import {
    postPasswordRegister,
    setPasswordRegisterCode,
    setPasswordRegisterPassword,
    setPasswordRegisterPasswordConfirm,
    setPasswordRegisterPasswordConfirmValidate,
    setPasswordRegisterPasswordValidate,
} from '../../../redux/actions/domain/client/password';
import completeMainImg from './img/complete_main.png';
import failedMainImg from './img/main_failed.png';

// パスワード再発行メール完了画面への切り替えは、内部的に行う
export const PasswordRegister: React.FC = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const token = query.get('token');

    const passwordRegisterSelector = (state: RootState) => state.rootReducer.domain.client.passwordRegister;
    const passwordReissueState: PasswordRegisterType = useSelector(passwordRegisterSelector);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setPasswordRegisterCode(token || ''));
    }, [dispatch, history, token]);

    if (!token) {
        return (
            <>
                <Header />
                <div className={styles.authContainer}>
                    <h1>再発行メールの有効期限がきれています</h1>
                    <div className={styles.confirmation}>
                        <div className={styles.confirmation__conversation}>Sorry..</div>
                        <p className={styles.confirmation__text}>
                            パスワード再発行メールの有効期限がきれています。
                            <br />
                            再度、再発行メールの送信をしてください
                        </p>
                        <div className={styles.registerFailedImg}>
                            <img src={failedMainImg} alt="" />
                        </div>
                        <WhiteBtn style={null} text="再発行メール送信へ" handleClick={null} isLink path="/password_reissue" />
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    if (passwordReissueState.result.flag) {
        return (
            <>
                <Header />
                <div className={styles.authContainer}>
                    <h1>パスワードが設定されました！</h1>
                    <div className={styles['confirmation--register']}>
                        <p className={styles.confirmation__text}>
                            新しいパスワードが設定されました！
                            <br />
                            ログイン画面にいき、新しいパスワードでログインしてください。
                        </p>
                        <div className={styles.registerImg}>
                            <img src={completeMainImg} alt="" />
                        </div>
                    </div>
                    <GreenBtn style={null} text="ログインへ" handleClick={null} isLink path="/" />
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <div className={styles.authContainer}>
                <h1>パスワード再発行</h1>
                <div className={styles.grayBg}>
                    <TextInput
                        label="新しいパスワード"
                        name="password"
                        id="password"
                        require
                        inputType="password"
                        validateType={InputValidateTypeEnum.PASSWORD}
                        annotation="※ 英字、数字、記号をすべて含む8桁以上で入力してください"
                        changeReturnFunc={(text, validate) => {
                            dispatch(setPasswordRegisterPassword(text));
                            dispatch(setPasswordRegisterPasswordValidate(validate));
                        }}
                        defaultInput=""
                        placeHolder="メールアドレスを入力"
                        minLength={0}
                        defaultInputValue={passwordReissueState.password}
                        disabled={false}
                    />

                    <TextInput
                        label="新しいパスワード確認"
                        name="passwordConfirm"
                        id="passwordConfirm"
                        require
                        inputType="password"
                        validateType={InputValidateTypeEnum.PASSWORD}
                        annotation="※ 英字、数字、記号をすべて含む8桁以上で入力してください"
                        changeReturnFunc={(text, validate) => {
                            dispatch(setPasswordRegisterPasswordConfirm(text));
                            dispatch(setPasswordRegisterPasswordConfirmValidate(validate));
                        }}
                        defaultInput=""
                        placeHolder="メールアドレスを入力"
                        minLength={0}
                        defaultInputValue={passwordReissueState.passwordConfirm}
                        disabled={false}
                    />
                </div>
                {passwordReissueState.result.reason !== '' && <div>{passwordReissueState.result.reason}</div>}

                <GreenBtn
                    text="パスワードを設定"
                    style={
                        !(passwordReissueState.passwordValidate && passwordReissueState.passwordConfirmValidate)
                            ? { pointerEvents: 'none', opacity: 0.3 }
                            : {}
                    }
                    handleClick={() => {
                        dispatch(
                            postPasswordRegister({
                                token: passwordReissueState.registerCode,
                                password: passwordReissueState.password,
                                passwordConfirm: passwordReissueState.passwordConfirm,
                            })
                        );
                    }}
                    isLink={false}
                    path=""
                />
                <div className={styles.links}>
                    <div className={styles.link}>
                        <Link to="#">お問い合わせ</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
