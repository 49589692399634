import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';

export const OwnerApplication: React.FC = () => (
    <>
        <div className={styles.ownerApplication}>
                oriori内に既に掲載されている教室・スクール情報のオーナー申請は
            <Link to="/settings">こちら</Link>
        </div>
    </>
)