import { ContractsActionTypes } from '../../../actions/domain/contracts';
import { SettlementsType } from './type/settlements.type';
import { settlementsInitial } from './InitialState/settlementsInitial';

export const settlements = (state = settlementsInitial, action: any): SettlementsType => {
    switch (action.type) {
        case ContractsActionTypes.SET_SETTLEMENT_LIST:
            return {
                ...state,
                data: action.payload.settlements,
            };
        case ContractsActionTypes.SET_SETTLEMENT_LIST_COUNT:
            return {
                ...state,
                count: action.payload.settlementsCount,
            };
        case ContractsActionTypes.SET_SETTLEMENT_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload.currentPage,
            };
        default:
            return state;
    }
};
