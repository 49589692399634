import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';
import { SchoolCouponData } from '../../../util/type/School/SchoolCouponData.type';
import { SchoolCardControlList } from '../SchoolCardControlList';
import {
    patchSchoolCouponPublish,
    patchSchoolCouponUnPublish,
} from '../../../redux/actions/domain/schools/schoolCoupon';

type SchoolCouponCardProps = {
    authToken: string,
    index: number,
    listLength: number,
    schoolId: string;
    schoolCouponData: SchoolCouponData ;
};

export const SchoolCouponCard: React.FC<SchoolCouponCardProps> = (props: SchoolCouponCardProps) => {
    const {authToken, index, listLength, schoolId,schoolCouponData} = props;
    const [menuButtonData, setMenuButtonData] = useState<Array<{
        id: string,
        label: string,
        returnFunc: () => void,
    }>>([])
    const dispatch = useDispatch();

    useEffect(() => {
        const menuButtonData =[];
        if (index + 1 !== 1) {
            menuButtonData.push({
                id: `${schoolCouponData.id}_SchoolCouponCard_menu_button_up`,
                label: `１つ上に移動`,
                returnFunc: () => {
                    // dispatch(putSchoolCouponEditData(authToken, ))
                }
            })
        }

        if (index + 1 !== listLength) {
            menuButtonData.push({
                id: `${schoolCouponData.id}_SchoolCouponCard_menu_button_down`,
                label: `１つ下に移動`,
                returnFunc: () => {
                    // dispatch(putSchoolCouponEditData(authToken, ))
                }
            })
        }

        menuButtonData.push({
            id: `${schoolCouponData.id}_SchoolCouponCard_menu_button_change`,
            label: schoolCouponData.showStatus ? '非公開' : '公開',
            returnFunc: () => {
                if (schoolCouponData.showStatus) {
                    dispatch(patchSchoolCouponUnPublish(authToken, schoolId, schoolCouponData.id.toString(), true ))
                } else {
                    dispatch(patchSchoolCouponPublish(authToken, schoolId, schoolCouponData.id.toString(), true ))
                }
            }
        })

        setMenuButtonData([...menuButtonData]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolCouponData]);


    return(
        <div className={styles.wrap}>
            <Link to={`/school/${schoolId}/coupon/${schoolCouponData.id}`} className={styles.schoolCoupon}>
                <div className={styles.schoolCoupon__head}>
                    {schoolCouponData.showStatus ?
                        <div className={styles.schoolCoupon__status}>公開中</div>
                        :
                        <div className={`${styles.schoolCoupon__status} ${styles["schoolCoupon__status--hidden"]}`}>非公開</div>
                    }
                    <div className={styles.schoolCoupon__title} >
                        {schoolCouponData.name}
                    </div>
                </div>
                <div className={styles.schoolCoupon__info}>提示条件：{schoolCouponData.presentationConditions}</div>
                <div className={styles.schoolCoupon__info}>利用条件：{schoolCouponData.utilizationConditions}</div>
                {/* <div className={styles.schoolCoupon__info}>お問い合わせ回数：２回</div> */}

            </Link>
            <SchoolCardControlList
                inputList={menuButtonData}
            />
        </div>
    )}