import { schoolDetailBlogInputInitial } from './schoolDetailBlogInputInitial';
import { schoolDetailBlogValidateInitial } from './schoolDetailBlogValidateInitial';
import { SchoolDetailBlogType } from '../../type/Blog/SchoolDetailBlog.type';

export const schoolDetailBlogInitial: SchoolDetailBlogType = {
    list: null,
    listCount: 0,
    detail:  null,
    new: schoolDetailBlogInputInitial,
    newValidate: schoolDetailBlogValidateInitial,
    editFlag: false,
    edit: null,
    editValidate: schoolDetailBlogValidateInitial,
}