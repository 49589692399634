import { Action } from 'redux';
import { SettingsActions } from './index';

export const postSchoolAuthUrl = (authToken: string, schoolUrl: string): PostSchoolAuthUrl => ({
    type: SettingsActions.POST_SCHOOL_AUTH_URL,
    payload: { schoolUrl, authToken }
});

export interface PostSchoolAuthUrl extends Action {
    type: string;
    payload: {
        schoolUrl: string,
        authToken: string,
    };
}
