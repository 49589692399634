import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import styles from '../../../asset/css/accountCommon.module.scss';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { WhiteBtn } from '../../../components/Common/WhiteBtn';
import { SignUpRegisterType } from '../../../redux/reducers/domain/client/type/signUpRegister.type';
import { postSignUpRegisterCode } from '../../../redux/actions/domain/client/signUp';
import completeMainImg from './img/complete_main.png';
import failedMainImg from './img/main_failed.png';

// クエリの有無、メール認証の結果にて、表示する画面構成を変更する
export const Register: React.FC = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const signUpRegisterSelector = (state: RootState) => state.rootReducer.domain.client.signUpRegister;
    const signUpRegisterState: SignUpRegisterType = useSelector(signUpRegisterSelector);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        const registerCode = query.get('register_code');
        dispatch(postSignUpRegisterCode(registerCode ? registerCode.toString() : ''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loadingSelector) {
        return <>ロード中</>;
    }

    if (signUpRegisterState.success) {
        return (
            <>
                <Header />
                <div className={styles.authContainer}>
                    <h1>メールアドレスの認証が完了しました</h1>
                    <div className={styles.confirmation}>
                        <div className={styles.confirmation__conversation}>Thank you !!</div>
                        <p>ログインをして、あなたの習い事情報を完成させてください。</p>
                        <div className={styles.completeMainImg}>
                            <img src={completeMainImg} alt="" />
                        </div>
                        <GreenBtn style={null} text="ログイン" handleClick={null} isLink path="/" />
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <div className={styles.authContainer}>
                <h1>このメールアドレスの認証ができません</h1>
                <div className={styles.confirmation}>
                    <div className={styles.confirmation__conversation}>Sorry..</div>
                    <p className={styles.confirmation__text}>メールアドレスの認証ができません。下記の方法をお試しください。</p>
                    <div className={styles.confirmation__sub}>
                        <div className={styles.confirmation__subImageFailed}>
                            <img src={failedMainImg} alt="" />
                        </div>
                        <ul className={styles.confirmation__emphasisList}>
                            <li>
                                既に認証済みの可能性があります
                                <br />
                                ログイン画面で、ログインをお試しください
                                <WhiteBtn style={null} text="ログインへ" handleClick={null} isLink path="/" />
                            </li>
                            <li>
                                認証メールの有効期限がきれている可能性があります
                                <br />
                                新規登録画面で、再度、新規登録をしてください
                                <WhiteBtn style={null} text="新規登録へ" handleClick={null} isLink path="/signup" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
