import React, { useEffect, useState } from 'react';
import styles from './css/style.module.scss';
import { SelectInput } from '../../Common/Input/SelectInput';
import { targetAgeMaster } from '../../../util/const/Schools/targetAgeMaster';

type TargetAgeInputProps = {
    defaultValue: Array<number>,
    returnFunc: (data: Array<number>) => void,
}

export const TargetAgeInput: React.FC<TargetAgeInputProps> = (props: TargetAgeInputProps) => {
    const { defaultValue, returnFunc } = props;

    const [fromSelectVal, setFromSelectVal] = useState(defaultValue.length > 0 ? Math.min(...defaultValue) : null);
    const [toSelectVal, setToSelectVal] = useState(defaultValue.length > 0 ? Math.max(...defaultValue) : null);

    useEffect(() => {
        // 最小値と最大値で埋める
        const returnVal: Array<number> = targetAgeMaster.map(d => {
            if (fromSelectVal !== null && Number(d.value) < Number(fromSelectVal)) {
                return null;
            }
            if (toSelectVal !== null && Number(d.value) > Number(toSelectVal)) {
                return null;
            }
            return Number(d.value);
        }).filter<number>((d): d is number => d !== null)
        returnFunc(returnVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromSelectVal, toSelectVal]);

    return (<>
        <div className={styles.label}>対象年齢</div>
        <div className={styles.inputWrap}>
            <div className={styles.input}>
                <SelectInput
                    label=''
                    require={false}
                    name='targetAgeFrom'
                    id='targetAgeFrom'
                    data={targetAgeMaster}
                    changeReturnFunc={(text) => {
                        setFromSelectVal(Number(text));
                    }}
                    defaultInput={fromSelectVal ? fromSelectVal.toString() : null}
                    disableSelectText='下限'
                    disable={false}
                    selectStartIndex={null}
                    selectEndIndex={toSelectVal ? toSelectVal - 1 : null}
                />
            </div>
            <div className={styles.inputCenter}>ー</div>
            <div className={styles.input}>
                <SelectInput
                    label=''
                    require={false}
                    name='targetAgeTo'
                    id='targetAgeTo'
                    data={targetAgeMaster}
                    changeReturnFunc={(text) => {
                        setToSelectVal(Number(text));
                    }}
                    defaultInput={toSelectVal ? toSelectVal.toString() : null}
                    disableSelectText='上限'
                    disable={false}
                    selectStartIndex={fromSelectVal ? fromSelectVal - 1 : null}
                    selectEndIndex={null}
                />
            </div>
        </div>
    </>);
};