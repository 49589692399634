import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getClient } from '../../redux/actions/domain/client/client';
import styles from '../../asset/css/myPageCommon.module.scss';
import ContractsStyles from './css/styles.module.scss';
import { Header } from '../../components/Common/Header';
import { Footer } from '../../components/Common/Footer';
import { OwnerApplication } from '../../components/MyPage/OwnerApplication';
import { TabBar } from '../../components/MyPage/TabBar';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import { PaymentHistoryTable } from '../../components/MyPage/PaymentHistoryTable';
import { DataList } from '../../components/Common/DataList';
import { ClientType } from '../../redux/reducers/domain/client/enum/clientType.enum';
import { SchoolH3 } from '../../components/Schools/SchoolH3';
import { InputStripeCreditCard } from '../../components/Common/InputStripeCreditCard/index';
import { stripePromise } from '../../util/helper/stipe';
import { getSettlements } from '../../redux/actions/domain/contracts/settlement';
import { postPaymentMethodToStripe, postPaymentSetup } from '../../redux/actions/domain/payment/schoolSubscription';

export const Contracts: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const dispatch = useDispatch();
    const history = useHistory();
    const clientSelector = useSelector((state: RootState) => state.rootReducer.domain.client);
    const paymentSelector = useSelector((store: RootState) => store.rootReducer.domain.payment);
    const contractsSelector = useSelector((store: RootState) => store.rootReducer.domain.contracts);
    const [editFlag, setEditFlag] = useState(false);

    useEffect(() => {
        // client情報の取得
        dispatch(getClient(authToken));
        // stripe連携用意
        dispatch(postPaymentSetup(authToken));
        // 決済履歴取得
        dispatch(getSettlements(authToken, 0, 10));
    }, [authToken, dispatch, history]);

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/contracts" />
            <div className={styles.myPageContainer}>
                <BreadCrumb
                    data={[
                        { text: 'マイページ', path: '/mypage' },
                        { text: 'お支払い情報', path: '/contracts' },
                    ]}
                />
                <h2>お支払い履歴</h2>
                <PaymentHistoryTable data={contractsSelector.settlements.data} />
                <Link className={ContractsStyles.contractMore} to="/payment_history">
                    お支払い履歴をすべて見る
                </Link>
                {clientSelector.clientData && (
                    <>
                        <h2>ご請求先の情報</h2>
                        <DataList
                            data={
                                clientSelector.clientData.type === ClientType.Enterprise
                                    ? [
                                        {
                                            title: '氏名',
                                            text: clientSelector.clientData.name,
                                        },
                                        {
                                            title: '会社名',
                                            text: clientSelector.clientData.representativeName,
                                        },
                                        {
                                            title: '電話番号',
                                            text: clientSelector.clientData.phone,
                                        },
                                        // {
                                        //     title: 'ご請求先メールアドレス',
                                        //     text: clientSelector.clientData.email,
                                        // },
                                        {
                                            title: '住所',
                                            text: clientSelector.clientData.addressDetail,
                                        },
                                    ]
                                    : [
                                        {
                                            title: '氏名',
                                            text: clientSelector.clientData.name,
                                        },
                                        {
                                            title: '電話番号',
                                            text: clientSelector.clientData.phone,
                                        },
                                        // {
                                        //     title: 'ご請求先メールアドレス',
                                        //     text: clientSelector.clientData.email,
                                        // },
                                        {
                                            title: '住所',
                                            text: clientSelector.clientData.addressDetail,
                                        },
                                    ]
                            }
                            id="contract_client_data"
                            longFlag
                        />
                    </>
                )}
                <div className={ContractsStyles.contractInfo}>
                    ※ご請求情報の変更は
                    <Link to="/settings">アカウント情報</Link>
                    を編集することで自動的に変更されます
                </div>
                {paymentSelector.schoolSubscription.paymentSecret &&
                    clientSelector.clientData?.paymentMethodId &&
                    clientSelector.clientData?.cardLast4 &&
                    clientSelector.clientData?.expYear &&
                    clientSelector.clientData?.expMonth && (
                    <div className={ContractsStyles.contractCreditCard}>
                        <SchoolH3
                            otherEditPlace={null}
                            text="お支払い方法"
                            editable={!editFlag}
                            ButtonFunc={() => setEditFlag(!editFlag)}
                            isShow={null}
                        />
                        {!editFlag && (
                            <DataList
                                data={[
                                    {
                                        title: 'クレジットカード番号',
                                        text: `xxxx-xxxx-xxxx-${clientSelector.clientData.cardLast4}`,
                                    },
                                    {
                                        title: '有効期限',
                                        text: `${clientSelector.clientData.expYear}/${clientSelector.clientData.expMonth}`,
                                    },
                                ]}
                                id="contract_creditCard_data"
                                longFlag
                            />
                        )}
                        <div className={!editFlag ? ContractsStyles.disable : ''}>
                            <Elements
                                stripe={stripePromise}
                                options={{
                                    clientSecret: paymentSelector.schoolSubscription.paymentSecret,
                                    locale: 'ja',
                                }}>
                                {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                                <StripeCardInput editFlagFunc={setEditFlag} />
                            </Elements>
                        </div>
                        <div className={ContractsStyles.contractInfo}>
                                ※クレジットカードでのお支払いは、ストライプの決済代行サービスを利用いたします
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

// stripeの使用上、別コンポーネントとする
const StripeCardInput: React.FC<{ editFlagFunc: (flag: boolean) => void }> = (props) => {
    const { editFlagFunc } = props;

    const stripe = useStripe();
    const stripeElements = useElements();

    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const paymentSelector = useSelector((store: RootState) => store.rootReducer.domain.payment);
    const dispatch = useDispatch();

    return (
        <InputStripeCreditCard
            submitButtonStr="保存する"
            cancelButtonStr=""
            submitButtonFunc={() => {
                // カード情報登録apiへpost
                if (paymentSelector.schoolSubscription.cardNumberElement && stripe && stripeElements) {
                    dispatch(
                        postPaymentMethodToStripe(
                            stripe,
                            stripeElements,
                            paymentSelector.schoolSubscription.paymentSecret,
                            paymentSelector.schoolSubscription.cardNumberElement,
                            authToken,
                            false,
                            null,
                            null
                        )
                    );

                    // editフラグ戻す
                    editFlagFunc(false);
                    // TODO 表示用カード情報更新 (api未実装)
                }
            }}
            cancelButtonFunc={() => null}
            contractsFlag
        />
    );
};
