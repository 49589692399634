import React from 'react';
import styles from './css/style.module.scss';

type SchoolH3Props = {
    text: string;
    editable: boolean,
    ButtonFunc: null |(() => void),
    otherEditPlace:{
        text:string;
        path:string;
    } | null;
    isShow: boolean | null;
};

export const SchoolH3: React.FC<SchoolH3Props> = (props: SchoolH3Props) => {
    const { text, editable, ButtonFunc,otherEditPlace, isShow } = props;

    return (
        <>
            <div className={styles.schoolH3}>
                <div className={styles.schoolH3__inner}>
                    {text}
                    {isShow !== null && 
                <div className={`${styles.schoolH3__status} ${!isShow ? styles["schoolH3__status--hidden"] : ""}`}>{ isShow ? "公開中" :"非公開"}</div>
                    }
                </div>
                {ButtonFunc !== null &&
                    <button type='button' onClick={ButtonFunc} >{editable ? '編集する' : 'キャンセル'}</button>
                }
                {otherEditPlace && 
                    <div className={styles.schoolH3__note}>※このエリアの情報は、<a href={otherEditPlace.path}>{otherEditPlace.text}</a>から編集できます</div>
                }
            </div>
        </>
    );
};