import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';


type TabBarProps = {
    currentPage:string;
};

export const TabBar: React.FC<TabBarProps> = (props: TabBarProps) => {
    const {currentPage} = props;
    const pageList =[
        {
            text:"マイページ",
            path:"/mypage"
        },
        {
            text:"教室一覧",
            path:"/school"
        },
        {
            text:"お問い合わせ一覧",
            path:"/inquiry"
        },
        {
            text:"契約・お支払い",
            path:"/contracts"
        },
        {
            text:"チーム管理",
            path:"/team"
        },
        {
            text:"設定",
            path:"/settings"
        },
    ]
    return(
        <>
            <nav className={styles.tabBar}>
                <ul className={styles.tabBar__list}>
                    {pageList.map((data)=>
                        <li key={data.text} className={`${styles.tabBar__item} ${currentPage === data.path && styles["tabBar__item--active"]}`}>
                            <Link to={data.path}>
                                {data.text}
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>
        </>
    )}