import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SchoolsActionTypes } from '../../redux/actions/domain/schools';
import {
    patchAllSchoolPublish,
    patchAllSchoolUnPublish,
    patchSchoolPublish,
    patchSchoolUnPublish,
    putSchoolDetailEditData,
    setSchoolDetailEditData,
} from '../../redux/actions/domain/schools/schoolIndexEdit';
import { SchoolsApi } from '../../util/api/schools';
import { getMySchools } from '../../redux/actions/domain/mypage/schools';
import { getSchoolDetail, resetEditFlag, setSchoolDetail } from '../../redux/actions/domain/schools/schoolDetail';
import { checkNewSchoolModalCheck, postNewSchool, setNewSchoolResult } from '../../redux/actions/domain/schools/newSchool';
import { SchoolDetail } from '../../util/type/SchoolDetail.type';
import { numberScheduleToString } from '../../util/helper/numberScheduleToString';
import { schoolDataToCreateSchoolData } from '../../redux/reducers/domain/schools/converter/schoolDataToCreateSchoolData';
import { RootState } from '../../redux/store';
import { MyPageApi } from '../../util/api/mypage';
import { setCommonModalContentType, setCommonModalShow, setCommonModalTitle } from '../../redux/actions/ui';
import { CommonModalContentTypeEnum } from '../../redux/reducers/ui/enum/CommonModalContentType.enum';

export const getMyPageState = (state: RootState) => state.rootReducer.domain.myPage;

// スクール新規登録
function* postCreateSchool({ payload }: ReturnType<typeof postNewSchool>) {
    try {
        const res: AxiosResponse<SchoolDetail> = yield call(SchoolsApi.postCreateSchool, payload.token, payload.data);
        yield put(setNewSchoolResult(res.data));
        yield put(push('/school/new_result'));
    } catch (e) {
        // TODO エラーハンドリング
    }
}

export function* postCreateSchoolAsync() {
    yield takeEvery(SchoolsActionTypes.POST_NEW_SCHOOL, postCreateSchool);
}

// スクール詳細取得
function* fetchSchool({ payload }: ReturnType<typeof getSchoolDetail>) {
    try {
        const res: AxiosResponse<SchoolDetail> = yield call(SchoolsApi.getSchool, payload.token, payload.schoolId);
        // スケジュールの時間を文字列にする
        const schoolData: SchoolDetail = res.data;
        schoolData.scheduleWeekSchedule = numberScheduleToString(schoolData.scheduleWeekSchedule);
        yield put(setSchoolDetail(schoolData));
        yield put(setSchoolDetailEditData(schoolDataToCreateSchoolData(schoolData)));
    } catch (e) {
        // TODO 404
        yield put(setSchoolDetail(null));
    }
}

export function* getSchoolAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_DETAIL, fetchSchool);
}

// スクール情報更新
function* putSchool({ payload }: ReturnType<typeof putSchoolDetailEditData>) {
    try {
        const { editData } = payload;
        editData.scheduleWeekSchedule = editData.scheduleWeekSchedule.map((s) => {
            if (s.from !== '0:0' && s.to !== '0:0') return s;
            const returnData = s;
            if (s.from === '0:0') returnData.from = '01:00';
            if (s.to === '0:0') returnData.to = '23:30';
            return { ...s };
        });

        yield call(SchoolsApi.putSchool, payload.authToken, payload.schoolId, editData, payload.images);
        // レスポンスは使用しない 最新取得
        yield put(getSchoolDetail(payload.authToken, Number(payload.schoolId)));
        yield put(resetEditFlag());
    } catch (e) {
        yield put(setSchoolDetail(null));
    }
}

export function* putSchoolAsync() {
    yield takeEvery(SchoolsActionTypes.PUT_SCHOOL_DETAIL_EDIT_DATA, putSchool);
}

// スクール公開
function* patchSchoolDetailPublish({ payload }: ReturnType<typeof patchSchoolPublish>) {
    try {
        yield call(SchoolsApi.patchPublishSchool, payload.authToken, payload.schoolId);
        // レスポンスは使用しない 最新取得
        if (payload.inList && payload.page !== null && payload.count !== null) {
            yield put(getMySchools(payload.authToken, payload.count, payload.page));
        } else {
            yield put(getSchoolDetail(payload.authToken, Number(payload.schoolId)));
            yield put(resetEditFlag());
        }
    } catch (e) {
        yield put(setSchoolDetail(null));
    }
}

export function* patchSchoolPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_SCHOOL_PUBLISH, patchSchoolDetailPublish);
}

// スクール複数公開
function* patchAllSchoolDetailPublish({ payload }: ReturnType<typeof patchAllSchoolPublish>) {
    try {
        yield all(payload.schoolIds.map((schoolId) => call(SchoolsApi.patchPublishSchool, payload.authToken, schoolId)));

        // レスポンスは使用しない 最新取得
        if (payload.inList && payload.page !== null && payload.count !== null) {
            yield put(getMySchools(payload.authToken, payload.count, payload.page));
        } else {
            yield put(resetEditFlag());
        }
    } catch (e) {
        yield put(setSchoolDetail(null));
    }
}

export function* patchAllSchoolPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_ALL_SCHOOLS_PUBLISH, patchAllSchoolDetailPublish);
}

// スクール非公開
function* patchSchoolDetailUnPublish({ payload }: ReturnType<typeof patchSchoolUnPublish>) {
    try {
        yield call(SchoolsApi.patchUnPublishSchool, payload.authToken, payload.schoolId);
        // レスポンスは使用しない 最新取得
        if (payload.inList && payload.page !== null && payload.count !== null) {
            yield put(getMySchools(payload.authToken, payload.count, payload.page));
        } else {
            yield put(getSchoolDetail(payload.authToken, Number(payload.schoolId)));
            yield put(resetEditFlag());
        }
    } catch (e) {
        yield put(setSchoolDetail(null));
    }
}

export function* patchSchoolUnPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_SCHOOL_UN_PUBLISH, patchSchoolDetailUnPublish);
}

// スクール複数非公開
function* patchAllSchoolDetailUnPublish({ payload }: ReturnType<typeof patchAllSchoolUnPublish>) {
    try {
        yield all(payload.schoolIds.map((schoolId) => call(SchoolsApi.patchUnPublishSchool, payload.authToken, schoolId)));

        // レスポンスは使用しない 最新取得
        if (payload.inList && payload.page !== null && payload.count !== null) {
            yield put(getMySchools(payload.authToken, payload.count, payload.page));
        } else {
            yield put(resetEditFlag());
        }
    } catch (e) {
        yield put(setSchoolDetail(null));
    }
}

export function* patchAllSchoolUnPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_ALL_SCHOOLS_UN_PUBLISH, patchAllSchoolDetailUnPublish);
}

// スクール新規作成 控除確認
function* checkNewSchool({ payload }: ReturnType<typeof checkNewSchoolModalCheck>) {
    try {
        const res: AxiosResponse<{
            data: Array<SchoolDetail>;
            count: number;
        }> = yield call(MyPageApi.getClientSchools, payload.authToken, 1, 0);
        // モーダル表示
        if (res.data.count === 1) {
            yield put(setCommonModalShow(true));
            yield put(setCommonModalTitle('有料お問い合わせ機能の控除対象から外れます'));
            yield put(setCommonModalContentType(CommonModalContentTypeEnum.SECOND_SCHOOL_CONFIRM));
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
}

export function* checkNewSchoolModalCheckAsync() {
    yield takeEvery(SchoolsActionTypes.CHECK_NEW_SCHOOL_MODAL_CHECK, checkNewSchool);
}
