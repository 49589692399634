import { AxiosResponse } from 'axios';
import { axiosBase } from './index';
import { SettlementData } from '../type/School/SettlementData.type';

export class ContractsApi {
    // 決済履歴取得
    static async getSettlements(
        token: string,
        limit: number,
        page: number
    ): Promise<
        AxiosResponse<{
            data: Array<SettlementData>;
            count: number;
        }>
        > {
        return axiosBase.get('/client/settlement', {
            params: {
                limit,
                page,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
