import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getClient } from '../../redux/actions/domain/client/client';
import { Header } from '../../components/Common/Header';
import { OwnerApplication } from '../../components/MyPage/OwnerApplication';
import { Footer } from '../../components/Common/Footer';
import { TabBar } from '../../components/MyPage/TabBar';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import styles from '../../asset/css/myPageCommon.module.scss';
import { SchoolH3 } from '../../components/Schools/SchoolH3';
import { putClientEdit, setClientEditFlag, setClientEditInput, setClientEditValidate } from '../../redux/actions/domain/settings/editClient';
import { editClientValidateInitial } from '../../redux/reducers/domain/settings/InitialState/editClientValidateInitial';
import { clientToEditClient } from '../../redux/reducers/domain/settings/converter/clientToEditClient';
import { DataList } from '../../components/Common/DataList';
import { ClientType } from '../../redux/reducers/domain/client/enum/clientType.enum';
import inputStyles from '../../asset/css/newSchool.module.scss';
import { InputValidateTypeEnum } from '../../components/Common/Input/TextInput/InputValidateType.enum';
import { TextInput } from '../../components/Common/Input/TextInput';
import { GreenBtn } from '../../components/Common/GreenBtn';
import exsample from './img/exsample.png';
import ng from './img/ng.png';
import human from './img/human.png';
import cats from './img/cats.png';
import { postSchoolAuthUrl } from '../../redux/actions/domain/settings/schoolAuth';

export const SettingsPage: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const clientSelector = useSelector((state: RootState) => state.rootReducer.domain.client);
    const settingsSelector = useSelector((state: RootState) => state.rootReducer.domain.settings);

    const dispatch = useDispatch();

    const [schoolAuthUrlInput, setSchoolAuthUrlInput] = useState('');
    const [schoolAuthUrlValidate, setSchoolAuthUrlValidate] = useState(false);

    useEffect(() => {
        // client情報の取得
        dispatch(getClient(authToken));
    }, [authToken, dispatch]);

    if (clientSelector.clientData) {
        return (
            <>
                <Header />
                <OwnerApplication />
                <TabBar currentPage="/settings" />
                <div className={styles.myPageContainer}>
                    <BreadCrumb
                        data={[
                            { text: 'マイページ', path: '/mypage' },
                            { text: '設定', path: '' },
                        ]}
                    />
                    <SchoolH3
                        otherEditPlace={null}
                        text="アカウント情報"
                        editable={!settingsSelector.EditClient.editFlag}
                        ButtonFunc={() => {
                            if (!settingsSelector.EditClient.editFlag) {
                                dispatch(setClientEditFlag(true));
                                dispatch(setClientEditInput(clientToEditClient(clientSelector.clientData)));
                                dispatch(setClientEditValidate(editClientValidateInitial));
                            } else {
                                dispatch(setClientEditFlag(false));
                                dispatch(getClient(authToken));
                            }
                        }}
                        isShow={null}
                    />
                    {!settingsSelector.EditClient.editFlag ? (
                        <DataList
                            data={
                                clientSelector.clientData.type === ClientType.Enterprise
                                    ? [
                                        {
                                            title: '種別',
                                            text: '法人',
                                        },
                                        {
                                            title: '法人名',
                                            text: clientSelector.clientData.representativeName,
                                        },
                                        {
                                            title: 'ご担当者名',
                                            text: clientSelector.clientData.name,
                                        },
                                        {
                                            title: 'お電話番号',
                                            text: clientSelector.clientData.phone,
                                        },
                                        {
                                            title: 'ご住所',
                                            text: clientSelector.clientData.addressDetail,
                                        },
                                    ]
                                    : [
                                        {
                                            title: '種別',
                                            text: '個人',
                                        },
                                        {
                                            title: 'お名前',
                                            text: clientSelector.clientData.name,
                                        },
                                        {
                                            title: 'お電話番号',
                                            text: clientSelector.clientData.phone,
                                        },
                                        {
                                            title: 'ご住所',
                                            text: clientSelector.clientData.addressDetail,
                                        },
                                    ]
                            }
                            id="settings_client_data"
                            longFlag={false}
                        />
                    ) : (
                        <div className={styles.schoolInfoWrap}>
                            <div className={inputStyles['inputWrap--update']}>
                                {clientSelector.clientData.type === ClientType.Enterprise && (
                                    <div className={inputStyles.input}>
                                        <TextInput
                                            label="法人名"
                                            name="editRepresentativeName"
                                            id="editRepresentativeName"
                                            require
                                            inputType="text"
                                            validateType={InputValidateTypeEnum.TEXT}
                                            annotation=""
                                            changeReturnFunc={(text: string, validate: boolean) => {
                                                dispatch(
                                                    setClientEditInput({
                                                        ...settingsSelector.EditClient.editInput,
                                                        representativeName: text,
                                                    })
                                                );
                                                dispatch(
                                                    setClientEditValidate({
                                                        ...settingsSelector.EditClient.editValidate,
                                                        representativeName: validate,
                                                    })
                                                );
                                            }}
                                            defaultInput={settingsSelector.EditClient.editInput.representativeName}
                                            placeHolder="法人名を記入"
                                            minLength={1}
                                            defaultInputValue={settingsSelector.EditClient.editInput.representativeName}
                                            disabled={false}
                                        />
                                    </div>
                                )}
                                <div className={inputStyles.input}>
                                    <TextInput
                                        label={clientSelector.clientData.type === ClientType.Enterprise ? 'ご担当者さまのお名前' : 'お名前'}
                                        name="editName"
                                        id="editName"
                                        require
                                        inputType="text"
                                        validateType={InputValidateTypeEnum.TEXT}
                                        annotation=""
                                        changeReturnFunc={(text: string, validate: boolean) => {
                                            dispatch(
                                                setClientEditInput({
                                                    ...settingsSelector.EditClient.editInput,
                                                    name: text,
                                                })
                                            );
                                            dispatch(
                                                setClientEditValidate({
                                                    ...settingsSelector.EditClient.editValidate,
                                                    name: validate,
                                                })
                                            );
                                        }}
                                        defaultInput={settingsSelector.EditClient.editInput.name}
                                        placeHolder="織田　太郎"
                                        minLength={1}
                                        defaultInputValue={settingsSelector.EditClient.editInput.name}
                                        disabled={false}
                                    />
                                </div>
                                <div className={inputStyles.input}>
                                    <TextInput
                                        label="お電話番号"
                                        name="editPhone"
                                        id="editPhone"
                                        require
                                        inputType="text"
                                        validateType={InputValidateTypeEnum.TEL}
                                        annotation=""
                                        changeReturnFunc={(text: string, validate: boolean) => {
                                            dispatch(
                                                setClientEditInput({
                                                    ...settingsSelector.EditClient.editInput,
                                                    phone: text,
                                                })
                                            );
                                            dispatch(
                                                setClientEditValidate({
                                                    ...settingsSelector.EditClient.editValidate,
                                                    phone: validate,
                                                })
                                            );
                                        }}
                                        defaultInput={settingsSelector.EditClient.editInput.phone}
                                        placeHolder="0312345678"
                                        minLength={1}
                                        defaultInputValue={settingsSelector.EditClient.editInput.phone}
                                        disabled={false}
                                    />
                                </div>
                                <div className={inputStyles.input}>
                                    <TextInput
                                        label="ご住所"
                                        name="editAddress"
                                        id="editAddress"
                                        require
                                        inputType="text"
                                        validateType={InputValidateTypeEnum.TEXT}
                                        annotation=""
                                        changeReturnFunc={(text: string, validate: boolean) => {
                                            dispatch(
                                                setClientEditInput({
                                                    ...settingsSelector.EditClient.editInput,
                                                    addressDetail: text,
                                                })
                                            );
                                            dispatch(
                                                setClientEditValidate({
                                                    ...settingsSelector.EditClient.editValidate,
                                                    addressDetail: validate,
                                                })
                                            );
                                        }}
                                        defaultInput={settingsSelector.EditClient.editInput.addressDetail}
                                        placeHolder="東京都渋谷区代々木1-2-3　ビル名13F"
                                        minLength={1}
                                        defaultInputValue={settingsSelector.EditClient.editInput.addressDetail}
                                        disabled={false}
                                    />
                                </div>
                                <GreenBtn
                                    text="アカウント情報を更新"
                                    style={
                                        Object.entries(settingsSelector.EditClient.editValidate).some((d) => !d[1])
                                            ? { pointerEvents: 'none', opacity: 0.3 }
                                            : {}
                                    }
                                    handleClick={() => {
                                        dispatch(putClientEdit(authToken, settingsSelector.EditClient.editInput));
                                        dispatch(setClientEditFlag(false));
                                        dispatch(getClient(authToken));
                                    }}
                                    isLink={false}
                                    path=""
                                />
                            </div>
                        </div>
                    )}

                    <h2>本人確認書類</h2>
                    {clientSelector.clientData?.activated ? (
                        <div className={styles.authContainer}>
                            <div className={styles.confirmation}>
                                <div className={styles.confirmation__conversation}>本人・事業運営者は提出、確認済みです</div>
                                <div className={styles.confirmation__text}>
                                    ご提出頂いた本人確認書類は確認されています。
                                    <br />
                                    ご協力ありがとうございました！
                                </div>
                                <div className={`${styles.confirmation__img} ${styles['confirmation__img--human']}`}>
                                    <img src={human} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {clientSelector.clientData?.activationPending ? (
                                <div className={styles.authContainer}>
                                    <div className={styles.confirmation}>
                                        <div className={styles.confirmation__conversation}>本人・事業運営者を確認中です</div>
                                        <div className={styles.confirmation__text}>
                                            ご提出頂いた本人確認書類を確認しています。
                                            <br />
                                            運営からのご連絡をお待ちください。
                                        </div>
                                        <div className={`${styles.confirmation__img} ${styles['confirmation__img--cats']}`}>
                                            <img src={cats} alt="" />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.authContainer}>
                                    <div className={styles.confirmation}>
                                        <div className={styles.confirmation__conversation}>本人・事業運営者確認書類を提出してください</div>
                                        <div className={styles.confirmation__text}>
                                            安心・安全にご利用いただくために、本人情報の確認にご協力をお願いしています。提出された本人確認書類や入力いただく情報が、orioriの他のユーザーに公開されることはありません。
                                        </div>
                                        <div className={styles.confirmation__img}>
                                            <img src={exsample} alt="本人確認書類として使用できるもの" />
                                        </div>
                                        <GreenBtn style={null} text="本人確認書類提出へ" handleClick={null} isLink path="/settings/submission" />
                                    </div>
                                    <div className={styles.confirmation__ngImg}>
                                        <img src={ng} alt="以下のような画像は承認されません" />
                                    </div>
                                    <GreenBtn style={null} text="本人確認書類提出へ" handleClick={null} isLink path="/settings/submission" />
                                </div>
                            )}
                        </>
                    )}
                    <h2>教室権限申請</h2>
                    {clientSelector.clientData.schoolAuthOffering ? (
                        <div className={styles.authContainer}>
                            <div className={styles.confirmation}>
                                <div className={styles.confirmation__conversation}>申請した教室のURLを確認中です</div>
                                <div className={styles.confirmation__text}>
                                    ご提出頂いた本人確認書類を確認しています。
                                    <br />
                                    運営からのご連絡をお待ちください。
                                </div>
                                <div className={`${styles.confirmation__img} ${styles['confirmation__img--cats']}`}>
                                    <img src={cats} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.authContainer}>
                            <div className={styles.confirmation}>
                                <div className={styles.confirmation__conversation}>申請する教室のURLを記入</div>
                                <div className={styles.confirmation__textInputWrap}>
                                    <TextInput
                                        label="URL"
                                        name="schoolAuthUrlInput"
                                        id="schoolAuthUrlInput"
                                        require
                                        inputType="text"
                                        validateType={InputValidateTypeEnum.ORIORI_URL}
                                        annotation="※あなたの教室・スクールに関するページが、すでにorioriによって作られている可能性があります。
同じ教室・スクールページが複数ある状態ですと、ユーザーが閲覧しようとした際に、どのページを見ればよいか迷ってしまいます。
あなたがすでに掲載されている教室・スクールページのオーナーである場合には、こちらからURLを送信（オーナー申請）してください。"
                                        changeReturnFunc={(text, validate) => {
                                            setSchoolAuthUrlInput(text);
                                            setSchoolAuthUrlValidate(validate);
                                        }}
                                        defaultInput=""
                                        placeHolder="http://oriori.xx.xx.xxxxxxxxx"
                                        minLength={0}
                                        defaultInputValue={schoolAuthUrlInput}
                                        disabled={false}
                                    />
                                </div>
                                <GreenBtn
                                    style={
                                        !schoolAuthUrlValidate
                                            ? {
                                                pointerEvents: 'none',
                                                opacity: 0.3,
                                            }
                                            : {}
                                    }
                                    text="申請"
                                    handleClick={() => {
                                        dispatch(postSchoolAuthUrl(authToken, schoolAuthUrlInput));
                                    }}
                                    isLink={false}
                                    path=""
                                />
                            </div>
                        </div>
                    )}
                    {/* <div className={styles.authContainer}>*/}
                    {/*    <div className={styles.confirmation}>*/}
                    {/*        <div className={styles.confirmation__conversation}>申請した教室のURLは権限移行済みです</div>*/}
                    {/*    </div>*/}
                    {/* </div>*/}
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.schoolContainer}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室名', path: '' },
                    ]}
                />
            </div>
            <Footer />
        </>
    );
};
