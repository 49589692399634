import React from 'react';
import styles from './css/style.module.scss';
import { TextInput } from '../../Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../Common/Input/TextInput/InputValidateType.enum';

type CostAverageInputProps = {
    defaultValue: {
        from: number | null,
        to: number | null
    },
    defaultValidate: {
        from: boolean,
        to: boolean
    },
    returnFunc: (data: {
        from: number | null,
        to: number | null,
    },
        validate: {
            from: boolean,
            to: boolean
        }
    ) => void,
}
export const CostAverageInput: React.FC<CostAverageInputProps> = (props) => {
    const { defaultValue, returnFunc, defaultValidate } = props;

    return (<>
        <div className={styles.label}>平均月費用</div>
        <div className={styles.inputWrap}>
            <div className={styles.input}>
                <TextInput
                    label=''
                    name='costAverageInputFrom'
                    id='costAverageInputFrom'
                    require={false}
                    inputType='number'
                    validateType={InputValidateTypeEnum.PRICE}
                    annotation={null}
                    changeReturnFunc={(text, validate) =>
                        returnFunc({
                            from: Number(text),
                            to: defaultValue.to
                        },
                        {
                            from: validate,
                            to: defaultValidate.to
                        }
                        )}
                    defaultInput={defaultValue.from ? defaultValue.from.toString() : ''}
                    placeHolder='3000'
                    minLength={null}
                    defaultInputValue={defaultValue.from ? defaultValue.from.toString() : ''.toString()}
                    disabled={false}
                />
            </div>
            <div className={styles.inputCenter}>ー</div>
            <div className={styles.input}>
                <TextInput
                    label=''
                    name='costAverageInputTo'
                    id='costAverageInputTo'
                    require={false}
                    inputType='number'
                    validateType={InputValidateTypeEnum.TEXT}
                    annotation={null}
                    changeReturnFunc={(text, validate) =>
                        returnFunc({
                            from: defaultValue.from,
                            to: Number(text)
                        },
                        {
                            from: defaultValidate.from,
                            to: validate
                        }
                        )}
                    defaultInput={defaultValue.to ? defaultValue.to.toString() : ''}
                    placeHolder='5000'
                    minLength={null}
                    defaultInputValue={defaultValue.to ? defaultValue.to.toString() : ''}
                    disabled={false} />
            </div>
        </div>
        <div className={styles.annotation}>※生徒が習い事を受けるのに掛かっているおおよその費用を入力してください</div>
    </>);
};
