import { inquiryInitialize } from './InitialState/InquiryInitialize';
import { InquiryType } from './type/Inquiry.type';
import { InquiryActionTypes } from '../../../actions/domain/inquiry';

export const inquiryDetail = (state = inquiryInitialize, action: any): InquiryType => {
    switch (action.type) {

        case InquiryActionTypes.SET_INQUIRY_DETAIL:
            return {
                ...state,
                detail: action.payload.data
            };
        default:
            return state;
    }
}