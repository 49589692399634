import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './css/style.module.scss';
import { SettlementData } from '../../../util/type/School/SettlementData.type';

type PaymentHistoryTableProps = {
    data: Array<SettlementData>;
};

export const PaymentHistoryTable: React.FC<PaymentHistoryTableProps> = (props: PaymentHistoryTableProps) => {
    const { data } = props;

    const [indexForBg, setIndexForBg] = useState<Array<number>>([]);

    useEffect(() => {
        // 交互に変わる背景色対応
        let count = 1;
        for (let i = 0; i < data.length; i += 1) {
            if (i !== 0 && !(data[i].schoolId === data[i - 1].schoolId && data[i].settlementDate === data[i - 1].settlementDate)) {
                count += 1;
                indexForBg.push(count);
            } else {
                indexForBg.push(count);
            }
        }
        setIndexForBg([...indexForBg]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <table className={styles.paymentHistoryTable}>
            <tbody>
                <tr>
                    <th>スクールID</th>
                    <th>契約時スクール名</th>
                    <th>内容</th>
                    <th>決済日</th>
                    <th>領収書</th>
                </tr>
                {data.map((d, index) => (
                    <tr key={d.id} className={indexForBg[index] % 2 === 0 ? styles.brown : ''}>
                        <td className={styles.paymentHistoryTable__id}>
                            <Link to={`/school/${d.schoolId}`}>
                                {index === 0 || !(d.schoolId === data[index - 1].schoolId && d.settlementDate === data[index - 1].settlementDate)
                                    ? d.schoolId
                                    : ''}
                            </Link>
                        </td>
                        <td className={styles.paymentHistoryTable__name}>
                            {index === 0 || !(d.schoolId === data[index - 1].schoolId && d.settlementDate === data[index - 1].settlementDate)
                                ? d.school.name
                                : ''}
                        </td>
                        <td className={styles.paymentHistoryTable__content}>
                            <div className={styles.paymentHistoryTable__inner}>
                                <div>{d.productId === 'price_1JkN3kEtCAeA4zDLCoxSmTpl' ? 'システム利用料' : 'お問い合わせ'}</div>
                                <div>
                                    ¥{Number(d.price).toLocaleString()}
                                    <span>(税込)</span>
                                </div>
                            </div>
                            {d.productId === 'plan_KPBZpbun9x8cPO' && (
                                <div className={styles.note}>
                                    (お問い合わせ{d.quantity}件：¥3,300(税込) x {d.quantity}件)
                                </div>
                            )}
                        </td>
                        <td>{moment(d.settlementDate).format('YYYY/MM/DD')}</td>
                        <td>
                            <a className={styles.paymentHistoryTable__receipt} href={d.settlementUrl} target="_blank" rel="noopener noreferrer">
                                領収書を発行
                            </a>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
