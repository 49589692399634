import { MyPageDataType } from './type/MyPageData.type';
import { MyPageInitial } from './InitialState/myPageInitial';
import { MyPageActionTypes } from '../../../actions/domain/mypage';

export const myPageData = (state = MyPageInitial, action: any): MyPageDataType => {
    switch (action.type) {
        case MyPageActionTypes.SET_MY_SCHOOLS:
            return {
                ...state,
                schools: action.payload.schools,
            }
        case MyPageActionTypes.SET_MY_SCHOOLS_COUNT:
            return {
                ...state,
                schoolsCount: action.payload.schoolsCount,
            }
        case MyPageActionTypes.SET_MY_SCHOOLS_CURRENT_PAGE:
            return {
                ...state,
                schoolsCount: action.payload.schoolsCount,
            }
        case MyPageActionTypes.SET_MY_SCHOOLS_REVIEWS:
            return {
                ...state,
                reviews: action.payload.reviews,
            }

        case MyPageActionTypes.SET_MY_INQUIRIES:
            return {
                ...state,
                inquiries: action.payload.inquiries,
            }
        case MyPageActionTypes.SET_MY_INQUIRIES_COUNT:
            return {
                ...state,
                inquiriesCount: action.payload.inquiriesCount,
            }
        default:
            return state;
    }
}