import React, {useState} from 'react';
import styles from './css/style.module.scss';
import arrow from './img/arrow.svg';


type SchoolDetailTopQaCardProps = {
    qa: {
        question: string;
        answer: string;
    };
};

export const SchoolDetailTopQaCard: React.FC<SchoolDetailTopQaCardProps> = (props: SchoolDetailTopQaCardProps) => {
    const {qa} = props;
    const [show,setShow] = useState(false);
    return(
        <>
            <div key={qa.question} className={styles.qaCard}>
                <button
                    className={`${styles.qaCard__question} ${show ? styles.showAnswer : ""}`}
                    onClick={
                        // (e) => {
                        // if (e.currentTarget.classList.contains('showAnswer')) {
                        //     e.currentTarget.classList.remove('showAnswer');
                        //     e.currentTarget.nextElementSibling?.classList.remove('showAnswer');
                        // } else {
                        //     e.currentTarget.classList.add('showAnswer');
                        //     e.currentTarget.nextElementSibling?.classList.add('showAnswer');
                        // }
                        // }
                        ()=>setShow(!show)
                    }
                    type="button">
                    {qa.question}
                    <img src={arrow} alt="arrow" />
                </button>
                <div className={`${styles.qaCard__answer} ${show ? styles.showAnswer : ""}`}>{qa.answer}</div>
            </div>
        </>
    )}