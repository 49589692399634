import { SignUpInputType } from '../type/signUpInput.type';

export const signUpInputInitial: SignUpInputType = {
    email: '',
    password: '',
    passwordConfirm: '',
    emailValidate: false,
    passwordValidate: false,
    passwordConfirmValidate: false,
    result: {
        sendEmail: '',
        flag: false,
        reason: '',
    },
};
