import { AxiosResponse } from 'axios';
import { axiosBase } from './index';
import { ClientUserType } from '../type/ClientUser.type';
import { ClientUserTypeEnum } from '../../redux/reducers/domain/user/enum/ClientUserType.enum';

export class UserApi {
    // 新規クライアントユーザー招待
    static async postInviteNewUser(token: string, email: string, type: number) {
        return axiosBase.post('/client/user/invite', {
            email,
            type
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
    };

    // 新規クライアントユーザー新規作成
    static async postCreateNewUser(code: string, password: string, password_confirm: string, name: string) {
        const formData = new FormData();
        formData.append('params', JSON.stringify({
            code,
            password,
            password_confirm,
            name
        }));
        return axiosBase.post('/client/user', formData);
    };

    // クライアントユーザー一覧取得
    static async getClientUserList(token: string): Promise<AxiosResponse<Array<ClientUserType>>> {
        return axiosBase.get('client/user', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // クライアントユーザー詳細取得
    static async getClientUserDetail(token: string, uuid: string): Promise<AxiosResponse<ClientUserType>> {
        return axiosBase.get(`/client/user/${uuid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // クライアントユーザー削除
    static async deleteClientUser(token: string, uuid: string) {
        return axiosBase.delete(`/client/user/${uuid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // クライアントユーザー情報更新
    // 権限、パスワード、名前、画像を変更可能だが、現状権限変更のみに使用する
    static async updateClientUser(token: string, uuid: string, type: ClientUserTypeEnum): Promise<AxiosResponse<ClientUserType>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify({
            type
        }));
        return axiosBase.put(`/client/user/${uuid}`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    }

    // クライアントユーザーメール受信
    static async patchClientUserEmailAccept(token: string, uuid: string) {
        return axiosBase.patch(`/client/user/${uuid}/inquiry/accept`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // クライアントユーザーメール拒否
    static async patchClientUserEmailReject(token: string, uuid: string) {
        return axiosBase.patch(`/client/user/${uuid}/inquiry/reject`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // クライアントユーザーメールアドレス変更オファー
    static async postClientUserEmailOffer(token: string, email: string) {
        return axiosBase.post('/client/email/offer', {
            email
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
    };

    // クライアントユーザーメールアドレス変更認証
    static async postClientUserEmailApprove(authToken: string, token: string) {
        return axiosBase.post('/client/email/approve', {
            token
        },
        {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
        );
    };

    // クライアント本人確認書類提出
    static async postClientDocument(
        authToken: string,
        document1: File | null,
        document2: File | null,
        document3: File | null,
        note: string
    ) {
        const formData = new FormData();
        formData.append('params', JSON.stringify({ note }));
        if (document1) formData.append('documents', document1);
        if (document2) formData.append('documents', document2);
        if (document3) formData.append('documents', document3);
        return axiosBase.post('/client/document', formData, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
    }

    // 教室権限申請
    static async postClientSchoolAuth(authToken: string, schoolUrl: string) {
        return axiosBase.post('client/schoolAuth', {
            schoolUrl
        }, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
    }
}