import { Action } from 'redux';
import { SchoolsActionTypes } from './index';
import { SchoolDetailCouponValidateType } from '../../../reducers/domain/schools/type/Coupon/SchoolDetailCouponValidate.type';
import { SchoolDetailCouponInputType } from '../../../reducers/domain/schools/type/Coupon/SchoolDetailCouponInput.type';
import { SchoolCouponData } from '../../../../util/type/School/SchoolCouponData.type';

export const getSchoolCouponList = (token: string, schoolId: number, limit: number, page: number): GetSchoolCouponList => ({
    type: SchoolsActionTypes.GET_SCHOOL_COUPON_LIST,
    payload: { schoolId, token, limit, page }
});

export interface GetSchoolCouponList extends Action {
    type: string;
    payload: {
        schoolId: number,
        token: string,
        limit: number,
        page: number
    };
}

export const setSchoolCouponList = (schoolCouponListData: Array<SchoolCouponData> | null): SetSchoolCouponList => ({
    type: SchoolsActionTypes.SET_SCHOOL_COUPON_LIST,
    payload: { schoolCouponListData }
});

export interface SetSchoolCouponList extends Action {
    type: string;
    payload: {
        schoolCouponListData: Array<SchoolCouponData> | null
    };
}

export const setSchoolCouponListCount = (schoolCouponListCount: number): SetSchoolCouponListCount => ({
    type: SchoolsActionTypes.SET_SCHOOL_COUPON_LIST_COUNT,
    payload: { schoolCouponListCount }
});

export interface SetSchoolCouponListCount extends Action {
    type: string;
    payload: {
        schoolCouponListCount: number
    };
}


export const getSchoolCouponDetail = (token: string, couponId: number): GetSchoolCouponDetail => ({
    type: SchoolsActionTypes.GET_SCHOOL_COUPON_DETAIL,
    payload: { couponId, token }
});

export interface GetSchoolCouponDetail extends Action {
    type: string;
    payload: {
        couponId: number,
        token: string,
    };
}

export const setSchoolCouponDetail = (schoolCouponDetailData: SchoolCouponData | null): SetSchoolCouponDetail => ({
    type: SchoolsActionTypes.SET_SCHOOL_COUPON_DETAIL,
    payload: { schoolCouponDetailData }
});

export interface SetSchoolCouponDetail extends Action {
    type: string;
    payload: {
        schoolCouponDetailData: SchoolCouponData | null
    };
}

export const setSchoolNewCouponInput = (input: SchoolDetailCouponInputType): SetSchoolNewCouponInput => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_COUPON_INPUT,
    payload: { input }
});

export interface SetSchoolNewCouponInput extends Action {
    type: string;
    payload: {
        input: SchoolDetailCouponInputType,
    };
}

export const setSchoolNewCouponValidate = (validate: SchoolDetailCouponValidateType): SetSchoolNewCouponValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_COUPON_VALIDATE,
    payload: { validate }
});

export interface SetSchoolNewCouponValidate extends Action {
    type: string;
    payload: {
        validate: SchoolDetailCouponValidateType,
    };
}

export const postNewSchoolCoupon = (
    token: string,
    schoolId: string,
    data: SchoolDetailCouponInputType,
    withPublish: boolean
): PostNewSchoolCoupon => ({
    type: SchoolsActionTypes.POST_NEW_SCHOOL_COUPON,
    payload: { token, schoolId, data, withPublish }
});

export interface PostNewSchoolCoupon extends Action {
    type: string;
    payload: {
        token: string,
        schoolId: string
        data: SchoolDetailCouponInputType,
        withPublish: boolean,
    };
}

export const patchSchoolCouponPublish = (authToken: string, schoolId: string, couponId: string, inList: boolean): PatchSchoolCouponPublish => ({
    type: SchoolsActionTypes.PATCH_SCHOOL_COUPON_PUBLISH,
    payload: { authToken, schoolId, couponId, inList }
});

export interface PatchSchoolCouponPublish extends Action {
    type: string;
    payload: {
        authToken: string,
        couponId: string,
        inList: boolean,
        schoolId: string,
    };
}

export const patchSchoolCouponUnPublish = (authToken: string, schoolId: string, couponId: string, inList: boolean): PatchSchoolCouponUnPublish => ({
    type: SchoolsActionTypes.PATCH_SCHOOL_COUPON_UN_PUBLISH,
    payload: { authToken, schoolId, couponId, inList }
});

export interface PatchSchoolCouponUnPublish extends Action {
    type: string;
    payload: {
        authToken: string,
        couponId: string,
        inList: boolean,
        schoolId: string,
    };
}


export const setSchoolCouponEditFlag = (flag: boolean): estSchoolCouponEditFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_COUPON_EDIT_FLAG,
    payload: { flag }
});

export interface estSchoolCouponEditFlag extends Action {
    type: string;
    payload: {
        flag: boolean
    };
}

export const setSchoolCouponEditData = (editData: SchoolDetailCouponInputType | null): SetSchoolCouponEditData => ({
    type: SchoolsActionTypes.SET_SCHOOL_COUPON_EDIT_DATA,
    payload: { editData }
});

export interface SetSchoolCouponEditData extends Action {
    type: string;
    payload: {
        editData: SchoolDetailCouponInputType | null
    };
}

export const setSchoolCouponEditValidate = (validate: SchoolDetailCouponValidateType): PostSchoolCouponEditValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_COUPON_EDIT_VALIDATE,
    payload: { validate }
});

export interface PostSchoolCouponEditValidate extends Action {
    type: string;
    payload: {
        validate: SchoolDetailCouponValidateType
    };
}

export const putSchoolCouponEditData = (token: string, couponId: string, data: SchoolDetailCouponInputType,): PutSchoolCouponEditData => ({
    type: SchoolsActionTypes.PUT_SCHOOL_COUPON_DATA,
    payload: { token, couponId, data}
});

export interface PutSchoolCouponEditData extends Action {
    type: string;
    payload: {
        token: string,
        couponId: string
        data: SchoolDetailCouponInputType,
    };
}
