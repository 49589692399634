import { Action } from 'redux';
import { SchoolDetailCourseInputType } from '../../../reducers/domain/schools/type/Course/SchoolDetailCourseNew.type';
import { SchoolsActionTypes } from './index';
import { SchoolDetailCourseNewValidateType } from '../../../reducers/domain/schools/type/Course/SchoolDetailCourseNewValidate.type';
import { SchoolCourseData } from '../../../../util/type/School/SchoolCourseData.type';

export const getSchoolCourseList = (token: string, schoolId: number): GetSchoolCourseList => ({
    type: SchoolsActionTypes.GET_SCHOOL_COURSE_LIST,
    payload: { schoolId, token }
});

export interface GetSchoolCourseList extends Action {
    type: string;
    payload: {
        schoolId: number,
        token: string,
    };
}

export const setSchoolCourseList = (schoolCourseListData: Array<SchoolCourseData> | null): SetSchoolCourseList => ({
    type: SchoolsActionTypes.SET_SCHOOL_COURSE_LIST,
    payload: { schoolCourseListData }
});

export interface SetSchoolCourseList extends Action {
    type: string;
    payload: {
        schoolCourseListData: Array<SchoolCourseData> | null
    };
}

export const getSchoolCourseDetail = (token: string, courseId: number): GetSchoolCourseDetail => ({
    type: SchoolsActionTypes.GET_SCHOOL_COURSE_DETAIL,
    payload: { courseId, token }
});

export interface GetSchoolCourseDetail extends Action {
    type: string;
    payload: {
        courseId: number,
        token: string,
    };
}

export const setSchoolCourseDetail = (schoolCourseDetailData: SchoolCourseData | null): SetSchoolCourseDetail => ({
    type: SchoolsActionTypes.SET_SCHOOL_COURSE_DETAIL,
    payload: { schoolCourseDetailData }
});

export interface SetSchoolCourseDetail extends Action {
    type: string;
    payload: {
        schoolCourseDetailData: SchoolCourseData | null
    };
}

export const setSchoolNewCourseInput = (input: SchoolDetailCourseInputType): SetSchoolNewCourseInput => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_COURSE_INPUT,
    payload: { input }
});

export interface SetSchoolNewCourseInput extends Action {
    type: string;
    payload: {
        input: SchoolDetailCourseInputType,
    };
}

export const setSchoolNewCourseValidate = (validate: SchoolDetailCourseNewValidateType): SetSchoolNewCourseValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_COURSE_VALIDATE,
    payload: { validate }
});

export interface SetSchoolNewCourseValidate extends Action {
    type: string;
    payload: {
        validate: SchoolDetailCourseNewValidateType,
    };
}

export const postNewSchoolCourse = (
    token: string,
    schoolId: string,
    data: SchoolDetailCourseInputType,
    withPublish: boolean,
): PostNewSchoolCourse => ({
    type: SchoolsActionTypes.POST_NEW_SCHOOL_COURSE,
    payload: { token, schoolId, data, withPublish}
});

export interface PostNewSchoolCourse extends Action {
    type: string;
    payload: {
        token: string,
        schoolId: string
        data: SchoolDetailCourseInputType,
        withPublish: boolean,
    };
}

export const patchSchoolCoursePublish = (authToken: string, schoolId: string,  courseId: string, inList: boolean): PatchSchoolCoursePublish => ({
    type: SchoolsActionTypes.PATCH_SCHOOL_COURSE_PUBLISH,
    payload: { authToken, schoolId, courseId, inList }
});

export interface PatchSchoolCoursePublish extends Action {
    type: string;
    payload: {
        authToken: string,
        courseId: string,
        inList: boolean,
        schoolId: string,
    };
}

export const patchSchoolCourseUnPublish = (authToken: string, schoolId: string, courseId: string, inList: boolean): PatchSchoolCourseUnPublish => ({
    type: SchoolsActionTypes.PATCH_SCHOOL_COURSE_UN_PUBLISH,
    payload: { authToken, schoolId, courseId, inList }
});

export interface PatchSchoolCourseUnPublish extends Action {
    type: string;
    payload: {
        authToken: string,
        courseId: string,
        inList: boolean,
        schoolId: string,
    };
}

export const setSchoolCourseEditFlag = (flag: boolean): estSchoolCourseEditFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_COURSE_EDIT_FLAG,
    payload: { flag }
});

export interface estSchoolCourseEditFlag extends Action {
    type: string;
    payload: {
        flag: boolean
    };
}

export const setSchoolCourseEditData = (editData: SchoolDetailCourseInputType | null): SetSchoolCourseEditData => ({
    type: SchoolsActionTypes.SET_SCHOOL_COURSE_EDIT_DATA,
    payload: { editData }
});

export interface SetSchoolCourseEditData extends Action {
    type: string;
    payload: {
        editData: SchoolDetailCourseInputType | null
    };
}

export const setSchoolCourseEditValidate = (validate: SchoolDetailCourseNewValidateType): PostSchoolCourseEditValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_COURSE_EDIT_VALIDATE,
    payload: { validate }
});

export interface PostSchoolCourseEditValidate extends Action {
    type: string;
    payload: {
        validate: SchoolDetailCourseNewValidateType
    };
}

export const putSchoolCourseEditData = (token: string, courseId: string, data: SchoolDetailCourseInputType,): PutSchoolCourseEditData => ({
    type: SchoolsActionTypes.PUT_SCHOOL_COURSE_DATA,
    payload: { token, courseId, data}
});

export interface PutSchoolCourseEditData extends Action {
    type: string;
    payload: {
        token: string,
        courseId: string
        data: SchoolDetailCourseInputType,
    };
}
