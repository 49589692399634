import { NewSchoolInputValidateType } from '../../type/New/NewSchoolInputValidate.type';

export const newSchoolInputValidateInitial: NewSchoolInputValidateType = {
    name: false,
    nameKana: false,
    copy: false,
    addressDetail: false,
    nearestStationText: true, // 任意
    costAverageFrom: true, // 任意
    costAverageTo: true, // 任意
    description: false,
    homepageUrl: true, // 任意
    twitterUrl: true, // 任意
    instagramUrl: true, // 任意
    facebookUrl: true, // 任意
    lineUrl: true, // 任意
    establishmentDate: true, // 任意
    questions: true, // 任意
    prefectureId: false,
    townId: false,
    streetId: false,
    note: false,
    scheduleWeekSchedule: false,
    nearestStation: true, // 任意
    nearestBusstop: true, // 任意
    categoryId: false,
    keywordIds: true,
    tagIds: false,
    stationIds: false,
    busstopIds: false,
    classStyleIds: false,
    targetAgeIds: false,
    realSchool: true,
};