import { combineReducers } from 'redux';
import { signUpInput } from './signUpInput';
import { signInInput } from './signInInput';
import { signUpRegister } from './signUpRegister';
import { clientData } from './clientData';
import { clientInput } from './clinetInput';
import { passwordReissueOffer } from './passwordReissueOffer';
import { passwordRegister } from './passwordRegister';

export const client = combineReducers({
    signUpInput,
    signInInput,
    signUpRegister,
    clientData,
    clientInput,
    passwordReissueOffer,
    passwordRegister,
});
