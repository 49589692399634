import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/style.module.scss';
import { SchoolInstructorDataType } from '../../../util/type/School/SchoolInstructorDataType';
import { TextInput } from '../../Common/Input/TextInput';
import { TextArea } from '../../Common/Input/TextArea';
import { InputValidateTypeEnum } from '../../Common/Input/TextInput/InputValidateType.enum';
import { ImageUploader, ImageUploaderSize } from '../../SchoolInput/ImageUploader';
import {
    deleteSchoolInstructor,
    getSchoolInstructorList, postEditSchoolInstructor, postNewSchoolInstructor,
    setSchoolEditInstructorIndex, setSchoolEditInstructorInput, setSchoolEditInstructorValidate,
    setSchoolNewInstructorFlag, setSchoolNewInstructorInput, setSchoolNewInstructorValidate
} from '../../../redux/actions/domain/schools/schoolinstructor';
import { RootState } from '../../../redux/store';
import { SchoolDetailInstructorType } from '../../../redux/reducers/domain/schools/type/Instructor/SchoolDetailInstructor.type';
import { schoolInstructorToInputInstructor } from '../../../redux/reducers/domain/schools/converter/schoolInstructorToInputInstructor';
import { SchoolDetailInstructorInputType } from '../../../redux/reducers/domain/schools/type/Instructor/SchoolDetailInstructorNew.type';
import { SchoolDetailInstructorValidateType } from '../../../redux/reducers/domain/schools/type/Instructor/SchoolDetailInstructorNewValidate.type';


type SchoolInstructorCardProps = {
    data: SchoolInstructorDataType | null;
    newFlag: boolean;
    index: number | null,
    schoolId: string,
};

export const SchoolInstructorCard: React.FC<SchoolInstructorCardProps> = (props: SchoolInstructorCardProps) => {
    const { data, newFlag, index, schoolId } = props;
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolInstructorStore: SchoolDetailInstructorType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailInstructor);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    return (
        <>
            {(schoolInstructorStore.editIndex !== index && !newFlag && data) &&
            <div className={styles.schoolInstructorCard}>
                <div className={styles.schoolInstructorCard__head}>
                    {data.imageUrl &&
                    <img className={styles.schoolInstructorCard__img} src={data.imageUrl} alt='講師画像' />
                    }
                    <div className={styles.schoolInstructorCard__info}>
                        <div className={styles.schoolInstructorCard__name}>名前：{data.name}</div>
                        {/* <div className={styles.schoolInstructorCard__time}>講師歴：講師歴は未実装？</div> */}
                    </div>
                    <button
                        type='button'
                        className={styles.schoolInstructorCard__button}
                        onClick={() => {
                            dispatch(setSchoolEditInstructorIndex(index));
                            dispatch(setSchoolEditInstructorInput(schoolInstructorToInputInstructor(data)));
                            dispatch(setSchoolEditInstructorValidate({
                                name: true,
                                description: true
                            }));
                            dispatch(getSchoolInstructorList(authToken, Number(schoolId)));
                        }}
                    >編集する
                    </button>
                </div>
                <div className={styles.schoolInstructorCard__body}>
                    紹介文：{data.description}
                </div>
            </div>
            }
            {((schoolInstructorStore.editIndex === index && schoolInstructorStore.edit) || newFlag) &&
            <div className={`${styles.schoolInstructorCard} ${styles['schoolInstructorCard--edit']}`}>
                <div className={styles.schoolInstructorCard__head}>
                    <ImageUploader
                        uiSize={ImageUploaderSize.MEDIUM}
                        identificationTitle={null}
                        identificationText={null}
                        defaultImageUrl={data && data.imageUrl ? data.imageUrl : null}
                        returnFunc={(file, deleteFlag) => {
                            if (newFlag) {
                                dispatch(setSchoolNewInstructorInput({
                                    ...schoolInstructorStore.new,
                                    deletingImage: deleteFlag
                                }));
                                dispatch(setSchoolNewInstructorInput({
                                    ...schoolInstructorStore.new,
                                    image: file
                                }));
                            } else {
                                dispatch(setSchoolEditInstructorInput({
                                    ...schoolInstructorStore.edit as SchoolDetailInstructorInputType,
                                    deletingImage: deleteFlag
                                }));
                                dispatch(setSchoolEditInstructorInput({
                                    ...schoolInstructorStore.edit as SchoolDetailInstructorInputType,
                                    image: file
                                }));
                            }
                        }}
                    />
                    <div className={styles.schoolInstructorCard__info}>
                        <div className={styles.schoolInstructorCard__name}>名前
                            <TextInput
                                label=''
                                name='instructorName'
                                id='instructorName'
                                require
                                inputType='text'
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=''
                                changeReturnFunc={(text, validate) => {
                                    if (newFlag) {
                                        dispatch(setSchoolNewInstructorInput({
                                            ...schoolInstructorStore.new,
                                            name: text
                                        }));
                                        dispatch(setSchoolNewInstructorValidate({
                                            ...schoolInstructorStore.newValidate,
                                            name: validate
                                        }));
                                    } else {
                                        dispatch(setSchoolEditInstructorInput({
                                            ...schoolInstructorStore.edit as SchoolDetailInstructorInputType,
                                            name: text
                                        }));
                                        dispatch(setSchoolEditInstructorValidate({
                                            ...schoolInstructorStore.editValidate as SchoolDetailInstructorValidateType,
                                            name: validate
                                        }));
                                    }
                                }}
                                defaultInput={data ? data.name : ''}
                                placeHolder='田中織子'
                                minLength={0}
                                defaultInputValue={data ? data.name : null}
                                disabled={false}
                            />
                        </div>
                        {/* 講師歴は一旦コメントアウト h_takesue 2021.10.15*/}
                        {/* <div className={styles.schoolInstructorCard__time}>*/}
                        {/*    <span>講師歴</span>*/}
                        {/*    <TextInput*/}
                        {/*        label=''*/}
                        {/*        name='instructorTime'*/}
                        {/*        id='instructorTime'*/}
                        {/*        require*/}
                        {/*        inputType='number'*/}
                        {/*        validateType={InputValidateTypeEnum.TEXT}*/}
                        {/*        annotation=''*/}
                        {/*        changeReturnFunc={() => {*/}
                        {/*            // dispatch(setInviteUserInput({*/}
                        {/*            //     ...userSelector.inviteUser.input,*/}
                        {/*            //     email: text*/}
                        {/*            // }));*/}
                        {/*            // setEmailValidate(validate);*/}
                        {/*        }}*/}
                        {/*        defaultInput=""*/}
                        {/*        placeHolder='9'*/}
                        {/*        minLength={0}*/}
                        {/*        defaultInputValue=""*/}
                        {/*        disabled={false}*/}
                        {/*    />*/}
                        {/*    <span>年</span>*/}
                        {/* </div>*/}
                    </div>
                    <button type='button' className={styles.schoolInstructorCard__button} onClick={() => {
                        if (newFlag) {
                            dispatch(setSchoolNewInstructorFlag(false));
                        } else {
                            dispatch(setSchoolEditInstructorIndex(null));
                            dispatch(setSchoolEditInstructorInput(null));
                        }
                        dispatch(getSchoolInstructorList(authToken, Number(schoolId)));
                    }}>キャンセル
                    </button>

                </div>
                <div className={styles.schoolInstructorCard__body}>
                    紹介文

                    <TextArea
                        label=''
                        name='editBlogText'
                        id='editBlogText'
                        require
                        validateType={InputValidateTypeEnum.TEXT}
                        annotation={null}
                        changeReturnFunc={(text, validate) => {
                            if (newFlag) {
                                dispatch(setSchoolNewInstructorInput({
                                    ...schoolInstructorStore.new,
                                    description: text
                                }));
                                dispatch(setSchoolNewInstructorValidate({
                                    ...schoolInstructorStore.newValidate,
                                    description: validate
                                }));
                            } else {
                                dispatch(setSchoolEditInstructorInput({
                                    ...schoolInstructorStore.edit as SchoolDetailInstructorInputType,
                                    description: text
                                }));
                                dispatch(setSchoolEditInstructorValidate({
                                    ...schoolInstructorStore.editValidate as SchoolDetailInstructorValidateType,
                                    description: validate
                                }));
                            }
                        }}
                        defaultInput={data ? data.description : ''}
                        placeHolder='プログラマーとして約８年。専門学校の講師として約１７年。
                        数多くのプログラマーを育て、就職させてきました。
                        現在も、大手専門学校にて非常勤講師として勤務しています。
                        また、大阪市の小学生プログラミング体験イベントの講師や小・中学校の情報教育アドバイザーなども経験しています。'
                        forReview={false}
                        forInquiry={false}
                        countFlag
                        maxLength={2000}
                        minLength={1}
                        height={174}
                    />
                    <div className={styles.schoolInstructorCard__buttons}>
                        <button type='button' className={styles.schoolInstructorCard__saveBtn} onClick={() => {
                            if (newFlag) {
                                dispatch(postNewSchoolInstructor(authToken, schoolId, schoolInstructorStore.new));
                            } else if (data) dispatch(postEditSchoolInstructor(authToken,
                                data.id.toString(),
                                schoolInstructorStore.edit as SchoolDetailInstructorInputType,
                                schoolId
                            ));
                            dispatch(setSchoolNewInstructorFlag(false));
                            dispatch(setSchoolEditInstructorIndex(null));
                            dispatch(setSchoolEditInstructorInput(null));
                        }}>{loadingSelector ? '通信中...' : '保存する'}
                        </button>
                        <button
                            type='button'
                            className={styles.schoolInstructorCard__deleteBtn}
                            onClick={() => {
                                // eslint-disable-next-line no-alert
                                if (window.confirm("削除しますか?") && data) {
                                    dispatch(deleteSchoolInstructor(authToken, data.id.toString(), schoolId));
                                    dispatch(setSchoolNewInstructorFlag(false));
                                    dispatch(setSchoolEditInstructorIndex(null));
                                    dispatch(setSchoolEditInstructorInput(null));
                                }
                            }}
                        >この講師を削除</button>
                    </div>
                </div>
            </div>
            }
        </>
    );
};