import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import { rootReducer } from './reducers';

const sagaMiddleware = createSagaMiddleware();
// USE VERSION 4.10.1
// 5系だとrouterとのconnectで動かない
export const history: any = createBrowserHistory();

const reducer = combineReducers({
    router: connectRouter(history),
    rootReducer,
});

export type RootState = ReturnType<typeof reducer>;

export const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware
        )
    )
);

sagaMiddleware.run(rootSaga);

export type RootStateType = ReturnType<typeof reducer>;
export const persistor = persistStore(store);
