import { ClientActionTypes } from '../../../actions/domain/client';
import { passwordRegisterInitial } from './InitialState/passwordRegisterInitial';
import { PasswordRegisterType } from './type/passwordRegisterType';

// パスワード再発行
export const passwordRegister = (state = passwordRegisterInitial, action: any): PasswordRegisterType => {
    switch (action.type) {
        case ClientActionTypes.SET_PASSWORD_REGISTER_CODE:
            return {
                ...state,
                registerCode: action.payload.registerCode,
            };
        case ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD:
            return {
                ...state,
                password: action.payload.password,
            };
        case ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD_VALIDATE:
            return {
                ...state,
                passwordValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD_CONFIRM:
            return {
                ...state,
                passwordConfirm: action.payload.passwordConfirm,
            };
        case ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD_CONFIRM_VALIDATE:
            return {
                ...state,
                passwordConfirmValidate: action.payload.flag,
            };
        // result
        case ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD_RESULT:
            return {
                ...state,
                result: action.payload.result,
            };
        default:
            return state;
    }
};
