import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import styles from '../../../asset/css/accountCommon.module.scss';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { CreateInvitedUserType } from '../../../redux/reducers/domain/user/type/CreateInviteUser.type';
import { postCreateInvitedUserInput, setCreateInvitedUserInput, setCreateInvitedUserValidate } from '../../../redux/actions/domain/user/inviteUser';

// クエリの有無、メール認証の結果にて、表示する画面構成を変更する
export const InvitedUserRegister: React.FC = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const createUserSelector: CreateInvitedUserType = useSelector((state: RootState) => state.rootReducer.domain.user.createUser);
    const loading: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();
    const registerCode = query.get('token');

    if (!registerCode) {
        return <Redirect to="/" />;
    }

    if (createUserSelector.result.flag) {
        return (
            <>
                <Header />
                <div className={styles.authContainer}>
                    <h1>新規ユーザーが作成されました！</h1>
                    <div className={styles.confirmation}>
                        <p className={styles.confirmation__text}>
                            新規ユーザーが作成されました！
                            <br />
                            ログイン画面にて、ログインしてください。
                        </p>
                    </div>
                    <GreenBtn style={null} text="ログインへ" handleClick={null} isLink path="/" />
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <div className={styles.authContainer}>
                <h1>ユーザーを作成</h1>
                <div className={styles.grayBg}>
                    <TextInput
                        label="パスワード"
                        name="createUserPass1"
                        id="createUserPass1"
                        require
                        inputType="password"
                        validateType={InputValidateTypeEnum.PASSWORD}
                        annotation="※ 英字、数字、記号をすべて含む8桁以上で入力してください"
                        changeReturnFunc={(text, validate) => {
                            dispatch(
                                setCreateInvitedUserInput({
                                    ...createUserSelector.input,
                                    password: text,
                                })
                            );
                            dispatch(
                                setCreateInvitedUserValidate({
                                    ...createUserSelector.inputValidate,
                                    password: validate,
                                })
                            );
                        }}
                        defaultInput={createUserSelector.input.password}
                        placeHolder="パスワードを入力"
                        minLength={0}
                        defaultInputValue={createUserSelector.input.password}
                        disabled={false}
                    />

                    <TextInput
                        label="パスワード確認"
                        name="createUserPass2"
                        id="createUserPass1"
                        require
                        inputType="password"
                        validateType={InputValidateTypeEnum.PASSWORD}
                        annotation="※ 英字、数字、記号をすべて含む8桁以上で入力してください"
                        changeReturnFunc={(text, validate) => {
                            dispatch(
                                setCreateInvitedUserInput({
                                    ...createUserSelector.input,
                                    password_confirm: text,
                                })
                            );
                            dispatch(
                                setCreateInvitedUserValidate({
                                    ...createUserSelector.inputValidate,
                                    password_confirm: validate,
                                })
                            );
                        }}
                        defaultInput={createUserSelector.input.password_confirm}
                        placeHolder="パスワードを再入力"
                        minLength={0}
                        defaultInputValue={createUserSelector.input.password_confirm}
                        disabled={false}
                    />

                    {createUserSelector.result.reason !== '' && <div>{createUserSelector.result.reason}</div>}
                </div>
                <p className={styles.btnAnnotation}>
                    <a href="<">規約・プライバシーポリシー</a>へ同意の上
                </p>
                <GreenBtn
                    text={loading ? '送信中' : '新規登録'}
                    style={
                        !(createUserSelector.inputValidate.password && createUserSelector.inputValidate.password_confirm)
                            ? { pointerEvents: 'none', opacity: 0.3 }
                            : {}
                    }
                    handleClick={() => {
                        dispatch(
                            postCreateInvitedUserInput(
                                registerCode,
                                createUserSelector.input.password,
                                createUserSelector.input.password_confirm,
                                'noname'
                            )
                        );
                    }}
                    isLink={false}
                    path=""
                />
            </div>
            <Footer />
        </>
    );
};
