import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AppActionTypes, clearState } from '../redux/actions/app';
import { persistor } from '../redux/store';

function* logOut() {
    localStorage.setItem('withLogout', 'true');
    // storeリセット
    yield put(clearState());
    // storeの永続化を削除
    yield persistor.purge();
    // loginへリダイレクト
    yield put(push('/'));
}

export function* logOutAsync() {
    yield takeEvery(AppActionTypes.LOGOUT, logOut);
}
