import { signUpInputInitial } from './InitialState/signUpInputInitial';
import { SignUpInputType } from './type/signUpInput.type';
import { ClientActionTypes } from '../../../actions/domain/client';

export const signUpInput = (state = signUpInputInitial, action: any): SignUpInputType => {
    switch (action.type) {
        case ClientActionTypes.SET_SIGN_UP_EMAIL:
            return {
                ...state,
                email: action.payload.email,
            };
        case ClientActionTypes.SET_SIGN_UP_PASSWORD:
            return {
                ...state,
                password: action.payload.password,
            };
        case ClientActionTypes.SET_SIGN_UP_PASSWORD_CONFIRM:
            return {
                ...state,
                passwordConfirm: action.payload.passwordConfirm,
            };
        case ClientActionTypes.SET_SIGN_UP_EMAIL_VALIDATE:
            return {
                ...state,
                emailValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_SIGN_UP_PASSWORD_VALIDATE:
            return {
                ...state,
                passwordValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_SIGN_UP_PASSWORD_CONFIRM_VALIDATE:
            return {
                ...state,
                passwordConfirmValidate: action.payload.flag,
            };
        case ClientActionTypes.SET_SIGN_UP_RESULT:
            return {
                ...state,
                result: action.payload.result,
            };
        case ClientActionTypes.RESET_SIGN_UP_INPUT:
            return {
                ...state,
                result: action.payload.initial,
            };

        default:
            return state;
    }
};
