import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { UserApi } from '../util/api/user';
import { UserActions } from '../redux/actions/domain/user';
import {
    postCreateInvitedUserInput, postInviteUserInput,
    setCreateInvitedUserResult, setInviteUserResult
} from '../redux/actions/domain/user/inviteUser';
import {
    deleteClientUser,
    getClientUsers, patchClientUserEmailInquiry,
    setClientUsers,
    updateClientUser
} from '../redux/actions/domain/user/clientUser';
import { ClientUserType } from '../util/type/ClientUser.type';
import {
    postChangeClientUserEmailCode,
    postChangeClientUserEmailOffer,
    setChangeClientUserEmailOfferResult, setChangeClientUserEmailResult
} from '../redux/actions/domain/user/changeEmail';

// 新規ユーザー招待
function* postInviteUser({ payload }: ReturnType<typeof postInviteUserInput>) {
    try {
        yield call(UserApi.postInviteNewUser, payload.token, payload.input.email, payload.input.type);
        yield put(
            setInviteUserResult({
                flag: true,
                reason: ''
            })
        );
    } catch (e) {
        const err = e as AxiosError;
        if (err.response) {
            const responseBody = err.response.data.message;
            yield put(
                setInviteUserResult({
                    flag: false,
                    reason: responseBody
                })
            );
        } else {
            yield put(
                setInviteUserResult({
                    flag: false,
                    reason: ''
                })
            );
        }
    }
}

export function* postInviteUserAsync() {
    yield takeEvery(UserActions.POST_INVITE_USER_INPUT, postInviteUser);
}

// 新規ユーザー登録
function* postCreateNewUser({ payload }: ReturnType<typeof postCreateInvitedUserInput>) {
    try {
        yield call(UserApi.postCreateNewUser, payload.code, payload.password, payload.password_confirm, payload.name);
        yield put(
            setCreateInvitedUserResult({
                flag: true,
                reason: ''
            })
        );
    } catch (e) {
        const err = e as AxiosError;
        if (err.response) {
            const responseBody = err.response.data.message;
            yield put(
                setCreateInvitedUserResult({
                    flag: false,
                    reason: responseBody
                })
            );
        } else {
            yield put(
                setCreateInvitedUserResult({
                    flag: false,
                    reason: ''
                })
            );
        }
    }
}

export function* postInvitedUserRegisterAsync() {
    yield takeEvery(UserActions.POST_CREATE_INVITED_USER_INPUT, postCreateNewUser);
}

// ユーザー一覧取得
function* fetchClientUsers({ payload }: ReturnType<typeof getClientUsers>) {
    try {
        const res: AxiosResponse<Array<ClientUserType>> = yield call(UserApi.getClientUserList, payload.token);
        yield put(setClientUsers(res.data));

    } catch (e) {
        yield put(setClientUsers([]));
    }
}

export function* fetchClientUsersAsync() {
    yield takeEvery(UserActions.GET_CLIENT_USERS, fetchClientUsers);
}

// ユーザー削除
function* sendDeleteClientUser({ payload }: ReturnType<typeof deleteClientUser>) {
    try {
        yield call(UserApi.deleteClientUser, payload.token, payload.uuid);
        // 一覧更新
        yield put(getClientUsers(payload.token));
    } catch (e) {
        yield put(getClientUsers(payload.token));
    }
}

export function* deleteClientUsersAsync() {
    yield takeEvery(UserActions.DELETE_CLIENT_USER, sendDeleteClientUser);
}

// ユーザー更新
function* postToUpdateClientUser({ payload }: ReturnType<typeof updateClientUser>) {
    try {
        yield call(UserApi.updateClientUser, payload.token, payload.uuid, payload.type);
        // 一覧更新
        yield put(getClientUsers(payload.token));
    } catch (e) {
        yield put(getClientUsers(payload.token));
    }
}

export function* postToUpdateClientUserAsync() {
    yield takeEvery(UserActions.UPDATE_CLIENT_USER, postToUpdateClientUser);
}

// メール受信設定更新
function* patchClientUserInquiry({ payload }: ReturnType<typeof patchClientUserEmailInquiry>) {
    try {
        if (payload.accept) {
            yield call(UserApi.patchClientUserEmailAccept, payload.token, payload.uuid);
        } else {
            yield call(UserApi.patchClientUserEmailReject, payload.token, payload.uuid);
        }
        // 一覧更新
        yield put(getClientUsers(payload.token));
    } catch (e) {
        yield put(getClientUsers(payload.token));
    }
}

export function* patchClientUserInquiryAsync() {
    yield takeEvery(UserActions.PATCH_CLIENT_USER_EMAIL_INQUIRY, patchClientUserInquiry);
}


// メールアドレス変更オファー
function* postClientUserChangeEmailOffer({ payload }: ReturnType<typeof postChangeClientUserEmailOffer>) {
    try {
        yield call(UserApi.postClientUserEmailOffer, payload.token, payload.email);
        yield put(
            setChangeClientUserEmailOfferResult(
                true,
                ''
            )
        );
    } catch (e) {
        const err = e as AxiosError;
        if (err.response) {
            const responseBody = err.response.data.message;
            yield put(
                setChangeClientUserEmailOfferResult(
                    false,
                    responseBody
                )
            );
        } else {
            yield put(
                setChangeClientUserEmailOfferResult(
                    false,
                    ''
                )
            );
        }
    }
}

export function* postClientUserChangeEmailOfferAsync() {
    yield takeEvery(UserActions.POST_CHANGE_CLIENT_USER_EMAIL_OFFER, postClientUserChangeEmailOffer);
}

// メールアドレス変更認証
function* postClientUserEmailApproval({ payload }: ReturnType<typeof postChangeClientUserEmailCode>) {

    try {
        yield call(UserApi.postClientUserEmailApprove, payload.authToken, payload.token);
        yield put(
            setChangeClientUserEmailResult({
                success: true,
            })
        );
    } catch (e) {
        yield put(
            setChangeClientUserEmailResult({
                success: false,
            })
        );
    }
}

export function* postClientUserEmailApprovalAsync() {
    yield takeEvery(UserActions.POST_CHANGE_CLIENT_USER_EMAIL_REGISTER_CODE, postClientUserEmailApproval);
}