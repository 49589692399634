import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { postPaymentSetup } from '../../../../../redux/actions/domain/payment/schoolSubscription';
import { InputStripeCreditCard } from '../../../InputStripeCreditCard';


type CreditCardSettingProps = {
    modalNumber: any;
    setModalNumber: any;
};

export const CreditCardSetting: React.FC<CreditCardSettingProps> = (props:CreditCardSettingProps) => {
    const {modalNumber,setModalNumber} = props;
    const authToken = useSelector((store: RootState) => store.rootReducer.app.authToken);
    const schoolSubscriptionSelector = useSelector((store: RootState) => store.rootReducer.domain.payment.schoolSubscription);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(postPaymentSetup(authToken))
    }, [authToken, dispatch])

    if (schoolSubscriptionSelector.paymentSecret) {
        return(
            <>
                <InputStripeCreditCard
                    submitButtonStr='次へ'
                    cancelButtonStr='ひとつ前に戻る'
                    submitButtonFunc={()=>setModalNumber(modalNumber + 1)}
                    cancelButtonFunc={()=>setModalNumber(modalNumber - 1)}
                    contractsFlag={false}
                />
            </>
        )
    } 
    return <></>
    

};