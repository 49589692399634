import { SchoolDetailCourseInputType } from '../type/Course/SchoolDetailCourseNew.type';
import { SchoolCourseData } from '../../../../../util/type/School/SchoolCourseData.type';
import { DateRequestType } from '../../../../../util/type/DateRequest.type';

export const schoolCourseToCourseInputData = (detailCourse: SchoolCourseData): SchoolDetailCourseInputType => ({
    name: detailCourse.name, // 名前
    description: detailCourse.description, // 説明
    paymentMethod: detailCourse.paymentMethod, // 支払い説明
    timeRemarks: detailCourse.timeRemarks, // 時間帯説明
    scheduleWeekSchedule: detailCourse.scheduleWeekSchedule.map((d, index) => {
        if (d !== null) {
            return {
                day: index,
                from: d.from,
                to: d.to,
            } as DateRequestType;
        }
        return false;
    }).filter(d => !!d) as Array<DateRequestType>,
    requirements: detailCourse.requirements, // 必要要綱
    membershipFee: detailCourse.membershipFee, // 入会費
    annualFee: detailCourse.annualFee, // 年費用
    monthlyFee: detailCourse.monthlyFee, // 月費用
    textFee: detailCourse.textFee, // テキスト費用
    feeRemark: detailCourse.feeRemark, // 費用説明
    timeMinutesFrom: detailCourse.timeMinutesFrom, // '授業最短時間（分）
    timeMinutesTo: detailCourse.timeMinutesTo, // 授業最長時間（分）
    sortId: detailCourse.sortId, // ソートid
    targetAgeIds: detailCourse.schoolTargetAge.map(d => d.id),
    instructorIds: detailCourse.schoolInstructor.map(d => d.id),
    image: null,
    deletingImage: false,
});