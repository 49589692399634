import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OwnerApplication } from '../../../components/MyPage/OwnerApplication';
import { TabBar } from '../../../components/MyPage/TabBar';
import { Header } from '../../../components/Common/Header';
import { NewSchoolHeader } from '../../../components/SchoolInput/NewSchoolHeader';
import styles from '../../../asset/css/newSchool.module.scss';
import { BreadCrumb } from '../../../components/Common/BreadCrumb';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { Footer } from '../../../components/Common/Footer';
import { RootState } from '../../../redux/store';
import { setNewSchoolInput, setNewSchoolInputValidate } from '../../../redux/actions/domain/schools/newSchool';
import { newSchoolInputDataInitial } from '../../../redux/reducers/domain/schools/InitialState/New/newSchoolInputDataInitial';
import { newSchoolInputValidateInitial } from '../../../redux/reducers/domain/schools/InitialState/New/newSchoolInputValidateInitial';
import { patchSchoolPublish } from '../../../redux/actions/domain/schools/schoolIndexEdit';
import tips from "./img/tips.png";

export const New_result: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const newSchoolResultStore = useSelector((state: RootState) => state.rootReducer.domain.schools.newSchoolResult);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (newSchoolResultStore === null) {
            history.replace('/school/new_1');
        } else {
            dispatch(setNewSchoolInput(newSchoolInputDataInitial));
            dispatch(setNewSchoolInputValidate(newSchoolInputValidateInitial));
        }
    }, [dispatch, history, newSchoolResultStore]);

    if (newSchoolResultStore === null) {
        // todo loading
        return <></>;
    }

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.wrap}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室を新規作成', path: '/school/new_result' },
                    ]}
                />
                <NewSchoolHeader step={4} />
                <div className={styles.resultWrap}>
                    <div className={styles.resultWrap__title}>新規作成が完了しました！</div>
                    <div className={styles.resultWrap__main}>
                        <div className={styles.resultWrap__mainTitle}>まだ教室は公開されていません</div>
                        <div className={styles.resultWrap__mainText}>
                            「<span>{newSchoolResultStore.name}</span>」<br />
                            のページが作成されました
                            <br />
                            続けて、トップの情報やコースの情報を登録しましょう！
                        </div>
                    </div>
                    <div className={styles.resultButtonWrap}>
                        <GreenBtn
                            text="教室トップ情報入力へ"
                            style={{}}
                            handleClick={() => false}
                            isLink
                            path={`/school/${newSchoolResultStore.id}`}
                        />
                    </div>
                    <div className={styles.resultLink}>
                        <button
                            type="button"
                            onClick={() => {
                                dispatch(patchSchoolPublish(authToken, newSchoolResultStore.id.toString(), true, 0, 10));
                                history.push('/mypage');
                            }}>
                            {loadingSelector ? '通信中...' : '教室を公開する'}
                        </button>
                    </div>
                </div>
                <div className={styles.secondMain}>
                    <div className={styles.secondMain__title}>
                        教室トップ情報を入力後に、<br/>
                        さらに掲載する情報を増やして集客強化しましょう！
                    </div>
                    <img className={styles.secondMain__img} src={tips} alt="入力のコツ"/>
                </div>
            </div>
            <Footer />
        </>
    );
};
