export const prefectureData =
    [
        {val: 1, label: '北海道'},
        {val: 2, label: '青森県'},
        {val: 3, label: '岩手県'},
        {val: 4, label: '宮城県'},
        {val: 5, label: '秋田県'},
        {val: 6, label: '山形県'},
        {val: 7, label: '福島県'},
        {val: 8, label: '茨城県'},
        {val: 9, label: '栃木県'},
        {val: 10, label: '群馬県'},
        {val: 11, label: '埼玉県'},
        {val: 12, label: '千葉県'},
        {val: 13, label: '東京都'},
        {val: 14, label: '神奈川県'},
        {val: 15, label: '新潟県'},
        {val: 16, label: '富山県'},
        {val: 17, label: '石川県'},
        {val: 18, label: '福井県'},
        {val: 19, label: '山梨県'},
        {val: 20, label: '長野県'},
        {val: 21, label: '岐阜県'},
        {val: 22, label: '静岡県'},
        {val: 23, label: '愛知県'},
        {val: 24, label: '三重県'},
        {val: 25, label: '滋賀県'},
        {val: 26, label: '京都府'},
        {val: 27, label: '大阪府'},
        {val: 28, label: '兵庫県'},
        {val: 29, label: '奈良県'},
        {val: 30, label: '和歌山県'},
        {val: 31, label: '鳥取県'},
        {val: 32, label: '島根県'},
        {val: 33, label: '岡山県'},
        {val: 34, label: '広島県'},
        {val: 35, label: '山口県'},
        {val: 36, label: '徳島県'},
        {val: 37, label: '香川県'},
        {val: 38, label: '愛媛県'},
        {val: 39, label: '高知県'},
        {val: 40, label: '福岡県'},
        {val: 41, label: '佐賀県'},
        {val: 42, label: '長崎県'},
        {val: 43, label: '熊本県'},
        {val: 44, label: '大分県'},
        {val: 45, label: '宮崎県'},
        {val: 46, label: '鹿児島県'},
        {val: 47, label: '沖縄県'},
    ];
