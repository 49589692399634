import React, { useCallback, useEffect, useState } from 'react';
import styles from './css/sytle.module.scss';
import { RadioInput } from '../../Common/Input/RadioInput';
import { SelectInput } from '../../Common/Input/SelectInput';
import { TextInput } from '../../Common/Input/TextInput';
import { prefectureData } from './data/prefecture';
import { SchoolsApi } from '../../../util/api/schools';
import { TownData } from '../../../util/type/School/TownData.type';
import { StreetData } from '../../../util/type/School/StreetData.type';
import searchIconImage from './img/search.svg';
import { InputValidateTypeEnum } from '../../Common/Input/TextInput/InputValidateType.enum';

type AddressInput = {
    returnFunc: (selectData: {
        withoutAddress: boolean;
        prefecture: number | null;
        town: number | null;
        street: number | null;
        addressDetail: string;
    }) => void;
    defaultWithoutAddress: boolean;
    defaultPrefectureId: number | null;
    defaultTown: number | null;
    defaultStreet: number | null;
    defaultAddressDetail: string;
};

export const AddressInput: React.FC<AddressInput> = (props) => {
    const { defaultWithoutAddress, returnFunc, defaultPrefectureId, defaultTown, defaultStreet, defaultAddressDetail } = props;

    const [withoutAddress, setWithoutAddress] = useState(defaultWithoutAddress);
    // 選択都道府県id
    const [prefecture, setPrefecture] = useState<number | null>(defaultPrefectureId);

    // 市区町村データ
    const [townData, setTownData] = useState<Array<TownData>>([]);
    // 市区町村データ取得
    const getTown = useCallback(async (prefectureId: number) => {
        try {
            const res = await SchoolsApi.getTownData(prefectureId.toString());
            setTownData(res.data);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }, []);
    // 選択市区町村id
    const [town, setTown] = useState<number | null>(defaultTown);

    // 大字町丁目データ
    const [streetData, setStreetData] = useState<Array<StreetData>>([]);
    // 大字町丁目サジェスト文字列
    const [streetSuggestStr, setStreetSuggestStr] = useState('');
    // 大字町丁目データ取得
    const getStreet = useCallback(async (townId: number, streetSuggestStr) => {
        try {
            const res = await SchoolsApi.getStreetData(townId.toString(), streetSuggestStr, null);
            setStreetData(res.data);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }, []);
    // 選択大字町丁目 表示UIの関係上、labelも合わせて保持する
    const [street, setStreet] = useState<{ value: number; label: string } | null>(null);

    // 選択その他住所
    const [addressDetail, setAddressDetail] = useState(defaultAddressDetail);

    useEffect(() => {
        if (defaultPrefectureId) {
            (async () => {
                await getTown(defaultPrefectureId);
            })();
        }

        if (defaultStreet && defaultTown) {
            (async () => {
                // 市区町村と大字町丁目に初期値があった場合、選択状態を再現する
                try {
                    const res = await SchoolsApi.getStreetData(defaultTown.toString(), '', [defaultStreet.toString()]);
                    setStreetData(res.data);
                    if (res.data) setStreet({ value: res.data[0].id, label: res.data[0].nameJp });
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        returnFunc({
            withoutAddress,
            prefecture,
            town,
            street: street ? street.value : null,
            addressDetail,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withoutAddress, addressDetail, prefecture, street, town]);

    return (
        <div className={styles.wrap}>
            {/* WithoutAddress -> 必須ではない よって有りを初期値とするためfalse */}
            <RadioInput
                label="実教室の有無"
                require
                name="withoutAddress"
                data={[
                    { id: 'withoutAddress_1', label: '有', value: 1 },
                    { id: 'withoutAddress_2', label: '無', value: 2 },
                ]}
                changeReturnFunc={(text) => setWithoutAddress(text === '2')}
                value={!withoutAddress ? '1' : '2'}
            />
            <div className={styles.annotation}>※スポーツ教室などで定期開催場所のある場合、町域までを選択し、具体的な場所を記入してください</div>

            <div className={`${styles.wrapLabel} ${!withoutAddress ? styles.wrapLabelRequire : ''}`}>住所</div>

            <div className={styles.addressWarp}>
                <div className={styles.innerWrap}>
                    <SelectInput
                        label="都道府県"
                        require={false}
                        name="prefecture"
                        id="prefecture"
                        data={prefectureData.map((d) => ({ value: d.val.toString(), label: d.label }))}
                        changeReturnFunc={async (data) => {
                            setPrefecture(Number(data));
                            await getTown(Number(data));
                            setTown(null);
                            setStreetSuggestStr('');
                            setStreet(null);
                        }}
                        defaultInput={prefecture ? prefecture.toString() : null}
                        disableSelectText="都道府県を選択"
                        disable={false}
                        selectStartIndex={null}
                        selectEndIndex={null}
                    />
                </div>

                <div className={styles.innerWrap}>
                    <SelectInput
                        label="市区町村"
                        require={false}
                        name="town"
                        id="town"
                        data={townData.map((d) => ({
                            value: d.id.toString(),
                            label: d.nameJp,
                        }))}
                        changeReturnFunc={async (data) => {
                            setTown(Number(data));
                            setStreetSuggestStr('');
                            setStreet(null);
                            await getStreet(Number(data), '');
                        }}
                        defaultInput={town ? town.toString() : null}
                        disableSelectText="市区町村を選択"
                        disable={false}
                        selectStartIndex={null}
                        selectEndIndex={null}
                    />
                </div>
                <div className={styles.streetWrap}>
                    <div className={styles.label}>町域</div>

                    <div className={styles.selected}>
                        {street === null ? (
                            <div className={styles.noSelected}>下部から市区町村を選択してください</div>
                        ) : (
                            <ul className={styles.selectedList}>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setStreet(null);
                                        }}>
                                        {street.label} ×
                                    </button>
                                </li>
                            </ul>
                        )}
                    </div>

                    <div className={styles.pickerWrap}>
                        <div className={styles.textInput}>
                            <img src={searchIconImage} alt="search" />
                            <input
                                type="text"
                                value={streetSuggestStr}
                                onChange={async (event) => {
                                    if (town) {
                                        setStreetSuggestStr(event.target.value);
                                        await getStreet(town, event.target.value);
                                    }
                                }}
                                placeholder="町域を入力後、以下から選択"
                            />
                        </div>

                        <ul className={styles.picker}>
                            {streetData.map((d) => (
                                <li key={`suggest_${d.id}`}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setStreet({
                                                value: d.id,
                                                label: d.nameJp,
                                            });
                                        }}>
                                        {d.nameJp}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.annotation}>※上部の入力エリアで、町域をサジェストし、選択してください</div>
                </div>

                <div className={styles['innerWrap--last']}>
                    <TextInput
                        label="それ以降の住所・建物名・定期開催場所など"
                        name="addressDetail"
                        id="addressDetail"
                        require={false}
                        inputType="text"
                        validateType={InputValidateTypeEnum.TEXT}
                        annotation={null}
                        changeReturnFunc={(text) => {
                            setAddressDetail(text);
                        }}
                        defaultInput={addressDetail}
                        placeHolder="それ以降の住所・建物名・定期開催場所を入力"
                        minLength={null}
                        defaultInputValue={addressDetail}
                        disabled={false}
                    />
                </div>
            </div>
        </div>
    );
};
