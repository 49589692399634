import React from 'react';
import styles from './css/style.module.scss';

type NewSchoolHeaderProps = {
    step: number,
}
export const NewSchoolHeader: React.FC<NewSchoolHeaderProps> = (props) => {
    const {step} = props;

    return (<div className={styles.wrap}>
        <div className={styles.title}>教室を新規作成</div>
        <div className={styles.description}>教室の基本情報、詳細情報を新規で作成します</div>
        <div className={styles.step}>
            <div className={`${styles.step__text} ${styles.active}`}>
                <span className={styles.step__number}>1</span>
                基本情報の入力</div>
            <div className={`${styles.step__border} ${step !== 1 ? styles.active : ""}`} />
            <div className={`${styles.step__text} ${step !== 1 ? styles.active : ""}`}>
                <span className={styles.step__number}>2</span>
                詳細情報の入力</div>
            <div className={`${styles.step__border} ${step === 4 ? styles.active : ""}`} />
            <div className={`${styles.step__text} ${step === 4 ? styles.active : ""}`}>
                <span className={styles.step__number}>3</span>
                完了</div>
        </div>
    </div>);
}