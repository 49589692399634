import { Action } from 'redux';
import { ClientActionTypes } from './index';
import { PasswordReissueOfferType } from '../../../reducers/domain/client/type/passwordReissueOffer.type';
import { passwordReissueOfferInitial } from '../../../reducers/domain/client/InitialState/passwordReissueOfferInitial';

// パスワード再発行申し込み
export const setPasswordReissueOfferEmail = (email: string): SetPasswordReissueOfferEmail => ({
    type: ClientActionTypes.SET_PASSWORD_REISSUE_OFFER_EMAIL,
    payload: { email },
});

export interface SetPasswordReissueOfferEmail extends Action {
    type: string;
    payload: { email: string };
}

export const setPasswordReissueOfferEmailValidate = (flag: boolean): SetPasswordReissueOfferEmailValidate => ({
    type: ClientActionTypes.SET_PASSWORD_REISSUE_OFFER_EMAIL_VALIDATE,
    payload: { flag },
});

export interface SetPasswordReissueOfferEmailValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const postPasswordReissueOfferEmail = (email: string): PostPasswordReissueOfferEmail => ({
    type: ClientActionTypes.POST_PASSWORD_REISSUE_OFFER_EMAIL,
    payload: { email },
});

export interface PostPasswordReissueOfferEmail extends Action {
    type: string;
    payload: { email: string };
}

export const setPasswordReissueOfferResult = (result: { sendEmail: string; flag: boolean; reason: string }): SetPasswordReissueOfferResult => ({
    type: ClientActionTypes.SET_PASSWORD_REISSUE_OFFER_RESULT,
    payload: { result },
});

export interface SetPasswordReissueOfferResult extends Action {
    type: string;
    payload: { result: { sendEmail: string; flag: boolean; reason: string } };
}

export const resetPasswordReissueOffer = (): ResetPasswordReissueOffer => ({
    type: ClientActionTypes.RESET_SIGN_IN_INPUT,
    payload: { initial: passwordReissueOfferInitial },
});

export interface ResetPasswordReissueOffer extends Action {
    type: string;
    payload: { initial: PasswordReissueOfferType };
}

// パスワード再発行
export const setPasswordRegisterCode = (registerCode: string): SetPasswordRegisterCode => ({
    type: ClientActionTypes.SET_PASSWORD_REGISTER_CODE,
    payload: { registerCode },
});

export interface SetPasswordRegisterCode extends Action {
    type: string;
    payload: { registerCode: string };
}

export const setPasswordRegisterPassword = (password: string): SetPasswordRegisterPassword => ({
    type: ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD,
    payload: { password },
});

export interface SetPasswordRegisterPassword extends Action {
    type: string;
    payload: { password: string };
}

export const setPasswordRegisterPasswordValidate = (flag: boolean): SetPasswordRegisterPasswordValidate => ({
    type: ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD_VALIDATE,
    payload: { flag },
});

export interface SetPasswordRegisterPasswordValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setPasswordRegisterPasswordConfirm = (passwordConfirm: string): SetPasswordRegisterPasswordConfirm => ({
    type: ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD_CONFIRM,
    payload: { passwordConfirm },
});

export interface SetPasswordRegisterPasswordConfirm extends Action {
    type: string;
    payload: { passwordConfirm: string };
}

export const setPasswordRegisterPasswordConfirmValidate = (flag: boolean): SetPasswordRegisterPasswordConfirmValidate => ({
    type: ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD_CONFIRM_VALIDATE,
    payload: { flag },
});

export interface SetPasswordRegisterPasswordConfirmValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setPasswordRegisterResult = (result: { flag: boolean; reason: string }): SetPasswordRegisterResult => ({
    type: ClientActionTypes.SET_PASSWORD_REGISTER_PASSWORD_RESULT,
    payload: { result },
});

export interface SetPasswordRegisterResult extends Action {
    type: string;
    payload: { result: { flag: boolean; reason: string } };
}

export const postPasswordRegister = (data: { token: string; password: string; passwordConfirm: string }): PostPasswordRegister => ({
    type: ClientActionTypes.POST_PASSWORD_REGISTER,
    payload: { data },
});

export interface PostPasswordRegister extends Action {
    type: string;
    payload: {
        data: {
            token: string;
            password: string;
            passwordConfirm: string;
        };
    };
}
