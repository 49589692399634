import { Action } from 'redux';
import { SchoolsActionTypes } from './index';
import { SchoolBlogData } from '../../../../util/type/School/SchoolBlogData.type';
import { SchoolDetailBlogInputType } from '../../../reducers/domain/schools/type/Blog/SchoolDetailBlogInput.type';
import { SchoolDetailBlogValidateType } from '../../../reducers/domain/schools/type/Blog/SchoolDetailBlogValidate.type';

export const getSchoolBlogList = (token: string, schoolId: number, limit: number, page: number): GetSchoolBlogList => ({
    type: SchoolsActionTypes.GET_SCHOOL_BLOG_LIST,
    payload: { schoolId, token, limit, page }
});

export interface GetSchoolBlogList extends Action {
    type: string;
    payload: {
        schoolId: number,
        token: string,
        limit: number,
        page: number
    };
}

export const setSchoolBlogList = (schoolBlogListData: Array<SchoolBlogData> | null): SetSchoolBlogList => ({
    type: SchoolsActionTypes.SET_SCHOOL_BLOG_LIST,
    payload: { schoolBlogListData }
});

export interface SetSchoolBlogList extends Action {
    type: string;
    payload: {
        schoolBlogListData: Array<SchoolBlogData> | null
    };
}

export const setSchoolBlogListCount = (schoolBlogListCount: number): SetSchoolBlogListCount => ({
    type: SchoolsActionTypes.SET_SCHOOL_BLOG_LIST_COUNT,
    payload: { schoolBlogListCount }
});

export interface SetSchoolBlogListCount extends Action {
    type: string;
    payload: {
        schoolBlogListCount: number
    };
}

export const getSchoolBlogDetail = (token: string, blogId: number): GetSchoolBlogDetail => ({
    type: SchoolsActionTypes.GET_SCHOOL_BLOG_DETAIL,
    payload: { blogId, token }
});

export interface GetSchoolBlogDetail extends Action {
    type: string;
    payload: {
        blogId: number,
        token: string,
    };
}

export const setSchoolBlogDetail = (schoolBlogDetailData: SchoolBlogData | null): SetSchoolBlogDetail => ({
    type: SchoolsActionTypes.SET_SCHOOL_BLOG_DETAIL,
    payload: { schoolBlogDetailData }
});

export interface SetSchoolBlogDetail extends Action {
    type: string;
    payload: {
        schoolBlogDetailData: SchoolBlogData | null
    };
}

export const setSchoolNewBlogInput = (input: SchoolDetailBlogInputType): SetSchoolNewBlogInput => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_BLOG_INPUT,
    payload: { input }
});

export interface SetSchoolNewBlogInput extends Action {
    type: string;
    payload: {
        input: SchoolDetailBlogInputType,
    };
}

export const setSchoolNewBlogValidate = (validate: SchoolDetailBlogValidateType): SetSchoolNewBlogValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_BLOG_VALIDATE,
    payload: { validate }
});

export interface SetSchoolNewBlogValidate extends Action {
    type: string;
    payload: {
        validate: SchoolDetailBlogValidateType,
    };
}

export const postNewSchoolBlog = (
    token: string,
    schoolId: string,
    data: SchoolDetailBlogInputType,
    withPublish: boolean,
): PostNewSchoolBlog => ({
    type: SchoolsActionTypes.POST_NEW_SCHOOL_BLOG,
    payload: { token, schoolId, data, withPublish}
});

export interface PostNewSchoolBlog extends Action {
    type: string;
    payload: {
        token: string,
        schoolId: string
        data: SchoolDetailBlogInputType,
        withPublish: boolean,
    };
}

export const patchSchoolBlogPublish = (authToken: string, schoolId: string,  blogId: string, inList: boolean): PatchSchoolBlogPublish => ({
    type: SchoolsActionTypes.PATCH_SCHOOL_BLOG_PUBLISH,
    payload: { authToken, schoolId, blogId, inList }
});

export interface PatchSchoolBlogPublish extends Action {
    type: string;
    payload: {
        authToken: string,
        blogId: string,
        inList: boolean,
        schoolId: string,
    };
}

export const patchSchoolBlogUnPublish = (authToken: string, schoolId: string, blogId: string, inList: boolean): PatchSchoolBlogUnPublish => ({
    type: SchoolsActionTypes.PATCH_SCHOOL_BLOG_UN_PUBLISH,
    payload: { authToken, schoolId, blogId, inList }
});

export interface PatchSchoolBlogUnPublish extends Action {
    type: string;
    payload: {
        authToken: string,
        blogId: string,
        inList: boolean,
        schoolId: string,
    };
}

export const setSchoolBlogEditFlag = (flag: boolean): estSchoolBlogEditFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_BLOG_EDIT_FLAG,
    payload: { flag }
});

export interface estSchoolBlogEditFlag extends Action {
    type: string;
    payload: {
        flag: boolean
    };
}

export const setSchoolBlogEditData = (editData: SchoolDetailBlogInputType | null): SetSchoolBlogEditData => ({
    type: SchoolsActionTypes.SET_SCHOOL_BLOG_EDIT_DATA,
    payload: { editData }
});

export interface SetSchoolBlogEditData extends Action {
    type: string;
    payload: {
        editData: SchoolDetailBlogInputType | null
    };
}

export const setSchoolBlogEditValidate = (validate: SchoolDetailBlogValidateType): PostSchoolBlogEditValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_BLOG_EDIT_VALIDATE,
    payload: { validate }
});

export interface PostSchoolBlogEditValidate extends Action {
    type: string;
    payload: {
        validate: SchoolDetailBlogValidateType
    };
}

export const putSchoolBlogEditData = (token: string, blogId: string, data: SchoolDetailBlogInputType,): PutSchoolBlogEditData => ({
    type: SchoolsActionTypes.PUT_SCHOOL_BLOG_DATA,
    payload: { token, blogId, data}
});

export interface PutSchoolBlogEditData extends Action {
    type: string;
    payload: {
        token: string,
        blogId: string
        data: SchoolDetailBlogInputType,
    };
}
