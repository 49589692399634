import React, { ChangeEvent, useEffect, useState } from 'react';
import * as queryString from 'querystring';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { getMyInquiries, setMyInquiriesCurrentPage } from '../../redux/actions/domain/mypage/inquiry';
import { getClient } from '../../redux/actions/domain/client/client';
import { SCHOOL_INQUIRY_PAGE_COUNT } from '../../util/const/schoolInqueryPageCount';
import { PageNation, TargetPage } from '../../components/Common/PageNation';
import { Header } from '../../components/Common/Header';
import { OwnerApplication } from '../../components/MyPage/OwnerApplication';
import { TabBar } from '../../components/MyPage/TabBar';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import { Footer } from '../../components/Common/Footer';
import { ShowMoreBtn } from '../../components/Common/ShowMoreBtn';
import { InquiryCard } from '../../components/Common/InquiryCard';
import styles from '../../asset/css/inquiryCommon.module.scss';
import { ZeroInquiry } from '../../components/MyPage/ZeroInquiry';

export const InquiriesList: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const MyPageState = useSelector((state: RootState) => state.rootReducer.domain.myPage);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const query = queryString.parse(search.replace('?', ''));
    const page = query.page ? Number(query.page) : 0;
    const [selectedSchool, setSelectedSchool] = useState('');

    useEffect(() => {
        // client情報の取得
        dispatch(setMyInquiriesCurrentPage(page));
        dispatch(getClient(authToken));
        // お問い合わせ情報取得
        dispatch(getMyInquiries(authToken, SCHOOL_INQUIRY_PAGE_COUNT, page));
    }, [authToken, dispatch, history, page]);

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedSchool(e.target.value.toString());
    };

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/inquiry" />
            <div className={styles.inquiryContainer}>
                <BreadCrumb
                    data={[
                        { text: 'マイページ', path: '/mypage' },
                        { text: 'お問い合わせ一覧', path: '/inquiry' },
                    ]}
                />
                {MyPageState.myPageData.inquiries.length !== 0 ? (
                    <>
                        <h2>お問い合わせ({MyPageState.myPageData.inquiriesCount})</h2>
                        <div className={styles.sort}>
                            <p>教室・スクールを名前で絞り込む</p>
                            <select
                                className={
                                    selectedSchool === ''
                                        ? `${styles.sort__selectBoxWrap} ${styles['sort__selectBoxWrap--default']}`
                                        : styles.sort__selectBoxWrap
                                }
                                onChange={handleChange}
                                value={selectedSchool}>
                                <option key="inquiry_select_option_id_0" className={styles.sort__defaultOption} value="">
                                    教室・スクール名を選択
                                </option>
                                {MyPageState.myPageData.schools.map((d) => (
                                    <option key={`inquiry_select_option_id_${d.id}`} value={d.id.toString()}>
                                        {d.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {MyPageState.myPageData.inquiries.length !== 0 && (
                            <ul>
                                {MyPageState.myPageData.inquiries
                                    .filter((d) => {
                                        if (selectedSchool !== '') {
                                            return d.schoolId.toString() === selectedSchool;
                                        }
                                        return true;
                                    })
                                    .map((d) => (
                                        <InquiryCard key={`inquiry_id_${d.id}`} schoolData={MyPageState.myPageData.schools} inquiryData={d} />
                                    ))}
                            </ul>
                        )}
                    </>
                ) : (
                    <>
                        <ZeroInquiry />
                    </>
                )}

                {page < Math.floor(MyPageState.myPageData.inquiriesCount / SCHOOL_INQUIRY_PAGE_COUNT) && (
                    <ShowMoreBtn text={`次の${SCHOOL_INQUIRY_PAGE_COUNT}件を見る`} onClick={() => history.push(`/inquiry?page=${page + 1}`)} />
                )}
                <PageNation page={page} dataLength={MyPageState.myPageData.inquiriesCount} path="/inquiry" targetPage={TargetPage.SCHOOL} />
            </div>
            <Footer />
        </>
    );
};
