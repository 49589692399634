import { ClientDataType } from '../../client/type/clientData.type';
import { EditClientInputType } from '../type/EditClientInput.type';

export const clientToEditClient = (clientData: ClientDataType): EditClientInputType | null => {
    if (!clientData) return null;
    return {
        name: clientData.name,
        representativeName: clientData.representativeName,
        phone: clientData.phone,
        addressDetail: clientData.addressDetail,
    }
}