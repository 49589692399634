import React, { useEffect, useState } from 'react';
import styles from './css/style.module.scss';
import { SelectInput } from '../../Common/Input/SelectInput';
import { schoolCategoryMaster } from '../../../util/const/Schools/schoolCategoryMaster';
import { schoolSubCategoryMaster } from '../../../util/const/Schools/schoolSubCategoryMaster';

type CategorySelectProps = {
    returnFunc: (value: string) => void;
    defaultInputValue: number;
    annotation: string;
};

export const CategorySelect: React.FC<CategorySelectProps> = (props) => {
    const { returnFunc, defaultInputValue, annotation } = props;

    const [selectCategory, setSelectCategory] = useState('');

    useEffect(() => {
        if (defaultInputValue !== 0) {
            schoolSubCategoryMaster.forEach((d, index) => {
                if (d.find((subData) => subData.value === defaultInputValue.toString())) {
                    setSelectCategory((index + 1).toString());
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`${styles.label} ${styles.require}`}>カテゴリー</div>
            <div className={styles.selectWrap}>
                <SelectInput
                    label=""
                    require={false}
                    name="category"
                    id="category"
                    data={schoolCategoryMaster}
                    changeReturnFunc={(text: string) => {
                        returnFunc('');
                        setSelectCategory(text);
                    }}
                    defaultInput={selectCategory}
                    disableSelectText="大カテゴリーを選択"
                    disable={false}
                    selectStartIndex={null}
                    selectEndIndex={null}
                />
            </div>
            <div className={styles['selectWrap--end']}>
                <SelectInput
                    label=""
                    require={false}
                    name="subCategory"
                    id="category"
                    data={selectCategory ? schoolSubCategoryMaster[Number(selectCategory) - 1] : []}
                    changeReturnFunc={(text: string) => {
                        returnFunc(text);
                    }}
                    defaultInput={defaultInputValue !== 0 ? defaultInputValue.toString() : null}
                    disableSelectText="カテゴリーを選択"
                    disable={false}
                    selectStartIndex={null}
                    selectEndIndex={null}
                />
            </div>
            {annotation && <div className={styles.annotation}>{annotation}</div>}
        </>
    );
};
