import { Action } from "redux";
import { SchoolsActionTypes } from './index';
import { SchoolDetailEditImagesType } from '../../../reducers/domain/schools/type/Top/SchoolDetailEditImages.type';

export const setSchoolTopNoticeEditFlag = (flag: boolean): SetSchoolTopNoticeEditFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_TOP_NOTICE_EDIT_FLAG,
    payload: { flag }
});

export interface SetSchoolTopNoticeEditFlag extends Action {
    type: string;
    payload: {
        flag: boolean
    };
}

export const setSchoolTopImagesEditFlag = (flag: boolean): SetSchoolTopImagesEditFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_TOP_IMAGES_EDIT_FLAG,
    payload: { flag }
});

export interface SetSchoolTopImagesEditFlag extends Action {
    type: string;
    payload: {
        flag: boolean
    };
}

export const setSchoolTopQaEditFlag = (flag: boolean): SetSchoolTopQaEditFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_TOP_QA_EDIT_FLAG,
    payload: { flag }
});

export interface SetSchoolTopQaEditFlag extends Action {
    type: string;
    payload: {
        flag: boolean
    };
}

export const setSchoolTopImages = (schoolDetailEditImages: SchoolDetailEditImagesType): SetSchoolTopImages => ({
    type: SchoolsActionTypes.SET_SCHOOL_TOP_IMAGES,
    payload: { schoolDetailEditImages }
});

export interface SetSchoolTopImages extends Action {
    type: string;
    payload: {
        schoolDetailEditImages: SchoolDetailEditImagesType
    };
}
