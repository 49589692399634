/**
 * スクール課金状態
 */
export enum BillingStatus {
    // 有料課金
    PaidBilling = 1,
    // 無料
    Free = 2,
    // クライアントが存在しない
    Undecided = 100,
}