import { SchoolDetailCouponInputType } from '../type/Coupon/SchoolDetailCouponInput.type';
import { SchoolCouponData } from '../../../../../util/type/School/SchoolCouponData.type';

export const schoolCouponTolCouponInputData = (schoolBlogData: SchoolCouponData): SchoolDetailCouponInputType => ({
    name: schoolBlogData.name,
    description: schoolBlogData.description,
    usableDate: schoolBlogData.usableDate,
    limitDate: schoolBlogData.limitDate,
    presentationConditions: schoolBlogData.presentationConditions,
    utilizationConditions: schoolBlogData.utilizationConditions,
    sortId: schoolBlogData.sortId,
    image: null,
    deletingImage: false,
});