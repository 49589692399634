import { Action } from 'redux';
import { ContractsActionTypes } from './index';
import { SettlementData } from '../../../../util/type/School/SettlementData.type';

// 決済履歴取得
export const getSettlements = (authToken: string, page: number, limit: number): GetSettlements => ({
    type: ContractsActionTypes.GET_SETTLEMENT_LIST,
    payload: {
        authToken,
        page,
        limit,
    },
});

export interface GetSettlements extends Action {
    type: string;
    payload: {
        authToken: string;
        page: number;
        limit: number;
    };
}

// 決済履歴
export const setSettlements = (settlements: Array<SettlementData>): SetSettlements => ({
    type: ContractsActionTypes.SET_SETTLEMENT_LIST,
    payload: { settlements },
});

export interface SetSettlements extends Action {
    type: string;
    payload: {
        settlements: Array<SettlementData>;
    };
}

// 決済履歴数
export const setSettlementsCount = (settlementsCount: number): SetSettlementsCount => ({
    type: ContractsActionTypes.SET_SETTLEMENT_LIST_COUNT,
    payload: { settlementsCount },
});

export interface SetSettlementsCount extends Action {
    type: string;
    payload: { settlementsCount: number };
}

// 決済履歴現在ページ
export const setSettlementsCurrentPage = (currentPage: number): SetSettlementsCurrentPage => ({
    type: ContractsActionTypes.SET_SETTLEMENT_CURRENT_PAGE,
    payload: { currentPage },
});

export interface SetSettlementsCurrentPage extends Action {
    type: string;
    payload: { currentPage: number };
}
