import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './css/style.module.scss';
import { setCommonModalShow } from '../../../../redux/actions/ui';

export const SecondSchoolConfirm: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className={styles.wrap}>
            <div className={styles.textWrap}>
                <div className={styles.textTitle}>有料お問い合わせ機能の控除について</div>
                <div className={styles.text}>
                    スクール・教室情報を1件のみ公開されている場合には、有料プランをご選択された際に通常掛かる「問合せ従量課金（3,300円/件）」が控除されております（小規模事業主様向け問い合わせ課金控除）。
                    <br />
                    <br />
                    スクール・教室情報を2件以上公開する場合には、「小規模事業主様向け問い合わせ課金控除」の対象外となり、問合せが発生した場合には1件あたり3,300円（税込）の課金が発生いたします。
                </div>
            </div>

            <div className={styles.buttonWrap}>
                <button className={styles.confirmButton} type="button" onClick={() => dispatch(setCommonModalShow(false))}>
                    2つめのスクールを公開する
                </button>
                <button
                    className={styles.cancelButton}
                    type="button"
                    onClick={() => {
                        dispatch(setCommonModalShow(false));
                        history.goBack();
                    }}>
                    キャンセル
                </button>
            </div>
        </div>
    );
};
