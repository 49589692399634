import { Action } from 'redux';
import { ClientActionTypes } from './index';
import { SignInInputType } from '../../../reducers/domain/client/type/signInInput.type';
import { signInInputInitial } from '../../../reducers/domain/client/InitialState/signInInputInitial';

// ログイン
export const setSignInEmail = (email: string): SetSignInEmail => ({
    type: ClientActionTypes.SET_SIGN_IN_EMAIL,
    payload: { email },
});

export interface SetSignInEmail extends Action {
    type: string;
    payload: { email: string };
}

export const setSignInPassword = (password: string): SetSignInPassword => ({
    type: ClientActionTypes.SET_SIGN_IN_PASSWORD,
    payload: { password },
});

export interface SetSignInPassword extends Action {
    type: string;
    payload: { password: string };
}

export const setSignInEmailValidate = (flag: boolean): SetSignInEmailValidate => ({
    type: ClientActionTypes.SET_SIGN_IN_EMAIL_VALIDATE,
    payload: { flag },
});

export interface SetSignInEmailValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setSignInPasswordValidate = (flag: boolean): SetSignInPasswordValidate => ({
    type: ClientActionTypes.SET_SIGN_IN_PASSWORD_VALIDATE,
    payload: { flag },
});

export interface SetSignInPasswordValidate extends Action {
    type: string;
    payload: { flag: boolean };
}

export const setSignInResult = (result: { flag: boolean; reason: string }): SetSignInResult => ({
    type: ClientActionTypes.SET_SIGN_IN_RESULT,
    payload: { result },
});

export interface SetSignInResult extends Action {
    type: string;
    payload: { result: { flag: boolean; reason: string } };
}

export const resetSignInInput = (): ResetSignInInput => ({
    type: ClientActionTypes.RESET_SIGN_IN_INPUT,
    payload: { initial: signInInputInitial },
});

export interface ResetSignInInput extends Action {
    type: string;
    payload: { initial: SignInInputType };
}
