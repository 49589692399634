import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { TabBar } from '../../components/MyPage/TabBar';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import { SchoolH3 } from '../../components/Schools/SchoolH3';
import { Header } from '../../components/Common/Header';
import { Footer } from '../../components/Common/Footer';
import { GreenBtn } from '../../components/Common/GreenBtn';
import { TextArea } from '../../components/Common/Input/TextArea';
import { InputValidateTypeEnum } from '../../components/Common/Input/TextInput/InputValidateType.enum';
import { ImageUploader, ImageUploaderSize } from '../../components/SchoolInput/ImageUploader';
import { ClientType } from '../../redux/reducers/domain/client/enum/clientType.enum';
import styles from '../../asset/css/myPageCommon.module.scss';
import { postDocuments, setSendDocumentsResult } from '../../redux/actions/domain/settings/submission';
import human from './img/human.png';
import { getClient } from '../../redux/actions/domain/client/client';


export const SubmissionPage: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const clientSelector = useSelector((state: RootState) => state.rootReducer.domain.client);
    const settingSelector = useSelector((state: RootState) => state.rootReducer.domain.settings);
    const dispatch = useDispatch();
    const history = useHistory();
    const today = new Date();

    const [images1, setImages1] = useState<File | null>(null);
    const [images2, setImages2] = useState<File | null>(null);
    const [images3, setImages3] = useState<File | null>(null);
    const [note, setNote] = useState('');

    useEffect(() => {
        dispatch(getClient(authToken))
        if (clientSelector.clientData?.activationPending || clientSelector.clientData?.activated) {
            history.push('/settings');
        }
        dispatch(setSendDocumentsResult(null));
    }, [authToken, clientSelector.clientData?.activated, clientSelector.clientData?.activationPending, dispatch, history]);

    if (settingSelector.SendDocuments.sendResult && settingSelector.SendDocuments.sendResult.flag) {
        return (<>
            <Header />
            <TabBar currentPage='/settings' />
            <div className={styles.myPageContainer}>
                <BreadCrumb data={[{ text: 'マイページ', path: '/mypage' }, { text: 'お問い合わせ一覧', path: '' }]} />
                <SchoolH3 otherEditPlace={null}
                    text='本人・事業運営者確認書類提出'
                    editable={false}
                    ButtonFunc={null}
                    isShow={null}
                />
                <div className={styles.sendDocumentResultTitle}>書類の提出が完了しました</div>
                <div className={styles.authContainer}>
                    <div className={styles.confirmation}>
                        <div className={styles.confirmation__conversation}>Thank you !!</div>
                        <div className={styles.confirmation__text}>書類のご提出ありがとうございました</div>
                        <div className={`${styles.confirmation__img} ${styles["confirmation__img--human"]}`}>
                            <img src={human} alt='' />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        );
    }

    return (<>
        <Header />
        <TabBar currentPage='/settings' />
        <div className={styles.myPageContainer}>
            <BreadCrumb data={[{ text: 'マイページ', path: '/mypage' }, { text: 'お問い合わせ一覧', path: '' }]} />
            <SchoolH3 otherEditPlace={null}
                text='本人・事業運営者確認書類提出'
                editable={false}
                ButtonFunc={null}
                isShow={null}
            />
            <div className={styles.identificationContainer}>
                <div className={styles.identificationDescription}>
                    安心・安全にご利用いただくために、本人情報の確認にご協力をお願いしています。
                    <span>提出された本人確認書類や入力いただく情報が、orioriの他のユーザーに公開されることはありません。</span><br />
                    orioriに登録している情報と本人確認書類に記載されている情報が異なる場合には、本人確認ができませんのでご注意ください。
                </div>
                {clientSelector.clientData?.type === ClientType.Enterprise ? (
                    <>
                        <div className={styles.identificationCaution}>
                            <div
                                className={`${styles.identificationCaution__title} ${styles['identificationCaution__title--green']}`}>履歴事項全部証明書の提出上のお願いとご注意
                            </div>
                            <ul className={styles.identificationCaution__list}>
                                <li className={styles.identificationCaution__item}>・書類をスキャン、または撮影し<span>画像化したもの</span>を<span>アップロード</span>してください
                                </li>
                                <li className={styles.identificationCaution__item}>・発行から3ヶ月以内のものを使用してください</li>
                                <li className={styles.identificationCaution__item}>・プロフィール情報にご登録の住所が確認できるものを使用してください</li>
                            </ul>
                            <div className={styles.identificationCaution__text}>
                                    プロフィールにご登録の住所<span>「{clientSelector.clientData.addressDetail}」</span>が<span>確認できる履歴事項全部証明書を提出</span>してください。<br />
                                    上記住所に誤りがある場合、<Link to='/settings\'>アカウント情報</Link>から内容の変更をしてください。<br />
                                    変更後、履歴事項全部証明書をご提出ください。<br />
                            </div>
                            <div className={styles.identificationCaution__text}>
                                    履歴事項全部証明書は3ヶ月以内に取得のものが有効となります。<span>本日{today.getFullYear()}年{today.getMonth() + 1}月{today.getDate()}日</span>にご提出の場合は、<span>{today.getFullYear()}年{today.getMonth() - 2}月{today.getDate()}日以降に取得した証明書であること</span>をご確認ください。
                            </div>
                        </div>
                        <div className={styles.identificationTitle}>履歴事項全部証明書をアップロード<span>必須</span></div>
                        <div className={styles.identificationImageWrap}>
                            <ImageUploader
                                uiSize={ImageUploaderSize.IDENTIFICATION}
                                identificationTitle='1:履歴事項全部証明書'
                                identificationText={['１ページ目など']}
                                defaultImageUrl={null}
                                returnFunc={(file, deleteFlag) => {
                                    if (deleteFlag) {
                                        setImages1(null);
                                    } else {
                                        setImages1(file);
                                    }
                                }}
                            />
                            <ImageUploader
                                uiSize={ImageUploaderSize.IDENTIFICATION}
                                identificationTitle='2:履歴事項全部証明書'
                                identificationText={['２ページ目など']}
                                defaultImageUrl={null}
                                returnFunc={(file, deleteFlag) => {
                                    if (deleteFlag) {
                                        setImages2(null);
                                    } else {
                                        setImages2(file);
                                    }
                                }}
                            />
                            <ImageUploader
                                uiSize={ImageUploaderSize.IDENTIFICATION}
                                identificationTitle='3:補助書類など'
                                identificationText={['その他、補助書類や履歴事項全部証明書のページなど']}
                                defaultImageUrl={null}
                                returnFunc={(file, deleteFlag) => {
                                    if (deleteFlag) {
                                        setImages3(null);
                                    } else {
                                        setImages3(file);
                                    }
                                }}
                            />
                        </div>
                        <div className={styles.identificationImageUploaderNote}>※10MB以下のサイズの画像をアップロードしてください</div>
                        <TextArea
                            label='備考'
                            name='editBlogText'
                            id='editBlogText'
                            require={false}
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text) => setNote(text)}
                            defaultInput={note}
                            placeHolder='備考'
                            forReview={false}
                            forInquiry={false}
                            countFlag
                            maxLength={500}
                            minLength={0}
                            height={174}
                        />
                        <GreenBtn
                            style={!(images1 !== null || images2 !== null || images3 !== null) ? {
                                pointerEvents: 'none',
                                opacity: 0.3
                            } : {}}
                            text='本人確認書類を提出する'
                            handleClick={() => {
                                dispatch(postDocuments(
                                    authToken,
                                    images1,
                                    images2,
                                    images3,
                                    note
                                ));
                            }}
                            isLink={false}
                            path=''
                        />
                    </>)
                    : (
                        <>
                            <div className={styles.identificationCaution}>
                                <div
                                    className={`${styles.identificationCaution__title} ${styles['identificationCaution__title--green']}`}>書類提出上のお願いとご注意
                                </div>
                                <ul className={styles.identificationCaution__list}>
                                    <li className={styles.identificationCaution__item}>・書類をスキャン、または撮影し<span>画像化したもの</span>を<span>アップロード</span>してください
                                    </li>
                                    <li className={styles.identificationCaution__item}>・健康保険証は「記号」「番号」「保険者番号」に、必ずマスキング（紙などで隠すこと）してください</li>
                                    <li className={styles.identificationCaution__item}>・マスキングされていない健康保険証は、再申請となります</li>
                                </ul>
                                <div className={styles.identificationCaution__title}>本人確認書類として使用できるもの</div>
                                <table>
                                    <tbody>
                                        <tr className={styles.identificationCaution__firstTr}>
                                            <th>本人確認書類</th>
                                            <th>提出するページ</th>
                                            <th>注意事項</th>
                                        </tr>
                                        <tr>
                                            <th>パスポート</th>
                                            <td>1.顔写真入りページ<br />
                                            2.住所記入欄ページ
                                            </td>
                                            <td>・日本国発行のもの<br />
                                            ・有効期限内のもの<br />
                                                <span>【複数住所の記載がある場合】<br />
                                            現住所以外を取り消し線等で無効な状態にしてください</span></td>
                                        </tr>
                                        <tr>
                                            <th>運転免許証</th>
                                            <td>1.表<br />
                                            2.裏
                                            </td>
                                            <td>・有効期限内のもの</td>
                                        </tr>
                                        <tr>
                                            <th>健康保険証＋住民票</th>
                                            <td>1.健康保険証の表<br />
                                            2.健康保険証の裏<br />
                                            3.住民票
                                            </td>
                                            <td>【住民票について】<br />
                                            ・発行から６ヶ月以内のもの<br />
                                            ・マイナンバー記載のないもの<br />
                                                <span>【複数住所の記載がある場合】<br />
                                            現住所以外を取り消し線等で無効な状態にしてください</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>住民基本台帳カード</th>
                                            <td>1.表<br />
                                            2.裏
                                            </td>
                                            <td>・顔写真付きのもの</td>
                                        </tr>
                                        <tr>
                                            <th>特別永住権証明書</th>
                                            <td>1.表<br />
                                            2.裏
                                            </td>
                                            <td>・有効期限内のもの</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={styles.identificationCaution__title}>本人確認書類として使用できないもの</div>
                                <ul className={styles.identificationCaution__list}>
                                    <li className={styles.identificationCaution__item}>・<span>マイナンバーカード</span>（個人番号カード、通知カード）は<span>ご使用いただけません</span>
                                    </li>
                                    <li className={styles.identificationCaution__item}>・<span>海外発行の書類提出</span>は、<span>ご使用いただけません</span>
                                    </li>
                                    <li className={styles.identificationCaution__item}>・<span>2020年2月4日以降発行のパスポート</span>は住所の確認ができないため、<span>ご使用いただけません</span>
                                    </li>
                                </ul>
                            </div>


                            <div className={styles.identificationTitle}>本人確認書類をアップロード<span>必須</span></div>
                            <div className={styles.identificationImageWrap}>
                                <ImageUploader
                                    uiSize={ImageUploaderSize.IDENTIFICATION}
                                    identificationTitle='1:表面など'
                                    identificationText={['各種書類の表', 'パスポートの顔写真入りページ']}
                                    defaultImageUrl={null}
                                    returnFunc={(file, deleteFlag) => {
                                        if (deleteFlag) {
                                            setImages1(null);
                                        } else {
                                            setImages1(file);
                                        }
                                    }}
                                />
                                <ImageUploader
                                    uiSize={ImageUploaderSize.IDENTIFICATION}
                                    identificationTitle='2:裏面など'
                                    identificationText={['各種書類の裏', 'パスポートの住所記入欄ページ']}
                                    defaultImageUrl={null}
                                    returnFunc={(file, deleteFlag) => {
                                        if (deleteFlag) {
                                            setImages2(null);
                                        } else {
                                            setImages2(file);
                                        }
                                    }}
                                />
                                <ImageUploader
                                    uiSize={ImageUploaderSize.IDENTIFICATION}
                                    identificationTitle='3:補助書類など'
                                    identificationText={['住民表', 'その他、補助書類など']}
                                    defaultImageUrl={null}
                                    returnFunc={(file, deleteFlag) => {
                                        if (deleteFlag) {
                                            setImages3(null);
                                        } else {
                                            setImages3(file);
                                        }
                                    }}
                                />
                            </div>
                            <div className={styles.identificationImageUploaderNote}>※10MB以下のサイズの画像をアップロードしてください</div>
                            <TextArea
                                label='備考'
                                name='editBlogText'
                                id='editBlogText'
                                require={false}
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation={null}
                                changeReturnFunc={(text) => setNote(text)}
                                defaultInput={note}
                                placeHolder='備考'
                                forReview={false}
                                forInquiry={false}
                                countFlag
                                maxLength={500}
                                minLength={0}
                                height={174}
                            />
                            <GreenBtn
                                style={!(images1 !== null || images2 !== null || images3 !== null) ? {
                                    pointerEvents: 'none',
                                    opacity: 0.3
                                } : {}}
                                text='本人確認書類を提出する'
                                handleClick={() => {
                                    dispatch(postDocuments(
                                        authToken,
                                        images1,
                                        images2,
                                        images3,
                                        note
                                    ));
                                }}
                                isLink={false}
                                path=''
                            />
                        </>)}
                <div className={styles.identificationSecurity}>
                    ご提出頂いた個人情報は、暗号化技術であるSSL（secure sockets layer）による暗号化を施し、通信経路における安全性を確保しております。<br />
                    また、プライバシーポリシーに定めるとおり、ご提出頂いた個人情報の滅失、き損、漏洩及び不正利用等を防止し、その安全管理を行うために必要な措置を講じ、個人情報を安全に管理します。
                </div>
            </div>
        </div>
        <Footer />
    </>);
};