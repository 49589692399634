import React from 'react';
import styles from './css/style.module.scss';
import { GreenBtn } from '../../Common/GreenBtn';


type ZeroSchoolProps = {
    handleClick:any;
};


export const ZeroSchool: React.FC<ZeroSchoolProps> = (props: ZeroSchoolProps) => {
    const {handleClick} = props;
    return(
        <>
            <div className={styles.borderBox}>
                <div className={styles.borderBox__title}>教室・スクールを作成してください</div>
                <div className={styles.borderBox__text}>
                    あなたの教室はまだ登録されていません
                    <br/>
                    教室を追加してください
                </div>
                <GreenBtn 
                    text="教室・スクール情報を作成する"
                    style={null}
                    handleClick={handleClick}
                    isLink
                    path="/school/new_1"
                />
            </div>
        </>
    )}