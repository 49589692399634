import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { MyPageActionTypes } from '../redux/actions/domain/mypage';
import { getMySchools, setMySchools, setMySchoolsCount } from '../redux/actions/domain/mypage/schools';
import { MyPageApi } from '../util/api/mypage';
import { setAuthToken, setClientDataEntered } from '../redux/actions/app';
import { SchoolDetail } from '../util/type/SchoolDetail.type';
import { getMyInquiries, setMyInquiries, setMyInquiriesCount } from '../redux/actions/domain/mypage/inquiry';
import { SchoolInquiryDataType } from '../util/type/SchoolInquiryData.type';

// スクール一覧取得
function* fetchClientSchools({ payload }: ReturnType<typeof getMySchools>) {
    try {
        const res: AxiosResponse<{
            data: Array<SchoolDetail>,
            count: number
        }> = yield call(MyPageApi.getClientSchools, payload.authToken, payload.limit, payload.page);
        yield put(setMySchools(res.data.data));
        yield put(setMySchoolsCount(res.data.count));

    } catch (e) {
        const err = e as AxiosError
        if (err.response) {
            const statusCode = err.response.status;
            const responseBody = err.response.data.message;
            if (statusCode === 401) {
                put(setAuthToken(''));
                return;
            }
            if (statusCode === 403 && responseBody === 'NotEnteredClient') {
                put(setClientDataEntered(false));
            }
        }
    }
}

export function* getClientSchoolsAsync() {
    yield takeEvery(MyPageActionTypes.GET_MY_SCHOOLS, fetchClientSchools);
}


// お問い合わせ一覧取得
function* fetchClientInquiries({ payload }: ReturnType<typeof getMyInquiries>) {
    try {
        const res: AxiosResponse<{
            data: Array<SchoolInquiryDataType>,
            count: number
        }> = yield call(MyPageApi.getClientInquiries, payload.authToken, payload.limit, payload.page);
        yield put(setMyInquiries(res.data.data));
        yield put(setMyInquiriesCount(res.data.count));

    } catch (e) {
        const err = e as AxiosError
        if (err.response) {
            const statusCode = err.response.status;
            if (statusCode === 401) {
                put(setAuthToken(''));
            }
        }
    }
}

export function* getClientInquiriesAsync() {
    yield takeEvery(MyPageActionTypes.GET_MY_INQUIRIES, fetchClientInquiries);
}
