import { schoolDetailInstructorInputInitial } from './schoolDetailInstructorInputInitial';
import { SchoolDetailInstructorType } from '../../type/Instructor/SchoolDetailInstructor.type';
import { schoolDetailInstructorValidateInitial } from './schoolDetailInstructorValidateInitial';

export const SchoolDetailInstructorInitial: SchoolDetailInstructorType = {
    list: null,
    detail: null,
    newFlag: false,
    new: schoolDetailInstructorInputInitial,
    newValidate: schoolDetailInstructorValidateInitial,
    edit: null,
    editValidate: null,
    editIndex: null,
}