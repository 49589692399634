import { combineReducers } from 'redux';
import { inviteUser } from './inviteUser';
import { createUser } from './createUser';
import { clientUsers } from './clientUsers';
import { changeEmail } from './changeEmail';

export const user = combineReducers({
    inviteUser,
    createUser,
    clientUsers,
    changeEmail,
})
