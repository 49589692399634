import { SchoolDetailBlogType } from './type/Blog/SchoolDetailBlog.type';
import { schoolDetailBlogInitial } from './InitialState/Blog/schoolDetailBlogInitial';
import { SchoolsActionTypes } from '../../../actions/domain/schools';

export const SchoolDetailBlog = (state: SchoolDetailBlogType = schoolDetailBlogInitial, action: any) => {
    switch (action.type) {
        case SchoolsActionTypes.SET_SCHOOL_BLOG_LIST:
            return {
                ...state,
                list: action.payload.schoolBlogListData
            };

        case SchoolsActionTypes.SET_SCHOOL_BLOG_LIST_COUNT:
            return {
                ...state,
                listCount: action.payload.schoolBlogListCount
            };

        case SchoolsActionTypes.SET_SCHOOL_BLOG_DETAIL:
            return {
                ...state,
                detail: action.payload.schoolBlogDetailData
            };

        case SchoolsActionTypes.SET_SCHOOL_NEW_BLOG_INPUT:
            return {
                ...state,
                new: action.payload.input
            };
        case SchoolsActionTypes.SET_SCHOOL_NEW_BLOG_VALIDATE:
            return {
                ...state,
                newValidate: action.payload.validate
            };
        case SchoolsActionTypes.SET_SCHOOL_BLOG_EDIT_FLAG:
            return {
                ...state,
                editFlag: action.payload.flag
            };
        case SchoolsActionTypes.SET_SCHOOL_BLOG_EDIT_DATA:
            return {
                ...state,
                edit: action.payload.editData
            };

        case SchoolsActionTypes.SET_SCHOOL_BLOG_EDIT_VALIDATE:
            return {
                ...state,
                editValidate: action.payload.validate
            };

        default:
            return state;
    }
};