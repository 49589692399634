import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    getSchoolBlogDetail,
    getSchoolBlogList,
    patchSchoolBlogPublish,
    patchSchoolBlogUnPublish,
    postNewSchoolBlog,
    putSchoolBlogEditData,
    setSchoolBlogDetail,
    setSchoolBlogEditData,
    setSchoolBlogEditFlag,
    setSchoolBlogList,
    setSchoolBlogListCount,
    setSchoolNewBlogInput,
    setSchoolNewBlogValidate,
} from '../../redux/actions/domain/schools/schoolBlog';
import { SchoolBlogData } from '../../util/type/School/SchoolBlogData.type';
import { SchoolsApi } from '../../util/api/schools';
import { SchoolsActionTypes } from '../../redux/actions/domain/schools';
import { SCHOOL_BLOG_COUNT } from '../../util/const/schoolBlogCount';
import { schoolBlogTolBlogInputData } from '../../redux/reducers/domain/schools/converter/schoolBlogTolBlogInputData';
import { schoolDetailBlogInputInitial } from '../../redux/reducers/domain/schools/InitialState/Blog/schoolDetailBlogInputInitial';
import { schoolDetailBlogValidateInitial } from '../../redux/reducers/domain/schools/InitialState/Blog/schoolDetailBlogValidateInitial';

// スクールブログ一覧取得
function* fetchSchoolBlogList({ payload }: ReturnType<typeof getSchoolBlogList>) {
    try {
        const res: AxiosResponse<{
            data: Array<SchoolBlogData>;
            count: number;
        }> = yield call(SchoolsApi.getSchoolBlogList, payload.token, payload.schoolId, payload.limit, payload.page);
        yield put(setSchoolBlogListCount(res.data.count));
        yield put(setSchoolBlogList(res.data.data));
    } catch (e) {
        // TODO 404
        yield put(setSchoolBlogList(null));
    }
}

export function* getSchoolBlogAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_BLOG_LIST, fetchSchoolBlogList);
}

// スクールブログ詳細取得
function* fetchSchoolBlogDetail({ payload }: ReturnType<typeof getSchoolBlogDetail>) {
    try {
        const res: AxiosResponse<SchoolBlogData> = yield call(SchoolsApi.getSchoolBlogDetail, payload.token, payload.blogId);
        yield put(setSchoolBlogDetail(res.data));
        yield put(setSchoolBlogEditData(schoolBlogTolBlogInputData(res.data)));
    } catch (e) {
        // TODO 404
        yield put(setSchoolBlogDetail(null));
    }
}

export function* getSchoolBlogDetailAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_BLOG_DETAIL, fetchSchoolBlogDetail);
}

// スクールブログ新規登録
function* postSchoolNewBlog({ payload }: ReturnType<typeof postNewSchoolBlog>) {
    try {
        const res: AxiosResponse<SchoolBlogData> = yield call(SchoolsApi.postCreateSchoolBlog, payload.token, payload.schoolId, payload.data);
        yield put(setSchoolBlogDetail(res.data));
        yield put(setSchoolNewBlogInput(schoolDetailBlogInputInitial));
        yield put(setSchoolNewBlogValidate(schoolDetailBlogValidateInitial));
        if (payload.withPublish) {
            yield put(patchSchoolBlogPublish(payload.token, payload.schoolId, res.data.id.toString(), true));
        }
        yield put(push(`/school/${payload.schoolId}/blog`));
    } catch (e) {
        // TODO 404
        yield put(setSchoolBlogDetail(null));
    }
}

export function* postSchoolBlogAsync() {
    yield takeEvery(SchoolsActionTypes.POST_NEW_SCHOOL_BLOG, postSchoolNewBlog);
}

// スクールブログ公開
function* patchSchoolDetailBlogPublish({ payload }: ReturnType<typeof patchSchoolBlogPublish>) {
    try {
        yield call(SchoolsApi.patchPublishSchoolBlog, payload.authToken, payload.blogId);
        // レスポンスは使用しない 最新取得
        if (payload.inList) {
            yield put(getSchoolBlogList(payload.authToken, Number(payload.schoolId), SCHOOL_BLOG_COUNT, 0));
        } else {
            yield put(getSchoolBlogDetail(payload.authToken, Number(payload.blogId)));
        }
    } catch (e) {
        yield put(setSchoolBlogDetail(null));
    }
}

export function* patchSchoolBlogPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_SCHOOL_BLOG_PUBLISH, patchSchoolDetailBlogPublish);
}

// スクールブログ非公開
function* patchSchoolDetailBlogUnPublish({ payload }: ReturnType<typeof patchSchoolBlogUnPublish>) {
    try {
        yield call(SchoolsApi.patchUnPublishSchoolBlog, payload.authToken, payload.blogId);
        // レスポンスは使用しない 最新取得
        if (payload.inList) {
            yield put(getSchoolBlogList(payload.authToken, Number(payload.schoolId), SCHOOL_BLOG_COUNT, 0));
        } else {
            yield put(getSchoolBlogDetail(payload.authToken, Number(payload.blogId)));
        }
    } catch (e) {
        yield put(setSchoolBlogDetail(null));
    }
}

export function* patchSchoolBlogUnPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_SCHOOL_BLOG_UN_PUBLISH, patchSchoolDetailBlogUnPublish);
}

// スクールブログ情報更新
function* putSchoolBlog({ payload }: ReturnType<typeof putSchoolBlogEditData>) {
    try {
        yield call(SchoolsApi.putSchoolBlog, payload.token, payload.blogId, payload.data);
        // レスポンスは使用しない 最新取得
        yield put(getSchoolBlogDetail(payload.token, Number(payload.blogId)));
        yield put(setSchoolBlogEditFlag(false));
    } catch (e) {
        yield put(setSchoolBlogDetail(null));
        yield put(setSchoolBlogEditFlag(false));
    }
}

export function* putSchoolBlogAsync() {
    yield takeEvery(SchoolsActionTypes.PUT_SCHOOL_BLOG_DATA, putSchoolBlog);
}
