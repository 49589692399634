export const SchoolsActionTypes = {
    SET_NEW_SCHOOL_INPUT: 'domain/schools/set_new_school_input',
    SET_NEW_SCHOOL_INPUT_VALIDATE: 'domain/schools/set_new_school_input_VALIDATE',
    POST_NEW_SCHOOL: 'domain/schools/post_new_school',
    GET_SCHOOL_DETAIL: 'domain/schools/get_school_detail',
    SET_SCHOOL_DETAIL: 'domain/schools/set_school_detail',
    RESET_SCHOOL_EDIT_FLAG: 'domain/schools/reset_school_edit_flag',
    SET_NEW_SCHOOL_RESULT: 'domain/schools/set_new_school_result',
    SET_SCHOOL_DETAIL_BASE_EDIT_FLAG: 'domain/schools/set_school_detail_base_edit_flag',
    SET_SCHOOL_DETAIL_ADVANCED_EDIT_FLAG: 'domain/schools/set_school_detail_advanced_edit_flag',
    SET_SCHOOL_DETAIL_BASE_EDIT_DATA: 'domain/schools/set_school_detail_base_edit_data',
    SET_SCHOOL_DETAIL_BASE_EDIT_VALIDATE: 'domain/schools/set_school_detail_base_edit_validate',
    PUT_SCHOOL_DETAIL_EDIT_DATA: 'domain/schools/put_school_detail_edit_data',
    PATCH_SCHOOL_PUBLISH: 'domain/schools/patch_school_publish',
    PATCH_SCHOOL_UN_PUBLISH: 'domain/schools/patch_school_un_publish',
    PATCH_ALL_SCHOOLS_PUBLISH: 'domain/schools/patch_all_schools_publish',
    PATCH_ALL_SCHOOLS_UN_PUBLISH: 'domain/schools/patch_all_schools_un_publish',
    SET_SCHOOL_TOP_NOTICE_EDIT_FLAG: 'domain/schools/set_school_top_notice_edit_flag',
    SET_SCHOOL_TOP_IMAGES_EDIT_FLAG: 'domain/schools/set_school_top_images_edit_flag',
    SET_SCHOOL_TOP_QA_EDIT_FLAG: 'domain/schools/set_school_top_qa_edit_flag',
    GET_SCHOOL_COURSE_LIST: 'domain/schools/get_school_course_list',
    SET_SCHOOL_COURSE_LIST: 'domain/schools/set_school_course_list',
    GET_SCHOOL_COURSE_DETAIL: 'domain/schools/get_school_course_detail',
    SET_SCHOOL_COURSE_DETAIL: 'domain/schools/set_school_course_detail',
    SET_SCHOOL_NEW_COURSE_INPUT: 'domain/schools/set_school_new_course_input',
    SET_SCHOOL_NEW_COURSE_VALIDATE: 'domain/schools/set_school_new_course_validate',
    PATCH_SCHOOL_COURSE_PUBLISH: 'domain/schools/patch_school_course_publish',
    PATCH_SCHOOL_COURSE_UN_PUBLISH: 'domain/schools/patch_school_course_un_publish',
    POST_NEW_SCHOOL_COURSE: 'domain/schools/post_new_school_course',
    SET_SCHOOL_COURSE_EDIT_DATA: 'domain/schools/set_school_course_edit_data',
    SET_SCHOOL_COURSE_EDIT_VALIDATE: 'domain/schools/set_school_course_edit_validate',
    PUT_SCHOOL_COURSE_DATA: 'domain/schools/put_school_course_data',
    SET_SCHOOL_COURSE_EDIT_FLAG: 'domain/schools/set_school_course_edit_flag',
    GET_SCHOOL_INSTRUCTOR_LIST: 'domain/schools/get_school_instructor_list',
    SET_SCHOOL_INSTRUCTOR_LIST: 'domain/schools/set_school_instructor_list',
    GET_SCHOOL_INSTRUCTOR_DETAIL: 'domain/schools/get_school_instructor_detail',
    SET_SCHOOL_INSTRUCTOR_DETAIL: 'domain/schools/set_school_instructor_detail',
    SET_SCHOOL_NEW_INSTRUCTOR_FLAG: 'domain/schools/set_school_new_instructor_flag',
    SET_SCHOOL_NEW_INSTRUCTOR_INPUT: 'domain/schools/set_school_new_instructor_input',
    SET_SCHOOL_NEW_INSTRUCTOR_VALIDATE: 'domain/schools/set_school_new_instructor_validate',
    SET_SCHOOL_EDIT_INSTRUCTOR_INPUT: 'domain/schools/set_school_edit_instructor_input',
    SET_SCHOOL_EDIT_INSTRUCTOR_VALIDATE: 'domain/schools/set_school_edit_instructor_validate',
    SET_SCHOOL_EDIT_INSTRUCTOR_INDEX: 'domain/schools/set_school_edit_instructor_index',
    POST_EDIT_SCHOOL_INSTRUCTOR: 'domain/schools/post_edit_school_instructor',
    POST_NEW_SCHOOL_INSTRUCTOR: 'domain/schools/post_new_school_instructor',
    PATCH_INSTRUCTOR_PUBLISH: 'domain/schools/patch_instructor_publish',
    PATCH_INSTRUCTOR_UN_PUBLISH: 'domain/schools/patch_instructor_un_publish',
    DELETE_SCHOOL_INSTRUCTOR: 'domain/schools/delete_school_instructor',
    SET_SCHOOL_TOP_IMAGES: 'domain/schools/set_school_top_images',
    GET_SCHOOL_BLOG_LIST: 'domain/schools/get_school_blog_list',
    SET_SCHOOL_BLOG_LIST: 'domain/schools/set_school_blog_list',
    SET_SCHOOL_BLOG_LIST_COUNT: 'domain/schools/set_school_blog_list_count',
    GET_SCHOOL_BLOG_DETAIL: 'domain/schools/get_school_blog_detail',
    SET_SCHOOL_BLOG_DETAIL: 'domain/schools/set_school_blog_detail',
    SET_SCHOOL_NEW_BLOG_INPUT: 'domain/schools/set_school_new_blog_input',
    SET_SCHOOL_NEW_BLOG_VALIDATE: 'domain/schools/set_school_new_blog_validate',
    PATCH_SCHOOL_BLOG_PUBLISH: 'domain/schools/patch_school_blog_publish',
    PATCH_SCHOOL_BLOG_UN_PUBLISH: 'domain/schools/patch_school_blog_un_publish',
    SET_SCHOOL_BLOG_EDIT_DATA: 'domain/schools/set_school_blog_edit_data',
    SET_SCHOOL_BLOG_EDIT_VALIDATE: 'domain/schools/set_school_blog_edit_validate',
    PUT_SCHOOL_BLOG_DATA: 'domain/schools/put_school_blog_data',
    SET_SCHOOL_BLOG_EDIT_FLAG: 'domain/schools/set_school_blog_edit_flag',
    POST_NEW_SCHOOL_BLOG: 'domain/schools/post_new_school_blog',
    GET_SCHOOL_COUPON_LIST: 'domain/schools/get_school_coupon_list',
    SET_SCHOOL_COUPON_LIST: 'domain/schools/set_school_coupon_list',
    SET_SCHOOL_COUPON_LIST_COUNT: 'domain/schools/set_school_coupon_list_count',
    GET_SCHOOL_COUPON_DETAIL: 'domain/schools/get_school_coupon_detail',
    SET_SCHOOL_COUPON_DETAIL: 'domain/schools/set_school_coupon_detail',
    SET_SCHOOL_NEW_COUPON_INPUT: 'domain/schools/set_school_new_coupon_input',
    SET_SCHOOL_NEW_COUPON_VALIDATE: 'domain/schools/set_school_new_coupon_validate',
    PATCH_SCHOOL_COUPON_PUBLISH: 'domain/schools/patch_school_coupon_publish',
    PATCH_SCHOOL_COUPON_UN_PUBLISH: 'domain/schools/patch_school_coupon_un_publish',
    POST_NEW_SCHOOL_COUPON: 'domain/schools/post_new_school_coupon',
    SET_SCHOOL_COUPON_EDIT_DATA: 'domain/schools/set_school_coupon_edit_data',
    SET_SCHOOL_COUPON_EDIT_VALIDATE: 'domain/schools/set_school_coupon_edit_validate',
    PUT_SCHOOL_COUPON_DATA: 'domain/schools/put_school_coupon_data',
    SET_SCHOOL_COUPON_EDIT_FLAG: 'domain/schools/set_school_coupon_edit_flag',
    CHECK_NEW_SCHOOL_MODAL_CHECK: 'domain/schools/check_new_school_modal_check',
};
