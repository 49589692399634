import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from '../../../asset/css/accountCommon.module.scss';
import { RootState } from '../../../redux/store';
import { SignUpInputType } from '../../../redux/reducers/domain/client/type/signUpInput.type';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import {
    postSignUp,
    resetSignUpInput,
    setSignUpEmail,
    setSignUpEmailValidate,
    setSignUpPassword,
    setSignUpPasswordConfirm,
    setSignUpPasswordConfirmValidate,
    setSignUpPasswordValidate,
} from '../../../redux/actions/domain/client/signUp';
import mainImg from './img/main.png';

export const SignUp: React.FC = () => {
    const signUpSelector = (state: RootState) => state.rootReducer.domain.client.signUpInput;
    const signUpState: SignUpInputType = useSelector(signUpSelector);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        // 初期化
        dispatch(resetSignUpInput());
    }, [dispatch]);

    if (signUpState.result.flag) {
        return (
            <>
                <Header />
                <div className={styles.authContainer}>
                    <h1>仮登録メールが送信されました</h1>
                    <div className={styles.confirmation}>
                        <div className={styles.confirmation__conversation}>Thank you !!</div>
                        <p className={styles.confirmation__text}>
                            oriori習い事へのご登録、ありがとうございます。
                            <br />
                            <span>{signUpState.result.sendEmail}</span>に仮登録のメールを送信しました。
                            <br />
                            メールをご確認ください。
                        </p>
                        <div className={styles.confirmation__sub}>
                            <div className={styles.confirmation__subImage}>
                                <img src={mainImg} alt="" />
                            </div>
                            <div className={styles.confirmation__subText}>
                                <div className={styles.confirmation__title}>完了メールが届かない場合</div>
                                <ul className={styles.confirmation__list}>
                                    <li>・「@oriori.education」からのメールが迷惑メールフォルダに振り分けられてないかをご確認ください</li>
                                    <li>
                                        ・迷惑メールフォルダ内にもない場合は、<a href="/">こちら</a>よりお問い合わせください
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <div className={styles.authContainer}>
                <h1>アカウントを作成（無料）</h1>
                <div className={styles.grayBg}>
                    <TextInput
                        label="メールアドレス"
                        name="textInput"
                        id="textInput"
                        require
                        inputType="email"
                        validateType={InputValidateTypeEnum.EMAIL}
                        annotation=""
                        changeReturnFunc={(text, validate) => {
                            dispatch(setSignUpEmail(text));
                            dispatch(setSignUpEmailValidate(validate));
                        }}
                        defaultInput=""
                        placeHolder="メールアドレスを入力"
                        minLength={0}
                        defaultInputValue={signUpState.email}
                        disabled={false}
                    />

                    <TextInput
                        label="パスワード"
                        name="textInput2"
                        id="textInput2"
                        require
                        inputType="password"
                        validateType={InputValidateTypeEnum.PASSWORD}
                        annotation="※ 英字、数字、記号をすべて含む8桁以上で入力してください"
                        changeReturnFunc={(text, validate) => {
                            dispatch(setSignUpPassword(text));
                            dispatch(setSignUpPasswordValidate(validate));
                        }}
                        defaultInput=""
                        placeHolder="パスワードを入力"
                        minLength={0}
                        defaultInputValue={signUpState.password}
                        disabled={false}
                    />

                    <TextInput
                        label="パスワード確認"
                        name="textInput2"
                        id="textInput2"
                        require
                        inputType="password"
                        validateType={InputValidateTypeEnum.PASSWORD}
                        annotation="※ 英字、数字、記号をすべて含む8桁以上で入力してください"
                        changeReturnFunc={(text, validate) => {
                            dispatch(setSignUpPasswordConfirm(text));
                            dispatch(setSignUpPasswordConfirmValidate(validate));
                        }}
                        defaultInput=""
                        placeHolder="パスワードを再入力"
                        minLength={0}
                        defaultInputValue={signUpState.passwordConfirm}
                        disabled={false}
                    />

                    {signUpState.result.reason !== '' && <div>{signUpState.result.reason}</div>}
                </div>
                <p className={styles.btnAnnotation}>
                    <a href="<">規約・プライバシーポリシー</a>へ同意の上
                </p>
                <GreenBtn
                    text={loadingSelector ? '送信中' : '新規登録'}
                    style={
                        !(signUpState.emailValidate && signUpState.passwordValidate && signUpState.passwordConfirmValidate)
                            ? { pointerEvents: 'none', opacity: 0.3 }
                            : {}
                    }
                    handleClick={() => dispatch(postSignUp(signUpState.email, signUpState.password, signUpState.passwordConfirm))}
                    isLink={false}
                    path=""
                />
                <div className={styles.links}>
                    <div className={styles.link}>
                        <Link to="/">アカウントをお持ちですか？ログイン</Link>
                    </div>
                    <div className={styles.link}>
                        <Link to="/password_reissue">パスワードをお忘れの方</Link>
                    </div>
                    <div className={styles.link}>
                        <Link to="#">お問い合わせ</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
