import { put, takeEvery, call } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { AppActionTypes, getAuthToken, setAuthToken, setClientDataEntered } from '../redux/actions/app';
import { ClientApi } from '../util/api/client';
import { ClientActionTypes } from '../redux/actions/domain/client';
import { AuthTokenResponseType } from '../util/type/AuthTokenResponse.type';
import { ClientResponseType } from '../util/type/ClientResponse.type';
import { postSignUp, postSignUpRegisterCode, setSignUpRegisterResult, setSignUpResult } from '../redux/actions/domain/client/signUp';
import {
    setSignInEmail,
    setSignInEmailValidate,
    setSignInPassword,
    setSignInPasswordValidate,
    setSignInResult,
} from '../redux/actions/domain/client/signIn';
import { getClient, putClientInput, setClientData, setClientInputResult } from '../redux/actions/domain/client/client';
import {
    postPasswordRegister,
    postPasswordReissueOfferEmail,
    setPasswordRegisterResult,
    setPasswordReissueOfferResult,
} from '../redux/actions/domain/client/password';

// ログイントークン取得
function* fetchAuthToken({ payload }: ReturnType<typeof getAuthToken>) {
    try {
        const res: AxiosResponse<AuthTokenResponseType> = yield call(ClientApi.postClientLogin, payload.email, payload.password);
        yield put(setAuthToken(res.data.token));
        // クライアント情報を取得し、遷移先を決定する
        const resClient: AxiosResponse<ClientResponseType> = yield call(ClientApi.getClient, res.data.token);
        yield put(setClientDataEntered(true));
        yield put(setClientData(resClient.data));
        yield put(
            setSignInResult({
                flag: true,
                reason: '',
            })
        );
    } catch (e) {
        const err = e as AxiosError;
        if (err.response) {
            const statusCode = err.response.status;
            const errorMessage = err.response.data.message;
            if (statusCode === 403 && errorMessage === 'NotEnteredClient') {
                // ログイン成功 クライアント情報未入力
                yield put(setClientDataEntered(false));
                yield put(
                    setSignInResult({
                        flag: true,
                        reason: '',
                    })
                );
                return;
            }
        }
        // ログイン失敗
        yield put(
            setSignInResult({
                flag: false,
                reason: 'ログインに失敗しました',
            })
        );
        // 401扱いで、login用inputもリセットされるので入れ直す
        yield put(setSignInEmail(payload.email));
        yield put(setSignInEmailValidate(true));
        yield put(setSignInPassword(payload.password));
        yield put(setSignInPasswordValidate(true));
    }
}

export function* getAuthTokenAsync() {
    yield takeEvery(AppActionTypes.GET_AUTH_TOKEN, fetchAuthToken);
}

// 新規仮登録
function* postClientPreRegister({ payload }: ReturnType<typeof postSignUp>) {
    try {
        const res: AxiosResponse<{
            email: string;
            expireDate: Date;
            approvalDate: Date | null;
            createdAt: Date;
            updatedAt: Date;
        }> = yield call(ClientApi.postClientEntry, payload.email, payload.password, payload.passwordConfirm);
        if (payload.email !== res.data.email) {
            throw new Error('signup error');
        }
        yield put(
            setSignUpResult({
                sendEmail: res.data.email,
                flag: true,
                reason: '',
            })
        );
    } catch (e) {
        yield put(
            setSignUpResult({
                sendEmail: '',
                flag: false,
                reason: 'エラーが発生しました。',
            })
        );
    }
}

export function* postClientPreRegisterAsync() {
    yield takeEvery(ClientActionTypes.POST_SIGN_UP, postClientPreRegister);
}

// コードを利用したメールアドレス認証、本登録
function* postClientRegister({ payload }: ReturnType<typeof postSignUpRegisterCode>) {
    try {
        yield call(ClientApi.postClientApprove, payload.code);
        yield put(
            setSignUpRegisterResult({
                success: true,
                loading: false,
            })
        );
    } catch (e) {
        yield put(
            setSignUpRegisterResult({
                success: false,
                loading: false,
            })
        );
    }
}

export function* postClientRegisterCodeAsync() {
    yield takeEvery(ClientActionTypes.POST_SIGN_UP_REGISTER_CODE, postClientRegister);
}

// クライアントデータ取得
function* getClientData({ payload }: ReturnType<typeof getClient>) {
    try {
        const res: AxiosResponse<ClientResponseType> = yield call(ClientApi.getClient, payload.authToken);
        put(setClientDataEntered(true));
        yield put(setClientData(res.data));
    } catch (e) {
        const err = e as AxiosError;
        if (err.response) {
            const statusCode = err.response.status;
            const responseBody = err.response.data.message;
            if (statusCode === 403 && responseBody === 'NotEnteredClient') {
                put(setClientDataEntered(false));
            }
        }
    }
}

export function* getClientDataAsync() {
    yield takeEvery(ClientActionTypes.GET_CLIENT, getClientData);
}

// クライアントデータ更新
function* putClientData({ payload }: ReturnType<typeof putClientInput>) {
    try {
        const res: AxiosResponse<ClientResponseType> = yield call(
            ClientApi.putClientInitial,
            payload.authToken,
            payload.clientInput.name,
            Number(payload.clientInput.clientType),
            payload.clientInput.representativeName,
            payload.clientInput.phone,
            payload.clientInput.address
        );
        yield put(setClientDataEntered(true));
        yield put(setClientData(res.data));
        yield put(
            setClientInputResult({
                flag: true,
                reason: '',
            })
        );
    } catch (e) {
        yield put(
            setClientInputResult({
                flag: false,
                reason: '',
            })
        );
    }
}

export function* putClientDataAsync() {
    yield takeEvery(ClientActionTypes.PUT_CLIENT_INPUT, putClientData);
}

// パスワード再発行登録
function* postPasswordReissueOfferData({ payload }: ReturnType<typeof postPasswordReissueOfferEmail>) {
    try {
        yield call(ClientApi.postOfferReissuePassword, payload.email);
        yield put(
            setPasswordReissueOfferResult({
                sendEmail: payload.email,
                flag: true,
                reason: '',
            })
        );
    } catch (e) {
        yield put(
            setPasswordReissueOfferResult({
                sendEmail: '',
                flag: false,
                reason: 'エラーが発生しました',
            })
        );
    }
}

export function* postPasswordReissueOfferDataAsync() {
    yield takeEvery(ClientActionTypes.POST_PASSWORD_REISSUE_OFFER_EMAIL, postPasswordReissueOfferData);
}

// パスワード再発行
// パスワード再発行登録
function* postPasswordRegisterData({ payload }: ReturnType<typeof postPasswordRegister>) {
    try {
        yield call(ClientApi.postReissuePassword, payload.data.token, payload.data.password, payload.data.passwordConfirm);
        yield put(
            setPasswordRegisterResult({
                flag: true,
                reason: '',
            })
        );
    } catch (e) {
        yield put(
            setPasswordRegisterResult({
                flag: false,
                reason: 'エラーが発生しました',
            })
        );
    }
}

export function* postPasswordRegisterAsync() {
    yield takeEvery(ClientActionTypes.POST_PASSWORD_REGISTER, postPasswordRegisterData);
}
