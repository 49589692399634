import { SchoolsActionTypes } from '../../../actions/domain/schools';
import { schoolDetailCourseInitial } from './InitialState/Course/schoolDetailCourseInitial';
import { SchoolDetailCourseType } from './type/Course/SchoolDetailCourse.type';

export const SchoolDetailCourse = (state: SchoolDetailCourseType = schoolDetailCourseInitial, action: any) => {
    switch (action.type) {
        case SchoolsActionTypes.SET_SCHOOL_COURSE_LIST:
            return {
                ...state,
                list: action.payload.schoolCourseListData
            };

        case SchoolsActionTypes.SET_SCHOOL_COURSE_DETAIL:
            return {
                ...state,
                detail: action.payload.schoolCourseDetailData
            };

        case SchoolsActionTypes.SET_SCHOOL_NEW_COURSE_INPUT:
            return {
                ...state,
                new: action.payload.input
            };
        case SchoolsActionTypes.SET_SCHOOL_NEW_COURSE_VALIDATE:
            return {
                ...state,
                newValidate: action.payload.validate
            };
        case SchoolsActionTypes.SET_SCHOOL_COURSE_EDIT_FLAG:
            return {
                ...state,
                editFlag: action.payload.flag
            };
        case SchoolsActionTypes.SET_SCHOOL_COURSE_EDIT_DATA:
            return {
                ...state,
                edit: action.payload.editData
            };

        case SchoolsActionTypes.SET_SCHOOL_COURSE_EDIT_VALIDATE:
            return {
                ...state,
                editValidate: action.payload.validate
            };

        default:
            return state;
    }
};