import React, { useCallback, useEffect, useState } from 'react';
import { SchoolsApi } from '../../../../util/api/schools';
import { GetSuggestAreaModeEnum } from '../../../../util/enum/GetSuggestAreaMode.enum';
import { SchoolNearestSpotType } from '../../../../util/type/SchoolNearestSpot.type';

export const useNearestStationBusStopPicker = (defaultData?: {
    nearestStation: SchoolNearestSpotType | null;
    nearestBusstop: SchoolNearestSpotType | null;
}): [
        {
            selectData: { value: number; label: string; isBusStop: boolean } | null;
            searchText: string;
            keywords: Array<{ id: number; text: string; isBusStop: boolean }>;
            onFoot: number;
        },
        {
            setSelectData: React.Dispatch<React.SetStateAction<{ value: number; label: string; isBusStop: boolean } | null>>;
            setSearchText: React.Dispatch<React.SetStateAction<string>>;
            getKeywordSuggest: (keyword: string) => Promise<void>;
            setOnFoot: React.Dispatch<React.SetStateAction<number>>;
        }
    ] => {
    // 選択情報
    const [selectData, setSelectData] = useState<{ value: number; label: string; isBusStop: boolean } | null>(null);
    // 検索テキスト
    const [searchText, setSearchText] = useState('');
    // keyword
    const [keywords, setKeywords] = useState<Array<{ id: number; text: string; isBusStop: boolean }>>([]);
    // キーワードを検索
    const getKeywordSuggest = useCallback(async (keyword: string) => {
        try {
            const res = await Promise.all([
                SchoolsApi.getAreaSuggest(GetSuggestAreaModeEnum.Station, keyword),
                SchoolsApi.getAreaSuggest(GetSuggestAreaModeEnum.Busstop, keyword),
            ]);

            const newKeyWords: Array<{ id: number; text: string; isBusStop: boolean }> = [];

            // 駅
            res[0].data.stations.forEach((d) =>
                newKeyWords.push({
                    id: d.id,
                    text: `${d.nameJp}駅`,
                    isBusStop: false,
                })
            );
            // バス停
            res[1].data.busstops.forEach((d) =>
                newKeyWords.push({
                    id: d.id,
                    text: `バス停：${d.nameJp}(${d.corporationName})`,
                    isBusStop: true,
                })
            );

            setKeywords(newKeyWords);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }, []);
    // 徒歩分
    const [onFoot, setOnFoot] = useState<number>(0);

    useEffect(() => {
        if (defaultData) {
            if (defaultData.nearestStation) {
                setSelectData({
                    value: defaultData.nearestStation.id,
                    label: defaultData.nearestStation.label,
                    isBusStop: false,
                });
                setOnFoot(defaultData.nearestStation.onFoot);
            }

            if (defaultData.nearestBusstop) {
                setSelectData({
                    value: defaultData.nearestBusstop.id,
                    label: defaultData.nearestBusstop.label,
                    isBusStop: true,
                });
                setOnFoot(defaultData.nearestBusstop.onFoot);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [
        {
            selectData,
            searchText,
            keywords,
            onFoot,
        },
        {
            setSelectData,
            setSearchText,
            getKeywordSuggest,
            setOnFoot,
        },
    ];
};
