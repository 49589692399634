import { SchoolDetailCourseInputType } from '../../type/Course/SchoolDetailCourseNew.type';

export const schoolDetailCourseNewInitial: SchoolDetailCourseInputType = {
    name: '', // 名前
    description: '', // 説明
    paymentMethod: '', // 支払い説明
    timeRemarks: '', // 時間帯説明
    scheduleWeekSchedule: [], // 開校時間
    requirements: '', // 必要要綱
    membershipFee: null, // 会員費
    annualFee: null, // 年費用
    monthlyFee: null, // 月費用
    textFee: null, // テキスト費用
    feeRemark: '', // 費用説明
    timeMinutesFrom: null, // '授業最短時間（分）
    timeMinutesTo: null, // 授業最長時間（分）
    sortId: null, // ソートid
    targetAgeIds: [],
    instructorIds: [],
    image: null,
    deletingImage: false,
}