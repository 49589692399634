import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import styles from '../../../asset/css/accountCommon.module.scss';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { getAuthToken } from '../../../redux/actions/app';
import { SignInInputType } from '../../../redux/reducers/domain/client/type/signInInput.type';
import { RootState } from '../../../redux/store';
import {
    resetSignInInput,
    setSignInEmail,
    setSignInEmailValidate,
    setSignInPassword,
    setSignInPasswordValidate,
} from '../../../redux/actions/domain/client/signIn';

export const SignIn: React.FC = () => {
    const signInSelector = (state: RootState) => state.rootReducer.domain.client.signInInput;
    const signInState: SignInInputType = useSelector(signInSelector);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        // 初期化
        dispatch(resetSignInInput());
    }, [dispatch]);

    if (signInState.result.flag) {

        const lastLocation = localStorage.getItem('lastLocation')
        const withLogout = localStorage.getItem('withLogout');
        return <Redirect to={lastLocation && !withLogout ? lastLocation.toString() : 'mypage'} />;
    }
    return (
        <>
            <Header />
            <div className={styles.authContainer}>
                <h1>ログイン</h1>
                <div className={styles.grayBg}>
                    <TextInput
                        label="メールアドレス"
                        name="textInput"
                        id="textInput"
                        require
                        inputType="email"
                        validateType={InputValidateTypeEnum.EMAIL}
                        annotation=""
                        changeReturnFunc={(text, validate) => {
                            dispatch(setSignInEmail(text));
                            dispatch(setSignInEmailValidate(validate));
                        }}
                        defaultInput=""
                        placeHolder="メールアドレスを入力"
                        minLength={0}
                        defaultInputValue={signInState.email}
                        disabled={false}
                    />

                    <TextInput
                        label="パスワード"
                        name="textInput2"
                        id="textInput2"
                        require
                        inputType="password"
                        validateType={InputValidateTypeEnum.PASSWORD}
                        annotation=""
                        changeReturnFunc={(text, validate) => {
                            dispatch(setSignInPassword(text));
                            dispatch(setSignInPasswordValidate(validate));
                        }}
                        defaultInput=""
                        placeHolder="パスワードを入力"
                        minLength={0}
                        defaultInputValue={signInState.password}
                        disabled={false}
                    />

                    {signInState.result.reason !== '' && <div>{signInState.result.reason}</div>}

                    <GreenBtn
                        text={loadingSelector ? '送信中' : 'ログイン'}
                        style={!(signInState.emailValidate && signInState.passwordValidate) ? { pointerEvents: 'none', opacity: 0.3 } : {}}
                        handleClick={() => {
                            dispatch(getAuthToken(signInState.email, signInState.password));
                        }}
                        isLink={false}
                        path=""
                    />
                </div>
                <div className={styles.links}>
                    <div className={styles.link}>
                        <Link to="/signUp">アカウントをお持ちでないですか？新規作成</Link>
                    </div>
                    <div className={styles.link}>
                        <Link to="/password_reissue">パスワードをお忘れの方</Link>
                    </div>
                    <div className={styles.link}>
                        <Link to="#">お問い合わせ</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
