import { combineReducers } from 'redux';
import { newSchoolInput } from './newSchoolInput';
import { SchoolDetailData } from './schoolDetailData';
import { newSchoolResult } from './newSchoolResult';
import { SchoolDetailCourse } from './schoolDetailCourse';
import { SchoolDetailInstructor } from './schoolDetailInstructor';
import { SchoolDetailBlog } from './schoolDetailBlog';
import { SchoolDetailCoupon } from './schoolDetailCoupon';

export const schools = combineReducers({
    newSchoolInput,
    SchoolDetailData,
    newSchoolResult,
    SchoolDetailCourse,
    SchoolDetailInstructor,
    SchoolDetailBlog,
    SchoolDetailCoupon,
})