import { Action } from 'redux';
import { SchoolInstructorDataType } from '../../../../util/type/School/SchoolInstructorDataType';
import { SchoolsActionTypes } from './index';
import { SchoolDetailInstructorInputType } from '../../../reducers/domain/schools/type/Instructor/SchoolDetailInstructorNew.type';
import { SchoolDetailInstructorValidateType } from '../../../reducers/domain/schools/type/Instructor/SchoolDetailInstructorNewValidate.type';

export const getSchoolInstructorList = (token: string, schoolId: number): GetSchoolInstructorList => ({
    type: SchoolsActionTypes.GET_SCHOOL_INSTRUCTOR_LIST,
    payload: { schoolId, token }
});

export interface GetSchoolInstructorList extends Action {
    type: string;
    payload: {
        schoolId: number,
        token: string,
    };
}

export const setSchoolInstructorList = (schoolInstructorListData: Array<SchoolInstructorDataType> | null): SetSchoolInstructorList => ({
    type: SchoolsActionTypes.SET_SCHOOL_INSTRUCTOR_LIST,
    payload: { schoolInstructorListData }
});

export interface SetSchoolInstructorList extends Action {
    type: string;
    payload: {
        schoolInstructorListData: Array<SchoolInstructorDataType> | null
    };
}

export const getSchoolInstructorDetail = (token: string, instructorId: number): GetSchoolInstructorDetail => ({
    type: SchoolsActionTypes.GET_SCHOOL_INSTRUCTOR_DETAIL,
    payload: { instructorId, token }
});

export interface GetSchoolInstructorDetail extends Action {
    type: string;
    payload: {
        instructorId: number,
        token: string,
    };
}

export const setSchoolInstructorDetail = (schoolInstructorDetailData: SchoolInstructorDataType | null): SetSchoolInstructorDetail => ({
    type: SchoolsActionTypes.SET_SCHOOL_INSTRUCTOR_DETAIL,
    payload: { schoolInstructorDetailData }
});

export interface SetSchoolInstructorDetail extends Action {
    type: string;
    payload: {
        schoolInstructorDetailData: SchoolInstructorDataType | null
    };
}

export const setSchoolNewInstructorFlag = (flag: boolean): SetSchoolNewInstructorFlag => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_INSTRUCTOR_FLAG,
    payload: { flag }
});

export interface SetSchoolNewInstructorFlag extends Action {
    type: string;
    payload: {
        flag: boolean,
    };
}

export const setSchoolNewInstructorInput = (input: SchoolDetailInstructorInputType): SetSchoolNewInstructorInput => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_INSTRUCTOR_INPUT,
    payload: { input }
});

export interface SetSchoolNewInstructorInput extends Action {
    type: string;
    payload: {
        input: SchoolDetailInstructorInputType,
    };
}

export const setSchoolNewInstructorValidate = (validate: SchoolDetailInstructorValidateType): SetSchoolNewInstructorValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_NEW_INSTRUCTOR_VALIDATE,
    payload: { validate }
});

export interface SetSchoolNewInstructorValidate extends Action {
    type: string;
    payload: {
        validate: SchoolDetailInstructorValidateType,
    };
}

export const postNewSchoolInstructor = (token: string, schoolId: string, data: SchoolDetailInstructorInputType): PostNewSchoolInstructor => ({
    type: SchoolsActionTypes.POST_NEW_SCHOOL_INSTRUCTOR,
    payload: { token, schoolId, data}
});

export interface PostNewSchoolInstructor extends Action {
    type: string;
    payload: {
        token: string,
        schoolId: string
        data: SchoolDetailInstructorInputType,
    };
}

export const setSchoolEditInstructorInput = (input: SchoolDetailInstructorInputType | null): SetSchoolEditInstructorInput => ({
    type: SchoolsActionTypes.SET_SCHOOL_EDIT_INSTRUCTOR_INPUT,
    payload: { input }
});

export interface SetSchoolEditInstructorInput extends Action {
    type: string;
    payload: {
        input: SchoolDetailInstructorInputType | null,
    };
}

export const setSchoolEditInstructorValidate = (validate: SchoolDetailInstructorValidateType): SetSchoolEditInstructorValidate => ({
    type: SchoolsActionTypes.SET_SCHOOL_EDIT_INSTRUCTOR_VALIDATE,
    payload: { validate }
});

export interface SetSchoolEditInstructorValidate extends Action {
    type: string;
    payload: {
        validate: SchoolDetailInstructorValidateType,
    };
}

export const setSchoolEditInstructorIndex = (index: number | null,): SetSchoolEditInstructorIndex => ({
    type: SchoolsActionTypes.SET_SCHOOL_EDIT_INSTRUCTOR_INDEX,
    payload: { index }
});

export interface SetSchoolEditInstructorIndex extends Action {
    type: string;
    payload: {
        index: number | null,
    };
}

export const postEditSchoolInstructor = (token: string, instructorId: string, data: SchoolDetailInstructorInputType, schoolId: string): PostEditSchoolInstructor => ({
    type: SchoolsActionTypes.POST_EDIT_SCHOOL_INSTRUCTOR,
    payload: { token, instructorId, data, schoolId}
});

export interface PostEditSchoolInstructor extends Action {
    type: string;
    payload: {
        token: string,
        instructorId: string
        data: SchoolDetailInstructorInputType,
        schoolId: string,
    };
}

export const patchSchoolInstructorPublish = (authToken: string, instructorId: string, schoolId: string): PatchSchoolInstructorPublish => ({
    type: SchoolsActionTypes.PATCH_INSTRUCTOR_PUBLISH,
    payload: { authToken, instructorId, schoolId}
});

export interface PatchSchoolInstructorPublish extends Action {
    type: string;
    payload: {
        authToken: string,
        instructorId: string,
        schoolId: string
    };
}

export const patchSchoolInstructorUnPublish = (authToken: string, instructorId: string, schoolId: string): PatchSchoolInstructorUnPublish => ({
    type: SchoolsActionTypes.PATCH_INSTRUCTOR_UN_PUBLISH,
    payload: { authToken, instructorId, schoolId}
});

export interface PatchSchoolInstructorUnPublish extends Action {
    type: string;
    payload: {
        authToken: string,
        instructorId: string,
        schoolId: string
    };
}

export const deleteSchoolInstructor = (authToken: string, instructorId: string, schoolId: string): DeleteSchoolInstructor => ({
    type: SchoolsActionTypes.DELETE_SCHOOL_INSTRUCTOR,
    payload: { authToken, instructorId, schoolId}
});

export interface DeleteSchoolInstructor extends Action {
    type: string;
    payload: {
        authToken: string,
        instructorId: string,
        schoolId: string
    };
}