import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';
import { SchoolBlogData } from '../../../util/type/School/SchoolBlogData.type';

// import dot from './img/dot.svg';
// showStatusは暫定
type SchoolBlogCardProps = {
    schoolId: string;
    schoolData: SchoolBlogData ;
    showStatus: boolean;
};

export const SchoolBlogCard: React.FC<SchoolBlogCardProps> = (props: SchoolBlogCardProps) => {
    const {schoolId,schoolData,showStatus} = props;
    return(
        <>
            <Link to={`/school/${schoolId}/blog/${schoolData.id}`} className={styles.schoolBlogCard}>
                <div className={styles.schoolBlogCard__head}>
                    {schoolData.imageUrl && 
                    <img src={schoolData.imageUrl} alt="ブログ画像" className={styles.schoolBlogCard__mainImg}/>
                    }
                    {showStatus ? 
                        <div className={styles.schoolBlogCard__status}>公開中</div>
                        :
                        <div className={`${styles.schoolBlogCard__status} ${styles["schoolBlogCard__status--hidden"]}`}>非公開</div>
                    }
                    <div className={schoolData.imageUrl ? styles.schoolBlogCard__textWrap : `${styles.schoolBlogCard__textWrap} ${styles["schoolBlogCard__textWrap--noImg"]}`}>
                        <div className={styles.schoolBlogCard__title} >
                            {schoolData.title}
                        </div>
                        {showStatus ? 
                            <div className={styles.schoolBlogCard__info}>公開日：{new Date(schoolData.updatedAt).getFullYear()}/{new Date(schoolData.updatedAt).getMonth()}/{new Date(schoolData.updatedAt).getDate()}</div>
                            :
                            <div className={styles.schoolBlogCard__infoWrap}>
                                <div className={styles.schoolBlogCard__info}>下書き：{new Date(schoolData.createdAt).getFullYear()}/{new Date(schoolData.createdAt).getMonth()}/{new Date(schoolData.createdAt).getDate()}</div>
                                <div className={`${styles.schoolBlogCard__info} ${styles["schoolBlogCard__info--hidden"]}`}>公開日：-</div>
                            </div>
                        }
                    </div>
                    {/* <button type="button" className={styles.schoolBlogCard__menuBtn}>*/}
                    {/*    <img src={dot} alt="メニューボタン"/>*/}
                    {/* </button>*/}
                </div>
            </Link>
        </>
    )}