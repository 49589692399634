import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import React, { useEffect } from 'react';
import { RootState } from '../../redux/store';
import { getClient } from '../../redux/actions/domain/client/client';
import { Header } from '../../components/Common/Header';
import { OwnerApplication } from '../../components/MyPage/OwnerApplication';
import { TabBar } from '../../components/MyPage/TabBar';
import styles from '../../asset/css/myPageCommon.module.scss';
import stylesTeam from '../../asset/css/teamCommon.module.scss';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import { Footer } from '../../components/Common/Footer';
import { DataList } from '../../components/Common/DataList';
import { ClientType } from '../../redux/reducers/domain/client/enum/clientType.enum';
import { setCommonModalContentType, setCommonModalShow, setCommonModalTitle } from '../../redux/actions/ui';
import { CommonModalContentTypeEnum } from '../../redux/reducers/ui/enum/CommonModalContentType.enum';
import { setInviteUserInput, setInviteUserResult } from '../../redux/actions/domain/user/inviteUser';
import { ClientUserTypeEnum } from '../../redux/reducers/domain/user/enum/ClientUserType.enum';
import { UsersList } from '../../components/Users/UsersList';
import { getClientUsers } from '../../redux/actions/domain/user/clientUser';

// eslint-disable-next-line @typescript-eslint/ban-types
export function getParsedJwt<T extends object= { [k: string]: string | number }>(
    token: string,
): T | undefined {
    try {
        return JSON.parse(atob(token.split('.')[1]))
    } catch {
        return undefined
    }
}

export const TeamPage: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const clientSelector = useSelector((state: RootState) => state.rootReducer.domain.client);
    const clientUserSelector = useSelector((state: RootState) => state.rootReducer.domain.user);
    const dispatch = useDispatch();

    useEffect(() => {
        // client情報の取得
        dispatch(getClient(authToken));
        // ユーザー情報の取得
        dispatch(getClientUsers(authToken));
    }, [authToken, dispatch]);

    if (clientSelector.clientData) {
        const {uuid} = JSON.parse(atob(authToken.split('.')[1]));
        const myData = clientUserSelector.clientUsers.users.find(d => d.uuid === uuid)
        return (<>
            <Header />
            <OwnerApplication />
            <TabBar currentPage='/team' />
            <div className={styles.myPageContainer}>
                <BreadCrumb data={[{ text: 'マイページ', path: '/mypage' }, { text: 'チーム管理', path: '' }]} />
                <h2>チーム管理</h2>
                <DataList
                    data={[{
                        title: 'アカウント',
                        text: clientSelector.clientData.type === ClientType.Enterprise ? clientSelector.clientData.representativeName : '個人',
                    }, {

                        title: 'メールアドレス',
                        text: clientSelector.clientData.email
                    }]}
                    id='teamPageAccount'
                    longFlag={false}
                />
                <button type="button" onClick={() => {
                    dispatch(setCommonModalShow(true))
                    dispatch(setCommonModalTitle('ユーザーの新規追加'))
                    dispatch(setCommonModalContentType(CommonModalContentTypeEnum.ADD_NEW_USER))
                    dispatch(setInviteUserInput({
                        email: '',
                        emailReceive: true,
                        type: ClientUserTypeEnum.Admin,
                        stepSecondFlag: false,
                    }))
                    dispatch(setInviteUserResult({
                        flag: false,
                        reason: '',
                    }));
                }} className={stylesTeam.addButton}>
                    ＋ ユーザーを追加する
                </button>

                {clientUserSelector.clientUsers.users.length > 0 && (<UsersList
                    myData={myData || clientUserSelector.clientUsers.users[0]}
                    users={clientUserSelector.clientUsers.users}
                />)}
            </div>
            <Footer />
        </>)
    }

    return <Redirect to="/" />;
};