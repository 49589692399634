import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'querystring';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getClient } from '../../redux/actions/domain/client/client';
import styles from '../../asset/css/myPageCommon.module.scss';
import { Header } from '../../components/Common/Header';
import { Footer } from '../../components/Common/Footer';
import { OwnerApplication } from '../../components/MyPage/OwnerApplication';
import { TabBar } from '../../components/MyPage/TabBar';
import { BreadCrumb } from '../../components/Common/BreadCrumb';
import { PaymentHistoryTable } from '../../components/MyPage/PaymentHistoryTable';
import { ShowMoreBtn } from '../../components/Common/ShowMoreBtn';
import { PageNation, TargetPage } from '../../components/Common/PageNation';
import { getSettlements } from '../../redux/actions/domain/contracts/settlement';

export const PaymentHistory: React.FC = () => {
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const contractsSelector = useSelector((store: RootState) => store.rootReducer.domain.contracts);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const query = queryString.parse(search.replace('?', ''));
    const page = query.page ? Number(query.page) : 0;

    useEffect(() => {
        // client情報の取得
        dispatch(getClient(authToken));
        // 決済履歴取得
        dispatch(getSettlements(authToken, page, 20));
    }, [authToken, dispatch, history, page]);

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/contracts" />
            <div className={styles.myPageContainer}>
                <BreadCrumb
                    data={[
                        { text: 'マイページ', path: '/mypage' },
                        { text: 'お支払い', path: '/contracts' },
                        { text: 'お支払い全履歴', path: '/payment_history' },
                    ]}
                />
                <h2>お支払い全履歴</h2>
                <PaymentHistoryTable data={contractsSelector.settlements.data} />
                {page < Math.floor(contractsSelector.settlements.page / 20) && (
                    <ShowMoreBtn text="次の20件を見る" onClick={() => history.push(`/payment_history?page=${page + 1}`)} />
                )}
                <PageNation
                    page={page}
                    dataLength={contractsSelector.settlements.data.length}
                    path="/payment_history"
                    targetPage={TargetPage.PAYMENT_HISTORY}
                />
            </div>
            <Footer />
        </>
    );
};
