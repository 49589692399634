import { ClientDataType } from './type/clientData.type';
import { ClientActionTypes } from '../../../actions/domain/client';

export const clientData = (state: null | ClientDataType  = null, action: any):  null | ClientDataType => {
    switch (action.type) {
        case ClientActionTypes.SET_CLIENT_DATA:
            return action.payload.data;
        default:
            return state;
    }
};