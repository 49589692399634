import React from 'react';
import { Link } from 'react-router-dom';
import logo from './img/logo.svg';
import styles from './css/style.module.scss';

export const Footer: React.FC = () => (
    <footer className={styles.footer}>
        <div className={styles.footer__inner}>
            <ul>
                <li>
                    <a href="https://numberleaps.co.jp/" target="_blank" rel="noopener noreferrer">
                        運営会社
                    </a>
                </li>
                <li>
                    <a href="/terms" target="_blank" rel="noopener noreferrer">
                        利用規約
                    </a>
                </li>
                <li>
                    <a href="https://oriori.education/privacy" target="_blank" rel="noopener noreferrer">
                        プライバシーポリシー
                    </a>
                </li>
                <li>
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdetfSMyp-EkFcVttqmLSJpXwSXNNLQwhV2kHHY9SUGXq068w/viewform"
                        target="_blank"
                        rel="noopener noreferrer">
                        お問い合わせ
                    </a>
                </li>
                <li>
                    <Link to="/">ログアウト</Link>
                </li>
            </ul>
            <img src={logo} alt="oriori" />
            <small>{new Date().getFullYear()} NumberLeaps</small>
        </div>
    </footer>
);
