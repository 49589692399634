import { CurrentSchoolDataType } from '../type/CurrentSchoolData.type';
import { editSchoolIndexValidateInitial } from './index/newSchoolInputValidateInitial';
import { schoolDetailEditImagesInitial } from './Top/schoolDetailEditImagesInitial';

export const schoolDetailDataInitial: CurrentSchoolDataType = {
    schoolDetailData: null,
    baseEditFlag: false,
    advancedEditFlag: false,
    noticeEditFlag: false,
    imagesEditFlag: false,
    qaEditFlag: false,
    schoolDetailEditData: null,
    schoolDetailEditValidate: editSchoolIndexValidateInitial, // 一度登録できているので、初期値はtrue
    schoolDetailEditImages: schoolDetailEditImagesInitial,
}