export const numberScheduleToString = (data: Array<{ from: string; to: string } | null>): Array<{ from: string; to: string } | null> =>
    data.map((d) => {
        if (d !== null) {
            const firstIndex = d.from.toString().length === 3;
            const firstToIndex = d.to.toString().length === 3;
            return {
                // 旧バルクデータでは動かない
                from: `${d.from.toString().slice(0, firstIndex ? 1 : 2)}:${d.from.toString().slice(-2)}`,
                to: `${d.to.toString().slice(0, firstToIndex ? 1 : 2)}:${d.to.toString().slice(-2)}`,
            };
        }
        return null;
    });
