export const UserActions = {
    SET_INVITE_USER_INPUT: 'domain/user/set_invite_user_input',
    SET_INVITE_USER_RESULT: 'domain/user/set_invite_user_result',
    POST_INVITE_USER_INPUT: 'domain/user/post_invite_user_input',
    SET_CREATE_INVITED_USER_INPUT: 'domain/user/set_create_invited_user_input',
    SET_CREATE_INVITED_USER_VALIDATE: 'domain/user/set_create_invited_user_validate',
    SET_CREATE_INVITED_USER_RESULT: 'domain/user/set_create_invited_user_result',
    POST_CREATE_INVITED_USER_INPUT: 'domain/user/post_create_invited_user_input',
    GET_CLIENT_USERS: 'domain/user/get_client_users',
    SET_CLIENT_USERS: 'domain/user/set_client_users',
    GET_CLIENT_USER: 'domain/user/get_client_user',
    SET_CLIENT_USER: 'domain/user/set_client_user',
    DELETE_CLIENT_USER: 'domain/user/delete_client_user',
    UPDATE_CLIENT_USER: 'domain/user/update_client_user',
    PATCH_CLIENT_USER_EMAIL_INQUIRY: 'domain/user/patch_client_user_email_Inquiry',
    SET_CHANGE_CLIENT_USER_EMAIL_OFFER_RESULT: 'domain/user/set_change_client_user_email_offer_result',
    POST_CHANGE_CLIENT_USER_EMAIL_OFFER: 'domain/user/post_change_client_user_email_offer',
    POST_CHANGE_CLIENT_USER_EMAIL_REGISTER_CODE: 'domain/user/post_change_client_user_email_register_code',
    SET_CHANGE_CLIENT_USER_EMAIL_REGISTER_RESULT: 'domain/user/set_change_client_user_email_register_result',
}