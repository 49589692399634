import { Action } from 'redux';
import { UserActions } from './index';
import { ClientUserType } from '../../../../util/type/ClientUser.type';
import { ClientUserTypeEnum } from '../../../reducers/domain/user/enum/ClientUserType.enum';

// ユーザー管理関

export const getClientUsers = (token: string): GetClientUsers => ({
    type: UserActions.GET_CLIENT_USERS,
    payload: {
        token
    }
});

export interface GetClientUsers extends Action {
    type: string,
    payload: {
        token: string
    }
}

export const setClientUsers = (users: Array<ClientUserType>): SetClientUsers => ({
    type: UserActions.SET_CLIENT_USERS,
    payload: {
        users
    }
});

export interface SetClientUsers extends Action {
    type: string,
    payload: {
        users: Array<ClientUserType>
    }
}


export const getClientUser = (token: string): GetClientUser => ({
    type: UserActions.GET_CLIENT_USER,
    payload: {
        token
    }
});

export interface GetClientUser extends Action {
    type: string,
    payload: {
        token: string
    }
}

export const setClientUser = (user: ClientUserType): SetClientUser => ({
    type: UserActions.SET_CLIENT_USER,
    payload: {
        user
    }
});

export interface SetClientUser extends Action {
    type: string,
    payload: {
        user: ClientUserType
    }
}

export const deleteClientUser = (token: string, uuid: string): DeleteClientUser => ({
    type: UserActions.DELETE_CLIENT_USER,
    payload: {
        token,
        uuid
    }
});

export interface DeleteClientUser extends Action {
    type: string,
    payload: {
        token: string,
        uuid: string,
    }
}

export const updateClientUser = (token: string, uuid: string, type: ClientUserTypeEnum): UpdateClientUser => ({
    type: UserActions.UPDATE_CLIENT_USER,
    payload: {
        token,
        uuid,
        type
    }
});

export interface UpdateClientUser extends Action {
    type: string,
    payload: {
        token: string,
        uuid: string,
        type: ClientUserTypeEnum,
    }
}

export const patchClientUserEmailInquiry = (
    token: string,
    uuid: string,
    accept: boolean): PatchClientUserEmailInquiry => ({
    type: UserActions.PATCH_CLIENT_USER_EMAIL_INQUIRY,
    payload: {
        token,
        uuid,
        accept
    }
});

export interface PatchClientUserEmailInquiry extends Action {
    type: string,
    payload: {
        token: string,
        uuid: string,
        accept: boolean,
    }
}