import { AxiosResponse } from 'axios';
import { SchoolInquiryDataType } from '../type/SchoolInquiryData.type';
import { axiosBase } from './index';

export class InquiryApi {
    // お問い合わせ詳細取得
    static async getInquiry(token: string, inquiryId: number): Promise<AxiosResponse<SchoolInquiryDataType>> {
        return axiosBase.get(`/school/inquiry/${inquiryId}/admin`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}
