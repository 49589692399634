import { SchoolSubscriptionType } from './type/SchoolSubscription.type';
import { PaymentActionTypes } from '../../../actions/domain/payment';
import { schoolSubscriptionInitialize } from './Initialize/schoolSubscriptionInitialize';

export const schoolSubscription = (state = schoolSubscriptionInitialize, action: any): SchoolSubscriptionType => {
    switch (action.type) {
        case PaymentActionTypes.SET_PAYMENT_MODAL_STEP:
            return {
                ...state,
                paymentModalStep: action.payload.step,
            }
        case PaymentActionTypes.SET_PAYMENT_INTENT_SECRET:
            return {
                ...state,
                paymentSecret: action.payload.secret,
            }
        case PaymentActionTypes.SET_BUDGET_LIMIT:
            return {
                ...state,
                budgetLimit: action.payload.budgetLimit,
            }
        case PaymentActionTypes.SET_PAYMENT_TARGET_SCHOOL:
            return {
                ...state,
                paymentTargetSchool: action.payload.paymentTargetSchool,
            }
        case PaymentActionTypes.SET_NEW_PAYMENT_METHOD_ID:
            return {
                ...state,
                newPaymentMethodId: action.payload.newPaymentMethodId,
            }
        case PaymentActionTypes.SET_STRIPE_CARD_NUMBER_ELEMENT:
            return {
                ...state,
                cardNumberElement: action.payload.cardNumberElement,
            }
        case PaymentActionTypes.SET_PAYMENT_RESULT:
            return {
                ...state,
                paymentResult: action.payload.paymentResult,
            }
        case PaymentActionTypes.INIT_PAYMENT_STORE:
            return schoolSubscriptionInitialize;

        default:
            return state;
    }
}