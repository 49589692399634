import { ChangeEmailType } from '../type/ChangeEmail.type';

export const changeEmailInitialize: ChangeEmailType = {
    result: {
        flag: false,
        reason: '',
    },
    approveResult: {
        success: null,
    }
}