import { PasswordReissueOfferType } from '../type/passwordReissueOffer.type';

export const passwordReissueOfferInitial: PasswordReissueOfferType = {
    email: '',
    emailValidate: false,
    result: {
        sendEmail: '',
        flag: false,
        reason: '',
    },
};
