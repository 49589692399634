import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import styles from './css/styles.module.scss';
import { TextInput } from '../../Input/TextInput';
import { InputValidateTypeEnum } from '../../Input/TextInput/InputValidateType.enum';
import { GreenBtn } from '../../GreenBtn';
import { setCommonModalShow } from '../../../../redux/actions/ui';
import { postChangeClientUserEmailOffer } from '../../../../redux/actions/domain/user/changeEmail';

export const ChangeMailAddress: React.FC = () => {

    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const userSelector = useSelector((state: RootState) => state.rootReducer.domain.user);
    const dispatch = useDispatch();
    const [emailInput, setEmailInput] = useState('');
    const [emailValidate, setEmailValidate] = useState(false);

    if (userSelector.changeEmail.result.flag) {
        return (
            <div className={styles.wrap}>
                <div className={styles.resultTitle}>仮登録メールが送信されました</div>
                <div className={styles.confirmation}>
                    <p className={styles.confirmation__text}>
                        <span>{emailInput}</span>にメールアドレス変更のメールを送信しました。<br />
                        メールに記載されている内容をご確認ください。
                    </p>
                    <div>
                        <div className={styles.confirmation__title}>完了メールが届かない場合</div>
                        <ul className={styles.confirmation__list}>
                            <li>・「@oriori.education」からのメールが迷惑メールフォルダに振り分けられてないかをご確認ください</li>
                            <li>・迷惑メールフォルダ内にもない場合は、<a href='/'>こちら</a>よりお問い合わせください</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.buttonWrap}>
                    <GreenBtn text='このウィンドウを閉じる' style={{}} handleClick={() => dispatch(setCommonModalShow(false))}
                        isLink={false}
                        path='' />
                </div>
            </div>);
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.inner}>
                <TextInput
                    label='メールアドレス'
                    name='inviteEmail'
                    id='inviteEmail'
                    require
                    inputType='email'
                    validateType={InputValidateTypeEnum.EMAIL}
                    annotation=''
                    changeReturnFunc={(text, validate) => {
                        setEmailInput(text)
                        setEmailValidate(validate);
                    }}
                    defaultInput={emailInput}
                    placeHolder='メールアドレスを入力'
                    minLength={0}
                    defaultInputValue={emailInput}
                    disabled={false}
                />
            </div>
            <div className={styles.buttonWrap}>
                <GreenBtn text='メールアドレスを変更する' style={!emailValidate ? {
                    background: '#c4c4c4',
                    pointerEvents: 'none'
                } : {}} handleClick={() => dispatch(postChangeClientUserEmailOffer(authToken, emailInput))} isLink={false} path='' />
            </div>
            <div className={styles.buttonWrap}>
                <button type='button' className={styles.cancelButton} onClick={() => {
                    dispatch(setCommonModalShow(false));
                }}>キャンセルする
                </button>
            </div
            >
        </div>)
}

