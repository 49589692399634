import { Action } from 'redux';
import { SettingsActions } from './index';

export const postDocuments = (
    authToken: string,
    document1: File | null,
    document2: File | null,
    document3: File | null,
    note: string
): PostDocuments => ({
    type: SettingsActions.POST_DOCUMENTS,
    payload: {
        authToken,
        document1,
        document2,
        document3,
        note,
    }
});

export interface PostDocuments extends Action {
    type: string;
    payload: {
        authToken: string,
        document1: File | null,
        document2: File | null,
        document3: File | null,
        note: string
    };
}

export const setSendDocumentsResult = (
    result: {
        flag: boolean,
        reason: string
    } | null): SetSendDocumentsResult => ({
    type: SettingsActions.SET_SEND_DOCUMENTS_RESULT,
    payload: { result }
});

export interface SetSendDocumentsResult extends Action {
    type: string;
    payload: {
        result: {
            flag: boolean,
            reason: string
        } | null,
    };
}
