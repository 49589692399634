import { SchoolDetailCouponType } from '../../type/Coupon/SchoolDetailCoupon.type';
import { schoolDetailCouponValidateInitial } from './schoolDetailCouponValidateInitial';
import { schoolDetailCouponInputInitial } from './schoolDetailCouponInputInitial';
import { schoolDetailCouponEditValidateInitial } from './schoolDetailCouponEditValidateInitial';

export const schoolDetailCouponInitial: SchoolDetailCouponType = {
    list: null,
    listCount: 0,
    detail: null,
    new: schoolDetailCouponInputInitial,
    newValidate: schoolDetailCouponValidateInitial,
    editFlag: false,
    edit: null,
    editValidate: schoolDetailCouponEditValidateInitial,
}