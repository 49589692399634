import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop:React.FC = (props) => {
    const {children} = props;
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{children}</>
};
