import { inviteUserInitialize } from './Initialize/InviteUserInitialize';
import { InviteUserType } from './type/InviteUser.type';
import { UserActions } from '../../../actions/domain/user';

export const inviteUser = (state = inviteUserInitialize, action: any): InviteUserType => {
    switch (action.type) {
        case UserActions.SET_INVITE_USER_INPUT:
            return {
                ...state,
                input: action.payload.input,
            }
        case UserActions.SET_INVITE_USER_RESULT:
            return {
                ...state,
                result: action.payload.result,
            }
        case UserActions.SET_CREATE_INVITED_USER_INPUT:
            return {
                ...state,
                input: action.payload.input,
            }
        case UserActions.SET_CREATE_INVITED_USER_RESULT:
            return {
                ...state,
                result: action.payload.result,
            }
        default:
            return state;
    }
}