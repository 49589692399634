import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store, history } from './redux/store';
import { SignIn } from './pages/Account/SignIn';
import { SignUp } from './pages/Account/SignUp';
import { Register } from './pages/Account/Register';
import { MyPage } from './pages/MyPage';
import { AuthRoute } from './components/Common/AuthRoute';
import { ClientEdit } from './pages/Client/Edit';
import { PasswordReissue } from './pages/Account/PasswordReissue';
import { PasswordRegister } from './pages/Account/PasswordRegister';
import { New_1 } from './pages/Schools/new/new_1';
import { New_2 } from './pages/Schools/new/new_2';
import { New_result } from './pages/Schools/new/new_result';
import { New_3 } from './pages/Schools/new/new_3';
import { SchoolListPage } from './pages/Schools/schoolList';
import { SchoolDetailIndex } from './pages/Schools/detail';
import { SchoolDetailTop } from './pages/Schools/detail/top';
import { SchoolDetailCourseList } from './pages/Schools/detail/course/courseList';
import { SchoolDetailBlogList } from './pages/Schools/detail/blog/blogList';
import { SchoolDetailCouponList } from './pages/Schools/detail/coupon/couponList';
import { SchoolDetailCourseNew } from './pages/Schools/detail/course/courseNew';
import { SchoolDetailCourseDetail } from './pages/Schools/detail/course/courseDetail';
import { SchoolDetailBlogNew } from './pages/Schools/detail/blog/blogNew';
import { SchoolDetailBlogDetail } from './pages/Schools/detail/blog/blogDetail';
import { SchoolDetailCouponDetail } from './pages/Schools/detail/coupon/couponDetail';
import { SchoolDetailCouponNew } from './pages/Schools/detail/coupon/couponNew';
import { TeamPage } from './pages/Team';
import { CommonModal } from './components/Common/CommonModal';
import { InquiriesList } from './pages/Inquiries/inquiriesList';
import { InquiriesDetail } from './pages/Inquiries/InquiriesDetail';
import { InvitedUserRegister } from './pages/Account/InvitedUserRegister';
import { ChangeEmailRegister } from './pages/Account/ChangeEmailRegister';
import { SettingsPage } from './pages/Settings';
import { SubmissionPage } from './pages/Settings/submission';
import { Contracts } from './pages/Contracts';
import { PaymentHistory } from './pages/PaymentHistory';
import { Loading } from './components/Common/loading';
import { ScrollToTop } from './components/Common/ScrollToTop';
import { Terms } from './pages/Terms';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <ScrollToTop>
                        <Loading>
                            <CommonModal>
                                <Switch>
                                    <Route exact path="/" component={SignIn} />
                                    <Route exact path="/signup" component={SignUp} />
                                    <Route exact path="/register" component={Register} />
                                    <Route exact path="/password_reissue" component={PasswordReissue} />
                                    <Route exact path="/password_register" component={PasswordRegister} />
                                    <Route exact path="/change_email_register" component={ChangeEmailRegister} />
                                    <Route path="/client/edit" component={ClientEdit} />
                                    <Route exact path="/invite_new_user_register" component={InvitedUserRegister} />
                                    <Route exact path="/terms" component={Terms} />
                                    <AuthRoute exact path="/mypage" component={MyPage} />
                                    <AuthRoute exact path="/school/new_1" component={New_1} />
                                    <AuthRoute exact path="/school/new_2" component={New_2} />
                                    <AuthRoute exact path="/school/new_3" component={New_3} />
                                    <AuthRoute exact path="/school/new_result" component={New_result} />
                                    <AuthRoute exact path="/school/:schoolId" component={SchoolDetailIndex} />
                                    <AuthRoute exact path="/school/:schoolId/top" component={SchoolDetailTop} />
                                    <AuthRoute exact path="/school/:schoolId/course" component={SchoolDetailCourseList} />
                                    <AuthRoute exact path="/school/:schoolId/course/new" component={SchoolDetailCourseNew} />
                                    <AuthRoute exact path="/school/:schoolId/course/:courseId" component={SchoolDetailCourseDetail} />
                                    <AuthRoute exact path="/school/:schoolId/blog" component={SchoolDetailBlogList} />
                                    <AuthRoute exact path="/school/:schoolId/blog/new" component={SchoolDetailBlogNew} />
                                    <AuthRoute exact path="/school/:schoolId/blog/:blogId" component={SchoolDetailBlogDetail} />
                                    <AuthRoute exact path="/school/:schoolId/coupon" component={SchoolDetailCouponList} />
                                    <AuthRoute exact path="/school/:schoolId/coupon/new" component={SchoolDetailCouponNew} />
                                    <AuthRoute exact path="/school/:schoolId/coupon/:couponId" component={SchoolDetailCouponDetail} />
                                    <AuthRoute exact path="/school" component={SchoolListPage} />
                                    <AuthRoute exact path="/inquiry/:inquiryId" component={InquiriesDetail} />
                                    <AuthRoute exact path="/inquiry" component={InquiriesList} />
                                    <AuthRoute exact path="/team" component={TeamPage} />
                                    <AuthRoute exact path="/settings" component={SettingsPage} />
                                    <AuthRoute exact path="/settings/submission" component={SubmissionPage} />
                                    <AuthRoute exact path="/contracts" component={Contracts} />
                                    <AuthRoute exact path="/payment_history" component={PaymentHistory} />
                                </Switch>
                            </CommonModal>
                        </Loading>
                    </ScrollToTop>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
