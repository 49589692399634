export const schoolSubCategoryMaster: Array<Array<{ value: string; label: string }>> = [
    [
        // スポーツ・運動
        { value: '9', label: '水泳・スイミング' },
        { value: '10', label: 'サッカー' },
        { value: '11', label: '野球・ソフトボール' },
        { value: '12', label: 'テニス' },
        { value: '13', label: 'バスケットボール' },
        { value: '14', label: 'ゴルフ' },
        { value: '15', label: 'ラグビー・アメフト' },
        { value: '16', label: 'ボルダリング' },
        { value: '17', label: 'スケート・フィギアスケート' },
        { value: '18', label: 'スキー・スノーボード' },
        { value: '19', label: '運動総合・かけっこ' },
        { value: '20', label: '体操・新体操' },
        { value: '21', label: 'その他スポーツ' },
    ],
    [
        // プログラミング・科学
        { value: '22', label: 'ロボット' },
        { value: '23', label: 'プログラミング' },
        { value: '24', label: '科学・実験教室' },
        { value: '25', label: 'パソコン・IT' },
        { value: '26', label: 'その他プログラミング・科学' },
    ],
    [
        // 音楽
        { value: '27', label: '歌・合唱' },
        { value: '28', label: 'ピアノ' },
        { value: '29', label: 'バイオリン' },
        { value: '30', label: 'ギター' },
        { value: '31', label: 'リトミック' },
        { value: '32', label: '音楽教室（総合）' },
        { value: '33', label: 'その他音楽' },
    ],
    [
        // アート・芸術
        { value: '34', label: '絵画・造形' },
        { value: '35', label: '演劇・劇団' },
        { value: '36', label: 'ミュージカル' },
        { value: '37', label: '伝統文化' },
        { value: '38', label: 'その他芸術' },
    ],
    [
        // 武道・格闘技
        { value: '39', label: '柔道' },
        { value: '40', label: '空手' },
        { value: '41', label: '剣道' },
        { value: '42', label: '合気道' },
        { value: '43', label: 'ボクシング' },
        { value: '44', label: 'レスリング' },
        { value: '45', label: 'その他武道・格闘技' },
    ],
    [
        // ダンス・バレエ
        { value: '65', label: 'ダンス' },
        { value: '46', label: 'チアリーディング' },
        { value: '47', label: 'バレエ' },
        { value: '48', label: 'フラダンス' },
        { value: '49', label: '日本舞踊' },
        { value: '50', label: 'その他ダンス' },
    ],
    [
        // 学習・塾
        { value: '51', label: '小学校受験対策' },
        { value: '52', label: '幼児教室' },
        { value: '53', label: '英語・英会話' },
        { value: '54', label: 'そろばん' },
        { value: '55', label: '習字・書道' },
        { value: '56', label: '学習塾・通信教育' },
        { value: '57', label: 'その他学習' },
    ],
    [
        // その他
        { value: '58', label: '将棋・囲碁' },
        { value: '59', label: '自然体験' },
        { value: '60', label: 'ボーイスカウト' },
        { value: '61', label: '料理教室' },
        { value: '62', label: 'アフタースクール・学童' },
        { value: '63', label: '放課後デイサービス' },
        { value: '64', label: 'その他習い事' },
    ],
];
