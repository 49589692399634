import { SchoolsActionTypes } from '../../../actions/domain/schools';
import { NewSchoolInputType } from './type/New/NewSchoolInput.type';
import { newSchoolInputInitial } from './InitialState/New/newSchoolInputInitial';

export const newSchoolInput = (state = newSchoolInputInitial, action: any): NewSchoolInputType => {
    switch (action.type) {
        case SchoolsActionTypes.SET_NEW_SCHOOL_INPUT:
            return {
                ...state,
                input: action.payload.input,
            }
        case SchoolsActionTypes.SET_NEW_SCHOOL_INPUT_VALIDATE:
            return {
                ...state,
                validate: action.payload.validate,
            }
        default:
            return state;
    }
}