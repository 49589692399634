import { SchoolSubscriptionType } from '../type/SchoolSubscription.type';

export const schoolSubscriptionInitialize: SchoolSubscriptionType = {
    paymentModalStep: 1,
    paymentSecret: '',
    budgetLimit: null,
    paymentTargetSchool: null,
    newPaymentMethodId: null,
    cardNumberElement: null,
    paymentResult: {
        success: false,
        error: null,
    }
}