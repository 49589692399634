import { Action } from 'redux';

export const AppActionTypes = {
    SET_AUTH_TOKEN: 'app/set_auth_token',
    GET_AUTH_TOKEN: 'app/get_auth_token',
    SET_CLIENT_DATA_ENTERED: 'app/set_client_data_entered',
    LOGOUT: 'app/logout',
};

export const setAuthToken = (authToken: string): SetAuthToken => ({
    type: AppActionTypes.SET_AUTH_TOKEN,
    payload: { authToken },
});

export interface SetAuthToken extends Action {
    type: string;
    payload: { authToken: string };
}

export const getAuthToken = (email: string, password: string): GetAuthToken => ({
    type: AppActionTypes.GET_AUTH_TOKEN,
    payload: { email, password },
});

export interface GetAuthToken extends Action {
    type: string;
    payload: { email: string; password: string };
}

export const setClientDataEntered = (entered: boolean): SetClientDataEntered => ({
    type: AppActionTypes.SET_CLIENT_DATA_ENTERED,
    payload: { entered },
});

export interface SetClientDataEntered extends Action {
    type: string;
    payload: { entered: boolean };
}

export const logOut = () => ({
    type: AppActionTypes.LOGOUT,
});

export interface LogOut extends Action {
    type: string;
}

export const clearState = () => ({
    type: 'CLEAR_STATE',
});
