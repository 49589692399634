import React from 'react';
import styles from './css/style.module.scss';
import { WhiteBtn } from '../../Common/WhiteBtn';

import title from "./img/title.png"
import content from "./img/content.png"
import sp from "./img/sp.png"

export const ZeroInquiry: React.FC = () => (
    <>
        <div className={styles.borderBox}>
            <div className={styles.borderBox__title}>お問い合わせ機能のご案内</div>
            <div className={styles.borderBox__text}>
            有料プランの開始で「お問い合わせ機能」をご使用頂けます
            </div>
            <div className={styles.planImgWarp}>
                <div className={styles.planImgInner}>
                    <img className={`${styles.planImg} ${styles.title}`}  src={title} alt="有料プラン3つのメリット" />
                    <img className={`${styles.planImg} ${styles.content}`}  src={content} alt="有料プラン3つのメリット" />
                    <div className={styles.planImgText}>※従量課金とは別にシステム利用料として3,300円（税込）/月の固定費がかかります
                    </div>
                </div>
                <img className={`${styles.planImg} ${styles.sp}`} src={sp} alt="有料プラン3つのメリット" />
            </div>
            <WhiteBtn 
                text="有料プランを詳しく知る"
                style={null}
                handleClick={null}
                isLink
                path=""
            />
        </div>
    </>
)