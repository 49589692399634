import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from '../../../asset/css/newSchool.module.scss';
import { RootState } from '../../../redux/store';
import { NewSchoolHeader } from '../../../components/SchoolInput/NewSchoolHeader';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { postNewSchool, setNewSchoolInput, setNewSchoolInputValidate } from '../../../redux/actions/domain/schools/newSchool';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { CostAverageInput } from '../../../components/SchoolInput/CostAverageInput';
import { TargetAgeInput } from '../../../components/SchoolInput/TargetAgeInput';
import { YearMonthSelect } from '../../../components/SchoolInput/YearMonthSelect';
import { OwnerApplication } from '../../../components/MyPage/OwnerApplication';
import { TabBar } from '../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../components/Common/BreadCrumb';
import { CheckBoxInput, CheckBoxInputType } from '../../../components/Common/Input/CheckBoxInput';

export const New_3: React.FC = () => {
    const authToken = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const newSchoolInputStore = useSelector((state: RootState) => state.rootReducer.domain.schools);
    const dispatch = useDispatch();
    // 暫定ローディング
    const [loading, setLoading] = useState(false);

    // バリデーション ステップ1,2の入力内容が未完了
    if (
        [
            newSchoolInputStore.newSchoolInput.validate.name,
            newSchoolInputStore.newSchoolInput.validate.nameKana,
            newSchoolInputStore.newSchoolInput.validate.categoryId,
            newSchoolInputStore.newSchoolInput.validate.keywordIds,
            newSchoolInputStore.newSchoolInput.validate.classStyleIds,
            newSchoolInputStore.newSchoolInput.validate.tagIds,
            newSchoolInputStore.newSchoolInput.validate.nearestStation,
            newSchoolInputStore.newSchoolInput.validate.nearestBusstop,
            newSchoolInputStore.newSchoolInput.validate.prefectureId,
            newSchoolInputStore.newSchoolInput.validate.townId,
            newSchoolInputStore.newSchoolInput.validate.streetId,
            newSchoolInputStore.newSchoolInput.validate.addressDetail,
            newSchoolInputStore.newSchoolInput.validate.nearestStationText,
            newSchoolInputStore.newSchoolInput.validate.scheduleWeekSchedule,
        ].filter((flag) => !flag).length > 0
    ) {
        return <Redirect to="/school/new_1" />;
    }

    return (
        <>
            <Header />
            <OwnerApplication />
            <TabBar currentPage="/school" />
            <div className={styles.wrap}>
                <BreadCrumb
                    data={[
                        { text: '教室一覧', path: '/school' },
                        { text: '教室を新規作成', path: '/school/new_3' },
                    ]}
                />
                <NewSchoolHeader step={3} />
                <div className={styles.title}>基本情報</div>
                <div className={styles.inputWrap}>
                    <div className={styles.input}>
                        <CostAverageInput
                            defaultValue={{
                                from: newSchoolInputStore.newSchoolInput.input.costAverageFrom,
                                to: newSchoolInputStore.newSchoolInput.input.costAverageTo,
                            }}
                            defaultValidate={{
                                from: newSchoolInputStore.newSchoolInput.validate.costAverageFrom,
                                to: newSchoolInputStore.newSchoolInput.validate.costAverageTo,
                            }}
                            returnFunc={(data, validate) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        costAverageFrom: data.from,
                                        costAverageTo: data.to,
                                    })
                                );
                                dispatch(
                                    setNewSchoolInputValidate({
                                        ...newSchoolInputStore.newSchoolInput.validate,
                                        costAverageFrom: validate.from,
                                        costAverageTo: validate.to,
                                    })
                                );
                            }}
                        />
                    </div>

                    <div className={styles.input}>
                        <TargetAgeInput
                            defaultValue={newSchoolInputStore.newSchoolInput.input.targetAgeIds}
                            returnFunc={(data) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        targetAgeIds: data,
                                    })
                                );
                                dispatch(
                                    setNewSchoolInputValidate({
                                        ...newSchoolInputStore.newSchoolInput.validate,
                                        targetAgeIds: true,
                                    })
                                );
                            }}
                        />
                        <CheckBoxInput
                            label=""
                            name="targetAgeIdAdd"
                            require
                            data={[
                                {
                                    id: `targetAgeIdAdd_1`,
                                    value: '1',
                                    uncheckValue: '',
                                    label: '大学生',
                                },
                                {
                                    id: `targetAgeIdAdd_2`,
                                    value: '2',
                                    uncheckValue: '',
                                    label: '大人',
                                },
                            ]}
                            changeReturnFunc={(values) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        withTargetUniversity: values.some((v) => v === '1'),
                                        withTargetWorking: values.some((v) => v === '2'),
                                    })
                                );
                            }}
                            defaultValue={[
                                newSchoolInputStore.newSchoolInput.input.withTargetUniversity ? 1 : 0,
                                newSchoolInputStore.newSchoolInput.input.withTargetWorking ? 2 : 0,
                            ]}
                            buttonType={CheckBoxInputType.BUTTON}
                            annotation=""
                            displayTextModalData={null}
                        />
                    </div>

                    <div className={styles.input}>
                        <YearMonthSelect
                            defaultValue={newSchoolInputStore.newSchoolInput.input.establishmentDate}
                            returnFunc={(data) => {
                                dispatch(
                                    setNewSchoolInput({
                                        ...newSchoolInputStore.newSchoolInput.input,
                                        establishmentDate: data,
                                    })
                                );
                            }}
                        />
                    </div>

                    <div className={styles.input}>
                        <div className={styles.inputTextWrap}>
                            <TextInput
                                label="公式サイトURL"
                                name="homepageUrl"
                                id="homepageUrl"
                                require={false}
                                inputType="homepageUrl"
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            homepageUrl: text,
                                        })
                                    );
                                    dispatch(
                                        setNewSchoolInputValidate({
                                            ...newSchoolInputStore.newSchoolInput.validate,
                                            homepageUrl: validate,
                                        })
                                    );
                                }}
                                defaultInput={newSchoolInputStore.newSchoolInput.input.homepageUrl}
                                placeHolder={null}
                                minLength={null}
                                defaultInputValue={newSchoolInputStore.newSchoolInput.input.homepageUrl}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <div className={styles.input}>
                        <div className={styles.snsLabel}>公式SNS URL</div>
                        <div className={styles.snsInner}>
                            <TextInput
                                label="Twitter"
                                name="twitter"
                                id="twitter"
                                require={false}
                                inputType="text"
                                validateType={InputValidateTypeEnum.TWITTER_URL}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            twitterUrl: text,
                                        })
                                    );
                                    dispatch(
                                        setNewSchoolInputValidate({
                                            ...newSchoolInputStore.newSchoolInput.validate,
                                            twitterUrl: validate,
                                        })
                                    );
                                }}
                                defaultInput={newSchoolInputStore.newSchoolInput.input.twitterUrl}
                                placeHolder="https://twitter.com/********"
                                minLength={null}
                                defaultInputValue={newSchoolInputStore.newSchoolInput.input.twitterUrl}
                                disabled={false}
                            />
                            <TextInput
                                label="Instagram"
                                name="Instagram"
                                id="Instagram"
                                require={false}
                                inputType="text"
                                validateType={InputValidateTypeEnum.INSTAGRAM_URL}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            instagramUrl: text,
                                        })
                                    );
                                    dispatch(
                                        setNewSchoolInputValidate({
                                            ...newSchoolInputStore.newSchoolInput.validate,
                                            instagramUrl: validate,
                                        })
                                    );
                                }}
                                defaultInput={newSchoolInputStore.newSchoolInput.input.instagramUrl}
                                placeHolder="https://www.instagram.com/********/"
                                minLength={null}
                                defaultInputValue={newSchoolInputStore.newSchoolInput.input.instagramUrl}
                                disabled={false}
                            />
                            <TextInput
                                label="LINE@"
                                name="line"
                                id="line"
                                require={false}
                                inputType="text"
                                validateType={InputValidateTypeEnum.LINE_AT_URL}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            lineUrl: text,
                                        })
                                    );
                                    dispatch(
                                        setNewSchoolInputValidate({
                                            ...newSchoolInputStore.newSchoolInput.validate,
                                            lineUrl: validate,
                                        })
                                    );
                                }}
                                defaultInput={newSchoolInputStore.newSchoolInput.input.lineUrl}
                                placeHolder="http://line.me/ti/p/********"
                                minLength={null}
                                defaultInputValue={newSchoolInputStore.newSchoolInput.input.lineUrl}
                                disabled={false}
                            />
                            <TextInput
                                label="Facebook"
                                name="Facebook"
                                id="Facebook"
                                require={false}
                                inputType="text"
                                validateType={InputValidateTypeEnum.FACEBOOK_URL}
                                annotation=""
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(
                                        setNewSchoolInput({
                                            ...newSchoolInputStore.newSchoolInput.input,
                                            facebookUrl: text,
                                        })
                                    );
                                    dispatch(
                                        setNewSchoolInputValidate({
                                            ...newSchoolInputStore.newSchoolInput.validate,
                                            facebookUrl: validate,
                                        })
                                    );
                                }}
                                defaultInput={newSchoolInputStore.newSchoolInput.input.facebookUrl}
                                placeHolder="https://www.facebook.com/********"
                                minLength={null}
                                defaultInputValue={newSchoolInputStore.newSchoolInput.input.facebookUrl}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <div className={styles.buttonWrap}>
                        <GreenBtn
                            text={loading ? '送信中' : '次へ　3/ 3'}
                            style={
                                [
                                    newSchoolInputStore.newSchoolInput.validate.costAverageFrom,
                                    newSchoolInputStore.newSchoolInput.validate.costAverageTo,
                                    newSchoolInputStore.newSchoolInput.validate.targetAgeIds,
                                    newSchoolInputStore.newSchoolInput.validate.establishmentDate,
                                    newSchoolInputStore.newSchoolInput.validate.homepageUrl,
                                    newSchoolInputStore.newSchoolInput.validate.twitterUrl,
                                    newSchoolInputStore.newSchoolInput.validate.instagramUrl,
                                    newSchoolInputStore.newSchoolInput.validate.lineUrl,
                                    newSchoolInputStore.newSchoolInput.validate.facebookUrl,
                                ].filter((flag) => !flag).length > 0
                                    ? {
                                        background: '#c4c4c4',
                                        pointerEvents: 'none',
                                    }
                                    : {}
                            }
                            handleClick={() => {
                                if (loading) return;
                                setLoading(true);
                                dispatch(postNewSchool(authToken, newSchoolInputStore.newSchoolInput.input));
                            }}
                            isLink={false}
                            path=""
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
