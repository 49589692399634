import React from 'react';
import styles from './css/style.module.scss';
import { TextArea } from '../../Common/Input/TextArea';
import { InputValidateTypeEnum } from '../../Common/Input/TextInput/InputValidateType.enum';
import { TextInput } from '../../Common/Input/TextInput';

type CourseFeesInputProps = {
    returnFunc: (
        input: {
            membershipFee: number | null;
            annualFee: number | null;
            monthlyFee: number | null;
            textFee: number | null;
            feeRemark: string;
        },
        validate: {
            membershipFee: boolean;
            annualFee: boolean;
            monthlyFee: boolean;
            textFee: boolean;
            feeRemark: boolean;
        }
    ) => void;
    defaultInput: {
        membershipFee: number | null; // 入会費
        annualFee: number | null; // 年費用
        monthlyFee: number | null; // 月費用
        textFee: number | null; // テキスト費用
        feeRemark: string; // 費用説明
    };
    defaultValidate: {
        membershipFee: boolean;
        annualFee: boolean;
        monthlyFee: boolean;
        textFee: boolean;
        feeRemark: boolean;
    };
};

export const CourseFeesInput: React.FC<CourseFeesInputProps> = (props) => {
    const { returnFunc, defaultInput, defaultValidate } = props;

    return (
        <div className={styles.wrap}>
            <div className={styles.inputRow}>
                <div className={styles.inputColumn}>
                    入会費：
                    <div className={styles.inputTextWrap}>
                        <TextInput
                            label=""
                            name="membershipFee"
                            id="membershipFee"
                            require
                            inputType="number"
                            validateType={InputValidateTypeEnum.PRICE}
                            annotation=""
                            changeReturnFunc={(text: string, validate: boolean) => {
                                returnFunc(
                                    {
                                        ...defaultInput,
                                        membershipFee: Number(text),
                                    },
                                    {
                                        ...defaultValidate,
                                        membershipFee: validate,
                                    }
                                );
                            }}
                            defaultInput={defaultInput.membershipFee ? defaultInput.membershipFee.toString() : ''}
                            placeHolder="-"
                            minLength={1}
                            defaultInputValue={defaultInput.membershipFee ? defaultInput.membershipFee.toString() : ''}
                            disabled={false}
                        />
                    </div>
                    円
                </div>
                <div className={styles.inputColumn}>
                    <div className={styles.inputColumnSecondLabel}>月会費・月謝：</div>
                    <div className={styles.inputTextWrap}>
                        <TextInput
                            label=""
                            name="monthlyFee"
                            id="monthlyFee"
                            require
                            inputType="number"
                            validateType={InputValidateTypeEnum.PRICE}
                            annotation=""
                            changeReturnFunc={(text: string, validate: boolean) => {
                                returnFunc(
                                    {
                                        ...defaultInput,
                                        monthlyFee: Number(text),
                                    },
                                    {
                                        ...defaultValidate,
                                        monthlyFee: validate,
                                    }
                                );
                            }}
                            defaultInput={defaultInput.monthlyFee ? defaultInput.monthlyFee.toString() : ''}
                            placeHolder="-"
                            minLength={1}
                            defaultInputValue={defaultInput.monthlyFee ? defaultInput.monthlyFee.toString() : ''}
                            disabled={false}
                        />
                    </div>
                    円
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.inputColumn}>
                    年会費：
                    <div className={styles.inputTextWrap}>
                        <TextInput
                            label=""
                            name="annualFee"
                            id="annualFee"
                            require
                            inputType="number"
                            validateType={InputValidateTypeEnum.PRICE}
                            annotation=""
                            changeReturnFunc={(text: string, validate: boolean) => {
                                returnFunc(
                                    {
                                        ...defaultInput,
                                        annualFee: Number(text),
                                    },
                                    {
                                        ...defaultValidate,
                                        annualFee: validate,
                                    }
                                );
                            }}
                            defaultInput={defaultInput.annualFee ? defaultInput.annualFee.toString() : ''}
                            placeHolder="-"
                            minLength={1}
                            defaultInputValue={defaultInput.annualFee ? defaultInput.annualFee.toString() : ''}
                            disabled={false}
                        />
                    </div>
                    円
                </div>
                <div className={styles.inputColumn}>
                    <div className={styles.inputColumnSecondLabel}>教材費・その他費用：</div>
                    <div className={styles.inputTextWrap}>
                        <TextInput
                            label=""
                            name="textFee"
                            id="textFee"
                            require
                            inputType="number"
                            validateType={InputValidateTypeEnum.PRICE}
                            annotation=""
                            changeReturnFunc={(text: string, validate: boolean) => {
                                returnFunc(
                                    {
                                        ...defaultInput,
                                        textFee: Number(text),
                                    },
                                    {
                                        ...defaultValidate,
                                        textFee: validate,
                                    }
                                );
                            }}
                            defaultInput={defaultInput.textFee ? defaultInput.textFee.toString() : ''}
                            placeHolder="-"
                            minLength={1}
                            defaultInputValue={defaultInput.textFee ? defaultInput.textFee.toString() : ''}
                            disabled={false}
                        />
                    </div>
                    円
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.inputColumnTextArea}>
                    <div className={styles.inputColumnTextArea__title}>補足：</div>
                    <div className={styles.inputColumnTextArea__input}>
                        <TextArea
                            label=""
                            name="feeRemark"
                            id="feeRemark"
                            require={false}
                            validateType={InputValidateTypeEnum.TEXT}
                            annotation={null}
                            changeReturnFunc={(text: string, validate: boolean) => {
                                returnFunc(
                                    {
                                        ...defaultInput,
                                        feeRemark: text,
                                    },
                                    {
                                        ...defaultValidate,
                                        feeRemark: validate,
                                    }
                                );
                            }}
                            defaultInput={defaultInput.feeRemark}
                            placeHolder="当教室では月謝制ではなく、チケット制・ワンレッスン制（１回あたり8,000円/90分）のため、月謝はあくまでも目安となっています。"
                            forReview={false}
                            forInquiry={false}
                            countFlag={false}
                            maxLength={500}
                            minLength={null}
                            height={92}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
