import React, { VFC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { RootState } from '../../../redux/store';

export const AuthRoute: VFC<RouteProps> = ({ component: Component, ...rest }) => {
    // ログインが必要なページをchildrenに持ち、ログイン状態じゃなかったらリダイレクトをする
    const appSelector = (state: RootState) => state.rootReducer.app;
    const appState = useSelector(appSelector);
    const location = useLocation();

    // 未ログイン
    if (appState.authToken === '') {
        localStorage.setItem('lastLocation', location.pathname);
        return <Redirect to="/" />;
    }

    localStorage.removeItem('lastLocation');
    localStorage.removeItem('withLogout');

    // 初回クライアント未入力
    if (!appState.clientDataEntered) return <Redirect to='/client/edit' />

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route component={Component} {...rest} />

}
