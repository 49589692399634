import { Action } from 'redux';
import { CommonModalContentTypeEnum } from '../../reducers/ui/enum/CommonModalContentType.enum';

export const UiActionTypes = {
    SET_API_LOADING: 'ui/set_api_loading',
    SET_COMMON_MODAL_SHOW: 'ui/set_common_modal_show',
    SET_COMMON_MODAL_TITLE: 'ui/set_common_modal_title',
    SET_COMMON_MODAL_CONTENT_TYPE: 'ui/set_common_modal_content_type',
    SET_DISPLAY_TEXT_MODAL_TEXT: 'ui/set_display_text_modal_text',
};

export const setApiLoading = (loading: boolean): SetApiLoading => ({
    type: UiActionTypes.SET_API_LOADING,
    payload: { loading },
});

export interface SetApiLoading extends Action {
    type: string;
    payload: { loading: boolean };
}

export const setCommonModalShow = (show: boolean): SetCommonModalShow => ({
    type: UiActionTypes.SET_COMMON_MODAL_SHOW,
    payload: { show },
});

export interface SetCommonModalShow extends Action {
    type: string;
    payload: { show: boolean };
}

export const setCommonModalTitle = (title: string): SetCommonModalTitle => ({
    type: UiActionTypes.SET_COMMON_MODAL_TITLE,
    payload: { title },
});

export interface SetCommonModalTitle extends Action {
    type: string;
    payload: { title: string };
}

export const setCommonModalContentType = (contentType: CommonModalContentTypeEnum | null): SetCommonModalContentType => ({
    type: UiActionTypes.SET_COMMON_MODAL_CONTENT_TYPE,
    payload: { contentType },
});

export interface SetCommonModalContentType extends Action {
    type: string;
    payload: { contentType: CommonModalContentTypeEnum | null };
}

export const setDisplayTextModalText = (text: string): SetDisplayTextModalText => ({
    type: UiActionTypes.SET_DISPLAY_TEXT_MODAL_TEXT,
    payload: { text },
});

export interface SetDisplayTextModalText extends Action {
    type: string;
    payload: {text: string};
}