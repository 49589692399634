import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';
import { SchoolInquiryDataType } from '../../../util/type/SchoolInquiryData.type';
import { SchoolDetailData } from '../../../redux/reducers/domain/schools/type/SchoolDetailData.type';
import { targetAgeMaster } from '../../../util/const/Schools/targetAgeMaster';

type InquiryCardProps = {
    inquiryData: SchoolInquiryDataType;
    schoolData: Array<SchoolDetailData>;
};

export const InquiryCard: React.FC<InquiryCardProps> = (props: InquiryCardProps) => {
    const { inquiryData, schoolData } = props;
    const school = schoolData.find((v) => v.id === inquiryData.schoolId);

    if (school !== undefined) {
        return (
            <>
                <Link to={`/inquiry/${inquiryData.id}`} className={styles.inquiryCard}>
                    <div className={styles.inquiryCard__head}>
                        {school.imageUrls[0] && <img src={school.imageUrls[0]} alt="スクール画像" className={styles.inquiryCard__mainImg} />}
                        <div className={styles.inquiryCard__status}>新着</div>
                        <div
                            className={
                                school.imageUrls[0]
                                    ? styles.inquiryCard__textWrap
                                    : `${styles.inquiryCard__textWrap} ${styles['inquiryCard__textWrap--noImg']}`
                            }>
                            <div className={styles.inquiryCard__title}>{school.name}</div>
                            <div className={styles.inquiryCard__place}>
                                【{school.prefecture?.nameJp}】{school.town?.nameJp} | {school.schoolCategory?.text}
                            </div>
                        </div>
                    </div>
                    <div className={styles.inquiryCard__body}>
                        <div className={styles.inquiryCard__infoWrap}>
                            <div className={styles.inquiryCard__infoTitle}>日付</div>
                            <div className={styles.inquiryCard__infoText}>
                                {new Date(inquiryData.createdAt).getFullYear()}/{new Date(inquiryData.createdAt).getMonth()}/
                                {new Date(inquiryData.createdAt).getDate()}
                            </div>
                            <div className={`${styles.inquiryCard__infoTitle} ${styles['inquiryCard__infoTitle--long']}`}>申込者情報</div>
                            <div className={`${styles.inquiryCard__infoText} ${styles['inquiryCard__infoText--long']}`}>
                                {inquiryData.applicantName}/{inquiryData.applicantRelation === 1 && '父'}
                                {inquiryData.applicantRelation === 2 && '母'}
                                {inquiryData.applicantRelation === 99 && 'その他'}
                            </div>
                        </div>
                        <div className={styles.inquiryCard__infoWrap}>
                            <div className={styles.inquiryCard__infoTitle}>内容</div>
                            <div className={styles.inquiryCard__infoText}>
                                {inquiryData.type === 1 && '資料請求'}
                                {inquiryData.type === 2 && '見学、体験希望'}
                                {inquiryData.type === 3 && '申し込み'}
                                {inquiryData.type === 99 && 'その他問合せ'}
                            </div>
                            <div className={`${styles.inquiryCard__infoTitle} ${styles['inquiryCard__infoTitle--long']}`}>子供の情報</div>
                            <div className={`${styles.inquiryCard__infoText} ${styles['inquiryCard__infoText--long']}`}>
                                {inquiryData.childName}/{targetAgeMaster.find((d) => d.value === inquiryData.childTargetAgeId.toString())?.label}
                            </div>
                        </div>
                    </div>
                </Link>
            </>
        );
    }
    return <></>;
};
