import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { ContractsActionTypes } from '../redux/actions/domain/contracts';
import { setAuthToken, setClientDataEntered } from '../redux/actions/app';
import { getSettlements, setSettlements, setSettlementsCount } from '../redux/actions/domain/contracts/settlement';
import { SettlementData } from '../util/type/School/SettlementData.type';
import { ContractsApi } from '../util/api/contracts';

// 決済履歴一覧取得
function* getContractSettlements({ payload }: ReturnType<typeof getSettlements>) {
    try {
        const res: AxiosResponse<{
            data: Array<SettlementData>;
            count: number;
        }> = yield call(ContractsApi.getSettlements, payload.authToken, payload.limit, payload.page);
        yield put(setSettlements(res.data.data));
        yield put(setSettlementsCount(res.data.count));
    } catch (e) {
        const err = e as AxiosError;
        if (err.response) {
            const statusCode = err.response.status;
            const responseBody = err.response.data.message;
            if (statusCode === 401) {
                put(setAuthToken(''));
                return;
            }
            if (statusCode === 403 && responseBody === 'NotEnteredClient') {
                put(setClientDataEntered(false));
            }
        }
    }
}

export function* getContractSettlementsAsync() {
    yield takeEvery(ContractsActionTypes.GET_SETTLEMENT_LIST, getContractSettlements);
}
