import { SchoolsActionTypes } from '../../../actions/domain/schools';
import { schoolDetailDataInitial } from './InitialState/schoolDetailDataInitial';
import { CurrentSchoolDataType } from './type/CurrentSchoolData.type';

export const SchoolDetailData = (state: CurrentSchoolDataType = schoolDetailDataInitial, action: any) => {
    switch (action.type) {
        case SchoolsActionTypes.SET_SCHOOL_DETAIL:
            return {
                ...state,
                schoolDetailData: action.payload.schoolDetailData
            };

        case SchoolsActionTypes.SET_SCHOOL_DETAIL_BASE_EDIT_FLAG:
            return {
                ...state,
                baseEditFlag: action.payload.flag
            };

        case SchoolsActionTypes.SET_SCHOOL_DETAIL_ADVANCED_EDIT_FLAG:
            return {
                ...state,
                advancedEditFlag: action.payload.flag
            };

        case SchoolsActionTypes.SET_SCHOOL_TOP_NOTICE_EDIT_FLAG:
            return {
                ...state,
                noticeEditFlag: action.payload.flag
            };

        case SchoolsActionTypes.SET_SCHOOL_TOP_IMAGES_EDIT_FLAG:
            return {
                ...state,
                imagesEditFlag: action.payload.flag
            };

        case SchoolsActionTypes.SET_SCHOOL_TOP_QA_EDIT_FLAG:
            return {
                ...state,
                qaEditFlag: action.payload.flag
            };

        case SchoolsActionTypes.SET_SCHOOL_DETAIL_BASE_EDIT_DATA:
            return {
                ...state,
                schoolDetailEditData: action.payload.editData
            };

        case SchoolsActionTypes.SET_SCHOOL_DETAIL_BASE_EDIT_VALIDATE:
            return {
                ...state,
                schoolDetailEditValidate: action.payload.schoolDetailEditValidate
            }

        case SchoolsActionTypes.RESET_SCHOOL_EDIT_FLAG:
            return {
                ...state,
                baseEditFlag: false,
                advancedEditFlag: false,
                noticeEditFlag: false,
                imagesEditFlag: false,
                qaEditFlag: false,
            }

        case SchoolsActionTypes.SET_SCHOOL_TOP_IMAGES:
            return {
                ...state,
                schoolDetailEditImages: action.payload.schoolDetailEditImages
            }
        default:
            return state;
    }
};