import { SchoolDetailInstructorType } from './type/Instructor/SchoolDetailInstructor.type';
import { SchoolsActionTypes } from '../../../actions/domain/schools';
import { SchoolDetailInstructorInitial } from './InitialState/Instructor/schoolDetailInstructorInitial';

export const SchoolDetailInstructor = (state: SchoolDetailInstructorType = SchoolDetailInstructorInitial, action: any) => {
    switch (action.type) {
        case SchoolsActionTypes.SET_SCHOOL_INSTRUCTOR_LIST:
            return {
                ...state,
                list: action.payload.schoolInstructorListData
            };

        case SchoolsActionTypes.SET_SCHOOL_INSTRUCTOR_DETAIL:
            return {
                ...state,
                detail: action.payload.schoolInstructorDetailData
            };

        case SchoolsActionTypes.SET_SCHOOL_NEW_INSTRUCTOR_FLAG:
            return {
                ...state,
                newFlag: action.payload.flag
            };

        case SchoolsActionTypes.SET_SCHOOL_NEW_INSTRUCTOR_INPUT:
            return {
                ...state,
                new: action.payload.input
            };

        case SchoolsActionTypes.SET_SCHOOL_NEW_INSTRUCTOR_VALIDATE:
            return {
                ...state,
                newValidate: action.payload.validate
            };

        case SchoolsActionTypes.SET_SCHOOL_EDIT_INSTRUCTOR_INDEX:
            return {
                ...state,
                editIndex: action.payload.index
            };

        case SchoolsActionTypes.SET_SCHOOL_EDIT_INSTRUCTOR_INPUT:
            return {
                ...state,
                edit: action.payload.input
            };

        case SchoolsActionTypes.SET_SCHOOL_EDIT_INSTRUCTOR_VALIDATE:
            return {
                ...state,
                editValidate: action.payload.validate
            };


        default:
            return state;
    }
};