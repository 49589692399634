import { all } from 'redux-saga/effects';
import {
    getAuthTokenAsync,
    getClientDataAsync,
    postClientPreRegisterAsync,
    postClientRegisterCodeAsync,
    postPasswordRegisterAsync,
    postPasswordReissueOfferDataAsync,
    putClientDataAsync,
} from './clientSaga';
import { getClientInquiriesAsync, getClientSchoolsAsync } from './mypageSaga';
import {
    checkNewSchoolModalCheckAsync,
    getSchoolAsync,
    patchAllSchoolPublishAsync,
    patchAllSchoolUnPublishAsync,
    patchSchoolPublishAsync,
    patchSchoolUnPublishAsync,
    postCreateSchoolAsync,
    putSchoolAsync,
} from './School/top';
import {
    getSchoolCourseAsync,
    getSchoolCourseDetailAsync,
    patchSchoolCoursePublishAsync,
    patchSchoolCourseUnPublishAsync,
    postSchoolCourseAsync,
    putSchoolCourseAsync,
} from './School/course';
import {
    deleteSchoolInstructorAsync,
    getSchoolInstructorAsync,
    getSchoolInstructorDetailAsync,
    patchSchoolInstructorPublishAsync,
    patchSchoolInstructorUnPublishAsync,
    postSchoolInstructorAsync,
    putSchoolInstructorAsync,
} from './School/instructor';
import {
    getSchoolBlogAsync,
    getSchoolBlogDetailAsync,
    patchSchoolBlogPublishAsync,
    patchSchoolBlogUnPublishAsync,
    postSchoolBlogAsync,
    putSchoolBlogAsync,
} from './School/blog';
import {
    getSchoolCouponAsync,
    getSchoolCouponDetailAsync,
    patchSchoolCouponPublishAsync,
    patchSchoolCouponUnPublishAsync,
    postSchoolCouponAsync,
    putSchoolCouponAsync,
} from './School/coupon';
import { getInquiryAsync } from './inquirySaga';
import {
    deleteClientUsersAsync,
    fetchClientUsersAsync,
    patchClientUserInquiryAsync,
    postClientUserChangeEmailOfferAsync,
    postClientUserEmailApprovalAsync,
    postInvitedUserRegisterAsync,
    postInviteUserAsync,
    postToUpdateClientUserAsync,
} from './userSaga';
import { postClientDocumentAsync, postClientSchoolAuthAsync, putClientAsync } from './settingsSaga';
import {
    postCancelSchoolSubscriptionAsync,
    postClientPaymentSetupAsync,
    postPaymentMethodToStripeAsync,
    postSchoolSubscriptionAsync,
} from './paymentSaga';
import { logOutAsync } from './appSaga';
import { getContractSettlementsAsync } from './contractsSaga';

export default function* rootSaga() {
    yield all([
        getAuthTokenAsync(),
        postClientPreRegisterAsync(),
        postClientRegisterCodeAsync(),
        getClientDataAsync(),
        putClientDataAsync(),
        postPasswordReissueOfferDataAsync(),
        postPasswordRegisterAsync(),
        getClientSchoolsAsync(),
        getClientInquiriesAsync(),
        postCreateSchoolAsync(),
        getSchoolAsync(),
        putSchoolAsync(),
        getSchoolCourseAsync(),
        getSchoolCourseDetailAsync(),
        postSchoolCourseAsync(),
        patchSchoolCoursePublishAsync(),
        patchSchoolCourseUnPublishAsync(),
        putSchoolCourseAsync(),
        patchSchoolPublishAsync(),
        patchSchoolUnPublishAsync(),
        getSchoolInstructorAsync(),
        getSchoolInstructorDetailAsync(),
        postSchoolInstructorAsync(),
        patchSchoolInstructorPublishAsync(),
        patchSchoolInstructorUnPublishAsync(),
        putSchoolInstructorAsync(),
        deleteSchoolInstructorAsync(),
        getSchoolBlogAsync(),
        getSchoolBlogDetailAsync(),
        postSchoolBlogAsync(),
        patchSchoolBlogPublishAsync(),
        patchSchoolBlogUnPublishAsync(),
        putSchoolBlogAsync(),
        getSchoolCouponAsync(),
        getSchoolCouponDetailAsync(),
        postSchoolCouponAsync(),
        patchSchoolCouponPublishAsync(),
        patchSchoolCouponUnPublishAsync(),
        putSchoolCouponAsync(),
        getInquiryAsync(),
        postInviteUserAsync(),
        postInvitedUserRegisterAsync(),
        fetchClientUsersAsync(),
        deleteClientUsersAsync(),
        postToUpdateClientUserAsync(),
        patchClientUserInquiryAsync(),
        postClientUserChangeEmailOfferAsync(),
        postClientUserEmailApprovalAsync(),
        putClientAsync(),
        postClientDocumentAsync(),
        postClientSchoolAuthAsync(),
        postClientPaymentSetupAsync(),
        postPaymentMethodToStripeAsync(),
        postSchoolSubscriptionAsync(),
        logOutAsync(),
        postCancelSchoolSubscriptionAsync(),
        getContractSettlementsAsync(),
        patchAllSchoolPublishAsync(),
        patchAllSchoolUnPublishAsync(),
        checkNewSchoolModalCheckAsync(),
    ]);
}
