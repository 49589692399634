import React from 'react';
import { ClientUserType } from '../../../util/type/ClientUser.type';
import { UsersListRow } from '../UsersListRow';
import { ClientUserTypeEnum } from '../../../redux/reducers/domain/user/enum/ClientUserType.enum';
import styles from './css/style.module.scss';

type UsersListProps = {
    users: Array<ClientUserType>,
    myData: ClientUserType,
}

export const UsersList: React.FC<UsersListProps> = (props) => {
    const { users, myData} = props;

    return <ul className={styles.list}>
        <li className={styles.listTitle}>
            <div className={styles.listTitleUser}>ユーザー</div>
            <div className={styles.listTitleLastLogin}>最終ログイン</div>
            <div className={styles.listTitleRole}>アクセス制限</div>
            <div className={styles.listTitleAddDay}>追加日</div>
            <div className={styles.listTitleMail}><span>お問合せメール受信</span></div>
            <div className={styles.listTitleAction}>アクション</div>
        </li>
        {users.map((user, index) =>
            <UsersListRow
                key={`client_user_list_${user.uuid}`}
                user={user}
                myDataFlag={user.uuid === myData.uuid}
                editableFlag={myData.type !== ClientUserTypeEnum.User && user.uuid !== myData.uuid}
                deletableFlag={myData.type !== ClientUserTypeEnum.User && user.uuid !== myData.uuid}
                index={index}
            />)}
    </ul>
}