import { SchoolDetailCourseNewValidateType } from '../../type/Course/SchoolDetailCourseNewValidate.type';

export const schoolDetailCourseEditValidateInitial: SchoolDetailCourseNewValidateType = {
    name: true, // 名前
    description: true, // 説明
    paymentMethod: true, // 支払い説明
    timeRemarks: true, // 時間帯説明
    scheduleWeekSchedule: true, // 開校時間
    requirements: true, // 必要要綱
    membershipFee: true, // 会員費
    annualFee: true, // 年費用
    monthlyFee: true, // 月費用
    textFee: true, // テキスト費用
    feeRemark: true, // 費用説明
    timeMinutesFrom: true, // '授業最短時間（分）
    timeMinutesTo: true, // 授業最長時間（分）
    sortId: true, // ソートid
    targetAgeIds: true,
    instructorIds: true,
}