import { call, put, takeEvery } from 'redux-saga/effects';
import { ClientApi } from '../util/api/client';
import { SettingsActions } from '../redux/actions/domain/settings';
import { getClient } from '../redux/actions/domain/client/client';
import { putClientEdit } from '../redux/actions/domain/settings/editClient';
import { postDocuments, setSendDocumentsResult } from '../redux/actions/domain/settings/submission';
import { UserApi } from '../util/api/user';
import { postSchoolAuthUrl } from '../redux/actions/domain/settings/schoolAuth';

// クライアント状更新
function* putClient({ payload }: ReturnType<typeof putClientEdit>) {
    try {
        yield call(ClientApi.putClient, payload.authToken, payload.editInput);
        yield put(getClient(payload.authToken));
    } catch (e) {
        yield put(getClient(payload.authToken));
    }
}

export function* putClientAsync() {
    yield takeEvery(SettingsActions.PUT_CLIENT_EDIT, putClient);
}

// 本人確認書類提出
function* postClientDocument({ payload }: ReturnType<typeof postDocuments>) {
    try {
        yield call(UserApi.postClientDocument, payload.authToken, payload.document1, payload.document2, payload.document3, payload.note);
        yield put(setSendDocumentsResult({
            reason: '',
            flag: true,
        }));
    } catch (e) {
        yield put(setSendDocumentsResult({
            reason: 'error',
            flag: false,
        }));
    }
}

export function* postClientDocumentAsync() {
    yield takeEvery(SettingsActions.POST_DOCUMENTS, postClientDocument);
}


// 教室権限申請
function* postClientSchoolAuth({ payload }: ReturnType<typeof postSchoolAuthUrl>) {
    try {
        yield call(UserApi.postClientSchoolAuth, payload.authToken, payload.schoolUrl );
        yield put(getClient(payload.authToken));
    } catch (e) {
        // yield put(setSendDocumentsResult({
        //     reason: 'error',
        //     flag: false,
        // }));
    }
}

export function* postClientSchoolAuthAsync() {
    yield takeEvery(SettingsActions.POST_SCHOOL_AUTH_URL, postClientSchoolAuth);
}