import { AxiosResponse } from 'axios';
import qs from 'qs';
import { axiosBase } from './index';
import { GetSuggestAreaModeEnum } from '../enum/GetSuggestAreaMode.enum';
import { GetSuggestKeywordModeEnum } from '../enum/GetSuggestKeywordMode.enum';
import { TownData } from '../type/School/TownData.type';
import { StreetData } from '../type/School/StreetData.type';
import { CreateSchoolDataType } from '../type/CreateSchoolData.type';
import { SchoolDetailData } from '../../redux/reducers/domain/schools/type/SchoolDetailData.type';
import { SchoolCourseData } from '../type/School/SchoolCourseData.type';
import { SchoolInstructorDataType } from '../type/School/SchoolInstructorDataType';
import { SchoolDetailEditImagesType } from '../../redux/reducers/domain/schools/type/Top/SchoolDetailEditImages.type';
import { SchoolDetailCourseInputType } from '../../redux/reducers/domain/schools/type/Course/SchoolDetailCourseNew.type';
import { SchoolBlogData } from '../type/School/SchoolBlogData.type';
import { SchoolDetailBlogInputType } from '../../redux/reducers/domain/schools/type/Blog/SchoolDetailBlogInput.type';
import { SchoolCouponData } from '../type/School/SchoolCouponData.type';
import { SchoolDetailCouponInputType } from '../../redux/reducers/domain/schools/type/Coupon/SchoolDetailCouponInput.type';
import { SchoolDetailInstructorInputType } from '../../redux/reducers/domain/schools/type/Instructor/SchoolDetailInstructorNew.type';

export class SchoolsApi {
    // 新規登録
    static async postClientEntry(email: string, password: string, passwordConfirm: string) {
        return axiosBase.post('/client/entry', {
            email,
            password,
            password_confirm: passwordConfirm
        });
    }

    // エリアサジェスト
    static async getAreaSuggest(mode: GetSuggestAreaModeEnum, word: string): Promise<AxiosResponse<{
        prefectures: Array<{ id: number, nameJp: string }>,
        towns: Array<{ id: number, nameJp: string }>,
        streets: Array<{ id: number, nameJp: string }>,
        stations: Array<{ id: number, nameJp: string }>,
        busstops: Array<{ id: number, nameJp: string, corporationName: string }>
    }>> {
        return axiosBase.get('/school/suggest/area', {
            params: {
                mode,
                word
            }
        });
    }

    // キーワードサジェスト
    static async getKeywordSuggest(mode: GetSuggestKeywordModeEnum, word: string): Promise<AxiosResponse<{
        schoolKeywords: Array<{ id: number, text: string }>,
        schoolCategories: Array<{ id: number, text: string }>,
        schoolTags: Array<{ id: number, text: string }>,
        schoolTargetAges: Array<{ id: number, text: string }>,
    }>> {
        return axiosBase.get('/school/suggest/keyword', {
            params: {
                mode,
                word
            }
        });
    }

    // キーワード文字列取得
    static async getKeywordStrFromIds(ids: Array<string>): Promise<AxiosResponse<Array<{ id: number, text: string }>>> {
        return axiosBase.get('/school/attr/keywords', {
            params: {
                ids
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' })
        });
    }

    // 市区町村情報取得
    static async getTownData(prefectureId: string): Promise<AxiosResponse<Array<TownData>>> {
        return axiosBase.get('/town', {
            params: {
                prefectureId
            }
        });
    }

    // 大字町丁目情報取得
    static async getStreetData(townId: string, word: string, ids: Array<string> | null): Promise<AxiosResponse<Array<StreetData>>> {
        if (ids !== null) {
            return axiosBase.get('/street', {
                params: {
                    townId,
                    word,
                    ids
                },
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' })
            });
        }
        return axiosBase.get('/street', {
            params: {
                townId,
                word
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' })
        });

    }

    // スクール新規登録
    static async postCreateSchool(token: string, data: CreateSchoolDataType): Promise<AxiosResponse<null>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));

        return axiosBase.post('/school', formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // スクール詳細取得
    static async getSchool(token: string, schoolId: number): Promise<AxiosResponse<SchoolDetailData>> {
        return axiosBase.get(`/school/${schoolId}/admin`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクール情報更新
    static async putSchool(token: string, schoolId: string, data: CreateSchoolDataType, images?: SchoolDetailEditImagesType): Promise<AxiosResponse<null>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (images) {
            if (images.image0) formData.append('image0', images.image0);
            if (images.image1) formData.append('image1', images.image1);
            if (images.image2) formData.append('image2', images.image2);
            if (images.image3) formData.append('image3', images.image3);
            if (images.image4) formData.append('image4', images.image4);
            if (images.image5) formData.append('image5', images.image5);
            if (images.image6) formData.append('image6', images.image6);
            if (images.image7) formData.append('image7', images.image7);
            if (images.image8) formData.append('image8', images.image8);
            if (images.image9) formData.append('image9', images.image9);
        }
        return axiosBase.put(`/school/${schoolId}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクール公開
    static async patchPublishSchool(token: string, schoolId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/${schoolId}/publish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクール非公開
    static async patchUnPublishSchool(token: string, schoolId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/${schoolId}/unpublish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコースリスト取得
    static async getSchoolCourseList(token: string, schoolId: number): Promise<AxiosResponse<Array<SchoolCourseData>>> {
        return axiosBase.get(`/school/${schoolId}/admin/course`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコース詳細取得
    static async getSchoolCourseDetail(token: string, courseId: number): Promise<AxiosResponse<SchoolCourseData>> {
        return axiosBase.get(`/school/course/${courseId}/admin`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコース新規登録
    static async postCreateSchoolCourse(token: string, schoolId: string, data: SchoolDetailCourseInputType): Promise<AxiosResponse<SchoolCourseData>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (data.image) formData.append('image', data.image);

        return axiosBase.post(`/school/${schoolId}/course`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // スクールコース公開
    static async patchPublishSchoolCourse(token: string, courseId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/course/${courseId}/publish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコース非公開
    static async patchUnPublishSchoolCourse(token: string, courseId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/course/${courseId}/unpublish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコース更新
    static async putSchoolCourse(token: string, courseId: string, data: SchoolDetailCourseInputType): Promise<AxiosResponse<SchoolCourseData>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (data.image) formData.append('image', data.image);

        return axiosBase.put(`/school/course/${courseId}`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // スクール講師リスト取得
    static async getSchoolInstructorList(token: string, schoolId: number): Promise<AxiosResponse<{
        count: number,
        data: Array<SchoolInstructorDataType>,
    }>> {
        return axiosBase.get(`/school/${schoolId}/admin/instructor`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクール講師詳細取得
    static async getSchoolInstructorDetail(token: string, instructorId: number): Promise<AxiosResponse<SchoolInstructorDataType>> {
        return axiosBase.get(`/school/instructor/${instructorId}/admin`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコース講師新規登録
    static async postCreateSchoolInstructor(token: string, schoolId: string, data: SchoolDetailInstructorInputType): Promise<AxiosResponse<SchoolCourseData>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (data.image) formData.append('image', data.image);

        return axiosBase.post(`/school/${schoolId}/instructor`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // スクールコース講師公開
    static async patchPublishSchoolInstructor(token: string, instructorId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/instructor/${instructorId}/publish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコース講師非公開
    static async patchUnPublishSchoolInstructor(token: string, instructorId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/instructor/${instructorId}/unpublish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコース講師更新
    static async putSchoolInstructor(token: string, instructorId: string, data: SchoolDetailInstructorInputType): Promise<AxiosResponse<SchoolCourseData>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (data.image) formData.append('image', data.image);

        return axiosBase.put(`/school/instructor/${instructorId}`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // スクールコース講師削除
    static async deleteSchoolInstructor(token: string, instructorId: string): Promise<AxiosResponse<null>> {
        return axiosBase.delete(`/school/instructor/${instructorId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }


    // スクールブログリスト取得
    static async getSchoolBlogList(token: string, schoolId: number, limit: number, page: number): Promise<AxiosResponse<{
        data: Array<SchoolBlogData>,
        count: number
    }>> {
        return axiosBase.get(`/school/${schoolId}/admin/blog`, {
            params: {
                limit,
                page
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールブログ詳細取得
    static async getSchoolBlogDetail(token: string, blogId: number): Promise<AxiosResponse<SchoolBlogData>> {
        return axiosBase.get(`/school/blog/${blogId}/admin`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールブログ新規登録
    static async postCreateSchoolBlog(token: string, schoolId: string, data: SchoolDetailBlogInputType): Promise<AxiosResponse<SchoolBlogData>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (data.image) formData.append('image', data.image);

        return axiosBase.post(`/school/${schoolId}/blog`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // スクールブログ公開
    static async patchPublishSchoolBlog(token: string, blogId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/blog/${blogId}/publish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールブログ非公開
    static async patchUnPublishSchoolBlog(token: string, blogId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/blog/${blogId}/unpublish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールコース更新
    static async putSchoolBlog(token: string, blogId: string, data: SchoolDetailBlogInputType): Promise<AxiosResponse<SchoolBlogData>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (data.image) formData.append('image', data.image);

        return axiosBase.put(`/school/blog/${blogId}`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // スクールクーポンリスト取得
    static async getSchoolCouponList(token: string, schoolId: number, limit: number, page: number): Promise<AxiosResponse<{
        data: Array<SchoolCouponData>,
        count: number
    }>> {
        return axiosBase.get(`/school/${schoolId}/admin/coupon`, {
            params: {
                limit,
                page
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールクーポン詳細取得
    static async getSchoolCouponDetail(token: string, couponId: number): Promise<AxiosResponse<SchoolCouponData>> {
        return axiosBase.get(`/school/coupon/${couponId}/admin`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールクーポン新規登録
    static async postCreateSchoolCoupon(token: string, schoolId: string, data: SchoolDetailCouponInputType): Promise<AxiosResponse<SchoolCouponData>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (data.image) formData.append('image', data.image);

        return axiosBase.post(`/school/${schoolId}/coupon`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

    // スクールクーポン公開
    static async patchPublishSchoolCoupon(token: string, couponId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/coupon/${couponId}/publish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // スクールクーポン非公開
    static async patchUnPublishSchoolCoupon(token: string, couponId: string): Promise<AxiosResponse<null>> {
        return axiosBase.patch(`/school/coupon/${couponId}/unpublish`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }


    // スクールクーポン更新
    static async putSchoolCoupon(token: string, couponId: string, data: SchoolDetailCouponInputType): Promise<AxiosResponse<SchoolCouponData>> {
        const formData = new FormData();
        formData.append('params', JSON.stringify(data));
        if (data.image) formData.append('image', data.image);

        return axiosBase.put(`/school/coupon/${couponId}`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    }

}
