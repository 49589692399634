import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
    getSchoolInstructorDetail,
    getSchoolInstructorList,
    patchSchoolInstructorPublish,
    patchSchoolInstructorUnPublish, postEditSchoolInstructor,
    postNewSchoolInstructor,
    setSchoolEditInstructorIndex,
    setSchoolInstructorDetail,
    setSchoolInstructorList,
    deleteSchoolInstructor,
} from '../../redux/actions/domain/schools/schoolinstructor';
import { SchoolInstructorDataType } from '../../util/type/School/SchoolInstructorDataType';
import { SchoolsApi } from '../../util/api/schools';
import { SchoolsActionTypes } from '../../redux/actions/domain/schools';

// スクール講師一覧取得
function* fetchSchoolInstructorList({ payload }: ReturnType<typeof getSchoolInstructorList>) {
    try {
        const res: AxiosResponse<{
            count: number,
            data: Array<SchoolInstructorDataType>,
        }> = yield call(SchoolsApi.getSchoolInstructorList, payload.token, payload.schoolId);
        yield put(setSchoolInstructorList(res.data.data));
    } catch (e) {
        // TODO 404
        yield put(setSchoolInstructorList(null));
    }
}

export function* getSchoolInstructorAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_INSTRUCTOR_LIST, fetchSchoolInstructorList);
}

// スクール講師詳細取得
function* fetchSchoolInstructorDetail({ payload }: ReturnType<typeof getSchoolInstructorDetail>) {
    try {
        const res: AxiosResponse<SchoolInstructorDataType> = yield call(SchoolsApi.getSchoolInstructorDetail, payload.token, payload.instructorId);
        yield put(setSchoolInstructorDetail(res.data));
    } catch (e) {
        // TODO 404
        yield put(setSchoolInstructorDetail(null));
    }
}

export function* getSchoolInstructorDetailAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_INSTRUCTOR_DETAIL, fetchSchoolInstructorDetail);
}

// スクール講師新規登録
function* postSchoolNewInstructor({ payload }: ReturnType<typeof postNewSchoolInstructor>) {
    try {
        yield call(SchoolsApi.postCreateSchoolInstructor, payload.token, payload.schoolId, payload.data);
        yield put(getSchoolInstructorList(payload.token, Number(payload.schoolId)));
        yield put(setSchoolEditInstructorIndex(null));
    } catch (e) {
        yield put(setSchoolInstructorList([]));
    }
}

export function* postSchoolInstructorAsync() {
    yield takeEvery(SchoolsActionTypes.POST_NEW_SCHOOL_INSTRUCTOR, postSchoolNewInstructor);
}

// スクール講師公開
function* patchSchoolDetailInstructorPublish({ payload }: ReturnType<typeof patchSchoolInstructorPublish>) {
    try {
        yield call(SchoolsApi.patchPublishSchoolInstructor, payload.authToken, payload.instructorId);
        yield put(getSchoolInstructorList(payload.authToken, Number(payload.schoolId)));
    } catch (e) {
        yield put(setSchoolInstructorList([]));
    }
}

export function* patchSchoolInstructorPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_INSTRUCTOR_PUBLISH, patchSchoolDetailInstructorPublish);
}

// スクール講師非公開
function* patchSchoolDetailInstructorUnPublish({ payload }: ReturnType<typeof patchSchoolInstructorUnPublish>) {
    try {
        yield call(SchoolsApi.patchUnPublishSchoolInstructor, payload.authToken, payload.instructorId);
        yield put(getSchoolInstructorList(payload.authToken, Number(payload.schoolId)));
    } catch (e) {
        yield put(setSchoolInstructorList([]));
    }
}

export function* patchSchoolInstructorUnPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_INSTRUCTOR_PUBLISH, patchSchoolDetailInstructorUnPublish);
}

// スクール講師情報更新
function* putSchoolInstructor({ payload }: ReturnType<typeof postEditSchoolInstructor>) {
    try {
        yield call(SchoolsApi.putSchoolInstructor, payload.token, payload.instructorId, payload.data,);
        yield call(SchoolsApi.patchUnPublishSchoolInstructor, payload.token, payload.instructorId);
        yield put(getSchoolInstructorList(payload.token, Number(payload.schoolId)));
    } catch (e) {
        yield call(SchoolsApi.patchUnPublishSchoolInstructor, payload.token, payload.instructorId);
        yield put(getSchoolInstructorList(payload.token, Number(payload.schoolId)));
    }
}

export function* putSchoolInstructorAsync() {
    yield takeEvery(SchoolsActionTypes.POST_EDIT_SCHOOL_INSTRUCTOR, putSchoolInstructor);
}

// スクール講師削除
function* deleteSchoolInstructorId({ payload }: ReturnType<typeof deleteSchoolInstructor>) {
    try {
        yield call(SchoolsApi.deleteSchoolInstructor, payload.authToken, payload.instructorId);
        yield put(getSchoolInstructorList(payload.authToken, Number(payload.schoolId)));
    } catch (e) {
        yield call(SchoolsApi.patchUnPublishSchoolInstructor, payload.authToken, payload.instructorId);
        yield put(getSchoolInstructorList(payload.authToken, Number(payload.schoolId)));
    }
}

export function* deleteSchoolInstructorAsync() {
    yield takeEvery(SchoolsActionTypes.DELETE_SCHOOL_INSTRUCTOR, deleteSchoolInstructorId);
}