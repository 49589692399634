import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    getSchoolCouponDetail,
    getSchoolCouponList,
    patchSchoolCouponPublish,
    patchSchoolCouponUnPublish,
    postNewSchoolCoupon,
    putSchoolCouponEditData,
    setSchoolCouponDetail,
    setSchoolCouponEditData,
    setSchoolCouponEditFlag,
    setSchoolCouponList,
    setSchoolCouponListCount,
    setSchoolNewCouponInput,
    setSchoolNewCouponValidate,
} from '../../redux/actions/domain/schools/schoolCoupon';
import { SchoolCouponData } from '../../util/type/School/SchoolCouponData.type';
import { SchoolsApi } from '../../util/api/schools';
import { SchoolsActionTypes } from '../../redux/actions/domain/schools';
import { schoolCouponTolCouponInputData } from '../../redux/reducers/domain/schools/converter/schoolCouponTolCouponInputData';
import { SCHOOL_COUPON_COUNT } from '../../util/const/schoolCouponCount';
import { schoolDetailCouponValidateInitial } from '../../redux/reducers/domain/schools/InitialState/Coupon/schoolDetailCouponValidateInitial';
import { schoolDetailCouponInputInitial } from '../../redux/reducers/domain/schools/InitialState/Coupon/schoolDetailCouponInputInitial';

// スクールクーポン一覧取得
function* fetchSchoolCouponList({ payload }: ReturnType<typeof getSchoolCouponList>) {
    try {
        const res: AxiosResponse<{
            data: Array<SchoolCouponData>;
            count: number;
        }> = yield call(SchoolsApi.getSchoolCouponList, payload.token, payload.schoolId, payload.limit, payload.page);
        yield put(setSchoolCouponListCount(res.data.count));
        yield put(setSchoolCouponList(res.data.data));
    } catch (e) {
        // TODO 404
        yield put(setSchoolCouponList(null));
    }
}

export function* getSchoolCouponAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_COUPON_LIST, fetchSchoolCouponList);
}

// スクールクーポン詳細取得
function* fetchSchoolCouponDetail({ payload }: ReturnType<typeof getSchoolCouponDetail>) {
    try {
        const res: AxiosResponse<SchoolCouponData> = yield call(SchoolsApi.getSchoolCouponDetail, payload.token, payload.couponId);
        yield put(setSchoolCouponDetail(res.data));
        yield put(setSchoolCouponEditData(schoolCouponTolCouponInputData(res.data)));
    } catch (e) {
        // TODO 404
        yield put(setSchoolCouponDetail(null));
    }
}

export function* getSchoolCouponDetailAsync() {
    yield takeEvery(SchoolsActionTypes.GET_SCHOOL_COUPON_DETAIL, fetchSchoolCouponDetail);
}

// スクールクーポン新規登録
function* postSchoolNewCoupon({ payload }: ReturnType<typeof postNewSchoolCoupon>) {
    try {
        const res: AxiosResponse<SchoolCouponData> = yield call(SchoolsApi.postCreateSchoolCoupon, payload.token, payload.schoolId, payload.data);
        yield put(setSchoolCouponDetail(res.data));
        yield put(setSchoolNewCouponInput(schoolDetailCouponInputInitial));
        yield put(setSchoolNewCouponValidate(schoolDetailCouponValidateInitial));
        if (payload.withPublish) {
            yield put(patchSchoolCouponPublish(payload.token, payload.schoolId, res.data.id.toString(), true));
        }
        yield put(push(`/school/${payload.schoolId}/coupon`));
    } catch (e) {
        // TODO 404
        yield put(setSchoolCouponDetail(null));
    }
}

export function* postSchoolCouponAsync() {
    yield takeEvery(SchoolsActionTypes.POST_NEW_SCHOOL_COUPON, postSchoolNewCoupon);
}

// スクールクーポン公開
function* patchSchoolDetailCouponPublish({ payload }: ReturnType<typeof patchSchoolCouponPublish>) {
    try {
        yield call(SchoolsApi.patchPublishSchoolCoupon, payload.authToken, payload.couponId);
        // レスポンスは使用しない 最新取得
        if (payload.inList) {
            yield put(getSchoolCouponList(payload.authToken, Number(payload.schoolId), SCHOOL_COUPON_COUNT, 0));
        } else {
            yield put(getSchoolCouponDetail(payload.authToken, Number(payload.couponId)));
        }
    } catch (e) {
        yield put(setSchoolCouponDetail(null));
    }
}

export function* patchSchoolCouponPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_SCHOOL_COUPON_PUBLISH, patchSchoolDetailCouponPublish);
}

// スクールクーポン非公開
function* patchSchoolDetailCouponUnPublish({ payload }: ReturnType<typeof patchSchoolCouponUnPublish>) {
    try {
        yield call(SchoolsApi.patchUnPublishSchoolCoupon, payload.authToken, payload.couponId);
        // レスポンスは使用しない 最新取得
        if (payload.inList) {
            yield put(getSchoolCouponList(payload.authToken, Number(payload.schoolId), SCHOOL_COUPON_COUNT, 0));
        } else {
            yield put(getSchoolCouponDetail(payload.authToken, Number(payload.couponId)));
        }
    } catch (e) {
        yield put(setSchoolCouponDetail(null));
    }
}

export function* patchSchoolCouponUnPublishAsync() {
    yield takeEvery(SchoolsActionTypes.PATCH_SCHOOL_COUPON_UN_PUBLISH, patchSchoolDetailCouponUnPublish);
}

// スクールクーポン情報更新
function* putSchoolCoupon({ payload }: ReturnType<typeof putSchoolCouponEditData>) {
    try {
        yield call(SchoolsApi.putSchoolCoupon, payload.token, payload.couponId, payload.data);
        // レスポンスは使用しない 最新取得
        yield put(getSchoolCouponDetail(payload.token, Number(payload.couponId)));
        yield put(setSchoolCouponEditFlag(false));
    } catch (e) {
        yield put(setSchoolCouponDetail(null));
        yield put(setSchoolCouponEditFlag(false));
    }
}

export function* putSchoolCouponAsync() {
    yield takeEvery(SchoolsActionTypes.PUT_SCHOOL_COUPON_DATA, putSchoolCoupon);
}
