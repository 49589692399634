import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/style.module.scss';
import { SCHOOL_PAGE_COUNT } from '../../../util/const/schoolPageCount';
import { PAYMENT_HISTORY_PAGE_COUNT } from '../../../util/const/paymentHistoryPageCount';


export enum TargetPage {
    SCHOOL = 1,
    PAYMENT_HISTORY,
}

// 暫定
type PageNationProps = {
    page: number;
    dataLength: number;
    path: string,
    targetPage: TargetPage
};

export const PageNation: React.FC<PageNationProps> = (props: PageNationProps) => {
    const { page, dataLength, path, targetPage} = props;
    let pageCount=0
    if(targetPage === TargetPage.SCHOOL){
        pageCount=SCHOOL_PAGE_COUNT
    }else{
        pageCount=PAYMENT_HISTORY_PAGE_COUNT
    }

    return (
        <div className={styles.pageNation}>
            <Link to={`${path}?page=${page - 1}`}
                className={`${styles.pageNation__prev} ${page === 0 && styles.deactivate}`} />
            1 / {Math.floor(dataLength / pageCount) + 1}
            <Link to={`${path}?page=${page + 1}`}
                className={`${styles.pageNation__next} ${page >= Math.floor(dataLength / pageCount) && styles.deactivate}`} />
        </div>
    );
};