import { SchoolsActionTypes } from '../../../actions/domain/schools';
import { SchoolDetailData } from './type/SchoolDetailData.type';

export const newSchoolResult = (state: SchoolDetailData | null = null, action: any): SchoolDetailData | null => {
    switch (action.type) {
        case SchoolsActionTypes.SET_NEW_SCHOOL_RESULT:
            return action.payload.newSchoolData;
        default:
            return state;
    }
}