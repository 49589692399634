import React from 'react';
import {SchoolDetailTopQaCard} from './SchoolDetailTopQaCard';
import styles from './css/style.module.scss';

type SchoolDetailTopQaProps = {
    qa: Array<{
        question: string;
        answer: string;
    }>;
};

export const SchoolDetailTopQa: React.FC<SchoolDetailTopQaProps> = (props: SchoolDetailTopQaProps) => {
    const {qa} = props;
    return(
        <>
            <section className={styles.schoolDetailTopQa}>
                {qa.map((data) => (
                    <SchoolDetailTopQaCard qa={data} key={data.question}/>
                ))}
            </section>
        </>
    )}