export const passwordRegisterInitial = {
    registerCode: '',
    password: '',
    passwordValidate: false,
    passwordConfirm: '',
    passwordConfirmValidate: false,
    result: {
        flag: false,
        reason: '',
    },
};
