import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as queryString from 'querystring';
import { SchoolDetailParamTypes } from '../type/SchoolDetaillParam.type';
import { Header } from '../../../../components/Common/Header';
import { OwnerApplication } from '../../../../components/MyPage/OwnerApplication';
import { TabBar } from '../../../../components/MyPage/TabBar';
import { BreadCrumb } from '../../../../components/Common/BreadCrumb';
import { Footer } from '../../../../components/Common/Footer';
import { getSchoolDetail } from '../../../../redux/actions/domain/schools/schoolDetail';
import { RootState } from '../../../../redux/store';
import { CurrentSchoolDataType } from '../../../../redux/reducers/domain/schools/type/CurrentSchoolData.type';
import styles from '../../../../asset/css/schoolCommon.module.scss';
import { SchoolHeader } from '../../../../components/Schools/SchoolHeader';
import { SchoolTabBar } from '../../../../components/Schools/SchoolTabBar';
import { SchoolPageExplanation } from '../../../../components/Schools/SchoolPageExplanation';
import { SchoolH3 } from '../../../../components/Schools/SchoolH3';
import { SchoolBlogCard } from '../../../../components/Schools/SchoolBlogCard';
import { ShowMoreBtn } from '../../../../components/Common/ShowMoreBtn';
import { PageNation, TargetPage } from '../../../../components/Common/PageNation';
import { SchoolDetailBlogType } from '../../../../redux/reducers/domain/schools/type/Blog/SchoolDetailBlog.type';
import { getSchoolBlogList } from '../../../../redux/actions/domain/schools/schoolBlog';
import { SCHOOL_BLOG_COUNT } from '../../../../util/const/schoolBlogCount';

export const SchoolDetailBlogList: React.FC = () => {
    const { schoolId } = useParams<SchoolDetailParamTypes>();
    const authToken: string = useSelector((state: RootState) => state.rootReducer.app.authToken);
    const schoolStore: CurrentSchoolDataType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailData);
    const schoolBlogStore: SchoolDetailBlogType = useSelector((state: RootState) => state.rootReducer.domain.schools.SchoolDetailBlog);
    const dispatch = useDispatch();
    const history = useHistory()
    const {search} = useLocation();
    const query = queryString.parse(search.replace('?', ''));
    const page = query.page ? Number(query.page) : 0;

    useEffect(() => {
        // 一旦リセット
        dispatch(getSchoolDetail(authToken, Number(schoolId)));
        dispatch(getSchoolBlogList(authToken, Number(schoolId), SCHOOL_BLOG_COUNT, page,));
    }, [authToken, dispatch, schoolId, page]);

    if (schoolStore.schoolDetailData !== null
    ) {
        return (<>
            <Header />
            <OwnerApplication />
            <TabBar currentPage='/school' />
            <div className={styles.schoolContainer}>
                <BreadCrumb data={[{ text: '教室一覧', path: '/school' },{ text: `${schoolStore.schoolDetailData.name}`, path: `/school/${schoolId}/blog` }]}  />
                <SchoolHeader data={schoolStore.schoolDetailData} />
                <SchoolTabBar currentPage={`/school/${schoolId}/blog`} schoolId={schoolId} />
                <SchoolPageExplanation title='ブログ' textDom={<>ブログの記事を追加、編集します。</>} btnText="＋ 記事を新規作成" path={`/school/${schoolId}/blog/new`}/>
                <SchoolH3 editable={!schoolStore.noticeEditFlag} otherEditPlace={null}
                    ButtonFunc={null} text='記事一覧' isShow={null}/>
                {schoolBlogStore.list && schoolBlogStore.list.length > 0 && (
                    <div>
                        {schoolBlogStore.list.map(d =>
                            <SchoolBlogCard key={`blog_id_${d.id}`} schoolId={schoolId} schoolData={d} showStatus={d.showStatus}/>)}
                    </div>
                )}

                {schoolBlogStore.list && schoolBlogStore.list.length > 0 && (
                    <>
                        {page < Math.floor(schoolBlogStore.list.length / SCHOOL_BLOG_COUNT) && (
                            <ShowMoreBtn
                                text={`次の${SCHOOL_BLOG_COUNT}件を見る`}
                                onClick={() => history.push(`/school/${schoolId}/blog/?page=${page + 1}`)}
                            />
                        )}
                        <PageNation page={page} dataLength={schoolBlogStore.listCount} path={`/school/${schoolId}/blog`} targetPage={TargetPage.SCHOOL}/>
                    </>
                )}

            </div>
            <Footer />
        </>);
    }
    return <>
        <Header />
        <OwnerApplication />
        <TabBar currentPage='/school' />
        <div className={styles.schoolContainer}>
            <BreadCrumb data={[{ text: '教室一覧', path: '/school' }, { text: '教室名', path: '' }]} />
        </div>
        <Footer />
    </>;
};
