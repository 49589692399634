import { call, put, takeEvery } from 'redux-saga/effects';
import { SetupIntentResult } from '@stripe/stripe-js';
import { AxiosResponse } from 'axios';
import {
    postCancelSubscription,
    postPaymentMethodToStripe,
    postPaymentSetup,
    postStartSubscription,
    setNewPaymentMethodId,
    setPaymentResult,
    setPaymentSecret,
} from '../redux/actions/domain/payment/schoolSubscription';
import { PaymentApi } from '../util/api/payment';
import { PaymentActionTypes } from '../redux/actions/domain/payment';
import { getClient } from '../redux/actions/domain/client/client';
import { setApiLoading } from '../redux/actions/ui';
import { PaymentResultErrorTypeEnum } from '../redux/reducers/domain/payment/enum/PaymentResultErrorType.enum';

// stripe secret取得
function* postClientPaymentSetup({ payload }: ReturnType<typeof postPaymentSetup>) {
    try {
        const res: AxiosResponse<{
            secret: string;
        }> = yield call(PaymentApi.postClientPaymentSetup, payload.authToken);
        yield put(setPaymentSecret(res.data.secret));
    } catch (e) {
        yield put(getClient(''));
    }
}

export function* postClientPaymentSetupAsync() {
    yield takeEvery(PaymentActionTypes.POST_PAYMENT_SET_UP, postClientPaymentSetup);
}

// stripeへpaymentMethod(カード情報)を登録する、決済をする場合
function* postPaymentMethod({ payload }: ReturnType<typeof postPaymentMethodToStripe>) {
    yield put(setApiLoading(true));
    if (payload.cardElement) {
        // stripeへクレジットカードを登録
        const result: SetupIntentResult = yield call(payload.stripe.confirmCardSetup, payload.paymentSecret, {
            payment_method: {
                card: payload.cardElement,
            },
        });
        if (result.error) {
            yield put(
                setPaymentResult({
                    success: false,
                    error: {
                        type: PaymentResultErrorTypeEnum.STRIPE_CARD_SUBMIT_ERROR,
                        text: 'ネットワークエラー　使用するカードを変更するか、時間をおいて再度申し込みをお願いいたします。',
                    },
                })
            );
            yield put(setApiLoading(false));
            return;
        }
        try {
            // apiへ登録する
            if (result.setupIntent.payment_method) {
                yield put(setNewPaymentMethodId(result.setupIntent.payment_method));
                yield call(PaymentApi.putUpdateClientPaymentMethodId, payload.authToken, result.setupIntent.payment_method);
            }
        } catch (e) {
            // payment Method id 登録失敗
            yield put(
                setPaymentResult({
                    success: false,
                    error: {
                        type: PaymentResultErrorTypeEnum.PAYMENT_METHOD_ID_SEND_ERROR,
                        text: 'ネットワークエラー　時間をおいてから、再度申し込みをお願いいたします。',
                    },
                })
            );
            yield put(setNewPaymentMethodId(null));
            yield put(setApiLoading(false));
        }
        yield put(getClient(payload.authToken));
    }
    try {
        if (!payload.withStartSubscription && payload.schoolId) {
            yield call(PaymentApi.postClientSubscription, payload.authToken, payload.schoolId, payload.budgetLimit);
        }
        // 成功
        yield put(
            setPaymentResult({
                success: true,
                error: null,
            })
        );
        yield put(setApiLoading(false));
    } catch (e) {
        // サブスクリプション登録失敗
        yield put(
            setPaymentResult({
                success: false,
                error: {
                    type: PaymentResultErrorTypeEnum.POST_SUBSCRIPTION_ERROR,
                    text: 'ネットワークエラー　時間をおいてから、再度申し込みをお願いいたします。',
                },
            })
        );
        yield put(setNewPaymentMethodId(null));
        yield put(setApiLoading(false));
    }
}

export function* postPaymentMethodToStripeAsync() {
    yield takeEvery(PaymentActionTypes.POST_PAYMENT_METHOD_TO_STRIPE, postPaymentMethod);
}

// サブスクリプションを登録する
function* postSchoolSubscription({ payload }: ReturnType<typeof postStartSubscription>) {
    try {
        yield call(PaymentApi.postClientSubscription, payload.authToken, payload.schoolId, payload.budgetLimit);
        // 成功
        yield put(
            setPaymentResult({
                success: true,
                error: null,
            })
        );
    } catch (e) {
        yield put(
            setPaymentResult({
                success: false,
                error: {
                    type: PaymentResultErrorTypeEnum.POST_SUBSCRIPTION_ERROR,
                    text: 'ネットワークエラー、時間をおいて再度申し込みをお願いいたします。',
                },
            })
        );
    }
}

export function* postSchoolSubscriptionAsync() {
    yield takeEvery(PaymentActionTypes.POST_START_SUBSCRIPTION, postSchoolSubscription);
}

// サブスクリプションをキャンセル状態にする
function* postCancelSchoolSubscription({ payload }: ReturnType<typeof postCancelSubscription>) {
    try {
        yield call(PaymentApi.postCancelClientSubscription, payload.authToken, payload.schoolId);
        // 成功
    } catch (e) {
        // error
    }
}

export function* postCancelSchoolSubscriptionAsync() {
    yield takeEvery(PaymentActionTypes.POST_CANCEL_SUBSCRIPTION, postCancelSchoolSubscription);
}
