import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'react-loader-spinner';
import { RootState } from '../../../redux/store';
import styles from './css/style.module.scss';

export const Loading:React.FC = (props) => {
    const {children} = props;
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);

    return <>
        {children}
        <div className={`${styles.loadingBG} ${loadingSelector ? styles.active : ''}`}/>
        <div className={`${styles.spinnerWrap} ${loadingSelector ? styles.active : ''}`}>
            <Spinner
                type="Oval"
                color="#17a076"
                height={80}
                width={80}
            />
        </div>
    </>
}