import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from '../../../asset/css/accountCommon.module.scss';
import clientStyles from '../../../asset/css/client.module.scss';
import { RootState } from '../../../redux/store';
import { Header } from '../../../components/Common/Header';
import { Footer } from '../../../components/Common/Footer';
import { GreenBtn } from '../../../components/Common/GreenBtn';
import { RadioInput } from '../../../components/Common/Input/RadioInput';
import { TextInput } from '../../../components/Common/Input/TextInput';
import { ClientType } from '../../../redux/reducers/domain/client/enum/clientType.enum';
import { InputValidateTypeEnum } from '../../../components/Common/Input/TextInput/InputValidateType.enum';
import {
    putClientInput,
    setClientInputAddress,
    setClientInputAddressValidate,
    setClientInputClientType,
    setClientInputClientTypeValidate,
    setClientInputName,
    setClientInputNameValidate,
    setClientInputPhone,
    setClientInputPhoneValidate,
    setClientInputRepresentativeName,
    setClientInputRepresentativeNameValidate,
} from '../../../redux/actions/domain/client/client';

export const ClientEdit: React.FC = () => {
    const appSelector = (state: RootState) => state.rootReducer.app;
    const appState = useSelector(appSelector);
    const clientInputSelector = (state: RootState) => state.rootReducer.domain.client.clientInput;
    const clientInputState = useSelector(clientInputSelector);
    const loadingSelector: boolean = useSelector((state: RootState) => state.rootReducer.ui.apiLoading);

    const dispatch = useDispatch();

    // AuthRouteでの判定で無限ループを回避するためここでログイン状態を確認する
    if (appState.authToken === '') return <Redirect to="/" />;

    if (clientInputState.result.flag) return <Redirect to="/mypage" />;

    return (
        <>
            <Header />
            <div className={styles.authContainer}>
                <h1>クライアント情報入力</h1>

                <div className={clientStyles.grayBg}>
                    <RadioInput
                        label="個人・法人を選択してください"
                        require
                        name="clientInput_clientType"
                        data={[
                            {
                                id: 'clientInput_clientType_personal',
                                value: 2,
                                label: '個人',
                            },
                            {
                                id: 'clientInput_clientType_enterprise',
                                value: 1,
                                label: '法人',
                            },
                        ]}
                        changeReturnFunc={(text: string, validate: boolean) => {
                            dispatch(setClientInputClientType(Number(text)));
                            dispatch(setClientInputClientTypeValidate(validate));
                            // 選択内容によって、法人名のバリデーションを判定する
                        }}
                        value={clientInputState.clientType ? clientInputState.clientType.toString() : null}
                    />
                    {clientInputState.clientType === ClientType.Enterprise && (
                        <div className={clientStyles.inputWrapBorder}>
                            <TextInput
                                label="法人名"
                                name="clientInput_representativeName"
                                id="clientInput_representativeName_id"
                                require={clientInputState.clientType === ClientType.Enterprise}
                                inputType="text"
                                validateType={InputValidateTypeEnum.TEXT}
                                annotation="※法人の場合のみ、ご記入ください"
                                changeReturnFunc={(text: string, validate: boolean) => {
                                    dispatch(setClientInputRepresentativeName(text));
                                    dispatch(setClientInputRepresentativeNameValidate(validate));
                                }}
                                defaultInput={clientInputState.representativeName}
                                placeHolder="法人名を記入"
                                minLength={0}
                                defaultInputValue={clientInputState.representativeName}
                                // disabled={clientInputState.clientType === ClientType.Personal}
                                disabled={false}
                            />
                        </div>
                    )}
                </div>
                <div className={clientStyles.whiteBg}>
                    <TextInput
                        label={clientInputState.clientType === ClientType.Enterprise ? 'ご担当者さまのお名前' : 'お名前'}
                        name="clientInput_name"
                        id="clientInput_name_id"
                        require
                        inputType="text"
                        validateType={InputValidateTypeEnum.TEXT}
                        annotation=""
                        changeReturnFunc={(text: string, validate: boolean) => {
                            dispatch(setClientInputName(text));
                            dispatch(setClientInputNameValidate(validate));
                        }}
                        defaultInput={clientInputState.name}
                        placeHolder="織田　太郎"
                        minLength={0}
                        defaultInputValue={clientInputState.name}
                        disabled={false}
                    />
                </div>
                <div className={clientStyles.grayBg}>
                    <TextInput
                        label="お電話番号"
                        name="clientInput_phone"
                        id="clientInput_phone_id"
                        require
                        inputType="text"
                        validateType={InputValidateTypeEnum.TEL}
                        annotation="※ハイフンなし、半角数字で入力"
                        changeReturnFunc={(text: string, validate: boolean) => {
                            dispatch(setClientInputPhone(text));
                            dispatch(setClientInputPhoneValidate(validate));
                        }}
                        defaultInput={clientInputState.phone}
                        placeHolder="0312345678"
                        minLength={0}
                        defaultInputValue={clientInputState.phone}
                        disabled={false}
                    />
                </div>
                <div className={clientStyles.whiteBg}>
                    <TextInput
                        label="ご住所"
                        name="clientInput_address"
                        id="clientInput_address_id"
                        require
                        inputType="text"
                        validateType={InputValidateTypeEnum.TEXT}
                        annotation="アカウント情報のご住所を入力"
                        changeReturnFunc={(text: string, validate: boolean) => {
                            dispatch(setClientInputAddress(text));
                            dispatch(setClientInputAddressValidate(validate));
                        }}
                        defaultInput={clientInputState.address}
                        placeHolder="東京都渋谷区代々木1-2-3　ビル名13F"
                        minLength={0}
                        defaultInputValue={clientInputState.address}
                        disabled={false}
                    />
                </div>
                <GreenBtn
                    text={loadingSelector ? '送信中' : 'アカウント情報を登録'}
                    style={
                        [
                            clientInputState.clientTypeValidate,
                            clientInputState.nameValidate,
                            clientInputState.representativeName,
                            clientInputState.phoneValidate,
                            clientInputState.addressValidate,
                        ].find((flag) => !flag)
                            ? { pointerEvents: 'none', opacity: 0.3 }
                            : {}
                    }
                    handleClick={() => {
                        if (loadingSelector) return;
                        dispatch(putClientInput(appState.authToken, clientInputState));
                    }}
                    isLink={false}
                    path=""
                />
            </div>
            <Footer />
        </>
    );
};
