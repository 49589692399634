import { Action } from 'redux';
import { SchoolsActionTypes } from './index';
import { NewSchoolInputDataType } from '../../../reducers/domain/schools/type/New/NewSchoolInputData.type';
import { NewSchoolInputValidateType } from '../../../reducers/domain/schools/type/New/NewSchoolInputValidate.type';
import { CreateSchoolDataType } from '../../../../util/type/CreateSchoolData.type';
import { SchoolDetailData } from '../../../reducers/domain/schools/type/SchoolDetailData.type';

export const setNewSchoolInput = (input: NewSchoolInputDataType): SetNewSchoolInput => ({
    type: SchoolsActionTypes.SET_NEW_SCHOOL_INPUT,
    payload: { input },
});

export interface SetNewSchoolInput extends Action {
    type: string;
    payload: {
        input: NewSchoolInputDataType;
    };
}

export const setNewSchoolInputValidate = (validate: NewSchoolInputValidateType): SetNewSchoolInputValidate => ({
    type: SchoolsActionTypes.SET_NEW_SCHOOL_INPUT_VALIDATE,
    payload: { validate },
});

export interface SetNewSchoolInputValidate extends Action {
    type: string;
    payload: {
        validate: NewSchoolInputValidateType;
    };
}

export const postNewSchool = (token: string, data: CreateSchoolDataType): PostNewSchool => ({
    type: SchoolsActionTypes.POST_NEW_SCHOOL,
    payload: { data, token },
});

export interface PostNewSchool extends Action {
    type: string;
    payload: {
        data: CreateSchoolDataType;
        token: string;
    };
}

export const setNewSchoolResult = (newSchoolData: SchoolDetailData | null) => ({
    type: SchoolsActionTypes.SET_NEW_SCHOOL_RESULT,
    payload: { newSchoolData },
});

export interface SetNewSchoolResult extends Action {
    type: string;
    payload: {
        newSchoolData: SchoolDetailData;
    };
}

export const checkNewSchoolModalCheck = (authToken: string): CheckNewSchoolModalCheck => ({
    type: SchoolsActionTypes.CHECK_NEW_SCHOOL_MODAL_CHECK,
    payload: { authToken },
});

export interface CheckNewSchoolModalCheck extends Action {
    type: string;
    payload: {
        authToken: string;
    };
}
