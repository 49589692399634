import React, { useCallback, useState } from 'react';
import { SchoolsApi } from '../../../../util/api/schools';
import { GetSuggestKeywordModeEnum } from '../../../../util/enum/GetSuggestKeywordMode.enum';

export const useKeywordPicker = (): [{
    selectData: Array<{ value: number, label: string }>,
    searchText: string,
    keywords: Array<{ id: number, text: string }>,
}, {
    setSelectData: React.Dispatch<React.SetStateAction<{value: number, label: string}[]>>,
    setSearchText: React.Dispatch<React.SetStateAction<string>>,
    getKeywordSuggest: (keyword: string) => Promise<void>,

}]=> {
    // 選択情報
    const [selectData, setSelectData] = useState<Array<{ value: number, label: string }>>([]);
    // 検索テキスト
    const [searchText, setSearchText] = useState('');
    // keyword
    const [keywords, setKeywords] = useState<Array<{ id: number, text: string }>>([]);
    // キーワードを検索
    const getKeywordSuggest = useCallback(async (keyword: string) => {
        try {
            const res = await SchoolsApi.getKeywordSuggest(GetSuggestKeywordModeEnum.Keyword, keyword);
            const newKeyWords: Array<{ id: number, text: string }> = [];
            res.data.schoolKeywords.map(d => {
                newKeyWords.push({
                    id: d.id,
                    text: d.text
                });
                return false;
            });
            setKeywords(newKeyWords);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }, []);

    return [
        {
            selectData,
            searchText,
            keywords,
        }, {
            setSelectData,
            setSearchText,
            getKeywordSuggest,
        }
    ]
}